import { Endpoint } from 'states/services/types';

export const METRICS_MRR_DOWNGRADE_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/mrr-downgrade/chart',
  isPrivate: true
};

export const METRICS_MRR_DOWNGRADE_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/mrr-downgrade/breakdown',
  isPrivate: true
};
