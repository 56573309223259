/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Jul 21st 2021, 8:19:07 pm
 *
 * *****************************************************
 */

/**
 * Format key/id/token using the following format: no space character included. The function must be used in conjuction with deformatKeyOrID function
 * @param {string} value Verififcation key/id/token value
 * @returns {string | undefined} Formatted key/id/token
 */
export const formatKeyOrID = (value: string): string => {
  if (!value) return value;
  return value.replace(/ /g, '');
};
/**
 * Format verififcation code using the following format: no space character included. The function must be used in conjuction with formatKeyOrID function
 * @param {string} value Verififcation key/id/token value
 * @returns {string | undefined} Formatted key/id/token
 */
export const deformatKeyOrID = (value: string): string => {
  if (!value) return value;
  return value.replace(/ /g, '');
};
