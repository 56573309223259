/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Aug 30th 2021, 10:10:32 am
 *
 * *****************************************************
 */

import { Icon as PolarisIcon } from '@shopify/polaris';
import { CircleTickMajor, CircleCancelMajor } from '@shopify/polaris-icons';

import { Common } from 'states/services/metrics/models';

import { Container, Process, Item, Done, Wait, Tail, IconWrapper, Content, Title, Number, Failed } from './styles';

type Props = {
  progress: Common.MetricProgress;
};

const ProgressBar = ({ progress: { data } }: Props) => {
  return (
    <Container>
      {data &&
        data.map((step, index) => {
          const {
            status,
            name,
            figure: { total, current }
          } = step;
          let StatusBlock = null;
          let icon = null;
          switch (status) {
            case 'NEW':
              StatusBlock = Wait;
              icon = <Number>{index + 1}</Number>;
              break;
            case 'IN_QUEUE':
              StatusBlock = Process;
              icon = <Number>{index + 1}</Number>;
              break;
            case 'DONE':
              StatusBlock = Done;
              icon = <PolarisIcon source={CircleTickMajor} color="success" />;
              break;
            default:
              StatusBlock = Failed;
              icon = <PolarisIcon source={CircleCancelMajor} color="warning" />;
          }
          return (
            <Item key={index.toString()}>
              <StatusBlock>
                <Tail />
                <IconWrapper>{icon}</IconWrapper>
                <Content>
                  <Title>{name}</Title>
                  {status === 'FAILED' ? (
                    <div>Failed</div>
                  ) : (
                    <div>{total !== null ? `${total ? ((current / total) * 100).toFixed(0) : '-'} %` : `${current} event`}</div>
                  )}
                </Content>
              </StatusBlock>
            </Item>
          );
        })}
    </Container>
  );
};

export default ProgressBar;
