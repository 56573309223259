/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 26th 2021, 10:51:39 am
 *
 * *****************************************************
 */

import { Endpoint } from 'states/services/types';

export const WORKSPACE_BASIC_INFO_ENDPOINT: Endpoint = {
  url: '/base/common/workspace-info',
  isPrivate: true
};
export const WORKSPACE_SETTING_ENDPOINT: Endpoint = {
  url: '/base/workspace/settings',
  isPrivate: true
};
