/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Jul 29th 2021, 9:23:43 am
 *
 * *****************************************************
 */

import { RootState } from 'states/types';

/**
 * Redux selectors - Project list slice - Select total count of projects
 */
export const selectTotalCount = (state: RootState) => state.projects.totalCount;
