/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Aug 9th 2021, 8:30:31 am
 *
 * *****************************************************
 */

import styled from 'styled-components';

export const DefaultButtonContainer = styled.div`
  display: block;
  @media (max-width: 34.25em) {
    display: none;
  }
`;

export const CompactButtonContainer = styled.div`
  display: none;
  @media (max-width: 34.25em) {
    display: block;
  }
`;
