/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Sat, Jul 24th 2021, 5:11:02 pm
 *
 * *****************************************************
 */

import WorkspaceDashboard from 'features/workspace-dashboard/views/pages/WorkspaceDashboard';
import { RouteDef } from 'utils/routes';

import * as workspaceDashboardPaths from './paths';

/**
 * Route definitions for workspace dashboard page group
 */
const workspaceDashboardRouteDefs: RouteDef[] = [
  {
    path: workspaceDashboardPaths.DEFAULT,
    exact: true,
    component: WorkspaceDashboard,
    isPrivate: true
  }
];

export default workspaceDashboardRouteDefs;
