/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Sat, Jul 24th 2021, 10:43:50 pm
 *
 * *****************************************************
 */

import React, { useCallback, useState } from 'react';

import { ActionList, Popover } from '@shopify/polaris';
import { LogOutMinor } from '@shopify/polaris-icons';

import { ReactComponent as CaretDownIcon } from 'assets/images/caretDown.svg';
import { AUTH_TYPE } from 'constants/api';
import { LOG_OUT_ENDPOINT } from 'states/services/auth/endpoints';
import { BASE_ENDPOINT } from 'states/services/endpoints';
import { selectCurrentUser } from 'states/slices/auth/selectors';
import { CurrentUser } from 'states/slices/auth/types';
import { useAuth, useAppSelector } from 'utils/hooks';
import { syncLogout } from 'utils/log-out';
import { redirectToSignInPage } from 'utils/redirect';

import { Container } from './styles';
import UserMenuAvatar from './UserMenuAvatar';

/**
 * User menu of top bar
 * It usually locates to the right of the top bar
 */
const UserMenu = () => {
  const [popoverActive, setPopoverActive] = useState<boolean>(false);
  const currentUser = useAppSelector(selectCurrentUser) as CurrentUser;
  const { jwtToken } = useAuth();

  const togglePopoverActive = useCallback(() => setPopoverActive(prevState => !prevState), []);

  const handleLogout = useCallback(async () => {
    try {
      // Call logout API
      await fetch(`${BASE_ENDPOINT.url}${LOG_OUT_ENDPOINT.url}`, {
        credentials: 'include',
        headers: {
          authorization: `${AUTH_TYPE} ${jwtToken}`
        }
      });
    } finally {
      // Trigger sync logout
      await syncLogout();
      // Redirect to sign in page
      redirectToSignInPage();
    }
  }, [jwtToken]);

  if (!currentUser) return null;

  const activator = (
    <div className="Polaris-TopBar-Menu__ActivatorWrapper">
      <button type="button" className="Polaris-Button" onClick={togglePopoverActive}>
        <UserMenuAvatar name={currentUser.fullName} source={currentUser.avatar} />
        <CaretDownIcon />
      </button>
    </div>
  );

  return (
    <Container>
      <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
        <ActionList items={[{ content: 'Log out', icon: LogOutMinor, onAction: handleLogout }]} />
      </Popover>
    </Container>
  );
};

export default UserMenu;
