/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Aug 9th 2021, 8:30:21 am
 *
 * *****************************************************
 */

import { Button, Icon } from '@shopify/polaris';
import { FilterMajor } from '@shopify/polaris-icons';

import { CompactButtonContainer, DefaultButtonContainer } from './styles';

type Props = {
  isExpanded: boolean;
  onClick: () => void;
};

const FilterAndSortButton = ({ isExpanded, onClick }: Props): JSX.Element => {
  return (
    <>
      <DefaultButtonContainer>
        <Button plain monochrome removeUnderline disclosure={isExpanded ? 'up' : 'down'} onClick={onClick}>
          Filter & Sort
        </Button>
      </DefaultButtonContainer>
      <CompactButtonContainer>
        <Button icon={<Icon source={FilterMajor} color="base" />} onClick={onClick} />
      </CompactButtonContainer>
    </>
  );
};

export default FilterAndSortButton;
