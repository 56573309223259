/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 27th 2021, 02:53:44 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';

export const PickerContainer = styled.div`
  max-width: 53.2rem;
  @media (max-width: 64em) {
    width: 100%;
  }
  margin-bottom: 1.6rem;
`;

export const FooterActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  gap: 1rem;
  @media (max-width: 50em) {
    flex-direction: column;
    grid-gap: 1rem;
  }

  & .Polaris-Button .Polaris-Button__Text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: 30em) {
      max-width: 20rem;
    }
  }
`;

export const ComparedBlock = styled.div`
  margin: auto 0;
`;

export const TimezoneContainer = styled.div`
  margin-bottom: 2.2rem;
`;

export const Title = styled.div`
  ${focus2Texts.website.heading['H5 - 14px']}
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  gap: 4rem;
  margin-top: 0.8rem;
`;

export const DateRangeContainer = styled.div`
  & .Polaris-Labelled__LabelWrapper {
    margin-bottom: 0.8rem;
  }
  margin-bottom: 1.6rem;
`;

export const CheckBoxContainer = styled.div<{ isChecked: boolean }>`
  padding-top: 2rem;
  border-top: 0.1rem solid ${focus2Colors.border.subdued};
  margin-bottom: ${({ isChecked }) => (isChecked ? '0.8rem' : '0.4rem')};
`;
