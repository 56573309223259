/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Oct 7th 2021, 7:57:36 am
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';

export const HeaderContainer = styled.div`
  padding: 2rem;
  box-shadow: inset 0px -1px 0px ${focus2Colors.divider.bottom};
  & > div.Polaris-Filters {
    display: none;
  }

  @media (max-width: 35em) {
    & > div.Polaris-Filters {
      display: block;
      margin-top: 1rem;
      width: 100%;
    }
  }
`;

export const CardHeader = styled.div<{ isAdditional: boolean }>`
  justify-content: space-between;
  h2 {
    ${({ isAdditional }) => (isAdditional ? focus2Texts.website.heading['H4 - 16px'] : focus2Texts.website.heading['H3 - 20px'])}
  }
  display: flex;
  align-items: center;
`;

export const Action = styled.div`
  display: flex;
  gap: 1.6rem;

  & > div.Polaris-Filters {
    width: 40rem;
  }

  @media (max-width: 50em) {
    & > div.Polaris-Filters {
      width: 20rem !important;
    }
  }

  @media (max-width: 35em) {
    & > div.Polaris-Filters {
      display: none;
    }
  }
`;

export const Value = styled.div`
  ${focus2Texts.website.heading['H5 - 14px']}
`;

export const ColorText = styled.div<{ value: any }>`
  ${focus2Texts.website.heading['H5 - 14px']}
  ${({ value }) =>
    value > 0 &&
    `
    color: ${focus2Colors.text.positive};
  `}
  ${({ value }) =>
    value < 0 &&
    `
    color: ${focus2Colors.text.negative};
  `}
`;

export const SubValue = styled.div`
  ${focus2Texts.website['Body 2 - 14px']}
  color: ${focus2Colors.text.subdued};
`;

export const Content = styled.div<{ columnContentTypes: ('text' | 'numeric')[] }>`
  width: 100%;
  th,
  td {
    border-bottom-color: ${focus2Colors.divider.bottom};
  }

  tbody {
    tr > *:first-child {
      padding-left: 2rem;
    }

    tr > *:last-child {
      padding-right: 2rem;
    }
  }

  ${({ columnContentTypes }) =>
    columnContentTypes
      .map((colType, index) => {
        return `thead > tr:nth-child(2) > *:nth-child(${index + 1}) {
            text-align: ${colType === 'text' ? 'left' : 'right'};
          }`;
      })
      .join('')}

  thead > tr:first-child > *:first-child:not(.Polaris-DataTable__Cell--sortable) {
    padding-left: 2rem;
  }
  thead > tr:first-child > *:first-child.Polaris-DataTable__Cell--sortable {
    padding-left: 0.4rem;
  }
  thead > tr:first-child > *:last-child:not(.Polaris-DataTable__Cell--sortable) {
    padding-right: 2rem;
  }
  thead > tr:first-child > *:last-child.Polaris-DataTable__Cell--sortable {
    padding-right: 0.4rem;
  }

  thead > tr:nth-child(2) > *:first-child {
    padding-left: 2rem;
  }
  thead > tr:nth-child(2) > *:last-child {
    padding-right: 2rem;
  }

  .Polaris-DataTable__Heading .Polaris-DataTable__Icon {
    opacity: 1;
  }

  .Polaris-DataTable__Footer {
    background-color: ${focus2Colors.surface.normal.disabled};
    color: ${focus2Colors.text.disabled};
  }

  .Polaris-DataTable__Cell--total {
    background-color: ${focus2Colors.surface.normal.disabled};
  }
`;

export const PaginationContainer = styled.div`
  padding: 1.2rem 0;
  display: flex;
  justify-content: center;
  background-color: ${focus2Colors.surface.normal.disabled};
  border-bottom-left-radius: var(--p-border-radius-wide, 3px);
  border-bottom-right-radius: var(--p-border-radius-wide, 3px);
`;

export const ButtonContainer = styled.span`
  .Polaris-Button__Text {
    max-width: 20rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: 24.6875em) {
    .Polaris-Button__Text {
      max-width: 10rem;
    }
  }

  @media (max-width: 22em) {
    .Polaris-Button__Text {
      max-width: 6rem;
    }
  }
`;
