/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 20th 2021, 2:37:44 pm
 *
 * *****************************************************
 */

import { createGlobalStyle } from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import texts from 'constants/texts';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%;
    font-family: Sans-Serif;
  }

  h1, h2 {
    font-family: SF Pro Display;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    ${texts.body['Body 1 - 14px - R']}
    background-color: ${focus2Colors.background.default};
  }

  /* OVERRIDEN CSS */
  #root > div > *,
  #root .Polaris-Frame-ContextualSaveBar,
  #root .Polaris-Popover__Wrapper {

    --p-action-primary: ${focus2Colors.action.primary.default};
    --p-action-primary-hovered: ${focus2Colors.action.primary.hovered};
    --p-action-primary-pressed: ${focus2Colors.action.primary.pressed};
    --p-action-primary-depressed: ${focus2Colors.action.primary.depressed};

    --p-text-subdued: ${focus2Colors.text.subdued};
    --p-text-primary: ${focus2Colors.interactive.primary.default};
    --p-text-primary-hovered: ${focus2Colors.interactive.primary.hovered};

    --p-interactive: ${focus2Colors.interactive.primary.default};
    --p-interactive-disabled: ${focus2Colors.interactive.primary.disabled};
    --p-interactive-hovered: ${focus2Colors.interactive.primary.hovered};
    --p-interactive-pressed: ${focus2Colors.interactive.primary.pressed};

    --p-border-critical-subdued: ${focus2Colors.border.critical.subdued};

    --p-surface-selected: ${focus2Colors.surface.selected.default};

    --p-button-font-weight: 500;

    .Polaris-Tooltip-TooltipOverlay,
    .Polaris-Tooltip-TooltipOverlay__Content {
      border-radius: 0.8rem;
    }
  }

  #root .Polaris-Frame-ContextualSaveBar {
    --p-action-primary-disabled: ${focus2Colors.action.primary.disabled};
    --p-action-secondary-hovered: unset;
    .Polaris-Frame-ContextualSaveBar__Contents {
      margin: 0;
      max-width: 100%;
    }

    .Polaris-Frame-ContextualSaveBar__ActionContainer {
      margin-left: auto;
    }
    @media (min-width: 48.6875em) {
      .Polaris-Frame-ContextualSaveBar__Message {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .metric-date-picker__scrollable {
    max-height: 55vh;
  }
`;

export default GlobalStyle;
