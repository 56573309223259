import { Endpoint } from 'states/services/types';

export const METRICS_COST_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/costs/chart',
  isPrivate: true
};

export const METRICS_COST_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/costs/breakdown',
  isPrivate: true
};
