/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 19th 2021, 5:57:01 pm
 *
 * *****************************************************
 */

import { createApi, fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import { AUTH_TYPE } from 'constants/api';
import { ERROR_500, ERROR_404 } from 'features/common/routes/paths';
import { authenticationDataReceived } from 'states/slices/auth';
import { selectExpiredTime, selectJwtToken } from 'states/slices/auth/selectors';
import { RootState } from 'states/types';
import { getFetchBaseQueryErrorStatusCode } from 'utils/fetch-base-query';
import { isJWTExpired } from 'utils/jwt';
import { syncLogout } from 'utils/log-out';
import { redirectToSignInPage } from 'utils/redirect';

import * as authEndpoints from './auth/endpoints';
import { AuthenticationResponse } from './auth/types';
import { BASE_ENDPOINT } from './endpoints';
import { PROJECT_METRICS_SETTINGS_TAG, PROJECT_TAG } from './tags';

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_ENDPOINT.url,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const jwtToken = selectJwtToken(getState() as RootState);
    if (jwtToken) {
      // Inject the authentication headers into every subsequent request.
      headers.set('authorization', `${AUTH_TYPE} ${jwtToken}`);
    }
    return headers;
  }
});

/**
 * Wraps fetchBaseQuery such that when JTW token is expired,
 * an additional request is sent to attempt
 * to refresh an authorization token,
 * and re-try to initial query after re-authorizing.
 */
const baseQueryWithSilentRefresh: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {
    isPrivate?: boolean;
  }
> = async (args, api, extraOptions) => {
  const { isPrivate = false } = extraOptions;
  /**
   * If main api is a private endpoint
   */
  if (isPrivate) {
    /**
     * Check JWT Token expired time
     */
    const tokenExpiredTime = selectExpiredTime(api.getState() as RootState);
    const isTokenExpired = isJWTExpired(tokenExpiredTime);

    if (isTokenExpired) {
      // try to get a new token
      const refreshResult = await baseQuery(authEndpoints.REFRESH_TOKEN_ENDPOINT.url, api, extraOptions);
      if (refreshResult.data) {
        const { data } = refreshResult.data as any;
        // store the new token
        api.dispatch(authenticationDataReceived(data as AuthenticationResponse));

        /**
         * If main api is a refresh token api,
         * skip processing the main api
         * since we have already process a similar one when trying to get a new token
         */
        if (args === authEndpoints.REFRESH_TOKEN_ENDPOINT.url) return refreshResult;
      } else {
        // If failing to get a new token,
        /**
         * Trigger sync logout
         */
        await syncLogout();
        /**
         * Since the sign in page is on different domain, redirecting to such domain leads the leaving of user from our app.
         * Therefore, we don't need to reset app state before redirecting.
         * Only redirecting is enough.
         */
        redirectToSignInPage();
      }
    }
  }

  // Process the main api
  let result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    const statusCode = getFetchBaseQueryErrorStatusCode(result.error);
    if (typeof statusCode === 'number') {
      if (statusCode >= 500) window.location.assign(ERROR_500);
      if (statusCode === 404) window.location.assign(ERROR_404);
      /**
       * Edge case
       * This situation happens when the delay of an api (probably due to low-speed netword or other factors)
       * is big enough to make the consumed JWT token expired
       * (even though it's valid at the beginning of the api call)
       */
      if (statusCode === 401) {
        // try to get a new token
        const refreshResult = await baseQuery(authEndpoints.REFRESH_TOKEN_ENDPOINT.url, api, extraOptions);
        if (refreshResult.data) {
          const { data } = refreshResult.data as any;
          // store the new token
          api.dispatch(authenticationDataReceived(data as AuthenticationResponse));

          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          // If failing to get a new token,
          /**
           * Trigger sync logout
           */
          await syncLogout();
          /**
           * Since the sign in page is on different domain, redirecting to such domain leads the leaving of user from our app.
           * Therefore, we don't need to reset app state before redirecting.
           * Only redirecting is enough.
           */
          redirectToSignInPage();
        }
      }
    }
  }
  return result;
};

/**
 * Initialize an empty api service that we'll inject endpoints into later as needed
 */
export const emptyAPI = createApi({
  baseQuery: baseQueryWithSilentRefresh,
  endpoints: () => ({}),
  tagTypes: [PROJECT_TAG, PROJECT_METRICS_SETTINGS_TAG]
});
