import { useCallback, useState } from 'react';

import { Select, DatePicker, Button, Popover, Scrollable } from '@shopify/polaris';
import { CalendarMajor } from '@shopify/polaris-icons';
import moment from 'moment';

import { Container, SelectGroup, ButtonGroup, PopoverFooter, DatePickerContainer, ActivatorContainer, Label } from './styles';

export const REGRESSION = [
  { label: 'Linear', value: 'linear' },
  { label: 'Exponential', value: 'exponential' }
];

export const FRAME_WIDTH = [
  { label: 'One Month', value: 'one_month' },
  { label: 'Three Months', value: 'three_month' },
  { label: 'Six Months', value: 'six_month' },
  { label: 'Twelve Months', value: 'twelve_month' },
  { label: 'Twenty Months', value: 'twenty_month' }
];

type Properties = {
  regression: string;
  frameWidth: string;
  forecastFrom: string;
  forecastTo: string;
  updateFormCallback?: (params: any) => void;
};

const ForecastForm = (props: Properties) => {
  const { regression, frameWidth, forecastFrom, forecastTo, updateFormCallback } = props;

  const [selectedRegression, setSelectedRegression] = useState(regression);
  const [selectedFrameWidth, setSelectedFrameWidth] = useState(frameWidth);
  const [selectedFromDate, setSelectedFromDate] = useState(forecastFrom);
  const [selectedToDate, setSelectedToDate] = useState(forecastTo);
  const [fromDateActive, setFromDateActive] = useState(false);
  const [toDateActive, setToDateActive] = useState(false);

  const [uncommittedFromDate, setUncommittedFromDate] = useState({
    start: moment(selectedFromDate).toDate(),
    end: moment(selectedFromDate).toDate()
  });

  const [uncommittedToDate, setUncommittedToDate] = useState({
    start: moment(selectedToDate).toDate(),
    end: moment(selectedToDate).toDate()
  });

  const [{ fMonth, fYear }, setFromDate] = useState({
    fMonth: moment(forecastFrom).month(),
    fYear: moment(forecastFrom).year()
  });

  const [{ tMonth, tYear }, setToDate] = useState({
    tMonth: moment(forecastTo).month(),
    tYear: moment(forecastTo).year()
  });

  const handleChangeRegression = useCallback(value => {
    setSelectedRegression(value);
  }, []);

  const reset = useCallback(() => {
    const resetForm = {
      forecastFrom: moment().startOf('day').toISOString(),
      forecastTo: moment().startOf('day').add(6, 'months').toISOString(),
      frameWidth: 'six_month',
      regression: 'linear'
    };
    setSelectedRegression(resetForm.regression);
    setSelectedFrameWidth(resetForm.frameWidth);
    setSelectedFromDate(resetForm.forecastFrom);
    setSelectedToDate(resetForm.forecastTo);

    if (updateFormCallback) {
      updateFormCallback(resetForm);
    }
  }, [updateFormCallback]);

  const forecast = useCallback(() => {
    if (updateFormCallback) {
      updateFormCallback({
        regression: selectedRegression,
        frameWidth: selectedFrameWidth,
        forecastFrom: selectedFromDate,
        forecastTo: selectedToDate
      });
    }
  }, [selectedFrameWidth, selectedFromDate, selectedRegression, selectedToDate, updateFormCallback]);

  const handleChangeFrameWidth = useCallback(value => {
    setSelectedFrameWidth(value);
  }, []);

  const handleFromDateChange = useCallback((month, year) => setFromDate({ fMonth: month, fYear: year }), []);
  const handleToDateChange = useCallback((month, year) => setToDate({ tMonth: month, tYear: year }), []);

  const toggleFromDate = useCallback(() => {
    setFromDateActive(prev => !prev);
  }, []);

  const toggleToDate = useCallback(() => {
    setToDateActive(prev => !prev);
  }, []);

  const generateDisplayText = useCallback(time => {
    return moment(time).format('MMM DD, YYYY');
  }, []);

  const commitFromDate = useCallback(() => {
    setSelectedFromDate(moment(uncommittedFromDate.start).startOf('day').toISOString());
    toggleFromDate();
  }, [toggleFromDate, uncommittedFromDate]);

  const commitToDate = useCallback(() => {
    setSelectedToDate(moment(uncommittedToDate.start).startOf('day').toISOString());
    toggleToDate();
  }, [toggleToDate, uncommittedToDate]);

  const openFromDatePopover = useCallback(() => {
    setUncommittedFromDate({
      start: moment(selectedFromDate).toDate(),
      end: moment(selectedFromDate).toDate()
    });
    setFromDate({
      fMonth: moment(selectedFromDate).month(),
      fYear: moment(selectedFromDate).year()
    });
    toggleFromDate();
  }, [selectedFromDate, toggleFromDate]);

  const openToDatePopover = useCallback(() => {
    setUncommittedToDate({
      start: moment(selectedToDate).toDate(),
      end: moment(selectedToDate).toDate()
    });
    setToDate({
      tMonth: moment(selectedToDate).month(),
      tYear: moment(selectedToDate).year()
    });
    toggleToDate();
  }, [selectedToDate, toggleToDate]);

  const fromDateActivator = (
    <ActivatorContainer>
      <Label>From date</Label>
      <Button icon={CalendarMajor} onClick={openFromDatePopover} fullWidth>
        {generateDisplayText(selectedFromDate)}
      </Button>
    </ActivatorContainer>
  );

  const toDateActivator = (
    <ActivatorContainer>
      <Label>To date</Label>
      <Button icon={CalendarMajor} onClick={openToDatePopover} fullWidth>
        {generateDisplayText(selectedToDate)}
      </Button>
    </ActivatorContainer>
  );

  return (
    <Container>
      <SelectGroup>
        <Select label="Forecast type" options={REGRESSION} onChange={handleChangeRegression} value={selectedRegression} />
        <Select label="History data" options={FRAME_WIDTH} onChange={handleChangeFrameWidth} value={selectedFrameWidth} />
        <Popover active={fromDateActive} preferredAlignment="left" activator={fromDateActivator} onClose={toggleFromDate}>
          <Popover.Pane fixed>
            <Scrollable shadow focusable className="metric-date-picker__scrollable">
              <Popover.Section>
                <DatePickerContainer>
                  <DatePicker
                    month={fMonth}
                    year={fYear}
                    onChange={setUncommittedFromDate}
                    onMonthChange={handleFromDateChange}
                    selected={uncommittedFromDate}
                  />
                </DatePickerContainer>
              </Popover.Section>
            </Scrollable>
          </Popover.Pane>
          <Popover.Pane fixed>
            <Popover.Section>
              <PopoverFooter>
                <Button onClick={toggleFromDate}>Cancel</Button>
                <Button
                  primary
                  onClick={commitFromDate}
                  disabled={selectedFromDate === moment(uncommittedFromDate.start).startOf('day').toISOString()}>
                  Apply
                </Button>
              </PopoverFooter>
            </Popover.Section>
          </Popover.Pane>
        </Popover>
        <Popover active={toDateActive} preferredAlignment="left" activator={toDateActivator} onClose={toggleToDate} fluidContent>
          <Popover.Pane fixed>
            <Scrollable shadow focusable className="metric-date-picker__scrollable">
              <Popover.Section>
                <DatePickerContainer>
                  <DatePicker
                    month={tMonth}
                    year={tYear}
                    onChange={setUncommittedToDate}
                    onMonthChange={handleToDateChange}
                    selected={uncommittedToDate}
                  />
                </DatePickerContainer>
              </Popover.Section>
            </Scrollable>
          </Popover.Pane>
          <Popover.Pane fixed>
            <Popover.Section>
              <PopoverFooter>
                <Button onClick={toggleToDate}>Cancel</Button>
                <Button
                  primary
                  onClick={commitToDate}
                  disabled={selectedToDate === moment(uncommittedToDate.start).startOf('day').toISOString()}>
                  Apply
                </Button>
              </PopoverFooter>
            </Popover.Section>
          </Popover.Pane>
        </Popover>
      </SelectGroup>
      <ButtonGroup>
        <Button onClick={reset}>Reset</Button>
        <Button primary onClick={forecast}>
          Forecast
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default ForecastForm;
