/**
 * Copyright (C) Boost commerce
 * This file is part of commercial Boost commerce projects
 *
 * This file can not be copied and/or distributed without the express
 * permission of Boost commerce
 *
 * Created on Mon Jan 15 2024 17:49:04
 */

import { useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';

import RelativeTime from 'features/common/views/components/RelativeTime';
import MetricChartCard from 'features/metrics/views/components/cards/MetricChartCard';
import DatePicker from 'features/metrics/views/components/DatePicker';
import MetricChartSkeleton from 'features/metrics/views/components/skeletons/MetricChartSkeleton';
import { useFetchRevenueOverviewQuery } from 'states/services/metrics/revenue/overview';
import { RevenueOverviewResponse } from 'states/services/metrics/revenue/overview/types';
import { createTextDatePickerRange, formatDate } from 'utils/project';

import {
  PageContentContainer,
  DatePickerContainer,
  OneTimeChargesAndUsageChargesContainer,
  ChartContainer,
  OneTimeChargesAndUsageChargesSubContainer,
  NoticeBlock,
  VisibleLargeScreenBlock,
  VisibleSmallScreenBlock
} from './styles';

const RevenueOverviewPage = () => {
  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedCurrentValue, setCommittedCurrentValue] = useState(() => {
    const last30days = {
      start: moment().subtract(30, 'day').toDate(),
      end: moment().subtract(1, 'day').toDate()
    };
    return last30days;
  });

  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedPreviousValue, setCommittedPreviousValue] = useState(() => {
    const last60days = {
      start: moment().subtract(60, 'day').toDate(),
      end: moment().subtract(31, 'day').toDate()
    };
    return last60days;
  });

  const [hasPreviousComparison, setHasPreviousComparison] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * initial data before calling api
   * data chart fetching by projectId, fromDate, toDate
   */
  const { isFetching, data } = useFetchRevenueOverviewQuery(
    {
      projectId: 'aggregated',
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end)
    },
    { refetchOnMountOrArgChange: true }
  );

  const metricCalculatedAt = _.get(data, 'metricCalculatedAt', null);

  const chartLegends = useMemo(() => {
    return [committedCurrentValue, hasPreviousComparison && committedPreviousValue].map(dateRange => {
      if (!dateRange) return '';
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    });
  }, [committedCurrentValue, committedPreviousValue, hasPreviousComparison]);

  const netRevenueChart = (() => {
    let children = null;
    if (isFetching)
      children = <MetricChartSkeleton hasOverview hasChart hasAdditionBlocks additionBlocksCount={3} size="large" />;
    else {
      const { netRevenueSection } = data as RevenueOverviewResponse;
      const { netRevenue, ...additionalBlocksData } = netRevenueSection;
      const { overview: metricOverviewData, data: metricChartData } = netRevenue;
      children = (
        <MetricChartCard
          metricName="netRevenue"
          size="large"
          overviewConfig={{
            show: true,
            type: 'view-details',
            data: metricOverviewData,
            alignLeftMovementIndicator: true
          }}
          chartConfig={{
            show: true,
            data: metricChartData.day,
            legends: chartLegends
          }}
          additionalMetricConfig={{
            show: true,
            data: additionalBlocksData
          }}
          hasPreviousComparison={hasPreviousComparison}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  })();

  const mrrChart = (() => {
    let children = null;
    if (isFetching)
      children = <MetricChartSkeleton hasOverview hasChart hasAdditionBlocks additionBlocksCount={7} size="large" />;
    else {
      const { mrrSection } = data as RevenueOverviewResponse;
      const { mrr, ...additionalBlocksData } = mrrSection;
      const { overview: metricOverviewData, data: metricChartData } = mrr;
      children = (
        <MetricChartCard
          metricName="mrr"
          size="large"
          overviewConfig={{
            type: 'view-details',
            show: true,
            data: metricOverviewData,
            alignLeftMovementIndicator: true
          }}
          chartConfig={{
            show: true,
            data: metricChartData.day,
            legends: chartLegends
          }}
          additionalMetricConfig={{
            show: true,
            data: additionalBlocksData,
            order: ['mrrNew', 'mrrUpgrade', 'mrrReactivation', 'mrrDowngrade', 'mrrCancelChurn', 'mrrFrozen', 'mrrUnfrozen']
          }}
          hasPreviousComparison={hasPreviousComparison}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  })();

  const oneTimeChargesChartAndUsageChargesChart = (() => {
    let children = null;
    if (isFetching) {
      children = (
        <>
          <OneTimeChargesAndUsageChargesSubContainer>
            <VisibleLargeScreenBlock>
              <MetricChartSkeleton hasOverview hasChart size="small" />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartSkeleton hasOverview hasChart size="large" />
            </VisibleSmallScreenBlock>
          </OneTimeChargesAndUsageChargesSubContainer>
          <OneTimeChargesAndUsageChargesSubContainer>
            <VisibleLargeScreenBlock>
              <MetricChartSkeleton hasOverview hasChart size="small" />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartSkeleton hasOverview hasChart size="large" />
            </VisibleSmallScreenBlock>
          </OneTimeChargesAndUsageChargesSubContainer>
        </>
      );
    } else {
      const { oneTimeChargesSection, usageChargesSection } = data as RevenueOverviewResponse;
      const { oneTimeCharges } = oneTimeChargesSection;
      const { usageCharges } = usageChargesSection;
      const { overview: oneTimeChargesMetricOverviewData, data: oneTimeChargesMetricChartData } = oneTimeCharges;
      const { overview: usageChargesMetricOverviewData, data: usageChargesMetricChartData } = usageCharges;
      children = (
        <>
          <OneTimeChargesAndUsageChargesSubContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCard
                metricName="oneTimeCharges"
                size="small"
                overviewConfig={{
                  type: 'view-details',
                  show: true,
                  data: oneTimeChargesMetricOverviewData,
                  alignLeftMovementIndicator: true
                }}
                chartConfig={{
                  show: true,
                  data: oneTimeChargesMetricChartData.day,
                  legends: chartLegends
                }}
                hasPreviousComparison={hasPreviousComparison}
              />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCard
                metricName="oneTimeCharges"
                size="large"
                overviewConfig={{
                  type: 'view-details',
                  show: true,
                  data: oneTimeChargesMetricOverviewData,
                  alignLeftMovementIndicator: true
                }}
                chartConfig={{
                  show: true,
                  data: oneTimeChargesMetricChartData.day,
                  legends: chartLegends
                }}
                hasPreviousComparison={hasPreviousComparison}
              />
            </VisibleSmallScreenBlock>
          </OneTimeChargesAndUsageChargesSubContainer>
          <OneTimeChargesAndUsageChargesSubContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCard
                metricName="usageCharges"
                size="small"
                overviewConfig={{
                  type: 'view-details',
                  show: true,
                  data: usageChargesMetricOverviewData,
                  alignLeftMovementIndicator: true
                }}
                chartConfig={{
                  show: true,
                  data: usageChargesMetricChartData.day,
                  legends: chartLegends
                }}
                hasPreviousComparison={hasPreviousComparison}
              />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCard
                metricName="usageCharges"
                size="large"
                overviewConfig={{
                  type: 'view-details',
                  show: true,
                  data: usageChargesMetricOverviewData,
                  alignLeftMovementIndicator: true
                }}
                chartConfig={{
                  show: true,
                  data: usageChargesMetricChartData.day,
                  legends: chartLegends
                }}
                hasPreviousComparison={hasPreviousComparison}
              />
            </VisibleSmallScreenBlock>
          </OneTimeChargesAndUsageChargesSubContainer>
        </>
      );
    }
    return (
      <ChartContainer>
        <OneTimeChargesAndUsageChargesContainer>{children}</OneTimeChargesAndUsageChargesContainer>
      </ChartContainer>
    );
  })();

  const failedChargesChart = (() => {
    let children = null;
    if (isFetching) children = <MetricChartSkeleton hasOverview hasChart size="large" />;
    else {
      const { failedChargesSection } = data as RevenueOverviewResponse;
      const { failedCharges } = failedChargesSection;
      const { overview: metricOverviewData, data: metricChartData } = failedCharges;
      children = (
        <MetricChartCard
          metricName="failedCharges"
          size="large"
          overviewConfig={{
            type: 'view-details',
            show: true,
            data: metricOverviewData,
            alignLeftMovementIndicator: true
          }}
          chartConfig={{
            show: true,
            data: metricChartData.day,
            legends: chartLegends
          }}
          hasPreviousComparison={hasPreviousComparison}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  })();

  return (
    <PageContentContainer>
      <DatePickerContainer>
        <DatePicker
          hasPreviousComparison={hasPreviousComparison}
          setHasPreviousComparison={setHasPreviousComparison}
          committedCurrentValue={committedCurrentValue}
          setCommittedCurrentValue={setCommittedCurrentValue}
          committedPreviousValue={committedPreviousValue}
          setCommittedPreviousValue={setCommittedPreviousValue}
        />
      </DatePickerContainer>
      {netRevenueChart}
      {mrrChart}
      {oneTimeChargesChartAndUsageChargesChart}
      {failedChargesChart}
      <NoticeBlock>
        <p>
          {isFetching ? (
            'Your data is loading...'
          ) : (
            <>
              Your data was last updated&nbsp;
              <RelativeTime value={metricCalculatedAt} />
            </>
          )}
        </p>
      </NoticeBlock>
    </PageContentContainer>
  );
};

export default RevenueOverviewPage;
