/**
 * Copyright (C) Boost commerce
 * This file is part of commercial Boost commerce projects
 *
 * This file can not be copied and/or distributed without the express
 * permission of Boost commerce
 *
 * Created on Thu Jun 22 2023 08:10:12
 */

import { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import RelativeTime from 'features/common/views/components/RelativeTime';
import ForecastChartCard from 'features/project/views/pages/ProjectMetric/MetricCard/ForecastChartCard';
import MetricTableCard from 'features/project/views/pages/ProjectMetric/MetricCard/MetricTableCard';
import MetricChartCardLoading from 'features/project/views/pages/ProjectMetric/MetricCardLoading/MetricChartCardLoading';
import MetricTableCardLoading from 'features/project/views/pages/ProjectMetric/MetricCardLoading/MetricTableCardLoading';
import DatePicker from 'features/project/views/pages/ProjectMetric/MetricDatePicker';
import { useFetchUsageMrrBreakdownQuery, useFetchUsageMrrChartQuery } from 'states/services/metrics/revenue/usage-mrr';
import { UsageMrrBreakdownResponse, UsageMrrChartResponse } from 'states/services/metrics/revenue/usage-mrr/types';
import { createTextDatePickerRange, formatCurrency, formatDate } from 'utils/project';
import { sortCurrency, sortDate, SortDirection, sortNumber, sortText } from 'utils/table-sorting';

import {
  PageContentContainer,
  DatePickerContainer,
  ChartContainer,
  NoticeBlock,
  BreakdownCardContainer,
  PlanCardContainer
} from './styles';

const INITIAL_FORM = {
  forecast: false,
  regression: 'linear',
  frameWidth: 'six_month',
  forecastFrom: moment().startOf('day').toISOString(),
  forecastTo: moment().startOf('day').add(6, 'months').toISOString()
};

const UsageMrrPage = () => {
  const { projectId } = useParams() as { projectId: string };
  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedCurrentValue, setCommittedCurrentValue] = useState(() => {
    const last30days = {
      start: moment().subtract(30, 'day').toDate(),
      end: moment().subtract(1, 'day').toDate()
    };
    return last30days;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [forecastForm, setForecastForm] = useState(INITIAL_FORM);

  const onForecastFormSubmit = useCallback(partialForm => {
    setForecastForm(prev => {
      return { ...prev, ...partialForm };
    });
  }, []);

  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedPreviousValue, setCommittedPreviousValue] = useState(() => {
    const last60days = {
      start: moment().subtract(60, 'day').toDate(),
      end: moment().subtract(31, 'day').toDate()
    };
    return last60days;
  });

  const [hasPreviousComparison, setHasPreviousComparison] = useState(true);

  /**
   * initial data before calling api
   * data chart fetching by projectId, fromDate, toDate
   */
  const chartResponse = useFetchUsageMrrChartQuery(
    {
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end),
      ...forecastForm
    },
    { refetchOnMountOrArgChange: true }
  );

  const breakdownResponse = useFetchUsageMrrBreakdownQuery(
    {
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end)
    },
    { refetchOnMountOrArgChange: true }
  );

  const metricCalculatedAt = _.get(chartResponse, 'data.metricCalculatedAt', null);

  const chartLegends = useMemo(() => {
    const { forecast, forecastFrom, forecastTo } = forecastForm;
    if (forecast) {
      return [createTextDatePickerRange(moment(forecastFrom).toDate(), moment(forecastTo).toDate())];
    }
    return [committedCurrentValue, hasPreviousComparison && committedPreviousValue].map(dateRange => {
      if (!dateRange) return '';
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    });
  }, [committedCurrentValue, committedPreviousValue, forecastForm, hasPreviousComparison]);

  const usageMrrChart = (() => {
    let children = null;
    const { isLoading, isFetching, data } = chartResponse;
    if (isLoading) children = <MetricChartCardLoading hasOverview hasChart size="large" />;
    else {
      const { usageMrr } = data as UsageMrrChartResponse;
      const { overview: metricOverviewData, data: metricChartData } = usageMrr;
      children = (
        <ForecastChartCard
          metricName="usageMrr"
          size="large"
          isFetching={isFetching}
          overviewConfig={{
            type: 'time-filter',
            forecastForm,
            updateFormCallback: onForecastFormSubmit,
            data: metricOverviewData
          }}
          chartConfig={{
            visible: true,
            data: metricChartData,
            legends: chartLegends
          }}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  })();

  const breakdownTable = useMemo(() => {
    let children = null;
    const { isFetching, data } = breakdownResponse;
    if (isFetching) children = <MetricTableCardLoading />;
    else {
      const { breakdown } = data as UsageMrrBreakdownResponse;
      const dataTableMapping = {
        shopName: {
          tableIndex: 0
        },
        shopDomain: {
          tableIndex: 1
        },
        date: {
          tableIndex: 2
        },
        status: {
          tableIndex: 3
        },
        planName: {
          tableIndex: 4
        },
        mrr: {
          tableIndex: 5,
          valueFormatter: formatCurrency
        }
      };

      const tableSortFunction = (rows: any[], index: number, direction: SortDirection) => {
        switch (index) {
          case 0:
          case 1:
            return sortText(rows, index, direction);
          case 2:
            return sortDate(rows, index, direction);
          case 5:
            return sortCurrency(rows, index, direction);
          default:
            return rows;
        }
      };

      children = (
        <MetricTableCard
          cardTitle="Breakdown"
          columnContentTypes={['text', 'text', 'text', 'text', 'text', 'numeric']}
          headings={['Name', 'Domain', 'Date', 'Action', 'Add-on', 'MRR']}
          totalsName={{ singular: 'Summary', plural: 'Summary' }}
          sortable={[true, true, true, false, false, true]}
          defaultSortDirection="descending"
          data={breakdown}
          dataTableMapping={dataTableMapping}
          sortFunction={tableSortFunction}
        />
      );
    }
    return <BreakdownCardContainer>{children}</BreakdownCardContainer>;
  }, [breakdownResponse]);

  const planTable = useMemo(() => {
    let children = null;
    const { isFetching, data } = breakdownResponse;
    if (isFetching) children = <MetricTableCardLoading />;
    else {
      const { plan } = data as UsageMrrBreakdownResponse;
      const dataTableMapping = {
        planName: {
          tableIndex: 0
        },
        activeCustomers: {
          tableIndex: 1
        },
        newCustomers: {
          tableIndex: 2
        },
        churnedCustomers: {
          tableIndex: 3
        },
        mrr: {
          tableIndex: 4,
          valueFormatter: formatCurrency
        }
      };

      const tableSortFunction = (rows: any[], index: number, direction: SortDirection) => {
        switch (index) {
          case 0:
            return sortText(rows, index, direction);
          case 1:
          case 2:
          case 3:
            return sortNumber(rows, index, direction);
          case 4:
            return sortCurrency(rows, index, direction);
          default:
            return rows;
        }
      };

      children = (
        <MetricTableCard
          cardTitle="Plans"
          columnContentTypes={['text', 'numeric', 'numeric', 'numeric', 'numeric']}
          headings={['Plan', 'Active Customers', 'New Customers', 'Churned Customers', 'MRR']}
          totalsName={{ singular: 'Summary', plural: 'Summary' }}
          sortable={[true, true, true, true, true]}
          defaultSortDirection="descending"
          data={plan}
          dataTableMapping={dataTableMapping}
          sortFunction={tableSortFunction}
        />
      );
    }
    return <PlanCardContainer>{children}</PlanCardContainer>;
  }, [breakdownResponse]);

  return (
    <PageContentContainer>
      <DatePickerContainer>
        <DatePicker
          hasPreviousComparison={hasPreviousComparison}
          setHasPreviousComparison={setHasPreviousComparison}
          committedCurrentValue={committedCurrentValue}
          setCommittedCurrentValue={setCommittedCurrentValue}
          committedPreviousValue={committedPreviousValue}
          setCommittedPreviousValue={setCommittedPreviousValue}
        />
      </DatePickerContainer>
      {usageMrrChart}
      {breakdownTable}
      {planTable}
      <NoticeBlock>
        <p>
          {chartResponse.isFetching ? (
            'Your data is loading...'
          ) : (
            <>
              Your data was last updated&nbsp;
              <RelativeTime value={metricCalculatedAt} />
            </>
          )}
        </p>
      </NoticeBlock>
    </PageContentContainer>
  );
};

export default UsageMrrPage;
