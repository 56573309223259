/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Oct 6th 2021, 12:22:48 pm
 *
 * *****************************************************
 */

import { useCallback, useState } from 'react';

import { Autocomplete, Icon } from '@shopify/polaris';
import { SearchMajor } from '@shopify/polaris-icons';
import _ from 'lodash';

import { Container, EmptyText } from './styles';

export type Option = {
  label: string;
  value: string;
};

type Props = {
  selectedOptions: Option[];
  updateSelectedOptions: (options: Option[]) => void;
  defaultOptions: Option[];
  triggerFunction?: (x: { option: string; action: 'ADD' | 'REMOVE' }) => void;
};

const SearchBar = ({ selectedOptions, updateSelectedOptions, defaultOptions, triggerFunction = () => {} }: Props) => {
  /**
   * input search box
   */
  const [inputValue, setInputValue] = useState<string>('');
  /**
   * default options
   */
  const [options, setOptions] = useState<Option[]>(defaultOptions);

  const updateText = useCallback(
    value => {
      setInputValue(value);

      if (value === '') {
        setOptions(defaultOptions);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = defaultOptions.filter(option => option.label.match(filterRegex));
      setOptions(resultOptions);
    },
    [defaultOptions]
  );

  const onChangeSelectedOptions = useCallback(
    (nOptions: string[]) => {
      const selectedValues = selectedOptions.map(o => o.value);
      const [newOption] = _(nOptions).difference(selectedValues).value();
      const [deletedOption] = _(selectedValues).difference(nOptions).value();
      if (newOption) {
        triggerFunction({
          option: newOption,
          action: 'ADD'
        });
      }
      if (deletedOption) {
        triggerFunction({
          option: deletedOption,
          action: 'REMOVE'
        });
      }
      updateSelectedOptions(options.filter(o => nOptions.includes(o.value)));
    },
    [selectedOptions, updateSelectedOptions, options, triggerFunction]
  );

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label=""
      autoComplete=""
      value={inputValue}
      prefix={<Icon source={SearchMajor} color="base" />}
      placeholder="Search"
    />
  );

  return (
    <Container>
      <Autocomplete
        allowMultiple
        emptyState={<EmptyText>Has no options</EmptyText>}
        options={options}
        selected={selectedOptions.map(o => o.value)}
        textField={textField}
        onSelect={onChangeSelectedOptions}
        listTitle={options.length ? 'Suggested Plans' : ''}
      />
    </Container>
  );
};

export default SearchBar;
