import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import { buildParams } from 'states/services/metrics';
import { GetMetricRequest } from 'states/services/metrics/types';

import * as Endpoints from './endpoints';
import * as Types from './types';

const failedChargeApi = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    fetchFailedChargeChart: builder.query<Types.FailedChargeChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_FAILED_CHARGE_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_FAILED_CHARGE_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.FailedChargeChartResponse }) => {
        return response.data;
      }
    }),
    fetchFailedChargeGrowthChart: builder.query<Types.FailedChargeGrowthChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_FAILED_CHARGE_GROWTH_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_FAILED_CHARGE_GROWTH_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.FailedChargeGrowthChartResponse }) => {
        return response.data;
      }
    }),
    fetchFailedChargeBreakdown: builder.query<Types.FailedChargeBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_FAILED_CHARGE_BREAKDOWN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_FAILED_CHARGE_BREAKDOWN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.FailedChargeBreakdownResponse }) => {
        return response.data;
      }
    })
  })
});

export const { useFetchFailedChargeBreakdownQuery, useFetchFailedChargeChartQuery, useFetchFailedChargeGrowthChartQuery } =
  failedChargeApi;
