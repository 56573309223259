/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Aug 12th 2021, 9:49:29 am
 *
 * *****************************************************
 */

export const PROJECT_VISIBILITY_VALUE_PRIVATE = 'private';
export const PROJECT_VISIBILITY_VALUE_PUBLIC = 'public';
