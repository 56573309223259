/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 26th 2021, 8:08:13 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import colors from 'constants/colors';
import texts from 'constants/texts';

export const IconContainer = styled.div`
  padding: 1rem;
  border-radius: var(--p-border-radius-base);
  background-color: ${colors.onSurface.neutralIcon};
`;

export const Container = styled.div`
  width: 100%;

  /* OVERRIDEN CSS */
  .Polaris-Card {
    padding-bottom: 2.2rem;
  }
  .Polaris-Card__Header {
    padding-bottom: 2rem;
  }
  .Polaris-ResourceItem__Media {
    margin-right: 1rem;
  }
  .Polaris-ResourceList > .Polaris-ResourceItem__ListItem:first-child {
    border-top: 0.1rem solid var(--p-divider);
  }
  // .Polaris-ResourceList > .Polaris-ResourceItem__ListItem:last-child {
  //   border-bottom: 0.1rem solid var(--p-divider);
  // }

  .Polaris-ResourceItem:hover {
    ${IconContainer} {
      background-color: ${colors.onSurface.divider};
    }
  }
`;

export const Name = styled.h3`
  ${texts.body['Body 1 - 14px - SB']}
  color: ${colors.primary.action};
`;
