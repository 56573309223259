import { Endpoint } from 'states/services/types';

export const METRICS_USAGE_CHARGE_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/usage-charges/chart',
  isPrivate: true
};

export const METRICS_USAGE_CHARGE_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/usage-charges/breakdown',
  isPrivate: true
};
