import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import { buildParams } from 'states/services/metrics';
import { GetMetricRequest } from 'states/services/metrics/types';

import * as Endpoints from './endpoints';
import * as Types from './types';

const closeApi = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    fetchCloseChart: builder.query<Types.CloseChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_CLOSE_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_CLOSE_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.CloseChartResponse }) => {
        return response.data;
      }
    }),
    fetchCloseBreakdown: builder.query<Types.CloseBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_CLOSE_BREAKDOWN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_CLOSE_BREAKDOWN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.CloseBreakdownResponse }) => {
        return response.data;
      }
    })
  })
});

export const { useFetchCloseBreakdownQuery, useFetchCloseChartQuery } = closeApi;
