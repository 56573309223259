/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Sep 20th 2021, 4:57:55 pm
 *
 * *****************************************************
 */

import React, { useCallback, useState } from 'react';

import { Button, Icon } from '@shopify/polaris';
import { ArrowLeftMinor } from '@shopify/polaris-icons';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { NavLink, useParams, useRouteMatch } from 'react-router-dom';

import { ReactComponent as GridIcon } from 'assets/images/grid.svg';
import { ReactComponent as RevenueIcon } from 'assets/images/revenue.svg';
import { ReactComponent as UserIcon } from 'assets/images/user-and-customer.svg';
import * as metricPaths from 'features/metrics/routes/paths';
import PageHeader from 'features/metrics/views/components/PageHeader';
import MetricLayout from 'features/metrics/views/layouts/MetricLayout';
import Sidebar from 'features/metrics/views/layouts/Sidebar';
import { useGetWorkspaceBasicInfoQuery } from 'states/services/workspace/hooks';
import { Workspace } from 'states/slices/workspace/types';

import ActiveCustomerPageV2 from './customer-pages-v2/ActiveCustomer';
import ActiveUserPageV2 from './customer-pages-v2/ActiveUser';
import ArpuPageV2 from './customer-pages-v2/ARPU';
import AverageCustomerLifespanPageV2 from './customer-pages-v2/AverageCustomerLifespan';
import ConversionRatePageV2 from './customer-pages-v2/ConversionRate';
import CustomerCancellationPageV2 from './customer-pages-v2/CustomerCancellation';
import CustomerChurnPageV2 from './customer-pages-v2/CustomerChurn';
import CustomerDowngradePageV2 from './customer-pages-v2/CustomerDowngrade';
import CustomerGrowthPageV2 from './customer-pages-v2/CustomerGrowth';
import CustomerReactivationPageV2 from './customer-pages-v2/CustomerReactivation';
import CustomerUpgradePageV2 from './customer-pages-v2/CustomerUpgrade';
import LtvPageV2 from './customer-pages-v2/LTV';
import NewCustomerPageV2 from './customer-pages-v2/NewCustomer';
import UserOverviewPageV2 from './customer-pages-v2/Overview';
import ActiveCustomerPage from './customer-pages/ActiveCustomer';
import ActiveUserPage from './customer-pages/ActiveUser';
import ArpuPage from './customer-pages/ARPU';
import AverageCustomerLifespanPage from './customer-pages/AverageCustomerLifespan';
import ConversionRatePage from './customer-pages/ConversionRate';
import CustomerCancellationPage from './customer-pages/CustomerCancellation';
import CustomerChurnPage from './customer-pages/CustomerChurn';
import CustomerDowngradePage from './customer-pages/CustomerDowngrade';
import CustomerGrowthPage from './customer-pages/CustomerGrowth';
import CustomerReactivationPage from './customer-pages/CustomerReactivation';
import CustomerUpgradePage from './customer-pages/CustomerUpgrade';
import LtvPage from './customer-pages/LTV';
import NewCustomerPage from './customer-pages/NewCustomer';
import UserOverviewPage from './customer-pages/Overview';
import DashboardPage from './DashboardPage';
import CostsPage from './revenue-pages/CostsPage';
import FailedChargesPage from './revenue-pages/FailedChargesPage';
import MRRCancellationPage from './revenue-pages/MRRCancellationPage';
import MRRChurnPage from './revenue-pages/MRRChurnPage';
import MRRDowngradePage from './revenue-pages/MRRDowngradePage';
import MRRNewPage from './revenue-pages/MRRNewPage';
import MRRPage from './revenue-pages/MRRPage';
import MRRReactivationPage from './revenue-pages/MRRReactivationPage';
import MRRUpgradePage from './revenue-pages/MRRUpgradePage';
import NetRevenuePage from './revenue-pages/NetRevenuePage';
import OneTimeChargesPage from './revenue-pages/OneTimeChargesPage';
import RevenueOverviewPage from './revenue-pages/OverviewPage';
import RefundsAndCreditsPage from './revenue-pages/RefundsAndCreditsPage';
import UsageChargesPage from './revenue-pages/UsageChargesPage';
import { MainContainer, PageContainer, HeaderWithBackButton } from './styles';

type PageContent = {
  name: string;
  content: React.ReactNode;
  goBack?: string;
};

const PAGES_CONTENT: { [index: string]: PageContent } = {
  [metricPaths.AGGREGATED_METRICS_DASHBOARD]: {
    name: 'Dashboard',
    content: <DashboardPage />
  },
  [metricPaths.AGGREGATED_METRICS_REVENUE_OVERVIEW]: {
    name: 'Revenue Overview',
    content: <RevenueOverviewPage />
  },
  [metricPaths.AGGREGATED_METRICS_NET_REVENUE]: {
    name: 'Net Revenue',
    content: <NetRevenuePage />
  },
  [metricPaths.AGGREGATED_METRICS_MRR]: {
    name: 'Monthly Recurring Revenue (MRR)',
    content: <MRRPage />
  },
  [metricPaths.AGGREGATED_METRICS_MRR_NEW]: {
    name: 'MRR New',
    content: <MRRNewPage />
  },
  [metricPaths.AGGREGATED_METRICS_MRR_UPGRADE]: {
    name: 'MRR Upgrade',
    content: <MRRUpgradePage />
  },
  [metricPaths.AGGREGATED_METRICS_MRR_DOWNGRADE]: {
    name: 'MRR Downgrade',
    content: <MRRDowngradePage />
  },
  [metricPaths.AGGREGATED_METRICS_MRR_REACTIVATION]: {
    name: 'MRR Reactivation',
    content: <MRRReactivationPage />
  },
  [metricPaths.AGGREGATED_METRICS_MRR_CANCELLATION]: {
    name: 'MRR Cancellation',
    content: <MRRCancellationPage />
  },
  [metricPaths.AGGREGATED_METRICS_MRR_CHURN]: {
    name: 'MRR Churn',
    content: <MRRChurnPage />
  },
  [metricPaths.AGGREGATED_METRICS_ONE_TIME_CHARGES]: {
    name: 'One Time Charges',
    content: <OneTimeChargesPage />
  },
  [metricPaths.AGGREGATED_METRICS_USAGE_CHARGES]: {
    name: 'Usage Charges',
    content: <UsageChargesPage />
  },
  [metricPaths.AGGREGATED_METRICS_FAILED_CHARGES]: {
    name: 'Failed Charges',
    content: <FailedChargesPage />
  },
  [metricPaths.AGGREGATED_METRICS_REFUNDS_AND_CREDITS]: {
    name: 'Refunds & Credits',
    content: <RefundsAndCreditsPage />
  },
  [metricPaths.AGGREGATED_METRICS_COSTS]: {
    name: 'Costs',
    content: <CostsPage />
  },
  [metricPaths.AGGREGATED_METRICS_CUSTOMER_CHURN]: {
    name: 'Customer Churn',
    content: <CustomerChurnPage />
  },
  [metricPaths.AGGREGATED_METRICS_ARPU]: {
    name: 'ARPU',
    content: <ArpuPage />
  },
  [metricPaths.AGGREGATED_METRICS_CUSTOMER_CHURN_V2]: {
    name: 'Customer Churn',
    content: <CustomerChurnPageV2 />
  },
  [metricPaths.AGGREGATED_METRICS_ARPU_V2]: {
    name: 'ARPU',
    content: <ArpuPageV2 />
  },
  [metricPaths.AGGREGATED_METRICS_USER_AND_CUSTOMER_OVERVIEW_V2]: {
    name: 'Overview',
    content: <UserOverviewPageV2 />
  },
  [metricPaths.AGGREGATED_METRICS_LTV_V2]: {
    name: 'Lifetime Value (LTV)',
    content: <LtvPageV2 />
  },
  [metricPaths.AGGREGATED_METRICS_ACTIVE_CUSTOMER_V2]: {
    name: 'Active Customer',
    content: <ActiveCustomerPageV2 />
  },
  [metricPaths.AGGREGATED_METRICS_NEW_CUSTOMER_V2]: {
    name: 'New Customer',
    content: <NewCustomerPageV2 />
  },
  [metricPaths.AGGREGATED_METRICS_CUSTOMER_UPGRADE_V2]: {
    name: 'Customer Upgrade',
    content: <CustomerUpgradePageV2 />
  },
  [metricPaths.AGGREGATED_METRICS_CUSTOMER_DOWNGRADE_V2]: {
    name: 'Customer Downgrade',
    content: <CustomerDowngradePageV2 />
  },
  [metricPaths.AGGREGATED_METRICS_CUSTOMER_CANCELLATION_V2]: {
    name: 'Customer Cancellation',
    content: <CustomerCancellationPageV2 />
  },
  [metricPaths.AGGREGATED_METRICS_CUSTOMER_REACTIVATION_V2]: {
    name: 'Customer Reactivation',
    content: <CustomerReactivationPageV2 />
  },
  [metricPaths.AGGREGATED_METRICS_CUSTOMER_GROWTH_V2]: {
    name: 'Customer Growth',
    content: <CustomerGrowthPageV2 />
  },
  [metricPaths.AGGREGATED_METRICS_CONVERSION_RATE_V2]: {
    name: 'Conversion Rate',
    content: <ConversionRatePageV2 />
  },
  [metricPaths.AGGREGATED_METRICS_AVERAGE_CUSTOMER_LIFESPAN_V2]: {
    name: 'Average Customer Lifespan',
    content: <AverageCustomerLifespanPageV2 />
  },
  [metricPaths.AGGREGATED_METRICS_ACTIVE_USER_V2]: {
    name: 'Active User',
    content: <ActiveUserPageV2 />
  },
  [metricPaths.AGGREGATED_METRICS_USER_AND_CUSTOMER_OVERVIEW]: {
    name: 'Overview',
    content: <UserOverviewPage />
  },
  [metricPaths.AGGREGATED_METRICS_LTV]: {
    name: 'Lifetime Value (LTV)',
    content: <LtvPage />
  },
  [metricPaths.AGGREGATED_METRICS_ACTIVE_CUSTOMER]: {
    name: 'Active Customer',
    content: <ActiveCustomerPage />
  },
  [metricPaths.AGGREGATED_METRICS_NEW_CUSTOMER]: {
    name: 'New Customer',
    content: <NewCustomerPage />
  },
  [metricPaths.AGGREGATED_METRICS_CUSTOMER_UPGRADE]: {
    name: 'Customer Upgrade',
    content: <CustomerUpgradePage />
  },
  [metricPaths.AGGREGATED_METRICS_CUSTOMER_DOWNGRADE]: {
    name: 'Customer Downgrade',
    content: <CustomerDowngradePage />
  },
  [metricPaths.AGGREGATED_METRICS_CUSTOMER_CANCELLATION]: {
    name: 'Customer Cancellation',
    content: <CustomerCancellationPage />
  },
  [metricPaths.AGGREGATED_METRICS_CUSTOMER_REACTIVATION]: {
    name: 'Customer Reactivation',
    content: <CustomerReactivationPage />
  },
  [metricPaths.AGGREGATED_METRICS_CUSTOMER_GROWTH]: {
    name: 'Customer Growth',
    content: <CustomerGrowthPage />
  },
  [metricPaths.AGGREGATED_METRICS_CONVERSION_RATE]: {
    name: 'Conversion Rate',
    content: <ConversionRatePage />
  },
  [metricPaths.AGGREGATED_METRICS_AVERAGE_CUSTOMER_LIFESPAN]: {
    name: 'Average Customer Lifespan',
    content: <AverageCustomerLifespanPage />
  },
  [metricPaths.AGGREGATED_METRICS_ACTIVE_USER]: {
    name: 'Active User',
    content: <ActiveUserPage />
  }
};

const sidebarData = [
  {
    url: metricPaths.AGGREGATED_METRICS_DASHBOARD,
    label: 'Dashboard',
    icon: GridIcon
  },
  {
    url: metricPaths.AGGREGATED_METRICS_REVENUE,
    label: 'Revenue',
    icon: RevenueIcon,
    subItems: [
      {
        url: metricPaths.AGGREGATED_METRICS_REVENUE_OVERVIEW,
        label: 'Overview'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_NET_REVENUE,
        label: 'Net Revenue'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_MRR,
        label: 'MRR'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_MRR_NEW,
        label: 'MRR New'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_MRR_UPGRADE,
        label: 'MRR Upgrade'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_MRR_DOWNGRADE,
        label: 'MRR Downgrade'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_MRR_REACTIVATION,
        label: 'MRR Reactivation'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_MRR_CANCELLATION,
        label: 'MRR Cancellation'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_MRR_CHURN,
        label: 'MRR Churn'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_ONE_TIME_CHARGES,
        label: 'One Time Charges'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_USAGE_CHARGES,
        label: 'Usage Charges'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_FAILED_CHARGES,
        label: 'Failed Charges'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_REFUNDS_AND_CREDITS,
        label: 'Refunds & Credits'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_COSTS,
        label: 'Costs'
      }
    ]
  },
  {
    url: metricPaths.AGGREGATED_METRICS_USER_AND_CUSTOMER,
    label: 'User & Customer',
    icon: UserIcon,
    subItems: [
      {
        url: metricPaths.AGGREGATED_METRICS_USER_AND_CUSTOMER_OVERVIEW,
        label: 'Overview'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_ARPU,
        label: 'ARPU'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_LTV,
        label: 'LTV'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_ACTIVE_CUSTOMER,
        label: 'Active Customer'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_NEW_CUSTOMER,
        label: 'New Customer'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_CUSTOMER_UPGRADE,
        label: 'Customer Upgrade'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_CUSTOMER_DOWNGRADE,
        label: 'Customer Downgrade'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_CUSTOMER_REACTIVATION,
        label: 'Customer Reactivation'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_CUSTOMER_CANCELLATION,
        label: 'Customer Cancellation'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_CUSTOMER_CHURN,
        label: 'Customer Churn'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_CUSTOMER_GROWTH,
        label: 'Customer Growth'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_CONVERSION_RATE,
        label: 'Conversion Rate'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_AVERAGE_CUSTOMER_LIFESPAN,
        label: 'ACL'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_ACTIVE_USER,
        label: 'Active User'
      }
    ]
  },
  {
    url: metricPaths.AGGREGATED_METRICS_USER_AND_CUSTOMER_V2,
    label: 'User & Customer V2',
    icon: UserIcon,
    subItems: [
      {
        url: metricPaths.AGGREGATED_METRICS_USER_AND_CUSTOMER_OVERVIEW_V2,
        label: 'Overview'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_ARPU_V2,
        label: 'ARPU'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_LTV_V2,
        label: 'LTV'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_ACTIVE_CUSTOMER_V2,
        label: 'Active Customer'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_NEW_CUSTOMER_V2,
        label: 'New Customer'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_CUSTOMER_UPGRADE_V2,
        label: 'Customer Upgrade'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_CUSTOMER_DOWNGRADE_V2,
        label: 'Customer Downgrade'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_CUSTOMER_REACTIVATION_V2,
        label: 'Customer Reactivation'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_CUSTOMER_CANCELLATION_V2,
        label: 'Customer Cancellation'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_CUSTOMER_CHURN_V2,
        label: 'Customer Churn'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_CUSTOMER_GROWTH_V2,
        label: 'Customer Growth'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_CONVERSION_RATE_V2,
        label: 'Conversion Rate'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_AVERAGE_CUSTOMER_LIFESPAN_V2,
        label: 'ACL'
      },
      {
        url: metricPaths.AGGREGATED_METRICS_ACTIVE_USER_V2,
        label: 'Active User'
      }
    ]
  }
];

const ProjectMetrics = () => {
  const [smallScreenSidebarActive, setSmallScreenSidebarActive] = useState(false);
  const match = useRouteMatch();
  const { planName, shopifyPlanName, countryCode } =
    useParams<{ planName: string; shopifyPlanName: string; countryCode: string }>();

  const { data: basicInfoWorkspace } = useGetWorkspaceBasicInfoQuery(undefined, {
    refetchOnMountOrArgChange: true
  });

  const { name: workspaceName } = (basicInfoWorkspace as Workspace) ?? { name: '' };

  const handleToggleSmallScreenSidebarActive = useCallback(() => {
    setSmallScreenSidebarActive(prevState => {
      const newState = !prevState;
      /**
       *  Reset scrolling position when sidebar is visible in mobile mode
       */
      if (newState) window.scrollTo(0, 0);

      return newState;
    });
  }, []);

  const handleDeactiveSmallScreenSidebar = useCallback(() => {
    setSmallScreenSidebarActive(false);
    window.scrollTo(0, 0);
  }, []);

  const pageName = _.get(PAGES_CONTENT[match.path], 'name', '');
  const pageContent = _.get(PAGES_CONTENT[match.path], 'content', null);
  const goBackLink = _.get(PAGES_CONTENT[match.path], 'goBack', '');

  return (
    <MetricLayout workspaceName={workspaceName}>
      <Helmet>
        <title>{workspaceName && `${pageName} - Aggregated Project | ${workspaceName}`}</title>
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <MainContainer>
        <Sidebar
          pageName={pageName}
          smallScreenSidebarActive={smallScreenSidebarActive}
          onDeactivateSmallScreenSidebar={handleDeactiveSmallScreenSidebar}
          onToggleSmallScreenSidebarActive={handleToggleSmallScreenSidebarActive}>
          <Sidebar.Section items={sidebarData} />
        </Sidebar>
        <PageContainer sidebarActive={smallScreenSidebarActive}>
          {goBackLink ? (
            <HeaderWithBackButton>
              <NavLink to={goBackLink}>
                <Button icon={<Icon source={ArrowLeftMinor} color="subdued" />} />
              </NavLink>
              <PageHeader
                name={planName || shopifyPlanName || countryCode}
                sidebarActive={smallScreenSidebarActive}
                onToggleSidebarActive={handleToggleSmallScreenSidebarActive}
              />
            </HeaderWithBackButton>
          ) : (
            <PageHeader
              name={pageName}
              sidebarActive={smallScreenSidebarActive}
              onToggleSidebarActive={handleToggleSmallScreenSidebarActive}
            />
          )}
          {pageContent}
        </PageContainer>
      </MainContainer>
    </MetricLayout>
  );
};

export default ProjectMetrics;
