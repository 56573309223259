/**
 * Copyright (C) Boost commerce
 * This file is part of commercial Boost commerce projects
 *
 * This file can not be copied and/or distributed without the express
 * permission of Boost commerce
 *
 * Created on Mon Jan 15 2024 17:18:37
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';

export const PageContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  & > span {
    display: none;
  }
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.4rem;
  grid-template-rows: auto;
  grid-template-areas:
    'metric metric'
    'metric metric';
  @media (max-width: 80rem) {
    & > span {
      display: block;
    }
    grid-template-columns: 100%;
    grid-template-areas:
      'metric'
      'metric'
      'metric'
      'metric'
      'notice'
      'notice';
  }
`;

export const GridBlock = styled.div<{ area: string }>`
  grid-area: ${({ area }) => area};
`;

export const MetricBlocksContainer = styled.div`
  grid-area: metric;
  display: grid;
  grid-template-columns: calc((100% - 2.4rem) / 2) calc((100% - 2.4rem) / 2);
  gap: 2.4rem;
  grid-template-rows: 1fr;

  grid-template-areas:
    'netRevenue mrr'
    'activeSubscriptions customerChurnRate'
    'growthSubscription growthSubscription'
    'growthSubscription growthSubscription'
    'notice notice';

  /* OVERRIDES CSS */
  .Polaris-Card {
    height: 100%;
    margin-top: 0;

    .Polaris-Card__Section {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 80rem) {
    & > span {
      display: none;
    }
    grid-template-columns: 100%;
    grid-template-areas:
      'netRevenue'
      'mrr'
      'activeSubscriptions'
      'customerChurnRate'
      'growthSubscription'
      'growthSubscription'
      'growthSubscription'
      'growthSubscription'
      'totalInstallReopen'
      'totalUninstallCloseStore';
  }
`;

export const BreakdownBlock = styled.div`
  grid-area: breakdown;
`;

export const ActivityBlock = styled.div`
  grid-area: activity;
`;

export const NoticeBlock = styled.span`
  width: 100%;
  text-align: center;
  grid-area: notice;
  padding-top: 0.6rem;
  ${focus2Texts.website['Body 2 - 14px']}
  color: ${focus2Colors.text.subdued};
`;

export const DatePickerContainer = styled.div`
  padding-top: 1.2rem;
  padding-bottom: 2.4rem;
`;

export const VisibleLargeScreenBlock = styled.div`
  display: block;
  @media (max-width: 80rem) {
    display: none;
  }
`;

export const VisibleSmallScreenBlock = styled.div`
  display: none;
  @media (max-width: 80rem) {
    display: block;
  }
`;
