/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Sat, Jul 24th 2021, 10:54:23 pm
 *
 * *****************************************************
 */

import { Container } from './styles';

type Props = {
  name: string;
  source?: string | null;
};

const UserMenuAvatar = ({ name, source }: Props): JSX.Element => {
  const imageAvailable = Boolean(source);
  const displayedName = name
    .split(' ')
    .reduce((res, current) => res + current[0], '')
    .toLocaleUpperCase();
  return (
    <Container aria-label={name} role="img" imageAvailable={imageAvailable}>
      {imageAvailable ? <img role="presentation" alt="" src={source!} /> : displayedName}
    </Container>
  );
};

export default UserMenuAvatar;
