import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import { buildParams } from 'states/services/metrics';
import { GetMetricRequest } from 'states/services/metrics/types';

import * as Endpoints from './endpoints';
import * as Types from './types';

const creditApi = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    fetchCreditChart: builder.query<Types.CreditChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_CREDIT_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_CREDIT_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.CreditChartResponse }) => {
        return response.data;
      }
    }),
    fetchCreditBreakdown: builder.query<Types.CreditBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_CREDIT_BREAKDOWN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_CREDIT_BREAKDOWN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.CreditBreakdownResponse }) => {
        return response.data;
      }
    })
  })
});

export const { useFetchCreditBreakdownQuery, useFetchCreditChartQuery } = creditApi;
