/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Sep 23rd 2021, 10:01:38 am
 *
 * *****************************************************
 */

type Unit = 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds';

export const getRelativeTimeString = (input: string | null) => {
  if (!input) return '';
  const date = new Date(input);
  const formatter = new Intl.RelativeTimeFormat('en');
  const ranges = {
    year: 3600 * 24 * 365,
    month: 3600 * 24 * 30,
    week: 3600 * 24 * 7,
    day: 3600 * 24,
    hour: 3600,
    minute: 60,
    second: 1
  };
  const secondsElapsed = (date.getTime() - Date.now()) / 1000;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(ranges)) {
    if (value < Math.abs(secondsElapsed)) {
      const delta = secondsElapsed / value;
      return formatter.format(Math.round(delta), key as Unit);
    }
  }
  return '';
};
