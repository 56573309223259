/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Oct 7th 2021, 7:57:36 am
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';

export const CardHeader = styled.div<{ isAdditional: boolean }>`
  padding: 2rem;
  h2 {
    ${({ isAdditional }) => (isAdditional ? focus2Texts.website.heading['H4 - 16px'] : focus2Texts.website.heading['H3 - 20px'])}
  }
  box-shadow: inset 0px -1px 0px ${focus2Colors.divider.bottom};
`;

export const Content = styled.div<{ columnContentTypes: ('text' | 'numeric')[] }>`
  width: 100%;

  th,
  td {
    border-bottom-color: ${focus2Colors.divider.bottom};
  }

  tbody {
    tr > *:first-child {
      padding-left: 2rem;
    }

    tr > *:last-child {
      padding-right: 2rem;
    }
  }

  ${({ columnContentTypes }) =>
    columnContentTypes
      .map((colType, index) => {
        return `thead > tr:nth-child(2) > *:nth-child(${index + 1}) {
            text-align: ${colType === 'text' ? 'left' : 'right'};
          }`;
      })
      .join('')}

  thead > tr:first-child > *:first-child:not(.Polaris-DataTable__Cell--sortable) {
    padding-left: 2rem;
  }
  thead > tr:first-child > *:first-child.Polaris-DataTable__Cell--sortable {
    padding-left: 0.4rem;
  }
  thead > tr:first-child > *:last-child:not(.Polaris-DataTable__Cell--sortable) {
    padding-right: 2rem;
  }
  thead > tr:first-child > *:last-child.Polaris-DataTable__Cell--sortable {
    padding-right: 0.4rem;
  }

  thead > tr:nth-child(2) > *:first-child {
    padding-left: 2rem;
  }
  thead > tr:nth-child(2) > *:last-child {
    padding-right: 2rem;
  }

  .Polaris-DataTable__Heading .Polaris-DataTable__Icon {
    opacity: 1;
  }

  .Polaris-DataTable__Footer {
    background-color: ${focus2Colors.surface.normal.disabled};
    color: ${focus2Colors.text.disabled};
  }

  .Polaris-DataTable__Cell--total {
    background-color: ${focus2Colors.surface.normal.disabled};
  }
`;

export const PaginationContainer = styled.div`
  padding: 1.2rem 0;
  display: flex;
  justify-content: center;
  background-color: ${focus2Colors.surface.normal.disabled};
  border-bottom-left-radius: var(--p-border-radius-wide, 3px);
  border-bottom-right-radius: var(--p-border-radius-wide, 3px);
`;
