/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Sep 20th 2021, 4:57:59 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
`;

export const PageContainer = styled.div<{ sidebarActive: boolean }>`
  margin-left: 24rem;
  padding-top: 3.2rem;
  padding-left: 4.8rem;
  padding-right: 4.8rem;
  padding-bottom: 4rem;
  width: 100%;

  @media (max-width: 64em) {
    margin-left: 0;
    padding-top: 1.6rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    padding-bottom: 2rem;
  }

  ${({ sidebarActive }) =>
    sidebarActive &&
    `
    position: fixed
  `}
`;

export const BannerContainer = styled.div`
  width: 100%;
  padding: 3.2rem 4.8rem;

  @media (max-width: 64em) {
    padding-top: 1.6rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top: 2.4rem;
  }
`;

export const HeaderWithBackButton = styled.div`
  display: flex;
  gap: 1.8rem;
`;
