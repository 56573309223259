/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 27th 2021, 09:35:54 am
 *
 * *****************************************************
 */

import { memo } from 'react';

import { ChartOptions } from 'chart.js';
import _ from 'lodash';
import { Line } from 'react-chartjs-2';

import focus2Colors from 'constants/focus-2-colors';
import { ChartConfig, MetricValueFormatter } from 'constants/metrics/base-blocks';

export type DynamicLineChartData = {
  key: string;
  data: number;
}[][];

export type ChartProps = {
  legends: string[];
  data: DynamicLineChartData;
  config: ChartConfig;
  metricValueFormatter: MetricValueFormatter;
};

const DEFAULT_CONFIG = {
  tension: 0.05,
  borderWidth: 3,
  pointRadius: 0,
  borderJoinStyle: 'round'
};

/**
 * @param legends label for legends and tooltips on line chart
 * @param data list of all data include label and data of each yAxis
 * @param config additional or custom config if any
 * @returns Line chart component with non predicted number of line data
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DynamicLineChart = ({ legends, data, config, metricValueFormatter }: ChartProps) => {
  /**
   * configuration for line chart
   */
  if (config.type !== 'DYNAMIC_LINE_CHART') return null;
  let max = -Infinity;
  let min = Infinity;
  const labels: string[] = _(data[0]).map(config.labelField).value(); // something like ["Nov 02", "Nov 03"]
  const { backgroundColors } = config;
  const datasets = _(data)
    .map((items, index) => {
      return {
        ...DEFAULT_CONFIG,
        label: legends[index], // for legends and tooltips
        data: _(items).map(config.dataField).value(),
        backgroundColor: _.get(backgroundColors, `${index}`, focus2Colors.chart.level4.default),
        borderColor: _.get(backgroundColors, `${index}`, focus2Colors.chart.level4.default)
      };
    })
    .filter('label')
    .value();

  datasets.forEach(({ data: mappedData }: any) => {
    if (mappedData) {
      max = _.max(_(mappedData).concat(max).value());
      min = _.min(_(mappedData).concat(min).value());
    }
  });

  if (max === min && max === 0) {
    min = 0;
    max = 1;
  }

  const lineOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      // TODO: update tooltip
      legend: { position: 'bottom', align: 'end', labels: { boxWidth: 12 } }
      // tooltip: {
      //   callbacks: {
      //     title: () => '',
      //     label: context => {
      //       let label = _.get(context, `dataset.labels.${context.dataIndex}`, '');
      //       if (label) {
      //         label += ': ';
      //       }
      //       if (context.parsed.y !== null) {
      //         label += `${metricValueFormatter(context.parsed.y)}  `;
      //       }
      //       return label;
      //     }
      //   }
      // }
    },
    interaction: { intersect: false, mode: 'index' },
    scales: {
      y: {
        ticks: {
          autoSkipPadding: 8
        },
        suggestedMin: min,
        suggestedMax: max,
        grid: { z: 1 } as any
      },
      x: {
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          autoSkipPadding: 15,
          backdropPadding: 10
        },
        grid: { z: 1 } as any
      }
    }
  };

  return <Line data={{ labels, datasets }} options={lineOptions} />;
};

export default memo(DynamicLineChart);
