import { Endpoint } from 'states/services/types';

export const METRICS_CUSTOMER_CANCELLATION_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/customer-cancellation/chart',
  isPrivate: true
};

export const METRICS_CUSTOMER_CANCELLATION_OTHER_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/customer-cancellation/other-chart',
  isPrivate: true
};

export const METRICS_CUSTOMER_CANCELLATION_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/customer-cancellation/breakdown',
  isPrivate: true
};

export const METRICS_CUSTOMER_CANCELLATION_CHART_V2_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user-v2/customer-cancellation/chart',
  isPrivate: true
};

export const METRICS_CUSTOMER_CANCELLATION_OTHER_CHART_V2_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user-v2/customer-cancellation/other-chart',
  isPrivate: true
};

export const METRICS_CUSTOMER_CANCELLATION_BREAKDOWN_V2_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user-v2/customer-cancellation/breakdown',
  isPrivate: true
};
