import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import { buildParams } from 'states/services/metrics';
import { GetMetricRequest } from 'states/services/metrics/types';

import * as Endpoints from './endpoints';
import * as Types from './types';

const uninstallApi = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    fetchUninstallChart: builder.query<Types.UninstallChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_UNINSTALL_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_UNINSTALL_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.UninstallChartResponse }) => {
        return response.data;
      }
    }),
    fetchUninstallBreakdown: builder.query<Types.UninstallBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_UNINSTALL_BREAKDOWN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_UNINSTALL_BREAKDOWN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.UninstallBreakdownResponse }) => {
        return response.data;
      }
    })
  })
});

export const { useFetchUninstallBreakdownQuery, useFetchUninstallChartQuery } = uninstallApi;
