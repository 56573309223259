/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Sun, Jul 25th 2021, 11:36:03 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 72.2rem;

  & > * {
    margin-top: 1.5rem;
  }

  @media (max-width: 47.5em) {
    width: 100%;
  }
`;
