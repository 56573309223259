/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Jul 23rd 2021, 1:28:55 pm
 *
 * *****************************************************
 */

export const LOGOUT_LOCAL_STORAGE_KEY = 'bc-wise-logout';

export const RECENT_PROJECTS_LOCAL_STORAGE_KEY = 'bc-wise-recent-pjs';
