/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Oct 4th 2021, 4:01:08 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';

export const Container = styled.div<{ color: 'success' | 'critical'; size: 'small' | 'medium' }>`
  display: flex;
  align-items: end;
  p {
    color: ${({ color }) => {
      switch (color) {
        case 'success':
          return focus2Colors.text.positive;
        case 'critical':
          return focus2Colors.text.negative;
        default:
          return '';
      }
    }};
    ${({ size }) => {
      switch (size) {
        case 'small':
          return focus2Texts.website.heading['H4 - 16px'];
        case 'medium':
          return focus2Texts.website.heading['H6 - 18px'];
        default:
          return '';
      }
    }}
  }

  svg {
    width: ${({ size }) => {
      switch (size) {
        case 'small':
          return '1.6rem';
        case 'medium':
          return '2rem';
        default:
          return '';
      }
    }};
    fill: ${({ color }) => {
      switch (color) {
        case 'success':
          return focus2Colors.text.positive;
        case 'critical':
          return focus2Colors.text.negative;
        default:
          return '';
      }
    }};
  }
`;
