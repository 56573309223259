/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 19th 2021, 4:55:50 pm
 *
 * *****************************************************
 */

import authReducer from './auth';
import metricsReducer from './metrics';
import projectsReducer from './projects';

/**
 * All slice reducers.
 * New slice reducer will go here
 */
export default {
  auth: authReducer,
  projects: projectsReducer,
  metrics: metricsReducer
};
