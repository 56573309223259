/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 19th 2021, 4:15:07 pm
 *
 * *****************************************************
 */

import { Endpoint } from 'states/services/types';

export const REFRESH_TOKEN_ENDPOINT: Endpoint = {
  url: '/auth/workspaces/refresh-token',
  isPrivate: true
};

export const LOG_OUT_ENDPOINT: Endpoint = {
  url: '/auth/logout',
  isPrivate: true
};
