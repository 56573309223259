/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Jul 30th 2021, 4:25:56 pm
 *
 * *****************************************************
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { SkeletonDisplayText, Popover, Button, ActionList, ActionListSection } from '@shopify/polaris';
import { AppsMajor, CategoriesMajor } from '@shopify/polaris-icons';
import { generatePath, useLocation, useParams } from 'react-router-dom';

import ItemAvatar from 'features/common/views/components/ItemAvatar';
import { PROJECT_METRICS_DASHBOARD, PROJECT_SETTINGS_INFO } from 'features/project/routes/paths';
import { DEFAULT } from 'features/workspace-dashboard/routes/paths';
import { useGetProjectBasicInfoQuery } from 'states/services/projects/hooks';
import { workspaceAPI } from 'states/services/workspace';
import { Project } from 'states/slices/projects/types';
import { Workspace } from 'states/slices/workspace/types';
import { updateRecentProjectsInLocalStorage } from 'utils/project';

import { AvatarPrefixContainer, Container, SkeletonContainer } from './styles';

type Props = {
  onOpenCreateProjectModal: () => void;
};

const ProjectListDropdown = ({ onOpenCreateProjectModal }: Props): JSX.Element => {
  /**
   * Get project ID from path parameters
   */
  const [recentProjects, setRecentProjects] = useState<Project[]>([]);
  const location = useLocation();
  const { projectId } = useParams() as { projectId: string };
  const [popoverActive, setPopoverActive] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const getWorkspaceBasicInfoStatus = workspaceAPI.endpoints.getWorkspaceBasicInfo.useQueryState();
  const getProjectBasicInfoStatus = useGetProjectBasicInfoQuery(projectId, {
    refetchOnMountOrArgChange: true
  });

  const currentProject = useMemo(
    () =>
      (getProjectBasicInfoStatus.data as Project) ?? {
        id: '',
        name: '',
        color: 'transparent'
      },
    [getProjectBasicInfoStatus.data]
  );

  const actionListSectionsData = useMemo((): ActionListSection[] => {
    const res: ActionListSection[] = [
      {
        title: 'MANAGE',
        items: [
          { content: 'View all projects', icon: CategoriesMajor, url: DEFAULT },
          { content: 'Add new project', icon: AppsMajor, onAction: onOpenCreateProjectModal }
        ]
      }
    ];
    /**
     * If there's cached recent project list, show it
     */
    if (recentProjects.length !== 0) {
      res.unshift({
        title: 'RECENT PROJECTS',
        items: recentProjects.map(({ id, name, color, image }) => ({
          content: name ?? '',
          prefix: (
            <AvatarPrefixContainer>
              <ItemAvatar size="tiny" name={name} color={color} source={image && image?.url} />
            </AvatarPrefixContainer>
          ),
          url: location.pathname.replace(new RegExp(projectId, 'g'), id)
        }))
      });
    }

    /**
     * In small screen, top bar navigation will be moved to this dropdown
     */
    if (isSmallScreen) {
      res.unshift({
        title: (currentProject as Project).name,
        items: [
          {
            content: 'Metrics',
            url: generatePath(PROJECT_METRICS_DASHBOARD, { projectId })
          },
          {
            content: 'Project Settings',
            url: generatePath(PROJECT_SETTINGS_INFO, { projectId })
          }
        ]
      });
    }

    return res;
  }, [location, projectId, recentProjects, onOpenCreateProjectModal, isSmallScreen, currentProject]);

  /**
   * Handlers
   */
  const togglePopoverActive = useCallback(() => setPopoverActive(prevState => !prevState), []);

  /**
   * Calculate submenu alignment
   */
  const checkSmallScreen = useCallback(() => {
    if (window.matchMedia('(max-width: 32.4375em)')) {
      setIsSmallScreen(true);
    } else setIsSmallScreen(false);
  }, []);

  useEffect(() => {
    /**
     * Apply suitable submenu aligment based on screen size
     */
    checkSmallScreen();
    window.addEventListener('resize', checkSmallScreen);
    return () => {
      window.removeEventListener('resize', checkSmallScreen);
    };
  }, [checkSmallScreen]);

  useEffect(() => {
    if (getProjectBasicInfoStatus.isSuccess && getWorkspaceBasicInfoStatus.isSuccess) {
      const { id: workspaceId } = getWorkspaceBasicInfoStatus.data as Workspace;
      /**
       * When we access a project,
       * the current accessed project becomes the most recent project.
       * Therefore, we have to update our recent project list in localStorage.
       * And the current accessed project will be the new first item of the list.
       */
      const updatedRecentProjects = updateRecentProjectsInLocalStorage(workspaceId, currentProject);
      /**
       * The displayed recent project list excludes the current project.
       * So we get the other recent projects except for the first one.
       */
      const updatedRecentProjectsWithoutCurrentProject = updatedRecentProjects.slice(1);
      setRecentProjects(updatedRecentProjectsWithoutCurrentProject);
    }
  }, [
    getProjectBasicInfoStatus.isSuccess,
    getWorkspaceBasicInfoStatus.isSuccess,
    getWorkspaceBasicInfoStatus.data,
    currentProject,
    projectId
  ]);

  if (getProjectBasicInfoStatus.isLoading || getWorkspaceBasicInfoStatus.isLoading)
    return (
      <SkeletonContainer>
        <SkeletonDisplayText size="medium" />
      </SkeletonContainer>
    );

  return (
    <Container>
      <Popover
        fluidContent
        active={popoverActive}
        activator={
          <div className="Polaris-TopBar-Menu__ActivatorWrapper">
            <Button
              disclosure
              icon={
                <ItemAvatar
                  size="small"
                  name={currentProject.name}
                  color={currentProject.color}
                  source={currentProject.image && currentProject.image?.url}
                />
              }
              onClick={togglePopoverActive}>
              {(currentProject as Project).name}
            </Button>
          </div>
        }
        preferredAlignment="left"
        onClose={togglePopoverActive}>
        <ActionList onActionAnyItem={togglePopoverActive} sections={actionListSectionsData} />
      </Popover>
    </Container>
  );
};

export default ProjectListDropdown;
