import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import { buildParams } from 'states/services/metrics';
import { GetMetricRequest } from 'states/services/metrics/types';

import * as Endpoints from './endpoints';
import * as Types from './types';

const mrrDowngradeApi = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    fetchMRRDowngradeChart: builder.query<Types.MRRDowngradeChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_MRR_DOWNGRADE_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_MRR_DOWNGRADE_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.MRRDowngradeChartResponse }) => {
        return response.data;
      }
    }),
    fetchMRRDowngradeBreakdown: builder.query<Types.MRRDowngradeBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_MRR_DOWNGRADE_BREAKDOWN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_MRR_DOWNGRADE_BREAKDOWN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.MRRDowngradeBreakdownResponse }) => {
        return response.data;
      }
    })
  })
});

export const { useFetchMRRDowngradeBreakdownQuery, useFetchMRRDowngradeChartQuery } = mrrDowngradeApi;
