/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Oct 6th 2021, 12:22:48 pm
 *
 * *****************************************************
 */

import { useCallback } from 'react';

import { ButtonGroup, Button } from '@shopify/polaris';

import { Container } from './styles';

type Props = {
  value: 'day' | 'week' | 'month';
  onChange: (value: 'day' | 'week' | 'month') => void;
};

const TimeFilter = ({ value, onChange }: Props) => {
  const handleDaysOptionClick = useCallback(() => {
    onChange('day');
  }, [onChange]);

  const handleWeeksOptionClick = useCallback(() => {
    onChange('week');
  }, [onChange]);

  const handleMonthsOptionClick = useCallback(() => {
    onChange('month');
  }, [onChange]);
  return (
    <Container>
      <p>View by</p>
      <ButtonGroup segmented>
        <Button outline={value === 'day'} onClick={handleDaysOptionClick}>
          Days
        </Button>
        <Button outline={value === 'week'} onClick={handleWeeksOptionClick}>
          Weeks
        </Button>
        <Button outline={value === 'month'} onClick={handleMonthsOptionClick}>
          Months
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default TimeFilter;
