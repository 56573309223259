/* eslint-disable react/jsx-props-no-spreading */
/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 20th 2021, 10:20:24 pm
 *
 * *****************************************************
 */

import { RadioButton, TextFieldProps } from '@shopify/polaris';
import { FieldRenderProps } from 'react-final-form';

type Props = TextFieldProps & FieldRenderProps<any>;

/**
 * A text field adapter for React Final Form's Field component.
 * Description: It use Polaris' TextField component as base.
 */
const PaymentTypeSelector = ({ input, ...rest }: Props) => {
  const { value, name, onChange } = input;

  const handleChange = (change: boolean, newValue: string) => {
    if (change) {
      onChange(newValue);
    }
  };

  return (
    <div style={{ display: 'flex', gap: '30px', justifyContent: 'center', marginBottom: '30px' }}>
      <RadioButton
        {...rest}
        id="shopify_payment"
        name={name}
        label="Shopify payment"
        checked={value === 'shopify_payment'}
        onChange={handleChange}
      />
      <RadioButton
        {...rest}
        id="stripe_payment"
        name={name}
        label="Stripe payment"
        checked={value === 'stripe_payment'}
        onChange={handleChange}
      />
    </div>
  );
};

export default PaymentTypeSelector;
