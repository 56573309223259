import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import { buildParams } from 'states/services/metrics';
import { GetMetricRequest } from 'states/services/metrics/types';

import * as Endpoints from './endpoints';
import * as Types from './types';

const mrrApi = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    fetchMRRChart: builder.query<Types.MRRChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_MRR_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_MRR_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.MRRChartResponse }) => {
        return response.data;
      }
    }),
    fetchMRRBreakdown: builder.query<Types.MRRBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_MRR_BREAKDOWN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_MRR_BREAKDOWN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.MRRBreakdownResponse }) => {
        return response.data;
      }
    }),
    fetchMRRGrowthChart: builder.query<Types.MRRGrowthChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_MRR_GROWTH_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_MRR_GROWTH_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.MRRGrowthChartResponse }) => {
        return response.data;
      }
    })
  })
});

export const { useFetchMRRChartQuery, useFetchMRRBreakdownQuery, useFetchMRRGrowthChartQuery } = mrrApi;
