import { Endpoint } from 'states/services/types';

export const METRICS_CUSTOMER_GROWTH_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/customer-growth/chart',
  isPrivate: true
};

export const METRICS_CUSTOMER_GROWTH_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/customer-growth/breakdown',
  isPrivate: true
};
