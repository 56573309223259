/* eslint-disable react/jsx-props-no-spreading */
/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 26th 2021, 4:32:47 pm
 *
 * *****************************************************
 */

import { Icon, TextField, TextFieldProps } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';

import { Container } from './styles';

const SearchTextField = (props: TextFieldProps): JSX.Element => {
  return (
    <Container>
      <Icon source={SearchMinor} />
      <TextField {...props} />
    </Container>
  );
};

export default SearchTextField;
