/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Jul 21st 2021, 2:38:48 pm
 *
 * *****************************************************
 */

import { Endpoint } from './types';

export const BASE_ENDPOINT: Endpoint = {
  url: '/api/admin'
};
