/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Oct 7th 2021, 7:57:29 am
 *
 * *****************************************************
 */

import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Card, DataTable, DataTableProps, Link, Pagination, Button, OptionList, Popover, Filters } from '@shopify/polaris';
import _ from 'lodash';

import { currencySignDisplayExceptZeroFormatter } from 'constants/intl-number-formatter';

import {
  CardHeader,
  Content,
  PaginationContainer,
  ButtonContainer,
  Value,
  SubValue,
  ColorText,
  Action,
  HeaderContainer
} from './styles';

type TableCardData = {
  options: { label: string; value: string }[];
  data: { [index: string]: any[] };
};

export type DateTableMapping = {
  [index: string]: {
    tableIndex: number;
    subField?: string;
    filter?: boolean;
    color?: boolean;
    link?: string;
    valueFormatter?: (value: any, rowData?: any) => string;
  };
};

export type Props<T extends DateTableMapping> = {
  cardTitle: string;
  sortFunction: (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => any[];
  dataTableMapping: T;
  data: TableCardData;
  hidePagination?: boolean;
  rowPerPage?: number;
  isAdditional?: boolean;
} & Pick<DataTableProps, 'columnContentTypes' | 'headings' | 'totalsName' | 'sortable' | 'defaultSortDirection'>;

const TableWithOption = <T extends DateTableMapping>({
  cardTitle,
  columnContentTypes,
  headings,
  totalsName,
  defaultSortDirection,
  sortable,
  sortFunction,
  dataTableMapping,
  data,
  hidePagination = false,
  rowPerPage = 10,
  isAdditional = false
}: PropsWithChildren<Props<T>>): JSX.Element => {
  const { options: optionListData, data: activitiesDataByOption } = data;

  const [popoverActive, setPopoverActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(['']);
  const [currentPage, setCurrentPage] = useState(0);
  const [queryValue, setQueryValue] = useState<string | undefined>(undefined);

  const handleFiltersQueryChange = useCallback(value => setQueryValue(value), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(undefined), []);

  const handleTogglePopoverActive = useCallback(() => {
    setPopoverActive(prevState => !prevState);
  }, []);

  const optionListHashTable: { [index: string]: string } = useMemo(() => {
    return optionListData.reduce((accumulator, { label, value }) => {
      return {
        ...accumulator,
        [value]: label
      };
    }, {});
  }, [optionListData]);

  const activator = (
    <ButtonContainer>
      <Button onClick={handleTogglePopoverActive} disclosure>
        {optionListHashTable[selectedOption[0]]}
      </Button>
    </ButtonContainer>
  );

  const tableKeys = useMemo(() => {
    return Object.keys(dataTableMapping);
  }, [dataTableMapping]);

  const handleSelectOption = useCallback(
    value => {
      setSelectedOption(value);
      setCurrentPage(0);
      handleTogglePopoverActive();
    },
    [handleTogglePopoverActive]
  );

  const transformRow = useCallback(
    (rows: any) => {
      return rows
        .map((row: any) => {
          const res: any[] = Array.from({ length: tableKeys.length });
          let isMatched = true;
          tableKeys.forEach(key => {
            const { tableIndex, valueFormatter, link, subField, color = false, filter = false } = dataTableMapping[key];
            const value = row[key];
            const formattedValue = valueFormatter ? valueFormatter(value, row) : value;
            const subValue = subField ? row[subField] : '';
            if (filter && queryValue) {
              if (!value.includes(queryValue) && !subValue.includes(queryValue)) {
                isMatched = false;
                return;
              }
            }
            res[tableIndex] = formattedValue;
            if (link) {
              res[tableIndex] = (
                <Link removeUnderline url={`${link}/${value}`}>
                  {formattedValue}
                </Link>
              );
            }
            if (subField) {
              res[tableIndex] = (
                <div>
                  <Value>{res[tableIndex]}</Value>
                  <SubValue>{subValue}</SubValue>
                </div>
              );
            }

            if (color && value) {
              res[tableIndex] = <ColorText value={value}>{currencySignDisplayExceptZeroFormatter.format(value)}</ColorText>;
            }
          });
          return (isMatched && res) || null;
        })
        .filter((row: any) => !!row);
    },
    [dataTableMapping, queryValue, tableKeys]
  );

  const transformedData = transformRow(activitiesDataByOption[selectedOption[0]]);

  const tableRef = useRef(transformedData);

  const [allRows, setAllRows] = useState<any[]>(transformedData);

  useEffect(() => {
    const dataFromProps = transformRow(activitiesDataByOption[selectedOption[0]]);
    if (!_(dataFromProps).isEqual(tableRef.current)) {
      setAllRows(dataFromProps);
      setCurrentPage(0);
      tableRef.current = dataFromProps;
    }
  }, [dataTableMapping, activitiesDataByOption, tableKeys, transformRow, selectedOption]);

  const totalPages = useMemo(() => {
    return Math.ceil(allRows.length / rowPerPage);
  }, [allRows.length, rowPerPage]);

  const handleSort = useCallback(
    (index, direction) => setAllRows(prevState => sortFunction(prevState, index, direction)),
    [sortFunction]
  );

  const handlePreviousPage = useCallback(() => {
    setCurrentPage(prevPage => Math.max(0, prevPage - 1));
  }, []);

  const handleNextPage = useCallback(() => {
    setCurrentPage(prevPage => Math.min(totalPages - 1, prevPage + 1));
  }, [totalPages]);

  const displayedRows = useMemo(() => {
    return allRows.slice(currentPage * rowPerPage, (currentPage + 1) * rowPerPage);
  }, [allRows, currentPage, rowPerPage]);

  const hasPreviousPage = currentPage !== 0;
  const hasNextPage = currentPage !== totalPages - 1;

  return (
    <Card>
      <HeaderContainer>
        <CardHeader isAdditional={isAdditional}>
          <h2>{cardTitle}</h2>
          <Action>
            <Filters
              queryValue={queryValue}
              filters={[]}
              onQueryChange={handleFiltersQueryChange}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleQueryValueRemove}
            />
            <Popover active={popoverActive} activator={activator} onClose={handleTogglePopoverActive}>
              <OptionList onChange={handleSelectOption} options={optionListData} selected={selectedOption} />
            </Popover>
          </Action>
        </CardHeader>
        <Filters
          queryValue={queryValue}
          filters={[]}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={handleQueryValueRemove}
          onClearAll={handleQueryValueRemove}
        />
      </HeaderContainer>
      <Content columnContentTypes={columnContentTypes}>
        <DataTable
          columnContentTypes={columnContentTypes}
          headings={headings}
          rows={displayedRows}
          totalsName={totalsName}
          defaultSortDirection={defaultSortDirection}
          sortable={sortable}
          onSort={handleSort}
        />
      </Content>
      {!hidePagination && totalPages > 1 && (
        <PaginationContainer>
          <Pagination
            hasPrevious={hasPreviousPage}
            onPrevious={handlePreviousPage}
            hasNext={hasNextPage}
            onNext={handleNextPage}
          />
        </PaginationContainer>
      )}
    </Card>
  );
};

export default TableWithOption;
