/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Oct 14th 2021, 3:36:59 pm
 *
 * *****************************************************
 */

export const numberFormatter = new Intl.NumberFormat('en-US');

export const decimalNumberFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal'
});

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  style: 'currency'
});

export const currencySignDisplayExceptZeroFormatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  style: 'currency',
  signDisplay: 'exceptZero'
});

export const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});
