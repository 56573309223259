/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 27th 2021, 11:00:38 am
 *
 * *****************************************************
 */

import styled from 'styled-components';

export const Container = styled.div`
  width: calc(100% + 1.6rem);
  margin-right: -1.6rem;

  & > * {
    width: 100%;
  }

  & > *:not(:first-child) {
    margin-top: 1.5rem;
  }

  /* OVERRIDEN CSS */
  .Polaris-Banner {
    margin-right: 1.6rem;
  }
`;
