/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Sep 28th 2021, 8:32:05 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import { SizeChart } from 'features/project/views/pages/ProjectMetric/MetricCard/MetricChartCard';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .Polaris-SkeletonDisplayText--sizeMedium:after {
    border-radius: var(--p-border-radius-base);
  }
`;

export const BreakdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  & > .Polaris-SkeletonDisplayText--sizeMedium {
    margin-bottom: 4.2rem;
    height: 1.4rem;
    border-radius: var(--p-border-radius-base);
    width: 100%;
    max-width: 100%;
  }

  & > .Polaris-SkeletonDisplayText--sizeMedium:first-child {
    margin-bottom: 3.2rem;
    width: 8.8rem;
    height: 2rem;
  }

  & > .Polaris-SkeletonDisplayText--sizeMedium:last-child {
    margin: 0 auto;
    max-width: 12rem;
    width: 12rem;
    height: 3.6rem;
  }
`;

export const CardTitleRow = styled.div<{ hasOverview: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ hasOverview }) => (hasOverview ? '0' : '-0.2rem')};

  & > .Polaris-SkeletonDisplayText--sizeMedium:first-child {
    height: 1.6rem;
    width: 8.8rem;
  }

  & > .Polaris-SkeletonDisplayText--sizeMedium:last-child {
    width: 9.6rem;
    height: 1.4rem;
  }
`;

export const OverviewFirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.6rem;
  margin-bottom: 0.8rem;

  & > .Polaris-SkeletonDisplayText--sizeMedium:first-child {
    width: 16rem;
    max-width: 16rem;
    height: 2.8rem;
  }

  & > .Polaris-SkeletonDisplayText--sizeMedium:last-child {
    width: 4.8rem;
    height: 2rem;
  }
`;

export const OverviewSecondRow = styled.div`
  & > .Polaris-SkeletonDisplayText--sizeMedium {
    width: 11.8rem;
    height: 1.4rem;
  }
`;

export const ChartRow = styled.div<{ size: SizeChart }>`
  margin-bottom: 2rem;
  ${({ size }) => {
    switch (size) {
      case 'small':
        return `
          margin-top: 1.6rem;
          & > .Polaris-SkeletonDisplayText--sizeMedium {
            height: 19.2rem;
          }
        `;
      case 'medium':
        return `
          margin-top: 2.4rem;
          & > .Polaris-SkeletonDisplayText--sizeMedium {
            height: 26.4rem;
          }
        `;
      case 'large':
        return `
          margin-top: 2.4rem;
          & > .Polaris-SkeletonDisplayText--sizeMedium {
            height: 35.2rem;
          }
        `;
      default:
        return '';
    }
  }}

  & > .Polaris-SkeletonDisplayText--sizeMedium {
    width: 100%;
    max-width: 100%;
  }
`;

export const ChartLegendRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > .Polaris-SkeletonDisplayText--sizeMedium {
    width: 9.6rem;
    height: 1.2rem;
  }

  & > *:first-child {
    margin-right: 1.6rem;
  }
`;

export const AdditionalBlock = styled.div`
  display: flex;
  flex-direction: column;

  & > .Polaris-SkeletonDisplayText--sizeMedium:first-child {
    width: 8.8rem;
    height: 1.6rem;
  }

  & > .Polaris-SkeletonDisplayText--sizeMedium:nth-child(2) {
    max-width: 16rem;
    width: 16rem;
    height: 2rem;
    margin-top: 1.6rem;
    margin-bottom: 0.8rem;
  }

  & > .Polaris-SkeletonDisplayText--sizeMedium:last-child {
    width: 11.8rem;
    height: 1.4rem;
  }
`;

export const AdditionalBlocksRow = styled.div`
  padding-top: 1.6rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: -2.4rem;
  margin-left: -2.4rem;

  & > div {
    width: 30%;
    margin-left: 2.4rem;
    margin-top: 2.4rem;
  }

  @media (max-width: 43.75em) {
    & > div {
      width: 40%;
    }
  }

  @media (max-width: 30em) {
    & > div {
      width: 100%;
    }
  }
`;
