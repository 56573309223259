/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Sat, Jul 24th 2021, 5:33:52 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import colors from 'constants/colors';
import texts from 'constants/texts';

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  main {
    width: 100%;
    min-height: calc(100vh - 5.6rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5.6rem;
    padding-bottom: 2rem !important;
  }
  @media (max-width: 47.5em) {
    main {
      padding-right: 1.9rem;
      padding-left: 1.9rem;
    }
  }
`;

export const TopBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  height: 5.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.2rem;
  background-color: ${colors.primary.actionDepressed};
`;

export const PageName = styled.span`
  ${texts.body['Body 1 - 14px - SB']}
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
