import { Endpoint } from 'states/services/types';

export const METRICS_CLOSE_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/installation/close/chart',
  isPrivate: true
};

export const METRICS_CLOSE_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/installation/close/breakdown',
  isPrivate: true
};
