/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 29th 2021, 10:02:45 am
 *
 * *****************************************************
 */

import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 1.4rem;
  padding-bottom: 3.4rem;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 2.8rem;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const SkeletonGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;

  & > *:first-child {
    margin-bottom: 0.8rem;
  }

  .Polaris-SkeletonDisplayText--sizeMedium {
    width: 100%;
    max-width: 100%;
    height: 1.6rem;
    border-radius: var(--p-border-radius-base);
  }
`;
