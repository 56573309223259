/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Jul 30th 2021, 10:40:14 pm
 *
 * *****************************************************
 */

import { useCallback } from 'react';

import { Tabs } from '@shopify/polaris';
import { Helmet } from 'react-helmet';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { PROJECT_METRICS_SETTINGS, PROJECT_SETTINGS_INFO } from 'features/project/routes/paths';
import ProjectLayout from 'features/project/views/layouts/ProjectLayout';
import { useGetProjectBasicInfoQuery } from 'states/services/projects/hooks';
import { useGetWorkspaceBasicInfoQuery } from 'states/services/workspace/hooks';
import { Project } from 'states/slices/projects/types';
import { Workspace } from 'states/slices/workspace/types';

import GeneralInformationTabContent from './GeneralInformationTabContent';
import MetricsSettingsTabContent from './MetricsSettingsTabContent';
import { TabsContainer, PageHeader } from './styles';

const ProjectSettings = () => {
  const { projectId } = useParams() as { projectId: string };
  const { data: currentProject } = useGetProjectBasicInfoQuery(projectId, {
    refetchOnMountOrArgChange: true
  });
  const { name: currentProjectName } = (currentProject as Project) ?? { name: '' };
  const { data: basicInfoWorkspace } = useGetWorkspaceBasicInfoQuery(undefined, {
    refetchOnMountOrArgChange: true
  });

  const { name: workspaceName } = (basicInfoWorkspace as Workspace) ?? { name: '' };
  const history = useHistory();
  const match = useRouteMatch();

  const handleTabChange = useCallback(
    tabIndex => {
      switch (tabIndex) {
        case 0: {
          history.push(generatePath(PROJECT_SETTINGS_INFO, { projectId }));
          break;
        }
        case 1: {
          history.push(generatePath(PROJECT_METRICS_SETTINGS, { projectId }));
          break;
        }
        default:
          break;
      }
    },
    [history, projectId]
  );

  let selectedTabIndex = 0;
  switch (match.path) {
    case PROJECT_SETTINGS_INFO: {
      selectedTabIndex = 0;
      break;
    }
    case PROJECT_METRICS_SETTINGS: {
      selectedTabIndex = 1;
      break;
    }
    default:
      break;
  }

  const tabsHeading = [
    {
      id: 'general-information',
      content: 'General information',
      accessibilityLabel: 'General information',
      panelID: 'general-information-content'
    },
    {
      id: 'metrics-settings',
      content: 'Metrics’ Settings',
      accessibilityLabel: 'Metrics’ Settings',
      panelID: 'metrics-settings-content'
    }
  ];

  const tabsContent = [<GeneralInformationTabContent />, <MetricsSettingsTabContent />];

  return (
    <ProjectLayout workspaceName={workspaceName}>
      <Helmet>
        <title>{currentProjectName && workspaceName && `Settings - ${currentProjectName} | ${workspaceName}`}</title>
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div>
        <PageHeader>
          <h1>Project Settings</h1>
        </PageHeader>
        <TabsContainer>
          <Tabs tabs={tabsHeading} selected={selectedTabIndex} onSelect={handleTabChange}>
            {tabsContent[selectedTabIndex]}
          </Tabs>
        </TabsContainer>
      </div>
    </ProjectLayout>
  );
};

export default ProjectSettings;
