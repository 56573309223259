/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Oct 1st 2021, 1:27:52 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';

export const MetricCardsContainer = styled.div`
  width: 100%;
  margin-bottom: 2.4rem;
`;

export const PageContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const DatePickerContainer = styled.div`
  padding-top: 1.2rem;
  padding-bottom: 2.4rem;
`;

export const ChargesContainer = styled.div`
  display: flex;

  margin-left: -2.4rem;
  margin-top: -2.4rem;
  margin-bottom: 2.4rem;

  @media (max-width: 64em) {
    flex-wrap: wrap;
  }
`;

export const ChargesSubContainer = styled.div`
  width: calc((100% - 2.4rem) / 2);
  margin-left: 2.4rem;
  margin-top: 2.4rem;

  @media (max-width: 64em) {
    width: 100%;
  }
`;

export const ChargesSubSmallContainer = styled.div`
  width: calc((100% - 2.4rem) / 3);
  margin-left: 2.4rem;
  margin-top: 2.4rem;

  @media (max-width: 64em) {
    width: 100%;
  }
`;

export const NoticeBlock = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 0.6rem;
  p {
    ${focus2Texts.website['Body 2 - 14px']}
    color: ${focus2Colors.text.subdued};
  }
`;

export const VisibleLargeScreenBlock = styled.div`
  display: block;
  @media (max-width: 80rem) {
    display: none;
  }
`;

export const VisibleSmallScreenBlock = styled.div`
  display: none;
  @media (max-width: 80rem) {
    display: block;
  }
`;
