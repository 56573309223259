/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Sep 21st 2021, 10:29:28 am
 *
 * *****************************************************
 */

import NavLink from 'features/project/views/pages/ProjectMetric/Sidebar/NavLink';

import { Container, Content } from './styles';

export type Props = {
  url: string;
  label: string;
  level?: number;
  subItems?: Props[];
};

const SubItem = ({ url, label, subItems, level = 1 }: Props): JSX.Element => {
  const hasSubItems = !!subItems?.length;

  return (
    <Container hasSubItems={hasSubItems}>
      <NavLink to={url} activeClassName="BC-WiseIO-Sidebar--navigationActive">
        <Content level={level}>
          <span>{label}</span>
        </Content>
      </NavLink>
      {hasSubItems && (
        <ul>
          {subItems!.map(subItem => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <SubItem key={subItem.url} {...subItem} level={level + 1} />
          ))}
        </ul>
      )}
    </Container>
  );
};

export default SubItem;
