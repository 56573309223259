/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Sep 16th 2021, 7:40:24 am
 *
 * *****************************************************
 */

// TODO: update color palette
const base = {
  surface: '#F0F0F0',
  onSurface: '#111213',
  primary: '#5D59A2',
  secondary: '#CCCCCC',
  interactive: '#2E72D2',
  critical: '#D82C0D',
  warning: '#FFC453',
  success: '#008060',
  highlight: '#5BCDDA',
  label: '#CCCCCC'
};

const background = {
  default: '#F6F6F7',
  hovered: '#F1F2F3',
  pressed: '#EDEEEF',
  selected: '#EDEEEF'
};

const surface = {
  normal: {
    default: '#FFFFFF',
    subdued: '#F9F9FC',
    hovered: '#F2F2FA',
    pressed: '#EDEDF7',
    depressed: '#E1E1F0',
    disabled: '#F7F7FA'
  },
  selected: {
    default: '#F2F7FE',
    hovered: '#EDF4FE',
    pressed: '#E5EFFD'
  },
  warning: {
    default: '#FFD79D',
    subdued: '#FFF5EA',
    hovered: '#FFF2E2',
    pressed: '#FFEBD3'
  },
  critical: {
    default: '#FED3D1',
    subdued: '#FFF4F4',
    hovered: '#FFF0F0',
    pressed: '#FFE9E8',
    depressed: '#FEBCB9'
  },
  success: {
    default: '#AEE9D1',
    subdued: '#F1F8F5',
    hovered: '#ECF6F1',
    pressed: '#E2F1EA'
  },
  highlight: {
    default: '#A4E8F2',
    subdued: '#EBF9FC',
    hovered: '#E4F7FA',
    pressed: '#D5F3F8'
  },
  neutral: {
    default: '#E4E5E7',
    subdued: '#F6F6F7',
    hovered: '#DBDDDF',
    pressed: '#C9CCD0',
    disabled: '#F1F2F3'
  }
};

const text = {
  default: '#202223',
  subdued: '#6D7175',
  disabled: '#8C9196',
  critical: '#D72C0D',
  warning: '#916A00',
  success: '#008060',
  highlight: '#347C84',
  primary: '#5850EC',
  positive: '#28B463',
  negative: '#E74C3C'
};

const textOn = {
  interactive: '#FFFFFF',
  primary: '#FFFFFF',
  critical: '#FFFFFF'
};

const icon = {
  default: '#5C5F62',
  subdued: '#8C9196',
  hovered: '#1A1C1D',
  pressed: '#44474A',
  disabled: '#BABEC3',
  critical: '#D72C0D',
  warning: '#B98900',
  success: '#007F5F',
  highlight: '#00A0AC'
};

const iconOn = {
  interactive: '#FFFFFF',
  primary: '#FFFFFF',
  critical: '#FFFFFF'
};

const interactive = {
  default: '#2C6ECB',
  hovered: '#1F5199',
  depressed: '#103262',
  disabled: '#BDC1CC',
  critical: {
    default: '#D82C0D',
    hovered: '#CD290C',
    pressed: '#670F03',
    disabled: '#FD938D'
  },
  primary: {
    default: '#5850EC',
    hovered: '#3E35D9',
    pressed: '#1A12A1',
    disabled: '#C7C6D5',
    selected: '#928DD7',
    strong: '#2F2E41',
    normal: '#4C4A6A'
  }
};

const border = {
  default: '#8C9196',
  subdued: '#C9CCCF',
  hovered: '#999EA4',
  depressed: '#575959',
  disabled: '#D2D5D8',
  critical: {
    default: '#FD5749',
    subdued: '#E0B3B2',
    disabled: '#FFA7A3'
  },
  success: {
    default: '#00A47C',
    subdued: '#95C9B4'
  },
  highlight: {
    default: '#449DA7',
    subdued: '#98C6CD'
  },
  shadow: {
    subdued: '#BABFC4'
  }
};

const focused = {
  default: '#458FFF'
};

const action = {
  primary: {
    default: '#5850EC',
    hovered: '#3E35D9',
    pressed: '#1A12A1',
    depressed: '#070266',
    disabled: '#C7C6D4'
  },
  critical: {
    default: '#D82C0D',
    hovered: '#BC2200',
    pressed: '#A21B00',
    depressed: '#6C0F00',
    disabled: '#F1F1F1'
  },
  secondary: {
    default: '#FFFFFF',
    hovered: '#F6F6F7',
    pressed: '#F1F2F3',
    depressed: '#6D7175',
    disabled: '#FFFFFF'
  }
};

const decorative = {
  surface: {
    one: '#FFC96B',
    two: '#FFC4B0',
    three: '#92E6B5',
    four: '#91E0D6',
    five: '#FDC9D0'
  },
  icon: {
    one: '#7E5700',
    two: '#AF294E',
    three: '#006D41',
    four: '#006A68',
    five: '#AE2B4C'
  },
  text: {
    one: '#3D2800',
    two: '#490B1C',
    three: '#002F19',
    four: '#002D2D',
    five: '#4F0E1F'
  }
};

const chart = {
  level1: {
    blue: '#AED6F1',
    magenta: '#A3E4D7',
    green: '#ABEBC6',
    yellow: '#F9E79F',
    orange: '#F5CBA7',
    red: '#F5B7B1',
    purple: '#D2B4DE',
    primary: '#BCB9F2',
    neutral: '#D5D5D5'
  },
  level2: {
    blue: '#85C1E9',
    magenta: '#76D7C4',
    green: '#82E0AA',
    yellow: '#F7DC6F',
    orange: '#F0B27A',
    red: '#F1948A',
    purple: '#BB8FCE',
    primary: '#9893FA',
    neutral: '#B6B6B6'
  },
  level3: {
    blue: '#5DADE2',
    magenta: '#48C9B0',
    green: '#58D68D',
    yellow: '#F4D03F',
    orange: '#EB984E',
    red: '#EC7063',
    purple: '#A569BD',
    primary: '#5C53F3',
    neutral: '#999999'
  },
  level4: {
    blue: '#3498DB',
    magenta: '#1ABC9C',
    green: '#2ECC71',
    yellow: '#F1C40F',
    orange: '#F7811A',
    red: '#E74C3C',
    purple: '#8E44AD',
    primary: '#473EE6',
    neutral: '#818181',
    default: '#5850EC',
    'default-opacity': '#eeedfd'
  },
  level5: {
    blue: '#2E86C1',
    magenta: '#17A589',
    green: '#28B463',
    yellow: '#D4AC0D',
    orange: '#CA6F1E',
    red: '#CB4335',
    purple: '#7D3C98',
    primary: '#2D23C1',
    neutral: '#676767'
  },
  level6: {
    blue: '#2874A6',
    magenta: '#148F77',
    green: '#239B56',
    yellow: '#9A7D0A',
    orange: '#AF601A',
    red: '#B03A2E',
    purple: '#6C3483',
    primary: '#171096',
    neutral: '#525252'
  }
};

const onDark = {
  default: '#2F2E41',
  normal: '#4C4A6A',
  hovered: '#5B5886',
  pressed: '#6C699D'
};

const divider = {
  bottom: '#D7D7E0'
};

export default {
  base,
  background,
  surface,
  text,
  textOn,
  icon,
  iconOn,
  interactive,
  border,
  focused,
  action,
  decorative,
  onDark,
  chart,
  divider
};
