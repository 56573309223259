/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 22nd 2021, 8:35:56 am
 *
 * *****************************************************
 */

import styled from 'styled-components';

export const ButtonContainer = styled.span`
  .Polaris-Button__Text {
    max-width: 13rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: 24.6875em) {
    .Polaris-Button__Text {
      max-width: 10rem;
    }
  }

  @media (max-width: 22em) {
    .Polaris-Button__Text {
      max-width: 6rem;
    }
  }
`;

export const SkeletonContainer = styled.div`
  width: 12rem;
  height: 3.6rem;
`;
