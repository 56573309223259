/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 27th 2021, 2:17:50 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  display: flex;
  flex-direction: row;
  aligh-items: center;
  padding: 5.8rem 5.8rem 0;

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1.2rem;

    border-radius: 50%;

    cursor: pointer;

    /* OVERRIDEN CSS */
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  @media (max-width: 38.8125em) {
    padding: 2.4rem 0.8rem 0;
  }
`;

export const CloseButton = styled.button<{ shouldBeHidden: boolean }>`
  margin-left: auto;
  visibility: ${({ shouldBeHidden }) => shouldBeHidden && 'hidden'};
`;

export const BackButton = styled.button<{ shouldBeHidden: boolean }>`
  visibility: ${({ shouldBeHidden }) => shouldBeHidden && 'hidden'};
`;
