/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 26th 2021, 8:08:06 pm
 *
 * *****************************************************
 */

import React, { useCallback } from 'react';

import { Card, Icon, ResourceItem, ResourceList, TextStyle } from '@shopify/polaris';
import { SmileyJoyMajor, FollowUpEmailMajor } from '@shopify/polaris-icons';

import { REPORT_A_BUG_LINK, SUGGEST_A_FEATURE_LINK } from 'constants/links';

import { Container, IconContainer, Name } from './styles';

const HelpCenterBlock = () => {
  const items = [
    {
      icon: <Icon source={SmileyJoyMajor} color="base" />,
      name: 'Report a bug',
      helperText: 'Found a bug? Report now and we will get rid of it!',
      external: true,
      url: REPORT_A_BUG_LINK
    },
    {
      icon: <Icon source={FollowUpEmailMajor} color="base" />,
      name: 'Suggest a feature',
      helperText: 'Need other features? Share your thoughts, opinions, or ideas.',
      external: true,
      url: SUGGEST_A_FEATURE_LINK
    }
    // {
    //   icon: <Icon source={ChatMajor} color="base" />,
    //   name: 'Start live chat',
    //   helperText: 'Get a quick support by talking to us directly.',
    //   url: ''
    // }
  ];

  const renderItem = useCallback(item => {
    const { icon, name, helperText, url, external } = item;
    const media = <IconContainer>{icon}</IconContainer>;
    return (
      <ResourceItem id={name} media={media} url={url} external={external} verticalAlignment="center">
        <Name>
          <TextStyle variation="strong">{name}</TextStyle>
        </Name>
        <span>{helperText}</span>
      </ResourceItem>
    );
  }, []);
  return (
    <Container>
      <Card title="Help center">
        <ResourceList items={items} renderItem={renderItem} />
      </Card>
    </Container>
  );
};

export default HelpCenterBlock;
