/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Aug 12th 2021, 1:46:11 pm
 *
 * *****************************************************
 */

import { workspaceAPI } from './index';

const { useGetWorkspaceBasicInfoQuery, useGetWorkspaceSettingsQuery } = workspaceAPI;

export { useGetWorkspaceBasicInfoQuery, useGetWorkspaceSettingsQuery };
