import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import { buildParams } from 'states/services/metrics';
import { GetMetricRequest } from 'states/services/metrics/types';

import * as Endpoints from './endpoints';
import * as Types from './types';

const mrrChurnApi = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    fetchMRRChurnChart: builder.query<Types.MRRChurnChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_MRR_CHURN_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_MRR_CHURN_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.MRRChurnChartResponse }) => {
        return response.data;
      }
    }),
    fetchMRRChurnRateChart: builder.query<Types.MRRChurnRateChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_MRR_CHURN_RATE_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_MRR_CHURN_RATE_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.MRRChurnRateChartResponse }) => {
        return response.data;
      }
    }),
    fetchMRRChurnBreakdown: builder.query<Types.MRRChurnBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_MRR_CHURN_BREAKDOWN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_MRR_CHURN_BREAKDOWN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.MRRChurnBreakdownResponse }) => {
        return response.data;
      }
    })
  })
});

export const { useFetchMRRChurnBreakdownQuery, useFetchMRRChurnChartQuery, useFetchMRRChurnRateChartQuery } = mrrChurnApi;
