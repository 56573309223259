/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Jul 16th 2021, 10:50:12 pm
 *
 * *****************************************************
 */

import { useEffect, useCallback } from 'react';

import { Frame } from '@shopify/polaris';
import { Switch, Route } from 'react-router-dom';

import { LOGOUT_LOCAL_STORAGE_KEY } from 'constants/local-storage';
import { ERROR_500 } from 'features/common/routes/paths';
import Page404 from 'features/common/views/pages/404';
import Page500 from 'features/common/views/pages/500';
import metricRouteDefs from 'features/metrics/routes/defs';
import projectRouteDefs from 'features/project/routes/defs';
import workspaceDashboardRouteDefs from 'features/workspace-dashboard/routes/defs';
import { redirectToSignInPage } from 'utils/redirect';
import { renderRoutes } from 'utils/routes';

const App = () => {
  /**
   * Sync logout signal handler
   */
  const handleSyncLogoutSignal = useCallback((e: StorageEvent) => {
    if (e.key === LOGOUT_LOCAL_STORAGE_KEY) {
      /**
       * Since the sign in page is on different domain, redirecting to such domain leads the leaving of user from our app.
       * Therefore, we don't need to reset app state before redirecting.
       * Only redirecting is enough.
       */
      redirectToSignInPage();
    }
  }, []);

  useEffect(() => {
    // Listen to sync logout signal
    // NOTE: The storage event fires when a storage area has been changed in the context of ANOTHER document.
    window.addEventListener('storage', handleSyncLogoutSignal);
    return (): void => {
      window.removeEventListener('storage', handleSyncLogoutSignal);
    };
  }, [handleSyncLogoutSignal]);

  /**
   * An array containing all route definitions of our app
   */
  const routeDefs = [...workspaceDashboardRouteDefs, ...metricRouteDefs, ...projectRouteDefs];

  return (
    <Frame>
      <Switch>
        {renderRoutes(routeDefs)}
        <Route exact path={ERROR_500} component={Page500} />
        <Route component={Page404} />
      </Switch>
    </Frame>
  );
};

export default App;
