/**
 * Copyright (C) Boost commerce
 * This file is part of commercial Boost commerce projects
 *
 * This file can not be copied and/or distributed without the express
 * permission of Boost commerce
 *
 * Created on Mon Jan 15 2024 17:18:37
 */

import { useState, useMemo, memo, useEffect, useReducer } from 'react';

import moment from 'moment';

import MetricChartCard from 'features/metrics/views/components/cards/MetricChartCard';
import DatePicker from 'features/metrics/views/components/DatePicker';
import MetricChartSkeleton from 'features/metrics/views/components/skeletons/MetricChartSkeleton';
import { useGetMetricsQuery } from 'states/services/metrics/dashboard';
import { GetMetricDashboardResponse } from 'states/services/metrics/dashboard/types';
import { createTextDatePickerRange, formatDate } from 'utils/project';
import { getRelativeTimeString } from 'utils/time';

import {
  PageContentContainer,
  Content,
  GridBlock,
  NoticeBlock,
  DatePickerContainer,
  MetricBlocksContainer,
  VisibleLargeScreenBlock,
  VisibleSmallScreenBlock
} from './styles';

const RELATIVE_TIME_REFRESH_INTERVAL = 60 * 1e3;

const DashboardPage = () => {
  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedCurrentValue, setCommittedCurrentValue] = useState(() => {
    const last30days = {
      start: moment().subtract(30, 'day').toDate(),
      end: moment().subtract(1, 'day').toDate()
    };
    return last30days;
  });

  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedPreviousValue, setCommittedPreviousValue] = useState(() => {
    const last60days = {
      start: moment().subtract(60, 'day').toDate(),
      end: moment().subtract(31, 'day').toDate()
    };
    return last60days;
  });

  const [, forceUpdate] = useReducer(x => x + 1, 1);

  const [hasPreviousComparison, setHasPreviousComparison] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const intervalID = setInterval(() => {
      forceUpdate();
    }, RELATIVE_TIME_REFRESH_INTERVAL);

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  /**
   * initial data before calling api
   * data chart fetching by projectId, fromDate, toDate
   */
  const { isFetching, data = { syncStatus: null, data: null } } = useGetMetricsQuery(
    {
      projectId: 'aggregated',
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end)
    },
    { refetchOnMountOrArgChange: true }
  );

  /**
   * Data from response
   */
  const { metrics, metricCalculatedAt } = data as GetMetricDashboardResponse;

  const chartLegends = useMemo(() => {
    return [committedCurrentValue, hasPreviousComparison && committedPreviousValue].map(dateRange => {
      if (!dateRange) return '';
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    });
  }, [committedCurrentValue, committedPreviousValue, hasPreviousComparison]);

  // Net revenue, MRR, Active customers, Customer churn rate
  const firstFourCharts = (() => {
    return (['netRevenue', 'mrr', 'activeSubscriptions', 'customerChurnRate'] as const).map(metricName => {
      let children = null;
      if (isFetching)
        children = (
          <>
            <VisibleLargeScreenBlock>
              <MetricChartSkeleton hasOverview size="small" />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartSkeleton hasOverview size="large" />
            </VisibleSmallScreenBlock>
          </>
        );
      else {
        const metricAPIData = metrics?.[metricName];
        if (!metricAPIData) return null;
        const { overview: metricOverviewData } = metricAPIData;
        children = (
          <>
            <VisibleLargeScreenBlock>
              <MetricChartCard
                metricName={metricName}
                size="small"
                hasPreviousComparison={hasPreviousComparison}
                overviewConfig={{
                  show: true,
                  type: 'view-details',
                  data: metricOverviewData,
                  alignLeftMovementIndicator: true
                }}
              />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCard
                metricName={metricName}
                size="large"
                hasPreviousComparison={hasPreviousComparison}
                overviewConfig={{
                  show: true,
                  type: 'view-details',
                  data: metricOverviewData,
                  alignLeftMovementIndicator: true
                }}
              />
            </VisibleSmallScreenBlock>
          </>
        );
      }
      return (
        <GridBlock key={metricName} area={metricName}>
          {children}
        </GridBlock>
      );
    });
  })();

  const customerGrowthChart = (() => {
    const metricName = 'growthSubscription';
    let children = null;
    if (isFetching)
      children = (
        <>
          <VisibleLargeScreenBlock>
            <MetricChartSkeleton hasOverview hasChart size="medium" />
          </VisibleLargeScreenBlock>
          <VisibleSmallScreenBlock>
            <MetricChartSkeleton hasOverview hasChart size="large" />
          </VisibleSmallScreenBlock>
        </>
      );
    else {
      const metricAPIData = metrics?.[metricName];
      if (!metricAPIData) return null;
      const { overview: metricOverviewData, data: chartData } = metricAPIData;
      children = (
        <>
          <VisibleLargeScreenBlock>
            <MetricChartCard
              metricName={metricName}
              size="medium"
              hasPreviousComparison={hasPreviousComparison}
              overviewConfig={{
                show: true,
                data: metricOverviewData,
                type: 'view-details',
                alignLeftMovementIndicator: true
              }}
              chartConfig={{
                show: true,
                data: chartData.day,
                legends: chartLegends
              }}
            />
          </VisibleLargeScreenBlock>
          <VisibleSmallScreenBlock>
            <MetricChartCard
              metricName={metricName}
              size="large"
              hasPreviousComparison={hasPreviousComparison}
              overviewConfig={{
                show: true,
                type: 'view-details',
                alignLeftMovementIndicator: true,
                data: metricOverviewData
              }}
              chartConfig={{
                show: true,
                data: chartData.day,
                legends: chartLegends
              }}
            />
          </VisibleSmallScreenBlock>
        </>
      );
    }
    return <GridBlock area={metricName}>{children}</GridBlock>;
  })();

  const labelForBreakdownOption = getRelativeTimeString(metricCalculatedAt);

  return (
    <PageContentContainer>
      <DatePickerContainer>
        <DatePicker
          hasPreviousComparison={hasPreviousComparison}
          setHasPreviousComparison={setHasPreviousComparison}
          committedCurrentValue={committedCurrentValue}
          setCommittedCurrentValue={setCommittedCurrentValue}
          committedPreviousValue={committedPreviousValue}
          setCommittedPreviousValue={setCommittedPreviousValue}
        />
      </DatePickerContainer>
      <Content>
        <MetricBlocksContainer>
          {firstFourCharts}
          {customerGrowthChart}
          {/*
            Notice block will be display for website screen
          */}
          <NoticeBlock>
            {isFetching ? (
              'Your data is loading...'
            ) : (
              <>
                Your data was last updated&nbsp;
                {labelForBreakdownOption}
              </>
            )}
          </NoticeBlock>
        </MetricBlocksContainer>
        {/*
          Notice block will be display for website screen
        */}
        <NoticeBlock>
          {isFetching ? (
            'Your data is loading...'
          ) : (
            <>
              Your data was last updated&nbsp;
              {labelForBreakdownOption}
            </>
          )}
        </NoticeBlock>
      </Content>
    </PageContentContainer>
  );
};

export default memo(DashboardPage);
