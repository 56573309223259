/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Oct 1st 2021, 1:27:52 pm
 *
 * *****************************************************
 */

import { useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import RelativeTime from 'features/common/views/components/RelativeTime';
import MetricChartCard from 'features/project/views/pages/ProjectMetric/MetricCard/MetricChartCard';
import MetricChartCardLoading from 'features/project/views/pages/ProjectMetric/MetricCardLoading/MetricChartCardLoading';
import DatePicker from 'features/project/views/pages/ProjectMetric/MetricDatePicker';
import {
  DatePickerContainer,
  NoticeBlock,
  ChargesContainer,
  ChargesSubContainer,
  PageContentContainer,
  VisibleLargeScreenBlock,
  VisibleSmallScreenBlock,
  ChargesSubSmallContainer
} from 'features/project/views/pages/ProjectMetric/MetricPage/InstallAndUninstallPageGroup/OverviewPage/styles';
import { useFetchInstallationOverviewQuery } from 'states/services/metrics/installation/overview';
import { InstallationOverviewResponse } from 'states/services/metrics/installation/overview/types';
import { createTextDatePickerRange, formatDate } from 'utils/project';

const OverviewPage = () => {
  const { projectId } = useParams() as { projectId: string };
  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedCurrentValue, setCommittedCurrentValue] = useState(() => {
    const last30days = {
      start: moment().subtract(30, 'day').toDate(),
      end: moment().subtract(1, 'day').toDate()
    };
    return last30days;
  });

  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedPreviousValue, setCommittedPreviousValue] = useState(() => {
    const last60days = {
      start: moment().subtract(60, 'day').toDate(),
      end: moment().subtract(31, 'day').toDate()
    };
    return last60days;
  });

  const [hasPreviousComparison, setHasPreviousComparison] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * initial data before calling api
   * data chart fetching by projectId, fromDate, toDate
   */
  const response = useFetchInstallationOverviewQuery(
    {
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end)
    },
    { refetchOnMountOrArgChange: true }
  );

  const metricCalculatedAt = _.get(response, 'data.metricCalculatedAt', null);

  const chartLegends = useMemo(() => {
    return [committedCurrentValue, hasPreviousComparison && committedPreviousValue].map(dateRange => {
      if (!dateRange) return '';
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    });
  }, [committedCurrentValue, committedPreviousValue, hasPreviousComparison]);

  const firstTwoBlocks = (() => {
    let children = null;
    const { isFetching, data } = response;
    if (isFetching) {
      children = (
        <>
          <ChargesSubSmallContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCardLoading hasOverview size="small" />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCardLoading hasOverview size="large" />
            </VisibleSmallScreenBlock>
          </ChargesSubSmallContainer>
          <ChargesSubSmallContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCardLoading hasOverview size="small" />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCardLoading hasOverview size="large" />
            </VisibleSmallScreenBlock>
          </ChargesSubSmallContainer>
        </>
      );
    } else {
      const { totalInstallAndReopenSection, totalUninstallAndCloseSection } = data as InstallationOverviewResponse;
      const {
        totalInstallAndReopen: { overview: totalInstallReopenOverview }
      } = totalInstallAndReopenSection;
      const {
        totalUninstallAndClose: { overview: totalUninstallCloseOverview }
      } = totalUninstallAndCloseSection;
      children = (
        <>
          <ChargesSubSmallContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCard
                metricName="totalInstallReopen"
                size="small"
                hasPreviousComparison={hasPreviousComparison}
                overviewConfig={{
                  type: 'view-details',
                  show: true,
                  data: totalInstallReopenOverview,
                  hideViewDetails: true,
                  alignLeftMovementIndicator: true
                }}
              />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCard
                metricName="totalInstallReopen"
                size="large"
                hasPreviousComparison={hasPreviousComparison}
                overviewConfig={{
                  type: 'view-details',
                  show: true,
                  data: totalInstallReopenOverview,
                  hideViewDetails: true,
                  alignLeftMovementIndicator: true
                }}
              />
            </VisibleSmallScreenBlock>
          </ChargesSubSmallContainer>
          <ChargesSubSmallContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCard
                metricName="totalUninstallCloseStore"
                size="small"
                hasPreviousComparison={hasPreviousComparison}
                overviewConfig={{
                  type: 'view-details',
                  data: totalUninstallCloseOverview,
                  show: true,
                  hideViewDetails: true,
                  alignLeftMovementIndicator: true
                }}
              />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCard
                metricName="totalUninstallCloseStore"
                size="large"
                hasPreviousComparison={hasPreviousComparison}
                overviewConfig={{
                  type: 'view-details',
                  data: totalUninstallCloseOverview,
                  show: true,
                  hideViewDetails: true,
                  alignLeftMovementIndicator: true
                }}
              />
            </VisibleSmallScreenBlock>
          </ChargesSubSmallContainer>
        </>
      );
    }
    return <ChargesContainer>{children}</ChargesContainer>;
  })();

  const installChartAndReopenChart = (() => {
    let children = null;
    const { isFetching, data } = response;
    if (isFetching) {
      children = (
        <>
          <ChargesSubContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCardLoading hasOverview hasChart size="small" />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCardLoading hasOverview hasChart size="large" />
            </VisibleSmallScreenBlock>
          </ChargesSubContainer>
          <ChargesSubContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCardLoading hasOverview hasChart size="small" />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCardLoading hasOverview hasChart size="large" />
            </VisibleSmallScreenBlock>
          </ChargesSubContainer>
        </>
      );
    } else {
      const { installSection, reopenSection } = data as InstallationOverviewResponse;
      const { install } = installSection;
      const { reopen } = reopenSection;
      const { overview: installOverview, data: installData } = install;
      const { overview: reopenOverview, data: reopenData } = reopen;
      children = (
        <>
          <ChargesSubContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCard
                metricName="install"
                size="small"
                overviewConfig={{
                  type: 'view-details',
                  show: true,
                  data: installOverview,
                  alignLeftMovementIndicator: true
                }}
                chartConfig={{
                  show: true,
                  data: installData.day,
                  legends: chartLegends
                }}
                hasPreviousComparison={hasPreviousComparison}
              />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCard
                metricName="install"
                size="large"
                overviewConfig={{
                  type: 'view-details',
                  show: true,
                  data: installOverview,
                  alignLeftMovementIndicator: true
                }}
                chartConfig={{
                  show: true,
                  data: installData.day,
                  legends: chartLegends
                }}
                hasPreviousComparison={hasPreviousComparison}
              />
            </VisibleSmallScreenBlock>
          </ChargesSubContainer>
          <ChargesSubContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCard
                metricName="reopen"
                size="small"
                overviewConfig={{
                  show: true,
                  type: 'view-details',
                  data: reopenOverview,
                  alignLeftMovementIndicator: true
                }}
                chartConfig={{
                  show: true,
                  data: reopenData.day,
                  legends: chartLegends
                }}
                hasPreviousComparison={hasPreviousComparison}
              />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCard
                metricName="reopen"
                size="large"
                overviewConfig={{
                  show: true,
                  type: 'view-details',
                  data: reopenOverview,
                  alignLeftMovementIndicator: true
                }}
                chartConfig={{
                  show: true,
                  data: reopenData.day,
                  legends: chartLegends
                }}
                hasPreviousComparison={hasPreviousComparison}
              />
            </VisibleSmallScreenBlock>
          </ChargesSubContainer>
        </>
      );
    }
    return <ChargesContainer>{children}</ChargesContainer>;
  })();

  const uninstallChartAndCloseChart = (() => {
    let children = null;
    const { isFetching, data } = response;
    if (isFetching) {
      children = (
        <>
          <ChargesSubContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCardLoading hasOverview hasChart size="small" />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCardLoading hasOverview hasChart size="large" />
            </VisibleSmallScreenBlock>
          </ChargesSubContainer>
          <ChargesSubContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCardLoading hasOverview hasChart size="small" />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCardLoading hasOverview hasChart size="large" />
            </VisibleSmallScreenBlock>
          </ChargesSubContainer>
        </>
      );
    } else {
      const { uninstallSection, closeSection } = data as InstallationOverviewResponse;
      const { uninstall } = uninstallSection;
      const { close } = closeSection;
      const { overview: uninstallOverview, data: uninstallData } = uninstall;
      const { overview: closeOverview, data: closeData } = close;
      children = (
        <>
          <ChargesSubContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCard
                metricName="uninstall"
                size="small"
                overviewConfig={{
                  type: 'view-details',
                  show: true,
                  data: uninstallOverview,
                  alignLeftMovementIndicator: true
                }}
                chartConfig={{
                  show: true,
                  data: uninstallData.day,
                  legends: chartLegends
                }}
                hasPreviousComparison={hasPreviousComparison}
              />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCard
                metricName="uninstall"
                size="large"
                overviewConfig={{
                  type: 'view-details',
                  show: true,
                  data: uninstallOverview,
                  alignLeftMovementIndicator: true
                }}
                chartConfig={{
                  show: true,
                  data: uninstallData.day,
                  legends: chartLegends
                }}
                hasPreviousComparison={hasPreviousComparison}
              />
            </VisibleSmallScreenBlock>
          </ChargesSubContainer>
          <ChargesSubContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCard
                metricName="close"
                size="small"
                overviewConfig={{
                  type: 'view-details',
                  show: true,
                  data: closeOverview,
                  alignLeftMovementIndicator: true
                }}
                chartConfig={{
                  show: true,
                  data: closeData.day,
                  legends: chartLegends
                }}
                hasPreviousComparison={hasPreviousComparison}
              />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCard
                metricName="close"
                size="large"
                overviewConfig={{
                  type: 'view-details',
                  show: true,
                  data: closeOverview,
                  alignLeftMovementIndicator: true
                }}
                chartConfig={{
                  show: true,
                  data: closeData.day,
                  legends: chartLegends
                }}
                hasPreviousComparison={hasPreviousComparison}
              />
            </VisibleSmallScreenBlock>
          </ChargesSubContainer>
        </>
      );
    }
    return <ChargesContainer>{children}</ChargesContainer>;
  })();

  return (
    <PageContentContainer>
      <DatePickerContainer>
        <DatePicker
          hasPreviousComparison={hasPreviousComparison}
          setHasPreviousComparison={setHasPreviousComparison}
          committedCurrentValue={committedCurrentValue}
          setCommittedCurrentValue={setCommittedCurrentValue}
          committedPreviousValue={committedPreviousValue}
          setCommittedPreviousValue={setCommittedPreviousValue}
        />
      </DatePickerContainer>
      {firstTwoBlocks}
      {installChartAndReopenChart}
      {uninstallChartAndCloseChart}
      <NoticeBlock>
        <p>
          {response.isFetching ? (
            'Your data is loading...'
          ) : (
            <>
              Your data was last updated&nbsp;
              <RelativeTime value={metricCalculatedAt} />
            </>
          )}
        </p>
      </NoticeBlock>
    </PageContentContainer>
  );
};

export default OverviewPage;
