/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Sat, Jul 24th 2021, 7:30:53 pm
 *
 * *****************************************************
 */

import { Helmet } from 'react-helmet';

import WorkspaceDashboardLayout from 'features/workspace-dashboard/views/layouts/WorkspaceDashboardLayout';
import { useGetWorkspaceBasicInfoQuery } from 'states/services/workspace/hooks';
import { Workspace } from 'states/slices/workspace/types';

// import FeedbackBlock from './FeedbackBlock';
import HelpCenterBlock from './HelpCenterBlock';
import ProjectListBlock from './ProjectListBlock';
import { ContentContainer } from './styles';
import WelcomeBlock from './WelcomeBlock';

const WorkspaceDashboard = () => {
  const { isSuccess, data } = useGetWorkspaceBasicInfoQuery();
  const { name: workspaceName } = (data as Workspace) ?? { name: '' };
  return (
    <WorkspaceDashboardLayout pageName="Home" workspaceName={workspaceName}>
      <Helmet>
        <title>Dashboard{isSuccess ? ` - ${workspaceName}` : ''} | WiseIO</title>
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <ContentContainer>
        <WelcomeBlock />
        <ProjectListBlock />
        <HelpCenterBlock />
        {/* <FeedbackBlock /> */}
      </ContentContainer>
    </WorkspaceDashboardLayout>
  );
};

export default WorkspaceDashboard;
