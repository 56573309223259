import { Endpoint } from 'states/services/types';

// METRIC/DASHBOARD
export const METRICS_DASHBOARD_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/dashboard',
  isPrivate: true
};

export const BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/dashboard/breakdown',
  isPrivate: true
};

export const ACTIVITIES_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/dashboard/activities',
  isPrivate: true
};
