/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Sep 24th 2021, 8:12:12 am
 *
 * *****************************************************
 */

import { useEffect, useReducer } from 'react';

import { getRelativeTimeString } from 'utils/time';

type Props = {
  value: string | null;
};

const RELATIVE_TIME_REFRESH_INTERVAL = 60 * 1e3;

const RelativeTime = ({ value: timeValue }: Props): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignore, forceUpdate] = useReducer(x => x + 1, 1);

  useEffect(() => {
    const intervalID = setInterval(() => {
      forceUpdate();
    }, RELATIVE_TIME_REFRESH_INTERVAL);

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  return <>{getRelativeTimeString(timeValue)}</>;
};

export default RelativeTime;
