/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Oct 1st 2021, 1:27:45 pm
 *
 * *****************************************************
 */

import { useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import { useParams } from 'react-router-dom';

import RelativeTime from 'features/common/views/components/RelativeTime';
import MetricChartCard from 'features/project/views/pages/ProjectMetric/MetricCard/MetricChartCard';
import MetricTableCard from 'features/project/views/pages/ProjectMetric/MetricCard/MetricTableCard';
import MetricChartCardLoading from 'features/project/views/pages/ProjectMetric/MetricCardLoading/MetricChartCardLoading';
import MetricTableCardLoading from 'features/project/views/pages/ProjectMetric/MetricCardLoading/MetricTableCardLoading';
import DatePicker from 'features/project/views/pages/ProjectMetric/MetricDatePicker';
import { useGetLocationDetailQuery } from 'states/services/metrics/hooks';
import { GetLocationDetailResponse } from 'states/services/metrics/types';
import { createTextDatePickerRange, formatCurrency, formatDate } from 'utils/project';

import { PageContentContainer, DatePickerContainer, ChartContainer, NoticeBlock, BreakdownContainer } from './styles';

const LocationDetailPage = () => {
  const { projectId, countryCode } = useParams<{ projectId: string; countryCode: string }>();
  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedCurrentValue, setCommittedCurrentValue] = useState(() => {
    const last30days = {
      start: moment().subtract(30, 'day').toDate(),
      end: moment().subtract(1, 'day').toDate()
    };
    return last30days;
  });

  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedPreviousValue, setCommittedPreviousValue] = useState(() => {
    const last60days = {
      start: moment().subtract(60, 'day').toDate(),
      end: moment().subtract(31, 'day').toDate()
    };
    return last60days;
  });

  const [hasPreviousComparison, setHasPreviousComparison] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * initial data before calling api
   * data chart fetching by projectId, fromDate, toDate
   */
  const { isFetching, data = { syncStatus: null, data: null, metricCalculatedAt: null } } = useGetLocationDetailQuery(
    {
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end),
      countryCode
    },
    { refetchOnMountOrArgChange: true }
  );

  const { metricCalculatedAt } = data as GetLocationDetailResponse;

  const chartLegends = useMemo(() => {
    return [committedCurrentValue, hasPreviousComparison && committedPreviousValue].map(dateRange => {
      if (!dateRange) return '';
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    });
  }, [committedCurrentValue, committedPreviousValue, hasPreviousComparison]);

  const activeCustomerChart = (() => {
    let children = null;
    if (isFetching)
      children = <MetricChartCardLoading hasOverview hasChart size="large" hasAdditionBlocks additionBlocksCount={2} />;
    else {
      const { activeCustomerSection } = data as GetLocationDetailResponse;
      const { activeCustomer, ...additionalMetricData } = activeCustomerSection;
      const { overview: metricOverviewData, data: metricChartData } = activeCustomer;
      children = (
        <MetricChartCard
          metricName="activeSubscriptions"
          size="large"
          overviewConfig={{
            type: 'time-filter',
            show: true,
            data: metricOverviewData
          }}
          chartConfig={{
            show: true,
            data: metricChartData,
            legends: chartLegends
          }}
          additionalMetricConfig={{
            show: true,
            data: additionalMetricData
          }}
          hasPreviousComparison={hasPreviousComparison}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  })();

  const mrrChart = (() => {
    let children = null;
    if (isFetching)
      children = <MetricChartCardLoading hasOverview hasChart size="large" hasAdditionBlocks additionBlocksCount={2} />;
    else {
      const { mrrSection } = data as GetLocationDetailResponse;
      const { mrr, ...additionalMetricData } = mrrSection;
      const { overview: metricOverviewData, data: metricChartData } = mrr;
      children = (
        <MetricChartCard
          metricName="mrr"
          size="large"
          overviewConfig={{
            type: 'time-filter',
            show: true,
            data: metricOverviewData
          }}
          chartConfig={{
            show: true,
            data: metricChartData,
            legends: chartLegends
          }}
          additionalMetricConfig={{
            show: true,
            data: additionalMetricData
          }}
          hasPreviousComparison={hasPreviousComparison}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  })();

  const activeCustomerBreakdown = useMemo(() => {
    let children = null;
    if (isFetching) children = <MetricTableCardLoading />;
    else {
      const { activeCustomerBreakdownSection } = data as GetLocationDetailResponse;
      const { activeCustomer } = activeCustomerBreakdownSection;
      const dataTableMapping = {
        shopName: {
          tableIndex: 0
        },
        oldPlan: {
          tableIndex: 1
        },
        updatedAt: {
          tableIndex: 2
        },
        shopifyPlan: {
          tableIndex: 3
        },
        mrr: {
          tableIndex: 4,
          valueFormatter: formatCurrency
        }
      };

      const tableSortFunction = (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => {
        if (index === 2)
          return [...rows].sort((rowA, rowB) => {
            const amountA = moment(rowA[index], 'MMM DD, YYYY');
            const amountB = moment(rowB[index], 'MMM DD, YYYY');

            return direction === 'descending' ? amountB.diff(amountA) : amountA.diff(amountB);
          });

        if ([0, 1].includes(index))
          return [...rows].sort((rowA, rowB) => {
            const amountA = rowA[index] as string;
            const amountB = rowB[index] as string;

            return direction === 'descending' ? amountB.localeCompare(amountA) : amountA.localeCompare(amountB);
          });

        return rows;
      };

      children = (
        <MetricTableCard
          cardTitle="Active Customer"
          columnContentTypes={['text', 'text', 'text', 'text', 'numeric']}
          headings={['Name', 'Old Plan', 'Last Update', 'Shopify Plan', 'MRR']}
          totalsName={{ singular: 'Summary', plural: 'Summary' }}
          sortable={[true, true, true, false]}
          defaultSortDirection="descending"
          data={activeCustomer}
          dataTableMapping={dataTableMapping}
          sortFunction={tableSortFunction}
        />
      );
    }
    return <BreakdownContainer>{children}</BreakdownContainer>;
  }, [data, isFetching]);

  const customerChurnTable = useMemo(() => {
    let children = null;
    if (isFetching) children = <MetricTableCardLoading />;
    else {
      const { customerChurnSection } = data as GetLocationDetailResponse;
      const dataTableMapping = {
        shopName: {
          tableIndex: 0
        },
        oldPlan: {
          tableIndex: 1
        },
        newPlan: {
          tableIndex: 2
        },
        updatedAt: {
          tableIndex: 3
        },
        mrr: {
          tableIndex: 4,
          valueFormatter: formatCurrency
        }
      };
      const { customerChurn } = customerChurnSection;

      const tableSortFunction = (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => {
        if (index === 0)
          return [...rows].sort((rowA, rowB) => {
            const amountA = rowA[index] as string;
            const amountB = rowB[index] as string;

            return direction === 'descending' ? amountB.localeCompare(amountA) : amountA.localeCompare(amountB);
          });

        return rows;
      };

      children = (
        <MetricTableCard
          cardTitle="Customer Churn"
          columnContentTypes={['text', 'text', 'text', 'text', 'numeric']}
          headings={['Customer', 'Old Plan', 'New Plan', 'Last Update', 'MRR Lost']}
          totalsName={{ singular: 'Summary', plural: 'Summary' }}
          sortable={[true, false, false, false]}
          defaultSortDirection="descending"
          data={customerChurn}
          dataTableMapping={dataTableMapping}
          sortFunction={tableSortFunction}
        />
      );
    }
    return <BreakdownContainer>{children}</BreakdownContainer>;
  }, [data, isFetching]);

  return (
    <PageContentContainer>
      <DatePickerContainer>
        <DatePicker
          hasPreviousComparison={hasPreviousComparison}
          setHasPreviousComparison={setHasPreviousComparison}
          committedCurrentValue={committedCurrentValue}
          setCommittedCurrentValue={setCommittedCurrentValue}
          committedPreviousValue={committedPreviousValue}
          setCommittedPreviousValue={setCommittedPreviousValue}
        />
      </DatePickerContainer>
      {activeCustomerChart}
      {mrrChart}
      {activeCustomerBreakdown}
      {customerChurnTable}
      <NoticeBlock>
        <p>
          {isFetching ? (
            'Your data is loading...'
          ) : (
            <>
              Your data was last updated&nbsp;
              <RelativeTime value={metricCalculatedAt} />
            </>
          )}
        </p>
      </NoticeBlock>
    </PageContentContainer>
  );
};

export default LocationDetailPage;
