/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Jul 22nd 2021, 9:33:34 pm
 *
 * *****************************************************
 */

import Illustration from 'assets/images/500.svg';
import ErrorPageLayout from 'features/common/views/layouts/ErrorPageLayout';

const Page500 = () => (
  <ErrorPageLayout
    title="Something went wrong from our side."
    description="An unexpected error happened. Please try again later!"
    imgSrc={Illustration}
  />
);

export default Page500;
