/**
 * Copyright (C) Boost commerce
 * This file is part of commercial Boost commerce projects
 *
 * This file can not be copied and/or distributed without the express
 * permission of Boost commerce
 *
 * Created on Mon Jan 15 2024 17:18:37
 */

import { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';

import RelativeTime from 'features/common/views/components/RelativeTime';
import ForecastChartCard from 'features/metrics/views/components/cards/ForecastChartCard';
import MetricTableCard from 'features/metrics/views/components/cards/MetricTableCard';
import DatePicker from 'features/metrics/views/components/DatePicker';
import MetricChartCardLoading from 'features/metrics/views/components/MetricCardLoading/MetricChartCardLoading';
import MetricTableCardLoading from 'features/metrics/views/components/MetricCardLoading/MetricTableCardLoading';
import {
  useFetchMRRChurnChartQuery,
  useFetchMRRChurnRateChartQuery,
  useFetchMRRChurnBreakdownQuery
} from 'states/services/metrics/revenue/mrr-churn';
import {
  MRRChurnBreakdownResponse,
  MRRChurnChartResponse,
  MRRChurnRateChartResponse
} from 'states/services/metrics/revenue/mrr-churn/types';
import { createTextDatePickerRange, formatCurrency, formatDate, formatDecimalNumber } from 'utils/project';

import {
  PageContentContainer,
  DatePickerContainer,
  ChartContainer,
  NoticeBlock,
  ChurnedCustomerCardContainer,
  PlansCardContainer
} from './styles';

const INITIAL_FORM = {
  forecast: false,
  regression: 'linear',
  frameWidth: 'six_month',
  forecastFrom: moment().startOf('day').toISOString(),
  forecastTo: moment().startOf('day').add(6, 'months').toISOString()
};

const MRRChurnPage = () => {
  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedCurrentValue, setCommittedCurrentValue] = useState(() => {
    const last30days = {
      start: moment().subtract(30, 'day').toDate(),
      end: moment().subtract(1, 'day').toDate()
    };
    return last30days;
  });

  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedPreviousValue, setCommittedPreviousValue] = useState(() => {
    const last60days = {
      start: moment().subtract(60, 'day').toDate(),
      end: moment().subtract(31, 'day').toDate()
    };
    return last60days;
  });

  const [hasPreviousComparison, setHasPreviousComparison] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [churnForecast, updateChurnForecast] = useState(INITIAL_FORM);
  const [churnRateForecast, updateChurnRateForecast] = useState(INITIAL_FORM);

  const onChurnForecast = useCallback(partialForm => {
    updateChurnForecast(prev => {
      return { ...prev, ...partialForm };
    });
  }, []);

  const onChurnRateForecast = useCallback(partialForm => {
    updateChurnRateForecast(prev => {
      return { ...prev, ...partialForm };
    });
  }, []);

  /**
   * initial data before calling api
   * data chart fetching by projectId, fromDate, toDate
   */
  const churnChartResponse = useFetchMRRChurnChartQuery(
    {
      projectId: 'aggregated',
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end),
      ...churnForecast
    },
    { refetchOnMountOrArgChange: true }
  );

  const churnRateChartResponse = useFetchMRRChurnRateChartQuery(
    {
      projectId: 'aggregated',
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end),
      ...churnRateForecast
    },
    { refetchOnMountOrArgChange: true }
  );

  const breakdownResponse = useFetchMRRChurnBreakdownQuery(
    {
      projectId: 'aggregated',
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end)
    },
    { refetchOnMountOrArgChange: true }
  );

  const metricCalculatedAt = _.get(churnChartResponse, 'data.metricCalculatedAt', null);

  const churnLegends = useMemo(() => {
    const { forecast, forecastFrom, forecastTo } = churnForecast;
    if (forecast) {
      return [createTextDatePickerRange(moment(forecastFrom).toDate(), moment(forecastTo).toDate())];
    }
    return [committedCurrentValue, hasPreviousComparison && committedPreviousValue].map(dateRange => {
      if (!dateRange) return '';
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    });
  }, [churnForecast, committedCurrentValue, committedPreviousValue, hasPreviousComparison]);

  const churnRateLegends = useMemo(() => {
    const { forecast, forecastFrom, forecastTo } = churnRateForecast;
    if (forecast) {
      return [createTextDatePickerRange(moment(forecastFrom).toDate(), moment(forecastTo).toDate())];
    }
    return [committedCurrentValue, hasPreviousComparison && committedPreviousValue].map(dateRange => {
      if (!dateRange) return '';
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    });
  }, [churnRateForecast, committedCurrentValue, committedPreviousValue, hasPreviousComparison]);

  const mrrChurnRateChart = (() => {
    let children = null;
    const { isLoading, isFetching, data } = churnRateChartResponse;
    if (isLoading) children = <MetricChartCardLoading hasOverview hasChart size="large" />;
    else {
      const { mrrChurnRate } = data as MRRChurnRateChartResponse;
      const { overview: metricOverviewData, data: metricChartData } = mrrChurnRate;
      children = (
        <ForecastChartCard
          metricName="mrrChurnRate"
          size="large"
          isFetching={isFetching}
          overviewConfig={{
            type: 'time-filter',
            data: metricOverviewData,
            updateFormCallback: onChurnRateForecast,
            forecastForm: churnRateForecast
          }}
          chartConfig={{
            visible: true,
            data: metricChartData,
            legends: churnRateLegends
          }}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  })();

  const mrrChurnChart = (() => {
    let children = null;
    const { isLoading, isFetching, data } = churnChartResponse;
    if (isLoading)
      children = <MetricChartCardLoading hasOverview hasChart size="large" hasAdditionBlocks additionBlocksCount={3} />;
    else {
      const { mrrChurn } = data as MRRChurnChartResponse;
      const additionalBlocks = _.pick(data, ['mrrCancellation', 'mrrDowngrade', 'mrrFrozen']);
      const { overview: metricOverviewData, data: metricChartData } = mrrChurn;
      children = (
        <ForecastChartCard
          metricName="mrrChurn"
          size="large"
          isFetching={isFetching}
          overviewConfig={{
            type: 'time-filter',
            data: metricOverviewData,
            updateFormCallback: onChurnForecast,
            forecastForm: churnForecast
          }}
          chartConfig={{
            visible: true,
            data: metricChartData,
            legends: churnLegends
          }}
          additionalMetricConfig={{
            visible: true,
            data: additionalBlocks,
            order: ['mrrDowngrade', 'mrrCancellation', 'mrrFrozen']
          }}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  })();

  const breakdownTable = useMemo(() => {
    let children = null;
    const { isFetching, data } = breakdownResponse;
    if (isFetching) children = <MetricTableCardLoading />;
    else {
      const { breakdown } = data as MRRChurnBreakdownResponse;
      const dataTableMapping = {
        shopName: {
          tableIndex: 0
        },
        date: {
          tableIndex: 1
        },
        planName: {
          tableIndex: 2
        },
        type: {
          tableIndex: 3
        },
        mrrChurn: {
          tableIndex: 4,
          valueFormatter: formatCurrency
        }
      };

      const tableSortFunction = (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => {
        if (index === 1)
          return [...rows].sort((rowA, rowB) => {
            const amountA = moment(rowA[index], 'MMM DD, YYYY');
            const amountB = moment(rowB[index], 'MMM DD, YYYY');

            return direction === 'descending' ? amountB.diff(amountA) : amountA.diff(amountB);
          });

        if (index === 0 || index === 2 || index === 3)
          return [...rows].sort((rowA, rowB) => {
            const amountA = rowA[index] as string;
            const amountB = rowB[index] as string;

            return direction === 'descending' ? amountB.localeCompare(amountA) : amountA.localeCompare(amountB);
          });

        return rows;
      };

      children = (
        <MetricTableCard
          cardTitle="Churned Customers"
          columnContentTypes={['text', 'text', 'text', 'text', 'numeric']}
          headings={['Name', 'Date', 'Plan', 'Type', 'MRR Lost']}
          totalsName={{ singular: 'Summary', plural: 'Summary' }}
          sortable={[true, true, true, true, false]}
          defaultSortDirection="descending"
          data={breakdown}
          dataTableMapping={dataTableMapping}
          sortFunction={tableSortFunction}
        />
      );
    }
    return <ChurnedCustomerCardContainer>{children}</ChurnedCustomerCardContainer>;
  }, [breakdownResponse]);

  const planTable = useMemo(() => {
    let children = null;
    const { isFetching, data } = breakdownResponse;
    if (isFetching) children = <MetricTableCardLoading />;
    else {
      const { plan } = data as MRRChurnBreakdownResponse;
      const dataTableMapping = {
        planName: {
          tableIndex: 0
        },
        interval: {
          tableIndex: 1
        },
        activeCustomers: {
          tableIndex: 2,
          valueFormatter: formatDecimalNumber
        },
        churns: {
          tableIndex: 3
        }
      };

      const tableSortFunction = (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => {
        if (index === 0)
          return [...rows].sort((rowA, rowB) => {
            const amountA = rowA[index] as string;
            const amountB = rowB[index] as string;

            return direction === 'descending' ? amountB.localeCompare(amountA) : amountA.localeCompare(amountB);
          });

        return rows;
      };

      children = (
        <MetricTableCard
          cardTitle="Plans"
          columnContentTypes={['text', 'text', 'numeric', 'numeric']}
          headings={['Label', 'Interval', 'Active Customers', 'Customer Churns']}
          totalsName={{ singular: 'Summary', plural: 'Summary' }}
          sortable={[true, false, false, false]}
          defaultSortDirection="descending"
          data={plan}
          dataTableMapping={dataTableMapping}
          sortFunction={tableSortFunction}
        />
      );
    }
    return <PlansCardContainer>{children}</PlansCardContainer>;
  }, [breakdownResponse]);

  return (
    <PageContentContainer>
      <DatePickerContainer>
        <DatePicker
          hasPreviousComparison={hasPreviousComparison}
          setHasPreviousComparison={setHasPreviousComparison}
          committedCurrentValue={committedCurrentValue}
          setCommittedCurrentValue={setCommittedCurrentValue}
          committedPreviousValue={committedPreviousValue}
          setCommittedPreviousValue={setCommittedPreviousValue}
        />
      </DatePickerContainer>
      {mrrChurnRateChart}
      {mrrChurnChart}
      {planTable}
      {breakdownTable}
      <NoticeBlock>
        <p>
          {churnChartResponse.isFetching ? (
            'Your data is loading...'
          ) : (
            <>
              Your data was last updated&nbsp;
              <RelativeTime value={metricCalculatedAt} />
            </>
          )}
        </p>
      </NoticeBlock>
    </PageContentContainer>
  );
};

export default MRRChurnPage;
