/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 27th 2021, 2:17:42 pm
 *
 * *****************************************************
 */

import { ReactComponent as BackIcon } from 'assets/images/arrowLeft.svg';
import { ReactComponent as CloseIcon } from 'assets/images/close.svg';

import { Container, BackButton, CloseButton } from './styles';

type Props = {
  hideCloseButton?: boolean;
  hideBackButton?: boolean;
  onCloseButtonClick?: () => void;
  onBackButtonClick?: () => void;
};

/**
 * Header for full screen modal -
 * Description: It contains two action buttons (BACK BUTTON - left side; CLOSE BUTTON - right side)
 */
const FullScreenModalFormHeaderActions = ({
  hideCloseButton = false,
  hideBackButton = false,
  onCloseButtonClick,
  onBackButtonClick
}: Props): JSX.Element => {
  return (
    <Container>
      <BackButton
        className="Polaris-Button"
        aria-label="back button"
        shouldBeHidden={hideBackButton}
        disabled={hideBackButton}
        type="button"
        onClick={onBackButtonClick}>
        <BackIcon />
      </BackButton>
      <CloseButton
        className="Polaris-Button"
        aria-label="close button"
        shouldBeHidden={hideCloseButton}
        disabled={hideCloseButton}
        type="button"
        onClick={onCloseButtonClick}>
        <CloseIcon />
      </CloseButton>
    </Container>
  );
};

export default FullScreenModalFormHeaderActions;
