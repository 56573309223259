/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Aug 26th 2021, 3:09:57 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import colors from 'constants/colors';

export const Container = styled.div`
  background-color: #fff;
  margin-top: 1.6rem;
  height: 100%;

  .banner-container {
    margin-left: 2rem;
    padding: 1.2rem 2rem 0 2rem;
    width: 100%;
  }

  /* OVERRIDEN CSS */
  .Polaris-Layout {
    padding-top: 0.8rem;
    margin-bottom: 4rem;
  }

  .Polaris-Layout__Section,
  .Polaris-Layout__AnnotatedSection {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .Polaris-Layout__Annotation,
  .Polaris-Layout__AnnotationContent {
    padding: 0;
    margin-top: 3.2rem;
  }

  .Polaris-Layout > .Polaris-Layout__AnnotatedSection:last-child {
    --p-action-primary-disabled: ${colors.primary.actionDisabledDarkMode};

    background-color: ${colors.primary.surface};
    padding: 2.2rem 0;
    margin-left: 4rem;
    margin-right: 2rem;
    margin-top: 3rem;

    button {
      z-index: 2;
    }

    .Polaris-Button--primary.Polaris-Button--disabled {
      border: 1px solid ${colors.primary.actionDisabledBorderDarkMode};
    }

    .Polaris-Layout__AnnotationContent {
      margin-top: 1.6rem;
    }

    .Polaris-Layout__Annotation {
      display: block;
    }
  }

  .Polaris-FormLayout {
    margin-top: -1.5rem;
  }

  .Polaris-FormLayout__Item {
    margin-top: 1.5rem;
  }

  @media (min-width: 64.3125em) {
    .Polaris-Layout {
      width: 100.3rem;
    }
    form {
      position: relative;
    }
    .submit-button-fill-the-rest {
      position: absolute;
      bottom: 0;
      left: 2rem;
      z-index: 0;
      background-color: ${colors.primary.surface};
      height: 8.1rem;
      width: calc(100% - 4rem);
      border-top: 0.1rem solid var(--p-divider);
    }
  }

  @media (max-width: 48.6875em) {
    .Polaris-Layout__Annotation {
      margin-top: 3.2rem;
    }
    .Polaris-Layout__AnnotationContent {
      margin-top: 3rem;
    }

    .Polaris-Layout > .Polaris-Layout__AnnotatedSection:last-child {
      button {
        margin-left: 2rem;
      }
      .Polaris-Layout__Annotation {
        display: none;
      }
    }
  }
`;
