/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 27th 2021, 09:35:54 am
 *
 * *****************************************************
 */

import { memo } from 'react';

import { ChartOptions } from 'chart.js';
import _ from 'lodash';
import { Line } from 'react-chartjs-2';

import { ChartConfig, MetricValueFormatter } from 'constants/metrics/base-blocks';

export type LineChartData = Record<string, any>;

export type ChartProps = {
  legends: string[];
  data: LineChartData;
  config: ChartConfig;
  metricValueFormatter: MetricValueFormatter;
};

const DEFAULT_CONFIG = {
  tension: 0.05,
  borderWidth: 3,
  pointRadius: 0,
  borderJoinStyle: 'round'
};

const LineChart = ({ legends, data, config, metricValueFormatter }: ChartProps) => {
  /**
   * configuration for line chart
   */
  if (config.type !== 'LINE_CHART') return null;
  let max = -Infinity;
  let min = Infinity;
  const labels: string[] = _(data).map(config.labelField).value();
  const datasets = _(config.datasets)
    .map(({ dataField, labelForDataField, ...restOfConfig }, index) => {
      return {
        ...DEFAULT_CONFIG,
        label: legends[index],
        data: _(data).map(dataField).value(),
        labels: labelForDataField ? _(data).map(labelForDataField).value() : undefined,
        ...restOfConfig
      };
    })
    .filter('label')
    .value();

  datasets.forEach(({ data: mappedData }: any) => {
    if (mappedData) {
      max = _.max(_(mappedData).concat(max).value());
      min = _.min(_(mappedData).concat(min).value());
    }
  });

  if (max === min && max === 0) {
    min = 0;
    max = 1;
  }

  const lineOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'bottom', align: 'end', labels: { boxWidth: 12 } },
      tooltip: {
        callbacks: {
          title: () => '',
          label: context => {
            let label = _.get(context, `dataset.labels.${context.dataIndex}`, '');
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += `${metricValueFormatter(context.parsed.y)}  `;
            }
            return label;
          }
        }
      }
    },
    interaction: { intersect: false, mode: 'index' },
    scales: {
      y: {
        ticks: {
          autoSkipPadding: 8
        },
        suggestedMin: min,
        suggestedMax: max,
        grid: { z: 1 } as any
      },
      x: {
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          autoSkipPadding: 15,
          backdropPadding: 10
        },
        grid: { z: 1 } as any
      }
    }
  };

  return <Line data={{ labels, datasets }} options={lineOptions} />;
};

export default memo(LineChart);
