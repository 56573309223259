/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 20th 2021, 11:14:33 am
 *
 * *****************************************************
 */

import { Button, Frame, Loading } from '@shopify/polaris';
import { Helmet } from 'react-helmet';

import { ReactComponent as LogoIcon } from 'assets/images/logoWhite.svg';

import { LayoutContainer, TopBar, FrameContainer, TopBarCompanyLogoButtonContainer } from './styles';

const WorkspaceDashboardLoading = () => (
  <LayoutContainer>
    <Helmet>
      <title>WiseIO</title>
    </Helmet>
    <TopBar>
      <TopBarCompanyLogoButtonContainer>
        <Button icon={<LogoIcon />} />
      </TopBarCompanyLogoButtonContainer>
    </TopBar>
    <main>
      <FrameContainer>
        <Frame>
          <Loading />
        </Frame>
      </FrameContainer>
    </main>
  </LayoutContainer>
);

export default WorkspaceDashboardLoading;
