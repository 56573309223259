/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Oct 1st 2021, 1:27:45 pm
 *
 * *****************************************************
 */

import { useCallback, useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import { useParams } from 'react-router-dom';

// import RelativeTime from 'features/common/views/components/RelativeTime';
import ForecastChartCard from 'features/project/views/pages/ProjectMetric/MetricCard/ForecastChartCard';
import MetricChartCard from 'features/project/views/pages/ProjectMetric/MetricCard/MetricChartCard';
import MetricTableCard from 'features/project/views/pages/ProjectMetric/MetricCard/MetricTableCard';
import MetricChartCardLoading from 'features/project/views/pages/ProjectMetric/MetricCardLoading/MetricChartCardLoading';
import MetricTableCardLoading from 'features/project/views/pages/ProjectMetric/MetricCardLoading/MetricTableCardLoading';
import DatePicker from 'features/project/views/pages/ProjectMetric/MetricDatePicker';
import {
  useFetchMRRBreakdownQuery,
  useFetchMRRChartQuery,
  useFetchMRRGrowthChartQuery
} from 'states/services/metrics/revenue/mrr';
import { MRRBreakdownResponse, MRRChartResponse, MRRGrowthChartResponse } from 'states/services/metrics/revenue/mrr/types';
import { createTextDatePickerRange, formatCurrency, formatDate, formatDecimalNumber, formatPercent } from 'utils/project';

import { PageContentContainer, DatePickerContainer, ChartContainer, PlansCardContainer } from './styles';

const INITIAL_FORM = {
  forecast: false,
  regression: 'linear',
  frameWidth: 'six_month',
  forecastFrom: moment().startOf('day').toISOString(),
  forecastTo: moment().startOf('day').add(6, 'months').toISOString()
};

const MRRPage = () => {
  const { projectId } = useParams<{ projectId: string }>();
  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedCurrentValue, setCommittedCurrentValue] = useState(() => {
    const last30days = {
      start: moment().subtract(30, 'day').toDate(),
      end: moment().subtract(1, 'day').toDate()
    };
    return last30days;
  });

  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedPreviousValue, setCommittedPreviousValue] = useState(() => {
    const last60days = {
      start: moment().subtract(60, 'day').toDate(),
      end: moment().subtract(31, 'day').toDate()
    };
    return last60days;
  });

  const [hasPreviousComparison, setHasPreviousComparison] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [forecastForm, setForecastForm] = useState(INITIAL_FORM);

  const onForecastFormSubmit = useCallback(partialForm => {
    setForecastForm(prev => {
      return { ...prev, ...partialForm };
    });
  }, []);

  /**
   * initial data before calling api
   * data chart fetching by projectId, fromDate, toDate
   */
  const mrrChart = useFetchMRRChartQuery(
    {
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end),
      ...forecastForm
    },
    { refetchOnMountOrArgChange: true }
  );

  const mrrBreakdown = useFetchMRRBreakdownQuery(
    {
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end)
    },
    { refetchOnMountOrArgChange: true }
  );

  const mrrGrowthChart = useFetchMRRGrowthChartQuery(
    {
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end)
    },
    { refetchOnMountOrArgChange: true }
  );

  const chartLegends = useMemo(() => {
    const { forecast, forecastFrom, forecastTo } = forecastForm;
    if (forecast) {
      return [createTextDatePickerRange(moment(forecastFrom).toDate(), moment(forecastTo).toDate())];
    }
    return [committedCurrentValue, hasPreviousComparison && committedPreviousValue].map(dateRange => {
      if (!dateRange) return '';
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    });
  }, [committedCurrentValue, committedPreviousValue, forecastForm, hasPreviousComparison]);

  const recurringRevenueChart = (() => {
    let children = null;
    if (mrrChart.isLoading) {
      children = <MetricChartCardLoading hasOverview hasChart size="large" />;
    } else {
      const { mrrMetric } = mrrChart.data as MRRChartResponse;
      const { overview: metricOverviewData, data: metricChartData } = mrrMetric;
      children = (
        <ForecastChartCard
          metricName="mrr"
          size="large"
          isFetching={mrrChart.isFetching}
          overviewConfig={{
            type: 'time-filter',
            forecastForm,
            updateFormCallback: onForecastFormSubmit,
            data: metricOverviewData
          }}
          chartConfig={{
            visible: true,
            data: metricChartData,
            legends: chartLegends
          }}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  })();

  const breakdownTable = useMemo(() => {
    let children = null;
    if (mrrBreakdown.isFetching) children = <MetricTableCardLoading />;
    else {
      const { breakdown } = mrrBreakdown.data as MRRBreakdownResponse;
      const dataTableMapping = {
        key: {
          tableIndex: 0
        },
        existingMrr: {
          tableIndex: 1,
          valueFormatter: formatCurrency
        },
        mrrNew: {
          tableIndex: 2,
          valueFormatter: formatCurrency
        },
        mrrReactivation: {
          tableIndex: 3,
          valueFormatter: formatCurrency
        },
        mrrUpgrade: {
          tableIndex: 4,
          valueFormatter: formatCurrency
        },
        mrrDowngrade: {
          tableIndex: 5,
          valueFormatter: formatCurrency
        },
        mrrCancellation: {
          tableIndex: 6,
          valueFormatter: formatCurrency
        },
        mrrFrozen: {
          tableIndex: 7,
          valueFormatter: formatCurrency
        },
        mrrUnfrozen: {
          tableIndex: 8,
          valueFormatter: formatCurrency
        },
        mrr: {
          tableIndex: 9,
          valueFormatter: formatCurrency
        }
      };
      const tableSortFunction = (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => {
        if (index === 0)
          return [...rows].sort((rowA, rowB) => {
            const amountA = moment(rowA[index], 'MMM DD, YYYY');
            const amountB = moment(rowB[index], 'MMM DD, YYYY');

            return direction === 'descending' ? amountB.diff(amountA) : amountA.diff(amountB);
          });
        return rows;
      };

      children = (
        <MetricTableCard
          cardTitle="Breakdown"
          columnContentTypes={[
            'text',
            'numeric',
            'numeric',
            'numeric',
            'numeric',
            'numeric',
            'numeric',
            'numeric',
            'numeric',
            'numeric'
          ]}
          headings={[
            'Day',
            'Existing',
            'New',
            'Reactivations',
            'Upgrades',
            'Downgrades',
            'Cancellations',
            'Frozen',
            'Unfrozen',
            'MRR'
          ]}
          hideTotals
          sortable={[true, false, false, false, false, false, false, false, false]}
          defaultSortDirection="descending"
          data={breakdown}
          dataTableMapping={dataTableMapping}
          sortFunction={tableSortFunction}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  }, [mrrBreakdown]);

  const dailyGrowthChart = useMemo(() => {
    let children = null;
    if (mrrGrowthChart.isFetching) children = <MetricChartCardLoading hasChart size="large" />;
    else {
      const { growth } = mrrGrowthChart.data as MRRGrowthChartResponse;
      const { data: metricChartData } = growth;
      children = (
        <MetricChartCard
          metricName="mrrDailyGrowth"
          size="large"
          overviewConfig={{
            type: 'time-filter',
            hideTooltip: true
          }}
          chartConfig={{
            show: true,
            data: metricChartData,
            legends: []
          }}
          hasPreviousComparison={hasPreviousComparison}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  }, [hasPreviousComparison, mrrGrowthChart]);

  const planTable = useMemo(() => {
    let children = null;
    if (mrrBreakdown.isFetching) children = <MetricTableCardLoading />;
    else {
      const { plan } = mrrBreakdown.data as MRRBreakdownResponse;
      const dataTableMapping = {
        planName: {
          tableIndex: 0
        },
        interval: {
          tableIndex: 1
        },
        price: {
          tableIndex: 2,
          valueFormatter: formatCurrency
        },
        activeCustomers: {
          tableIndex: 3,
          valueFormatter: formatDecimalNumber
        },
        percent: {
          tableIndex: 4,
          valueFormatter: (value: number) => formatPercent(value / 100)
        },
        mrr: {
          tableIndex: 5,
          valueFormatter: formatCurrency
        }
      };

      const tableSortFunction = (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => {
        if (index === 0)
          return [...rows].sort((rowA, rowB) => {
            const amountA = rowA[index] as string;
            const amountB = rowB[index] as string;

            return direction === 'descending' ? amountB.localeCompare(amountA) : amountA.localeCompare(amountB);
          });

        return rows;
      };

      children = (
        <MetricTableCard
          cardTitle="Plans"
          columnContentTypes={['text', 'text', 'numeric', 'numeric', 'numeric', 'numeric']}
          headings={['Plan', 'Interval', 'Price', 'Active Customers', 'Percentage', 'MRR']}
          totalsName={{ singular: 'Summary', plural: 'Summary' }}
          sortable={[true, false, false, false, false, false]}
          defaultSortDirection="descending"
          data={plan}
          dataTableMapping={dataTableMapping}
          sortFunction={tableSortFunction}
        />
      );
    }
    return <PlansCardContainer>{children}</PlansCardContainer>;
  }, [mrrBreakdown]);

  return (
    <PageContentContainer>
      <DatePickerContainer>
        <DatePicker
          hasPreviousComparison={hasPreviousComparison}
          setHasPreviousComparison={setHasPreviousComparison}
          committedCurrentValue={committedCurrentValue}
          setCommittedCurrentValue={setCommittedCurrentValue}
          committedPreviousValue={committedPreviousValue}
          setCommittedPreviousValue={setCommittedPreviousValue}
        />
      </DatePickerContainer>
      {recurringRevenueChart}
      {breakdownTable}
      {dailyGrowthChart}
      {planTable}
    </PageContentContainer>
  );
};

export default MRRPage;
