/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Sun, Jul 25th 2021, 11:50:06 pm
 *
 * *****************************************************
 */

import { TextStyle, SkeletonBodyText } from '@shopify/polaris';

import { workspaceAPI } from 'states/services/workspace';
import { selectCurrentUser } from 'states/slices/auth/selectors';
import { CurrentUser } from 'states/slices/auth/types';
import { Workspace } from 'states/slices/workspace/types';
import { useAppSelector } from 'utils/hooks';

import { Container, ContentContainer, SkeletonContainer } from './styles';

const WelcomeBlock = () => {
  const { isLoading, isSuccess, data } = workspaceAPI.endpoints.getWorkspaceBasicInfo.useQueryState();
  const { name: workspaceName } = (data as Workspace) ?? { name: '' };
  const currentUser = useAppSelector(selectCurrentUser) as CurrentUser;

  return (
    <Container>
      {isLoading ? (
        <SkeletonContainer>
          <SkeletonBodyText lines={2} />
        </SkeletonContainer>
      ) : (
        <ContentContainer>
          <h1>{isSuccess ? `${workspaceName} Dashboard` : 'Welcome!'}</h1>
          <TextStyle variation="subdued">Welcome, {currentUser.fullName}! Select or create a project to start.</TextStyle>
        </ContentContainer>
      )}
    </Container>
  );
};

export default WelcomeBlock;
