/**
 * Copyright (C) Boost commerce
 * This file is part of commercial Boost commerce projects
 *
 * This file can not be copied and/or distributed without the express
 * permission of Boost commerce
 *
 * Created on Mon Jan 15 2024 17:18:37
 */

import { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';

import RelativeTime from 'features/common/views/components/RelativeTime';
import ForecastChartCard from 'features/metrics/views/components/cards/ForecastChartCard';
import MetricChartCard from 'features/metrics/views/components/cards/MetricChartCard';
import MetricTableCard from 'features/metrics/views/components/cards/MetricTableCard';
import DatePicker from 'features/metrics/views/components/DatePicker';
import MetricChartCardLoading from 'features/metrics/views/components/MetricCardLoading/MetricChartCardLoading';
import MetricTableCardLoading from 'features/metrics/views/components/MetricCardLoading/MetricTableCardLoading';
import {
  useFetchFailedChargeBreakdownQuery,
  useFetchFailedChargeChartQuery,
  useFetchFailedChargeGrowthChartQuery
} from 'states/services/metrics/revenue/failed-charge';
import {
  FailedChargeBreakdownResponse,
  FailedChargeChartResponse,
  FailedChargeGrowthChartResponse
} from 'states/services/metrics/revenue/failed-charge/types';
import { createTextDatePickerRange, formatCurrency, formatDate } from 'utils/project';

import {
  PageContentContainer,
  DatePickerContainer,
  ChartContainer,
  NoticeBlock,
  FailedChargesTableCardContainer
} from './styles';

const INITIAL_FORM = {
  forecast: false,
  regression: 'linear',
  frameWidth: 'six_month',
  forecastFrom: moment().startOf('day').toISOString(),
  forecastTo: moment().startOf('day').add(6, 'months').toISOString()
};

const FailedChargesPage = () => {
  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedCurrentValue, setCommittedCurrentValue] = useState(() => {
    const last30days = {
      start: moment().subtract(30, 'day').toDate(),
      end: moment().subtract(1, 'day').toDate()
    };
    return last30days;
  });

  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedPreviousValue, setCommittedPreviousValue] = useState(() => {
    const last60days = {
      start: moment().subtract(60, 'day').toDate(),
      end: moment().subtract(31, 'day').toDate()
    };
    return last60days;
  });

  const [hasPreviousComparison, setHasPreviousComparison] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [forecastForm, setForecastForm] = useState(INITIAL_FORM);

  const onForecastFormSubmit = useCallback(partialForm => {
    setForecastForm(prev => {
      return { ...prev, ...partialForm };
    });
  }, []);

  /**
   * initial data before calling api
   * data chart fetching by projectId, fromDate, toDate
   */
  const chartResponse = useFetchFailedChargeChartQuery(
    {
      projectId: 'aggregated',
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end),
      ...forecastForm
    },
    { refetchOnMountOrArgChange: true }
  );

  const growthChartResponse = useFetchFailedChargeGrowthChartQuery(
    {
      projectId: 'aggregated',
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end)
    },
    { refetchOnMountOrArgChange: true }
  );

  const breakdownResponse = useFetchFailedChargeBreakdownQuery(
    {
      projectId: 'aggregated',
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end)
    },
    { refetchOnMountOrArgChange: true }
  );

  const metricCalculatedAt = _.get(chartResponse, 'data.metricCalculatedAt', null);

  const chartLegends = useMemo(() => {
    const { forecast, forecastFrom, forecastTo } = forecastForm;
    if (forecast) {
      return [createTextDatePickerRange(moment(forecastFrom).toDate(), moment(forecastTo).toDate())];
    }
    return [committedCurrentValue, hasPreviousComparison && committedPreviousValue].map(dateRange => {
      if (!dateRange) return '';
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    });
  }, [committedCurrentValue, committedPreviousValue, forecastForm, hasPreviousComparison]);

  const failedChargesChart = (() => {
    let children = null;
    const { isLoading, isFetching, data } = chartResponse;
    if (isLoading) children = <MetricChartCardLoading hasOverview hasChart size="large" />;
    else {
      const { failedCharge } = data as FailedChargeChartResponse;
      const { overview: metricOverviewData, data: metricChartData } = failedCharge;
      children = (
        <ForecastChartCard
          metricName="failedCharges"
          size="large"
          isFetching={isFetching}
          overviewConfig={{
            type: 'time-filter',
            forecastForm,
            updateFormCallback: onForecastFormSubmit,
            data: metricOverviewData
          }}
          chartConfig={{
            visible: true,
            data: metricChartData,
            legends: chartLegends
          }}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  })();

  const dailyGrowthChart = useMemo(() => {
    let children = null;
    const { isFetching, data } = growthChartResponse;
    if (isFetching) children = <MetricChartCardLoading hasChart size="large" />;
    else {
      const { growth } = data as FailedChargeGrowthChartResponse;
      const { data: metricChartData } = growth;
      children = (
        <MetricChartCard
          metricName="failedChargesDailyGrowth"
          size="large"
          overviewConfig={{
            type: 'time-filter',
            hideTooltip: true
          }}
          chartConfig={{
            show: true,
            data: metricChartData,
            legends: []
          }}
          hasPreviousComparison={hasPreviousComparison}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  }, [hasPreviousComparison, growthChartResponse]);

  const breakdownTable = useMemo(() => {
    let children = null;
    const { isFetching, data } = breakdownResponse;
    if (isFetching) children = <MetricTableCardLoading />;
    else {
      const { breakdown } = data as FailedChargeBreakdownResponse;
      const dataTableMapping = {
        key: {
          tableIndex: 0
        },
        frozenCharge: {
          tableIndex: 1
        },
        declineCharge: {
          tableIndex: 2
        },
        total: {
          tableIndex: 3
        }
      };

      const tableSortFunction = (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => {
        if (index === 0)
          return [...rows].sort((rowA, rowB) => {
            const amountA = moment(rowA[index], 'MMM DD, YYYY');
            const amountB = moment(rowB[index], 'MMM DD, YYYY');

            return direction === 'descending' ? amountB.diff(amountA) : amountA.diff(amountB);
          });
        return rows;
      };

      children = (
        <MetricTableCard
          cardTitle="Breakdown"
          columnContentTypes={['text', 'numeric', 'numeric', 'numeric']}
          headings={['Date', 'Frozen Charge', 'Decline Charge', 'Total']}
          totalsName={{ singular: 'Summary', plural: 'Summary' }}
          sortable={[true, false, false, false]}
          defaultSortDirection="descending"
          data={breakdown}
          dataTableMapping={dataTableMapping}
          sortFunction={tableSortFunction}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  }, [breakdownResponse]);

  const failedChargeTable = useMemo(() => {
    let children = null;
    const { isFetching, data } = breakdownResponse;
    if (isFetching) children = <MetricTableCardLoading />;
    else {
      const { failedCharge } = data as FailedChargeBreakdownResponse;
      const dataTableMapping = {
        shopName: {
          tableIndex: 0
        },
        date: {
          tableIndex: 1
        },
        type: {
          tableIndex: 2
        },
        mrrLost: {
          tableIndex: 3,
          valueFormatter: (value: number | null) => (value === null ? '—' : formatCurrency(value))
        }
      };

      const tableSortFunction = (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => {
        if (index === 1)
          return [...rows].sort((rowA, rowB) => {
            const amountA = moment(rowA[index], 'MMM DD, YYYY');
            const amountB = moment(rowB[index], 'MMM DD, YYYY');

            return direction === 'descending' ? amountB.diff(amountA) : amountA.diff(amountB);
          });
        if (index === 0 || index === 2)
          return [...rows].sort((rowA, rowB) => {
            const amountA = rowA[index] as string;
            const amountB = rowB[index] as string;

            return direction === 'descending' ? amountB.localeCompare(amountA) : amountA.localeCompare(amountB);
          });

        return rows;
      };

      children = (
        <MetricTableCard
          cardTitle="Failed Charges"
          columnContentTypes={['text', 'text', 'text', 'numeric']}
          headings={['Name', 'Date', 'Type', 'Loss']}
          totalsName={{ singular: 'Summary', plural: 'Summary' }}
          sortable={[true, true, true, false]}
          defaultSortDirection="descending"
          data={failedCharge}
          dataTableMapping={dataTableMapping}
          sortFunction={tableSortFunction}
        />
      );
    }
    return <FailedChargesTableCardContainer>{children}</FailedChargesTableCardContainer>;
  }, [breakdownResponse]);

  return (
    <PageContentContainer>
      <DatePickerContainer>
        <DatePicker
          hasPreviousComparison={hasPreviousComparison}
          setHasPreviousComparison={setHasPreviousComparison}
          committedCurrentValue={committedCurrentValue}
          setCommittedCurrentValue={setCommittedCurrentValue}
          committedPreviousValue={committedPreviousValue}
          setCommittedPreviousValue={setCommittedPreviousValue}
        />
      </DatePickerContainer>
      {failedChargesChart}
      {dailyGrowthChart}
      {breakdownTable}
      {failedChargeTable}
      <NoticeBlock>
        <p>
          {chartResponse.isFetching ? (
            'Your data is loading...'
          ) : (
            <>
              Your data was last updated&nbsp;
              <RelativeTime value={metricCalculatedAt} />
            </>
          )}
        </p>
      </NoticeBlock>
    </PageContentContainer>
  );
};

export default FailedChargesPage;
