import { Endpoint } from 'states/services/types';

export const METRICS_MRR_UPGRADE_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/mrr-upgrade/chart',
  isPrivate: true
};

export const METRICS_MRR_UPGRADE_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/mrr-upgrade/breakdown',
  isPrivate: true
};
