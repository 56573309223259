/**
 * Copyright (C) Boost commerce
 * This file is part of commercial Boost commerce projects
 *
 * This file can not be copied and/or distributed without the express
 * permission of Boost commerce
 *
 * Created on Thu Jan 11 2024 18:07:31
 */

import { Button, SkeletonDisplayText, Stack } from '@shopify/polaris';
import { Helmet } from 'react-helmet';

import { ReactComponent as LogoIcon } from 'assets/images/logoWhite.svg';
import FrameLoading from 'features/common/views/components/FrameLoading';

import { LayoutContainer, TopBar, TopBarCompanyLogoButtonContainer, SkeletonContainer } from './styles';

const PageLoading = () => (
  <LayoutContainer>
    <Helmet>
      <title>WiseIO</title>
    </Helmet>
    <TopBar>
      <div>
        <Stack alignment="center" spacing="tight">
          <TopBarCompanyLogoButtonContainer>
            <Button icon={<LogoIcon />} />
          </TopBarCompanyLogoButtonContainer>
          <SkeletonContainer>
            <SkeletonDisplayText size="medium" />
          </SkeletonContainer>
        </Stack>
      </div>
    </TopBar>
    <main>
      <FrameLoading />
    </main>
  </LayoutContainer>
);

export default PageLoading;
