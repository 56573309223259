/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 26th 2021, 1:25:46 pm
 *
 * *****************************************************
 */

import { Endpoint } from 'states/services/types';

export const PROJECTS_ENDPOINT: Endpoint = {
  url: '/base/projects',
  isPrivate: true
};
export const PROJECTS_METRICS_SETTINGS_ENDPOINT: Endpoint = {
  url: '/base/projects/:projectId/metrics-settings',
  isPrivate: true
};
export const PROJECTS_GENERAL_SETTINGS_ENDPOINT: Endpoint = {
  url: '/base/projects/:projectId/general-settings',
  isPrivate: true
};
export const PROJECTS_BASIC_INFO_ENDPOINT: Endpoint = {
  url: '/base/projects/:projectId/info',
  isPrivate: true
};
