/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Aug 12th 2021, 9:59:37 am
 *
 * *****************************************************
 */

export const redirectToSignInPage = () => {
  window.location.replace(`${process.env.HTTPS ? 'https' : 'http'}://${process.env.REACT_APP_AUTH_DOMAIN}`);
};

export const redirectToExternalPage = (url: string) => {
  window.location.assign(url);
};
