/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Oct 1st 2021, 1:27:52 pm
 *
 * *****************************************************
 */

import { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import RelativeTime from 'features/common/views/components/RelativeTime';
import ForecastChartCard from 'features/project/views/pages/ProjectMetric/MetricCard/ForecastChartCard';
import MetricTableCard from 'features/project/views/pages/ProjectMetric/MetricCard/MetricTableCard';
import MetricChartCardLoading from 'features/project/views/pages/ProjectMetric/MetricCardLoading/MetricChartCardLoading';
import MetricTableCardLoading from 'features/project/views/pages/ProjectMetric/MetricCardLoading/MetricTableCardLoading';
import DatePicker from 'features/project/views/pages/ProjectMetric/MetricDatePicker';
import {
  ChartContainer,
  CustomerTableCardContainer,
  DatePickerContainer,
  NoticeBlock,
  PageContentContainer,
  PlanTableCardContainer
} from 'features/project/views/pages/ProjectMetric/MetricPage/UserAndCustomerPageGroup/CustomerChurn/styles';
import {
  useFetchCustomerChurnBreakdownQuery,
  useFetchCustomerChurnChartQuery,
  useFetchCustomerChurnRateChartQuery
} from 'states/services/metrics/user/customer-churn';
import {
  CustomerChurnBreakdownResponse,
  CustomerChurnChartResponse,
  CustomerChurnRateChartResponse
} from 'states/services/metrics/user/customer-churn/types';
import { createTextDatePickerRange, formatCurrency, formatDate } from 'utils/project';

const INITIAL_FORM = {
  forecast: false,
  regression: 'linear',
  frameWidth: 'six_month',
  forecastFrom: moment().startOf('day').toISOString(),
  forecastTo: moment().startOf('day').add(6, 'months').toISOString()
};

const CustomerChurnPage = () => {
  const { projectId } = useParams() as { projectId: string };
  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedCurrentValue, setCommittedCurrentValue] = useState(() => {
    const last30days = {
      start: moment().subtract(30, 'day').toDate(),
      end: moment().subtract(1, 'day').toDate()
    };
    return last30days;
  });

  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedPreviousValue, setCommittedPreviousValue] = useState(() => {
    const last60days = {
      start: moment().subtract(60, 'day').toDate(),
      end: moment().subtract(31, 'day').toDate()
    };
    return last60days;
  });

  const [hasPreviousComparison, setHasPreviousComparison] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [churnForm, setChurnForm] = useState(INITIAL_FORM);

  const onChurnFormSubmit = useCallback(partialForm => {
    setChurnForm(prev => {
      return { ...prev, ...partialForm };
    });
  }, []);

  const [churnRateForm, setChurnRateForm] = useState(INITIAL_FORM);

  const onChurnRateFormSubmit = useCallback(partialForm => {
    setChurnRateForm(prev => {
      return { ...prev, ...partialForm };
    });
  }, []);

  /**
   * initial data before calling api
   * data chart fetching by projectId, fromDate, toDate
   */
  const churnChartResponse = useFetchCustomerChurnChartQuery(
    {
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end),
      ...churnForm
    },
    { refetchOnMountOrArgChange: true }
  );

  const churnRateChartResponse = useFetchCustomerChurnRateChartQuery(
    {
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end),
      ...churnRateForm
    },
    { refetchOnMountOrArgChange: true }
  );

  const breakdownResponse = useFetchCustomerChurnBreakdownQuery(
    {
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end)
    },
    { refetchOnMountOrArgChange: true }
  );

  const metricCalculatedAt = _.get(churnChartResponse, 'data.metricCalculatedAt', null);

  const churnLegends = useMemo(() => {
    const { forecast, forecastFrom, forecastTo } = churnForm;
    if (forecast) {
      return [createTextDatePickerRange(moment(forecastFrom).toDate(), moment(forecastTo).toDate())];
    }
    return [committedCurrentValue, hasPreviousComparison && committedPreviousValue].map(dateRange => {
      if (!dateRange) return '';
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    });
  }, [churnForm, committedCurrentValue, committedPreviousValue, hasPreviousComparison]);

  const churnRateLegends = useMemo(() => {
    const { forecast, forecastFrom, forecastTo } = churnRateForm;
    if (forecast) {
      return [createTextDatePickerRange(moment(forecastFrom).toDate(), moment(forecastTo).toDate())];
    }
    return [committedCurrentValue, hasPreviousComparison && committedPreviousValue].map(dateRange => {
      if (!dateRange) return '';
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    });
  }, [churnRateForm, committedCurrentValue, committedPreviousValue, hasPreviousComparison]);

  const customerChurnRateChart = (() => {
    let children = null;
    const { isFetching, isLoading, data } = churnRateChartResponse;
    if (isLoading) children = <MetricChartCardLoading hasOverview hasChart size="large" />;
    else {
      const { customerChurnRate } = data as CustomerChurnRateChartResponse;
      const { overview: metricOverviewData, data: metricChartData } = customerChurnRate;
      children = (
        <ForecastChartCard
          metricName="customerChurnRate"
          size="large"
          isFetching={isFetching}
          overviewConfig={{
            type: 'time-filter',
            forecastForm: churnRateForm,
            updateFormCallback: onChurnRateFormSubmit,
            data: metricOverviewData
          }}
          chartConfig={{
            visible: true,
            data: metricChartData,
            legends: churnRateLegends
          }}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  })();

  const customerChurnChart = (() => {
    let children = null;
    const { isLoading, isFetching, data } = churnChartResponse;
    if (isLoading) children = <MetricChartCardLoading hasOverview hasChart size="large" />;
    else {
      const { customerChurn } = data as CustomerChurnChartResponse;
      const { overview: metricOverviewData, data: metricChartData } = customerChurn;
      children = (
        <ForecastChartCard
          metricName="customerChurn"
          size="large"
          isFetching={isFetching}
          overviewConfig={{
            type: 'time-filter',
            forecastForm: churnForm,
            updateFormCallback: onChurnFormSubmit,
            data: metricOverviewData
          }}
          chartConfig={{
            visible: true,
            data: metricChartData,
            legends: churnLegends
          }}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  })();

  const planTable = useMemo(() => {
    let children = null;
    const { isFetching, data } = breakdownResponse;
    if (isFetching) children = <MetricTableCardLoading />;
    else {
      const { plan } = data as CustomerChurnBreakdownResponse;
      const dataTableMapping = {
        planName: {
          tableIndex: 0
        },
        interval: {
          tableIndex: 1
        },
        activeCustomers: {
          tableIndex: 2
        },
        churns: {
          tableIndex: 3
        }
      };

      const tableSortFunction = (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => {
        if (index === 0)
          return [...rows].sort((rowA, rowB) => {
            const amountA = rowA[index] as string;
            const amountB = rowB[index] as string;

            return direction === 'descending' ? amountB.localeCompare(amountA) : amountA.localeCompare(amountB);
          });
        if (index === 1)
          return [...rows].sort((rowA, rowB) => {
            const amountA = moment(rowA[index], 'MMM DD, YYYY');
            const amountB = moment(rowB[index], 'MMM DD, YYYY');

            return direction === 'descending' ? amountB.diff(amountA) : amountA.diff(amountB);
          });
        return rows;
      };

      children = (
        <MetricTableCard
          cardTitle="Plans"
          columnContentTypes={['text', 'text', 'numeric', 'numeric']}
          headings={['Plan', 'Interval', 'Active customer', 'Customer churn']}
          totalsName={{ singular: 'Summary', plural: 'Summary' }}
          sortable={[true, false, false, false]}
          defaultSortDirection="descending"
          data={plan}
          dataTableMapping={dataTableMapping}
          sortFunction={tableSortFunction}
        />
      );
    }
    return <PlanTableCardContainer>{children}</PlanTableCardContainer>;
  }, [breakdownResponse]);

  const customerTable = useMemo(() => {
    let children = null;
    const { isFetching, data } = breakdownResponse;
    if (isFetching) children = <MetricTableCardLoading />;
    else {
      const { breakdown } = data as CustomerChurnBreakdownResponse;
      const dataTableMapping = {
        shopName: {
          tableIndex: 0
        },
        date: {
          tableIndex: 1
        },
        planName: {
          tableIndex: 2
        },
        type: {
          tableIndex: 3
        },
        mrrChurn: {
          tableIndex: 4,
          valueFormatter: formatCurrency
        }
      };

      const tableSortFunction = (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => {
        if (index === 0)
          return [...rows].sort((rowA, rowB) => {
            const amountA = rowA[index] as string;
            const amountB = rowB[index] as string;

            return direction === 'descending' ? amountB.localeCompare(amountA) : amountA.localeCompare(amountB);
          });
        if (index === 1)
          return [...rows].sort((rowA, rowB) => {
            const amountA = moment(rowA[index], 'MMM DD, YYYY');
            const amountB = moment(rowB[index], 'MMM DD, YYYY');

            return direction === 'descending' ? amountB.diff(amountA) : amountA.diff(amountB);
          });
        return rows;
      };

      children = (
        <MetricTableCard
          cardTitle="Customers"
          columnContentTypes={['text', 'text', 'text', 'text', 'numeric']}
          headings={['Name', 'Date', 'Plan', 'Type', 'MRR Churn']}
          totalsName={{ singular: 'Summary', plural: 'Summary' }}
          sortable={[true, true, true, true, false]}
          defaultSortDirection="descending"
          data={breakdown}
          dataTableMapping={dataTableMapping}
          sortFunction={tableSortFunction}
        />
      );
    }
    return <CustomerTableCardContainer>{children}</CustomerTableCardContainer>;
  }, [breakdownResponse]);

  return (
    <PageContentContainer>
      <DatePickerContainer>
        <DatePicker
          hasPreviousComparison={hasPreviousComparison}
          setHasPreviousComparison={setHasPreviousComparison}
          committedCurrentValue={committedCurrentValue}
          setCommittedCurrentValue={setCommittedCurrentValue}
          committedPreviousValue={committedPreviousValue}
          setCommittedPreviousValue={setCommittedPreviousValue}
        />
      </DatePickerContainer>
      {customerChurnRateChart}
      {customerChurnChart}
      {planTable}
      {customerTable}
      <NoticeBlock>
        <p>
          {churnRateChartResponse.isFetching ? (
            'Your data is loading...'
          ) : (
            <>
              Your data was last updated&nbsp;
              <RelativeTime value={metricCalculatedAt} />
            </>
          )}
        </p>
      </NoticeBlock>
    </PageContentContainer>
  );
};

export default CustomerChurnPage;
