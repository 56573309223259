/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Jul 21st 2021, 10:07:44 am
 *
 * *****************************************************
 */

import { List, Banner } from '@shopify/polaris';
import { FORM_ERROR, SubmissionErrors } from 'final-form';

import { Container } from './styles';

type Props = {
  data: SubmissionErrors;
};

/**
 * Error banner for form error -
 * Description: Whole form error (banner title);
 * individual error for form field (a list of error below banner title)
 */
const FormErrorBanner = ({ data }: Props): JSX.Element => {
  const { [FORM_ERROR]: wholeFormErrorMessage, ...fieldErrors } = data as {
    [FORM_ERROR]: string;
    [key: string]: string;
  };
  const fieldErrorsArray = Object.entries(fieldErrors);
  return (
    <Container>
      <Banner status="critical" title={wholeFormErrorMessage}>
        {fieldErrorsArray.length !== 0 && (
          <List type="bullet">
            {fieldErrorsArray.map(([fieldName, fieldError]) => (
              <List.Item key={fieldName}>{fieldError}</List.Item>
            ))}
          </List>
        )}
      </Banner>
    </Container>
  );
};

export default FormErrorBanner;
