import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import { buildParams } from 'states/services/metrics';
import { GetMetricRequest } from 'states/services/metrics/types';

import * as Endpoints from './endpoints';
import * as Types from './types';

const costApi = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    fetchCostChart: builder.query<Types.CostChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_COST_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_COST_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.CostChartResponse }) => {
        return response.data;
      }
    }),
    fetchCostBreakdown: builder.query<Types.CostBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_COST_BREAKDOWN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_COST_BREAKDOWN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.CostBreakdownResponse }) => {
        return response.data;
      }
    })
  })
});

export const { useFetchCostBreakdownQuery, useFetchCostChartQuery } = costApi;
