/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 27th 2021, 9:57:11 am
 *
 * *****************************************************
 */

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  flex-direction: column;
  align-items: center;

  width: 58.1rem;
  margin: 0 auto;

  @media (max-width: 38.8125em) {
    width: 100%;
    padding: 0 2rem;
  }
`;

export const FooterActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 58.1rem;
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 6.3rem;

  & > * {
    width: 100%;
  }

  @media (max-width: 38.8125em) {
    width: 100%;
    padding: 2rem 2rem 4rem;
  }
`;
