/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 29th 2021, 10:02:41 am
 *
 * *****************************************************
 */

import { SkeletonDisplayText } from '@shopify/polaris';

import { Container } from './styles';

const LoadingContent = () => {
  return (
    <Container>
      <SkeletonDisplayText />
      <SkeletonDisplayText />
      <SkeletonDisplayText />
      <SkeletonDisplayText />
      <SkeletonDisplayText />
      <SkeletonDisplayText />
      <SkeletonDisplayText />
      <SkeletonDisplayText />
      <SkeletonDisplayText />
      <SkeletonDisplayText />
    </Container>
  );
};

export default LoadingContent;
