/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Oct 4th 2021, 3:34:36 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';

export const FirstRow = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h2`
  ${focus2Texts.website.heading['H5 - 14px']}
  margin-right: 0.8rem;
`;

export const TooltipActivator = styled.div`
  .Polaris-Icon {
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
  }
`;

export const SecondRow = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 0.8rem;
`;

export const BigNumber = styled.p`
  ${focus2Texts.website.heading['H2 - 24px']}
  margin-right: 0.4rem;
`;

export const PreviousBigNumberContainer = styled.p`
  ${focus2Texts.website['Body 2 - 14px']}
  color: ${focus2Colors.text.subdued};
`;

export const PreviousBigNumber = styled.span`
  ${focus2Texts.website.heading['H5 - 14px']}
`;

export const TooltipContent = styled.div`
  padding: 1.6rem 1.2rem;
`;

export const TooltipTitle = styled.div`
  ${focus2Texts.website.heading['H4 - 16px']}
  margin-bottom: 0.4rem;
`;

export const TooltipButtonContainer = styled.div`
  margin-top: 2rem;
`;

export const MovementIndicatorContainer = styled.div<{ alignLeftMovementIndicator: boolean }>`
  margin-left: ${({ alignLeftMovementIndicator }) => (alignLeftMovementIndicator ? '' : 'auto')};
`;

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > .Polaris-SkeletonDisplayText--sizeMedium:first-child {
    width: 8.8rem;
    height: 1.6rem;
  }

  & > .Polaris-SkeletonDisplayText--sizeMedium:nth-child(2) {
    max-width: 16rem;
    width: 16rem;
    height: 2rem;
    margin-top: 1.6rem;
    margin-bottom: 0.8rem;
  }

  & > .Polaris-SkeletonDisplayText--sizeMedium:last-child {
    width: 11.8rem;
    height: 1.4rem;
  }
`;
