/* eslint-disable react/jsx-props-no-spreading */
/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 20th 2021, 10:20:24 pm
 *
 * *****************************************************
 */

import { useCallback } from 'react';

import { TextField, TextFieldProps } from '@shopify/polaris';
import { FieldRenderProps } from 'react-final-form';

import { Container } from './styles';

type Props = TextFieldProps & FieldRenderProps<any>;

/**
 * A text field adapter for React Final Form's Field component.
 * Description: It use Polaris' TextField component as base.
 */
const TextFieldAdapter = ({ input, meta, ...rest }: Props) => {
  const { value, name, onChange } = input;

  const handleChange = useCallback(changedValue => onChange(changedValue), [onChange]);

  let errorContent = null;
  if (meta.touched) {
    if (meta.error) errorContent = meta.error;
    else if (!meta.dirtySinceLastSubmit) errorContent = meta.submitError;
  }

  return (
    <Container>
      <TextField {...rest} value={value} name={name} onChange={handleChange} error={errorContent} />
    </Container>
  );
};

export default TextFieldAdapter;
