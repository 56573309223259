/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Sep 21st 2021, 4:52:01 am
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';

export const SidebarContainer = styled.div<{ active: boolean }>`
  position: fixed;
  top: 5.6rem;
  bottom: 0;
  left: 0;
  min-height: calc(100vh - 5.6rem);
  z-index: 1;

  box-shadow: inset -1px 0px 0px ${focus2Colors.surface.neutral.default};

  padding-bottom: 2rem;

  background-color: ${focus2Colors.background.default};

  // hide scroll bar but still being able to scroll
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    /* Chrome */
    width: 0;
    height: 0;
  }

  @media (max-width: 64em) {
    display: none;
    box-shadow: none;

    ${({ active }) =>
      active &&
      `
      display: block;
      right: 0;
    `}
  }
`;

export const PageHeaderContainer = styled.div`
  padding: 1.6rem 2.4rem 2rem;
  display: none;

  @media (max-width: 64em) {
    display: block;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 24rem;
  height: 100%;
  padding-top: 3.2rem;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style-type: none;
  }

  @media (max-width: 64em) {
    width: 100%;
    padding: 0;
  }
`;
