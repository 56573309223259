/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Aug 17th 2021, 11:45:16 am
 *
 * *****************************************************
 */

import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

/**
 * Get status code of an FetchBaseQueryError object
 * @param errorObject - FetchBaseQueryError object
 * @returns Error status code
 */
export const getFetchBaseQueryErrorStatusCode = (errorObject: FetchBaseQueryError): number | string => {
  if (errorObject.status === 'PARSING_ERROR') return errorObject.originalStatus;
  return errorObject.status;
};
