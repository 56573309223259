/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 29th 2021, 7:56:23 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  margin-top: 1rem;
`;
