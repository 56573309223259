import { Endpoint } from 'states/services/types';

export const METRICS_CUSTOMER_REACTIVATION_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/customer-reactivation/chart',
  isPrivate: true
};

export const METRICS_CUSTOMER_REACTIVATION_OTHER_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/customer-reactivation/other-chart',
  isPrivate: true
};

export const METRICS_CUSTOMER_REACTIVATION_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/customer-reactivation/breakdown',
  isPrivate: true
};

export const METRICS_CUSTOMER_REACTIVATION_CHART_V2_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user-v2/customer-reactivation/chart',
  isPrivate: true
};

export const METRICS_CUSTOMER_REACTIVATION_OTHER_CHART_V2_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user-v2/customer-reactivation/other-chart',
  isPrivate: true
};

export const METRICS_CUSTOMER_REACTIVATION_BREAKDOWN_V2_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user-v2/customer-reactivation/breakdown',
  isPrivate: true
};
