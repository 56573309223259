/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Oct 1st 2021, 2:03:09 pm
 *
 * *****************************************************
 */

import { FillTarget, ComplexFillTarget } from 'chart.js';

import focus2Colors from 'constants/focus-2-colors';
// import * as MetricPaths from 'features/metrics/routes/paths';
import * as ProjectMetricPaths from 'features/project/routes/paths';
import { formatCurrency, formatNumber, formatPercent } from 'utils/project';

export type LineChartDataset = {
  fill?: FillTarget | ComplexFillTarget;
  order?: number;
  label?: string; // for overwriting default label of line chart
  dataField: string;
  borderColor?: string;
  backgroundColor?: string;
  labelForDataField?: string;
};

export type BarChartDataset = {
  label?: string; // for overwriting default label for bar chart
  dataField: string;
  isPositiveMetric: boolean;
  backgroundColor: string;
};

export type DoughnutChartDataset = {
  dataField: string;
  backgroundColors: string[];
};

export type ChartConfig = { labelField: string } & (
  | {
      type: 'LINE_CHART';
      datasets: LineChartDataset[];
    }
  | {
      type: 'BAR_CHART';
      datasets: BarChartDataset[];
    }
  | {
      type: 'DOUGHNUT_CHART';
      datasets: DoughnutChartDataset[];
    }
  | {
      type: 'DYNAMIC_LINE_CHART';
      datasets: undefined;
      dataField: string;
      backgroundColors: string[];
    }
);

export type ChartType = ChartConfig['type'];

export type Datasets = ChartConfig['datasets'];

export type BlockTooltip = {
  title: string;
  descriptions: string;
  url: string;
};

export type MetricValueFormatter = (value: number | null, config?: Intl.NumberFormatOptions) => string;

type BaseBlock = {
  url: string;
  title: string;
  aggregatedUrl?: string;
  chartConfig?: ChartConfig;
  tooltip?: BlockTooltip;
  isIncreasePositiveMovement: boolean;
  metricValueFormatter: MetricValueFormatter;
};

const createValueFormatterTypeMap = <T extends { [name: string]: MetricValueFormatter }>(
  cfg: T
): Record<keyof T, MetricValueFormatter> => {
  return cfg;
};

const VALUE_FORMATTERS = createValueFormatterTypeMap({
  CURRENCY: formatCurrency,
  PERCENT: (value: number | null) => formatPercent(value && value / 100),
  DEFAULT: formatNumber
});

const createBaseBlockTypeMap = <T extends { [name: string]: BaseBlock }>(cfg: T): Record<keyof T, BaseBlock> => {
  return cfg;
};

const DEFAULT_CONFIG_LINE_CHART: ChartConfig = {
  type: 'LINE_CHART',
  labelField: 'key',
  datasets: [
    {
      dataField: 'data',
      labelForDataField: 'key',
      borderColor: focus2Colors.chart.level4.default,
      backgroundColor: focus2Colors.chart.level4.default,
      fill: { value: -Infinity, above: focus2Colors.chart.level4['default-opacity'] },
      order: 1
    },
    {
      dataField: 'previousData',
      labelForDataField: 'previousKey',
      borderColor: focus2Colors.chart.level4.orange,
      backgroundColor: focus2Colors.chart.level4.orange,
      order: 0
    }
  ]
};

const doughnutBackgroundColors = [
  focus2Colors.chart.level4.primary,
  focus2Colors.chart.level4.blue,
  focus2Colors.chart.level4.yellow,
  focus2Colors.chart.level4.orange,
  focus2Colors.chart.level4.red,
  focus2Colors.chart.level1.neutral
];

const BASE_BLOCKS = createBaseBlockTypeMap({
  netRevenue: {
    title: 'Net Revenue',
    tooltip: {
      title: 'Net Revenue',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_NET_REVENUE,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  tempGrossRevenue: {
    title: 'Gross Revenue',
    tooltip: {
      title: 'Gross Revenue',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_NET_REVENUE,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  mrr: {
    title: 'MRR',
    tooltip: {
      title: 'MRR',
      descriptions:
        'MRR (Monthly Recurring Revenue) is a measure of the predictable revenue that your company can expect to receive in a month.',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_MRR,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  mrrLost: {
    title: 'MRR Lost',
    tooltip: {
      title: 'MRR Lost',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_MRR,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  activeSubscriptions: {
    title: 'Active Customers',
    tooltip: {
      title: 'Active Customers',
      descriptions: 'Total number of paid users who are using your app.',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_ACTIVE_CUSTOMER,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  mrrChurn: {
    title: 'MRR Churn',
    tooltip: {
      title: 'MRR Churn',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  mrrChurnRate: {
    title: 'MRR Churn Rate',
    tooltip: {
      title: 'MRR Churn Rate',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.PERCENT
  },
  growthSubscription: {
    title: 'Customer Growth',
    tooltip: {
      title: 'Customer Growth',
      descriptions: 'Customer growth measures how your customers change each day.',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_CUSTOMER_GROWTH,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  totalInstallReopen: {
    title: 'Installs & Reopens',
    tooltip: {
      title: 'Installs & Reopens',
      descriptions: 'Total number of merchants installed your app or reopened their store in a day.',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  totalUninstallCloseStore: {
    title: 'Uninstalls & Closes',
    tooltip: {
      title: 'Uninstalls & Closes',
      descriptions: 'Total number of merchants uninstalled your app or closed their store in a day.',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  grossRevenue: {
    title: 'Gross Revenue',
    tooltip: {
      title: 'Gross Revenue',
      descriptions: '',
      url: ''
    },
    url: '',
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  appSaleCredit: {
    title: 'Refunds & Credits',
    tooltip: {
      title: 'Refunds & Credits',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_REFUNDS_AND_CREDITS,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  costs: {
    title: 'Costs',
    tooltip: {
      title: 'Costs',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_COSTS,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  mrrNew: {
    title: 'MRR New',
    tooltip: {
      title: 'MRR New',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_MRR_NEW,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  mrrExisting: {
    title: 'MRR Existing',
    tooltip: {
      title: 'MRR Existing',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_MRR_NEW,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  mrrUpgrade: {
    title: 'MRR Upgrade',
    tooltip: {
      title: 'MRR Upgrade',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_MRR_UPGRADE,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  mrrDowngrade: {
    title: 'MRR Downgrade',
    tooltip: {
      title: 'MRR Downgrade',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_MRR_DOWNGRADE,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  mrrReactivation: {
    title: 'MRR Reactivation',
    tooltip: {
      title: 'MRR Reactivation',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_MRR_REACTIVATION,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  mrrReinstall: {
    title: 'MRR Reinstall',
    tooltip: {
      title: 'MRR Reinstall',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_MRR_REACTIVATION,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  mrrReopen: {
    title: 'MRR Reopen',
    tooltip: {
      title: 'MRR Reopen',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_MRR_REACTIVATION,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  mrrCancellation: {
    title: 'MRR Cancellation',
    tooltip: {
      title: 'MRR Cancellation',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_MRR_CANCELLATION,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  mrrUninstall: {
    title: 'MRR Uninstall',
    tooltip: {
      title: 'MRR Uninstall',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_MRR_CANCELLATION,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  mrrClose: {
    title: 'MRR Close',
    tooltip: {
      title: 'MRR Close',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_MRR_CANCELLATION,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  mrrFrozen: {
    title: 'MRR Frozen',
    tooltip: {
      title: 'MRR Frozen',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_MRR_CANCELLATION,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  mrrUnfrozen: {
    title: 'MRR Unfrozen',
    tooltip: {
      title: 'MRR Unfrozen',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_MRR_CANCELLATION,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  mrrCancelChurn: {
    title: 'MRR Cancellation',
    tooltip: {
      title: 'MRR Cancellation',
      descriptions: '',
      url: ''
    },
    url: '',
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  oneTimeCharges: {
    title: 'One Time Charges',
    tooltip: {
      title: 'One Time Charges',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_ONE_TIME_CHARGES,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  usageCharges: {
    title: 'Usage Charges',
    tooltip: {
      title: 'Usage Charges',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_USAGE_CHARGES,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  usageMrr: {
    title: 'Add-on',
    tooltip: {
      title: 'Add-on',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_ADD_ON,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  failedCharges: {
    title: 'Failed Charges',
    tooltip: {
      title: 'Failed Charges',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_FAILED_CHARGES,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  netRevenueDailyGrowth: {
    title: 'Growth',
    url: '',
    chartType: 'BAR_CHART',
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY,
    chartConfig: {
      type: 'BAR_CHART',
      labelField: 'key',
      datasets: [
        {
          dataField: 'costs',
          isPositiveMetric: false,
          label: 'Costs',
          backgroundColor: focus2Colors.chart.level2.orange
        },
        {
          dataField: 'appSaleCredit',
          isPositiveMetric: false,
          label: 'Refunds & Credits',
          backgroundColor: focus2Colors.chart.level4.orange
        },
        {
          dataField: 'grossRevenue',
          isPositiveMetric: true,
          label: 'Gross Revenue',
          backgroundColor: focus2Colors.chart.level4.primary
        }
      ]
    }
  },
  mrrDailyGrowth: {
    title: 'Growth',
    url: '',
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY,
    chartConfig: {
      type: 'BAR_CHART',
      labelField: 'key',
      datasets: [
        {
          dataField: 'existingMrr',
          isPositiveMetric: true,
          label: 'Existing',
          backgroundColor: focus2Colors.chart.level4.primary
        },
        {
          dataField: 'mrrNew',
          isPositiveMetric: true,
          label: 'New',
          backgroundColor: focus2Colors.chart.level3.primary
        },
        {
          dataField: 'mrrReactivation',
          isPositiveMetric: true,
          label: 'Reactivations',
          backgroundColor: focus2Colors.chart.level2.primary
        },
        {
          dataField: 'mrrUpgrade',
          isPositiveMetric: true,
          label: 'Upgrades',
          backgroundColor: focus2Colors.chart.level1.primary
        },
        {
          dataField: 'mrrDowngrade',
          isPositiveMetric: false,
          label: 'Downgrades',
          backgroundColor: focus2Colors.chart.level4.orange
        },
        {
          dataField: 'mrrCancellation',
          isPositiveMetric: false,
          label: 'Cancellations',
          backgroundColor: focus2Colors.chart.level2.orange
        },
        {
          dataField: 'mrrFrozen',
          isPositiveMetric: false,
          label: 'Frozen',
          backgroundColor: focus2Colors.chart.level1.orange
        }
      ]
    }
  },
  failedChargesDailyGrowth: {
    title: 'Growth',
    url: '',
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT,
    chartConfig: {
      type: 'BAR_CHART',
      labelField: 'key',
      datasets: [
        {
          dataField: 'frozenCharge',
          isPositiveMetric: true,
          label: 'Frozen Charge',
          backgroundColor: focus2Colors.chart.level4.primary
        },
        {
          dataField: 'declineCharge',
          isPositiveMetric: false,
          label: 'Decline Charge',
          backgroundColor: focus2Colors.chart.level4.orange
        }
      ]
    }
  },
  install: {
    title: 'Installs',
    tooltip: {
      title: 'Installs',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_INSTALL,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  installGrowth: {
    title: 'Growth',
    url: '',
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT,
    chartConfig: {
      type: 'BAR_CHART',
      labelField: 'key',
      datasets: [
        {
          dataField: 'install',
          isPositiveMetric: true,
          label: 'Install',
          backgroundColor: focus2Colors.chart.level4.primary
        },
        {
          dataField: 'uninstall',
          isPositiveMetric: false,
          label: 'Uninstall',
          backgroundColor: focus2Colors.chart.level4.orange
        }
      ]
    }
  },
  uninstall: {
    title: 'Uninstalls',
    tooltip: {
      title: 'Uninstalls',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_UNINSTALL,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  uninstallGrowth: {
    title: 'Growth',
    url: '',
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT,
    chartConfig: {
      type: 'BAR_CHART',
      labelField: 'key',
      datasets: [
        {
          dataField: 'sameDay',
          isPositiveMetric: true,
          label: 'Same day',
          backgroundColor: focus2Colors.chart.level4.orange
        },
        {
          dataField: 'halfMonth',
          isPositiveMetric: true,
          label: '1-14 days',
          backgroundColor: focus2Colors.chart.level4.yellow
        },
        {
          dataField: 'lessThan90Days',
          isPositiveMetric: true,
          label: '15-90 days',
          backgroundColor: focus2Colors.chart.level4.primary
        },
        {
          dataField: 'above90Days',
          isPositiveMetric: true,
          label: 'Above 90 days',
          backgroundColor: focus2Colors.chart.level4.blue
        }
      ]
    }
  },
  close: {
    title: 'Closes',
    tooltip: {
      title: 'Closes',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_CLOSE,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  reopen: {
    title: 'Reopens',
    tooltip: {
      title: 'Reopens',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_REOPEN,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  totalInstallAndReopen: {
    title: 'Total Install & Reopen',
    tooltip: {
      title: 'Total Install & Reopen',
      descriptions: '',
      url: ''
    },
    url: '',
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.PERCENT
  },
  totalUninstallAndClose: {
    title: 'Total Uninstall & Close',
    tooltip: {
      title: 'Total Uninstall & Close',
      descriptions: '',
      url: ''
    },
    url: '',
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.PERCENT
  },
  customerChurnRate: {
    title: 'Customer Churn Rate',
    tooltip: {
      title: 'Customer Churn Rate',
      descriptions: '',
      url: '#'
    },
    url: ProjectMetricPaths.PROJECT_METRICS_CUSTOMER_CHURN,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.PERCENT,
    chartConfig: DEFAULT_CONFIG_LINE_CHART
  },

  customerChurn: {
    title: 'Customer Churn',
    tooltip: {
      title: 'Customer Churn',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_CUSTOMER_CHURN,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  ltv: {
    title: 'LTV',
    tooltip: {
      title: 'LTV',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_LTV,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  arpu: {
    title: 'ARPU',
    tooltip: {
      title: 'ARPU',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_ARPU,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.CURRENCY
  },
  activeUser: {
    title: 'Active User',
    tooltip: {
      title: 'Active User',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_ACTIVE_USER,
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  newCustomer: {
    title: 'New Customer',
    tooltip: {
      title: 'New Customer',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  existingCustomer: {
    title: 'Existing Customer',
    tooltip: {
      title: 'Existing Customer',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  customerCancellation: {
    title: 'Customer Cancellation',
    tooltip: {
      title: 'Customer Cancellation',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  customerUninstall: {
    title: 'Customer Uninstall',
    tooltip: {
      title: 'Customer Uninstall',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  customerClose: {
    title: 'Customer Close',
    tooltip: {
      title: 'Customer Close',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  customerReactivation: {
    title: 'Customer Reactivation',
    tooltip: {
      title: 'Customer Reactivation',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  customerReinstall: {
    title: 'Customer Reinstall',
    tooltip: {
      title: 'Customer Reinstall',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  customerReopen: {
    title: 'Customer Reopen',
    tooltip: {
      title: 'Customer Reopen',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  customerDowngrade: {
    title: 'Customer Downgrade',
    tooltip: {
      title: 'Customer Downgrade',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  customerUpgrade: {
    title: 'Customer Upgrade',
    tooltip: {
      title: 'Customer Upgrade',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  customerFrozen: {
    title: 'Customer Frozen',
    tooltip: {
      title: 'Customer Frozen',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  customerUnfrozen: {
    title: 'Customer Unfrozen',
    tooltip: {
      title: 'Customer Unfrozen',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  growthSubscriptionBar: {
    title: 'Growth',
    url: '',
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT,
    chartConfig: {
      type: 'BAR_CHART',
      labelField: 'key',
      datasets: [
        {
          dataField: 'customerReactivation',
          isPositiveMetric: false,
          label: 'Customer Reactivation',
          backgroundColor: focus2Colors.chart.level2.primary
        },
        {
          dataField: 'customerCancellation',
          isPositiveMetric: false,
          label: 'Customer Cancellation',
          backgroundColor: focus2Colors.chart.level4.orange
        },
        {
          dataField: 'newCustomer',
          isPositiveMetric: true,
          label: 'New Customer',
          backgroundColor: focus2Colors.chart.level4.primary
        }
      ]
    }
  },
  totalSubscription: {
    title: 'Subscriptions',
    tooltip: {
      title: 'Subscriptions',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  newSubscription: {
    title: 'Subscriptions',
    tooltip: {
      title: 'Subscriptions',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  totalInstall: {
    title: 'Installs',
    tooltip: {
      title: 'Installs',
      descriptions: '',
      url: ''
    },
    url: '',
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  conversionRate: {
    title: 'Conversion Rate',
    tooltip: {
      title: 'Conversion Rate',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.PERCENT
  },
  averageCustomerLifeSpan: {
    title: 'Average Customer Lifespan',
    tooltip: {
      title: 'Average Customer Lifespan',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  newUser: {
    title: 'New User',
    tooltip: {
      title: 'New User',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: DEFAULT_CONFIG_LINE_CHART,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  appPlan: {
    title: 'App Plan',
    tooltip: {
      title: 'App Plan',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_APP_PLAN,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.PERCENT,
    chartConfig: {
      type: 'DOUGHNUT_CHART',
      labelField: 'key',
      datasets: [
        {
          backgroundColors: doughnutBackgroundColors,
          dataField: 'data'
        },
        {
          backgroundColors: doughnutBackgroundColors,
          dataField: 'previousData'
        }
      ]
    }
  },
  appPlanChurn: {
    title: 'App Plan Churn',
    tooltip: {
      title: 'App Plan Churn',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_APP_PLAN_CHURN,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.PERCENT,
    chartConfig: {
      type: 'DOUGHNUT_CHART',
      labelField: 'key',
      datasets: [
        {
          backgroundColors: doughnutBackgroundColors,
          dataField: 'data'
        },
        {
          backgroundColors: doughnutBackgroundColors,
          dataField: 'previousData'
        }
      ]
    }
  },
  shopifyPlan: {
    title: 'Shopify Plan',
    tooltip: {
      title: 'Shopify Plan',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_SHOPIFY_PLAN,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.PERCENT,
    chartConfig: {
      type: 'DOUGHNUT_CHART',
      labelField: 'key',
      datasets: [
        {
          backgroundColors: doughnutBackgroundColors,
          dataField: 'data'
        },
        {
          backgroundColors: doughnutBackgroundColors,
          dataField: 'previousData'
        }
      ]
    }
  },
  shopifyPlanChurn: {
    title: 'Shopify Plan Churn',
    tooltip: {
      title: 'Shopify Plan Churn',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_SHOPIFY_PLAN_CHURN,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.PERCENT,
    chartConfig: {
      type: 'DOUGHNUT_CHART',
      labelField: 'key',
      datasets: [
        {
          backgroundColors: doughnutBackgroundColors,
          dataField: 'data'
        },
        {
          backgroundColors: doughnutBackgroundColors,
          dataField: 'previousData'
        }
      ]
    }
  },
  location: {
    title: 'Location',
    tooltip: {
      title: 'Location',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_LOCATION,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.PERCENT,
    chartConfig: {
      type: 'DOUGHNUT_CHART',
      labelField: 'key',
      datasets: [
        {
          backgroundColors: doughnutBackgroundColors,
          dataField: 'data'
        },
        {
          backgroundColors: doughnutBackgroundColors,
          dataField: 'previousData'
        }
      ]
    }
  },
  locationChurn: {
    title: 'Location Churn',
    tooltip: {
      title: 'Location Churn',
      descriptions: '',
      url: ''
    },
    url: ProjectMetricPaths.PROJECT_METRICS_LOCATION_CHURN,
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.PERCENT,
    chartConfig: {
      type: 'DOUGHNUT_CHART',
      labelField: 'key',
      datasets: [
        {
          backgroundColors: doughnutBackgroundColors,
          dataField: 'data'
        },
        {
          backgroundColors: doughnutBackgroundColors,
          dataField: 'previousData'
        }
      ]
    }
  },
  planPercentage: {
    title: 'Plan Percentage',
    tooltip: {
      title: 'Plan Percentage',
      descriptions: '',
      url: ''
    },
    url: '',
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.PERCENT
  },
  locationPercentage: {
    title: 'Location Percentage',
    tooltip: {
      title: 'Location Percentage',
      descriptions: '',
      url: ''
    },
    url: '',
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.PERCENT
  },
  subscribedPlanPercentage: {
    title: 'Subscribed Plan Percentage',
    tooltip: {
      title: 'Subscribed Plan Percentage',
      descriptions: '',
      url: ''
    },
    url: '',
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.PERCENT,
    chartConfig: {
      type: 'DOUGHNUT_CHART',
      labelField: 'key',
      datasets: [
        {
          backgroundColors: doughnutBackgroundColors,
          dataField: 'data'
        },
        {
          backgroundColors: doughnutBackgroundColors,
          dataField: 'previousData'
        }
      ]
    }
  },
  churnPercentage: {
    title: 'Churn Percentage',
    tooltip: {
      title: 'Churn Percentage',
      descriptions: '',
      url: ''
    },
    url: '',
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.PERCENT,
    chartConfig: {
      type: 'DOUGHNUT_CHART',
      labelField: 'key',
      datasets: [
        {
          backgroundColors: doughnutBackgroundColors,
          dataField: 'data'
        },
        {
          backgroundColors: doughnutBackgroundColors,
          dataField: 'previousData'
        }
      ]
    }
  },
  planGrowth: {
    title: 'Plan Growth',
    tooltip: {
      title: 'Plan Growth',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: {
      labelField: 'key',
      type: 'DYNAMIC_LINE_CHART',
      datasets: undefined,
      dataField: 'data',
      backgroundColors: [focus2Colors.chart.level4.default, focus2Colors.chart.level4.orange, focus2Colors.chart.level4.magenta]
    },
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  shopifyPlanGrowth: {
    title: 'Shopify Plan Growth',
    tooltip: {
      title: 'Shopify Plan Growth',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: {
      labelField: 'key',
      type: 'DYNAMIC_LINE_CHART',
      datasets: undefined,
      dataField: 'data',
      backgroundColors: [focus2Colors.chart.level4.default, focus2Colors.chart.level4.orange, focus2Colors.chart.level4.magenta]
    },
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  locationGrowth: {
    title: 'Location Growth',
    tooltip: {
      title: 'Location Growth',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: {
      labelField: 'key',
      type: 'DYNAMIC_LINE_CHART',
      datasets: undefined,
      dataField: 'data',
      backgroundColors: [focus2Colors.chart.level4.default, focus2Colors.chart.level4.orange, focus2Colors.chart.level4.magenta]
    },
    isIncreasePositiveMovement: true,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  planChurnGrowth: {
    title: 'Plan Churn',
    tooltip: {
      title: 'Plan Churn',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: {
      labelField: 'key',
      type: 'DYNAMIC_LINE_CHART',
      datasets: undefined,
      dataField: 'data',
      backgroundColors: [focus2Colors.chart.level4.default, focus2Colors.chart.level4.orange, focus2Colors.chart.level4.magenta]
    },
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  shopifyPlanChurnOverview: {
    title: 'Shopify Plan Churn',
    tooltip: {
      title: 'Shopify Plan Churn',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: {
      labelField: 'key',
      type: 'DYNAMIC_LINE_CHART',
      datasets: undefined,
      dataField: 'data',
      backgroundColors: [focus2Colors.chart.level4.default, focus2Colors.chart.level4.orange, focus2Colors.chart.level4.magenta]
    },
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  },
  locationChurnOverview: {
    title: 'Location Churn',
    tooltip: {
      title: 'Location Churn',
      descriptions: '',
      url: ''
    },
    url: '',
    chartConfig: {
      labelField: 'key',
      type: 'DYNAMIC_LINE_CHART',
      datasets: undefined,
      dataField: 'data',
      backgroundColors: [focus2Colors.chart.level4.default, focus2Colors.chart.level4.orange, focus2Colors.chart.level4.magenta]
    },
    isIncreasePositiveMovement: false,
    metricValueFormatter: VALUE_FORMATTERS.DEFAULT
  }
});

export type MetricName = keyof typeof BASE_BLOCKS;

export default BASE_BLOCKS;
