/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Jul 22nd 2021, 9:37:44 pm
 *
 * *****************************************************
 */

import { Button, Link, Stack, TextStyle } from '@shopify/polaris';
import { Helmet } from 'react-helmet';

import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { HELP_CENTER_LINK } from 'constants/links';
import { DEFAULT } from 'features/workspace-dashboard/routes/paths';

import { LayoutContainer, LayoutHeader, LayoutBlock } from './styles';

type Props = {
  title: string;
  description: string;
  imgSrc: string;
};

const ErrorPageLayout = ({ title, description, imgSrc }: Props): JSX.Element => (
  <LayoutContainer>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <LayoutHeader>
      <Logo />
    </LayoutHeader>
    <main>
      <LayoutBlock>
        <img src={imgSrc} alt="" role="presentation" />
        <header>
          <h1>{title}</h1>
          <span>{description}</span>
        </header>
        <Stack alignment="center" distribution="center">
          <Button primary url={DEFAULT}>
            Back to home
          </Button>
          <TextStyle variation="subdued">
            or visit our{' '}
            <Link removeUnderline url={HELP_CENTER_LINK}>
              Help Center
            </Link>
          </TextStyle>
        </Stack>
      </LayoutBlock>
    </main>
  </LayoutContainer>
);

export default ErrorPageLayout;
