/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Aug 12th 2021, 1:58:16 pm
 *
 * *****************************************************
 */

import { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { authenticationDataReceived } from 'states/slices/auth';
import { useAppDispatch } from 'utils/hooks';

import { authAPI } from './index';
import { AuthenticationResponse } from './types';

const { useLazyRefreshTokenQuery: useLazyRefreshTokenQueryOriginal } = authAPI;

const useLazyRefreshTokenQuery: typeof useLazyRefreshTokenQueryOriginal = (...args) => {
  const res = useLazyRefreshTokenQueryOriginal<any>(...args);
  const refreshTokenStatus = res[1];
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (refreshTokenStatus.isSuccess) {
      const responseData = refreshTokenStatus.data as AuthenticationResponse;
      dispatch(authenticationDataReceived(responseData));
    }
  }, [
    refreshTokenStatus.isSuccess,
    refreshTokenStatus.isError,
    refreshTokenStatus.error,
    refreshTokenStatus.data,
    history,
    dispatch
  ]);

  return res;
};

export { useLazyRefreshTokenQuery };
