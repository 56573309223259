/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 29th 2021, 5:54:59 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';

export const FrameContainer = styled.div`
  width: 100%;

  /* OVERRIDEN CSS */
  .Polaris-Frame {
    min-height: unset;
  }

  --p-icon-success: ${focus2Colors.interactive.primary.default};
`;
