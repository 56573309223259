/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Oct 6th 2021, 4:51:18 pm
 *
 * *****************************************************
 */

import { Card, SkeletonDisplayText } from '@shopify/polaris';

import { Content } from './styles';

const TableCardLoading = () => {
  return (
    <Card>
      <Content>
        <SkeletonDisplayText />
        <SkeletonDisplayText />
        <SkeletonDisplayText />
        <SkeletonDisplayText />
        <SkeletonDisplayText />
        <SkeletonDisplayText />
        <SkeletonDisplayText />
        <SkeletonDisplayText />
        <SkeletonDisplayText />
        <SkeletonDisplayText />
        <SkeletonDisplayText />
        <SkeletonDisplayText />
        <SkeletonDisplayText />
      </Content>
    </Card>
  );
};

export default TableCardLoading;
