/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Sep 20th 2021, 4:57:55 pm
 *
 * *****************************************************
 */

import React, { useCallback, useMemo, useState } from 'react';

import { Button, Icon } from '@shopify/polaris';
import { ArrowLeftMinor } from '@shopify/polaris-icons';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { generatePath, NavLink, useParams, useRouteMatch } from 'react-router-dom';

import { ReactComponent as GridIcon } from 'assets/images/grid.svg';
import { ReactComponent as InstallIcon } from 'assets/images/install-and-uninstall.svg';
import { ReactComponent as PlanIcon } from 'assets/images/plan-and-location.svg';
import { ReactComponent as RevenueIcon } from 'assets/images/revenue.svg';
import { ReactComponent as UserIcon } from 'assets/images/user-and-customer.svg';
import FrameLoading from 'features/common/views/components/FrameLoading';
import * as projectMetricsPaths from 'features/project/routes/paths';
import ProjectLayout from 'features/project/views/layouts/ProjectLayout';
import { useGetSyncStatusQuery } from 'states/services/metrics/hooks';
import { Common } from 'states/services/metrics/models';
import { useGetProjectBasicInfoQuery } from 'states/services/projects/hooks';
import { useGetWorkspaceBasicInfoQuery } from 'states/services/workspace/hooks';
import { Project } from 'states/slices/projects/types';
import { Workspace } from 'states/slices/workspace/types';

import ActivityPage from './MetricPage/ActivityPage';
import DashboardPage from './MetricPage/DashboardPage';
import ClosePage from './MetricPage/InstallAndUninstallPageGroup/ClosePage';
import InstallPage from './MetricPage/InstallAndUninstallPageGroup/InstallPage';
import OverviewPage from './MetricPage/InstallAndUninstallPageGroup/OverviewPage';
import ReopenPage from './MetricPage/InstallAndUninstallPageGroup/ReopenPage';
import UninstallPage from './MetricPage/InstallAndUninstallPageGroup/UninstallPage';
import AppPlanPage from './MetricPage/PlanAndLocationPages/AppPlan';
import AppPlanChurnPage from './MetricPage/PlanAndLocationPages/AppPlanChurn';
import AppPlanChurnDetailPage from './MetricPage/PlanAndLocationPages/AppPlanChurnDetail';
import AppPlanDetailPage from './MetricPage/PlanAndLocationPages/AppPlanDetail';
import LocationPage from './MetricPage/PlanAndLocationPages/Location';
import LocationChurnPage from './MetricPage/PlanAndLocationPages/LocationChurn';
import LocationChurnDetailPage from './MetricPage/PlanAndLocationPages/LocationChurnDetail';
import LocationDetailPage from './MetricPage/PlanAndLocationPages/LocationDetail';
import PlanAndLocationOverviewPage from './MetricPage/PlanAndLocationPages/Overview';
import ShopifyPlanPage from './MetricPage/PlanAndLocationPages/ShopifyPlan';
import ShopifyPlanChurnPage from './MetricPage/PlanAndLocationPages/ShopifyPlanChurn';
import ShopifyPlanChurnDetailPage from './MetricPage/PlanAndLocationPages/ShopifyPlanChurnDetail';
import ShopifyPlanDetailPage from './MetricPage/PlanAndLocationPages/ShopifyPlanDetail';
import CostsPage from './MetricPage/RevenuePageGroup/CostsPage';
import FailedChargesPage from './MetricPage/RevenuePageGroup/FailedChargesPage';
import MRRCancellationPage from './MetricPage/RevenuePageGroup/MRRCancellationPage';
import MRRChurnPage from './MetricPage/RevenuePageGroup/MRRChurnPage';
import MRRDowngradePage from './MetricPage/RevenuePageGroup/MRRDowngradePage';
import MRRNewPage from './MetricPage/RevenuePageGroup/MRRNewPage';
import MRRPage from './MetricPage/RevenuePageGroup/MRRPage';
import MRRReactivationPage from './MetricPage/RevenuePageGroup/MRRReactivationPage';
import MRRUpgradePage from './MetricPage/RevenuePageGroup/MRRUpgradePage';
import NetRevenuePage from './MetricPage/RevenuePageGroup/NetRevenuePage';
import OneTimeChargesPage from './MetricPage/RevenuePageGroup/OneTimeChargesPage';
import RevenueOverviewPage from './MetricPage/RevenuePageGroup/OverviewPage';
import RefundsAndCreditsPage from './MetricPage/RevenuePageGroup/RefundsAndCreditsPage';
import SubscriptionPage from './MetricPage/RevenuePageGroup/Subscription';
import UsageChargesPage from './MetricPage/RevenuePageGroup/UsageChargesPage';
import UsageMrrPage from './MetricPage/RevenuePageGroup/UsageMrrPage';
import ActiveCustomerPage from './MetricPage/UserAndCustomerPageGroup/ActiveCustomer';
import ActiveUserPage from './MetricPage/UserAndCustomerPageGroup/ActiveUser';
import ArpuPage from './MetricPage/UserAndCustomerPageGroup/ARPU';
import AverageCustomerLifespanPage from './MetricPage/UserAndCustomerPageGroup/AverageCustomerLifespan';
import ConversionRatePage from './MetricPage/UserAndCustomerPageGroup/ConversionRate';
import CustomerCancellationPage from './MetricPage/UserAndCustomerPageGroup/CustomerCancellation';
import CustomerChurnPage from './MetricPage/UserAndCustomerPageGroup/CustomerChurn';
import CustomerDowngradePage from './MetricPage/UserAndCustomerPageGroup/CustomerDowngrade';
import CustomerGrowthPage from './MetricPage/UserAndCustomerPageGroup/CustomerGrowth';
import CustomerReactivationPage from './MetricPage/UserAndCustomerPageGroup/CustomerReactivation';
import CustomerUpgradePage from './MetricPage/UserAndCustomerPageGroup/CustomerUpgrade';
import LtvPage from './MetricPage/UserAndCustomerPageGroup/LTV';
import NewCustomerPage from './MetricPage/UserAndCustomerPageGroup/NewCustomer';
import UserOverviewPage from './MetricPage/UserAndCustomerPageGroup/Overview';
import ActiveCustomerPageV2 from './MetricPage/UserAndCustomerPageGroupV2/ActiveCustomer';
import ActiveUserPageV2 from './MetricPage/UserAndCustomerPageGroupV2/ActiveUser';
import ArpuPageV2 from './MetricPage/UserAndCustomerPageGroupV2/ARPU';
import AverageCustomerLifespanPageV2 from './MetricPage/UserAndCustomerPageGroupV2/AverageCustomerLifespan';
import ConversionRatePageV2 from './MetricPage/UserAndCustomerPageGroupV2/ConversionRate';
import CustomerCancellationPageV2 from './MetricPage/UserAndCustomerPageGroupV2/CustomerCancellation';
import CustomerChurnPageV2 from './MetricPage/UserAndCustomerPageGroupV2/CustomerChurn';
import CustomerDowngradePageV2 from './MetricPage/UserAndCustomerPageGroupV2/CustomerDowngrade';
import CustomerGrowthPageV2 from './MetricPage/UserAndCustomerPageGroupV2/CustomerGrowth';
import CustomerReactivationPageV2 from './MetricPage/UserAndCustomerPageGroupV2/CustomerReactivation';
import CustomerUpgradePageV2 from './MetricPage/UserAndCustomerPageGroupV2/CustomerUpgrade';
import LtvPageV2 from './MetricPage/UserAndCustomerPageGroupV2/LTV';
import NewCustomerPageV2 from './MetricPage/UserAndCustomerPageGroupV2/NewCustomer';
import UserOverviewPageV2 from './MetricPage/UserAndCustomerPageGroupV2/Overview';
import PageHeader from './PageHeader';
import Sidebar from './Sidebar';
import { MainContainer, PageContainer, BannerContainer, HeaderWithBackButton } from './styles';
import SyncStatusBanner from './SyncStatusBanner';

type PageContent = {
  name: string;
  content: React.ReactNode;
  goBack?: string;
};

const PAGES_CONTENT: { [index: string]: PageContent } = {
  [projectMetricsPaths.PROJECT_METRICS_DASHBOARD]: {
    name: 'Dashboard',
    content: <DashboardPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_REVENUE_OVERVIEW]: {
    name: 'Revenue Overview',
    content: <RevenueOverviewPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_NET_REVENUE]: {
    name: 'Net Revenue',
    content: <NetRevenuePage />
  },
  [projectMetricsPaths.PROJECT_METRICS_MRR]: {
    name: 'Monthly Recurring Revenue (MRR)',
    content: <MRRPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_MRR_NEW]: {
    name: 'MRR New',
    content: <MRRNewPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_MRR_UPGRADE]: {
    name: 'MRR Upgrade',
    content: <MRRUpgradePage />
  },
  [projectMetricsPaths.PROJECT_METRICS_MRR_DOWNGRADE]: {
    name: 'MRR Downgrade',
    content: <MRRDowngradePage />
  },
  [projectMetricsPaths.PROJECT_METRICS_MRR_REACTIVATION]: {
    name: 'MRR Reactivation',
    content: <MRRReactivationPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_MRR_CANCELLATION]: {
    name: 'MRR Cancellation',
    content: <MRRCancellationPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_MRR_CHURN]: {
    name: 'MRR Churn',
    content: <MRRChurnPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_ONE_TIME_CHARGES]: {
    name: 'One Time Charges',
    content: <OneTimeChargesPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_USAGE_CHARGES]: {
    name: 'Usage Charges',
    content: <UsageChargesPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_ADD_ON]: {
    name: 'Add-on',
    content: <UsageMrrPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_FAILED_CHARGES]: {
    name: 'Failed Charges',
    content: <FailedChargesPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_REFUNDS_AND_CREDITS]: {
    name: 'Refunds & Credits',
    content: <RefundsAndCreditsPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_COSTS]: {
    name: 'Costs',
    content: <CostsPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_INSTALL_AND_UNINSTALL_OVERVIEW]: {
    name: 'Overview',
    content: <OverviewPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_INSTALL]: {
    name: 'Install',
    content: <InstallPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_REOPEN]: {
    name: 'Reopen',
    content: <ReopenPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_UNINSTALL]: {
    name: 'Uninstall',
    content: <UninstallPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_CLOSE]: {
    name: 'Close',
    content: <ClosePage />
  },
  [projectMetricsPaths.PROJECT_METRICS_CUSTOMER_CHURN]: {
    name: 'Customer Churn',
    content: <CustomerChurnPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_ARPU]: {
    name: 'ARPU',
    content: <ArpuPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_CUSTOMER_CHURN_V2]: {
    name: 'Customer Churn',
    content: <CustomerChurnPageV2 />
  },
  [projectMetricsPaths.PROJECT_METRICS_ARPU_V2]: {
    name: 'ARPU',
    content: <ArpuPageV2 />
  },
  [projectMetricsPaths.PROJECT_METRICS_USER_AND_CUSTOMER_OVERVIEW_V2]: {
    name: 'Overview',
    content: <UserOverviewPageV2 />
  },
  [projectMetricsPaths.PROJECT_METRICS_LTV_V2]: {
    name: 'Lifetime Value (LTV)',
    content: <LtvPageV2 />
  },
  [projectMetricsPaths.PROJECT_METRICS_ACTIVE_CUSTOMER_V2]: {
    name: 'Active Customer',
    content: <ActiveCustomerPageV2 />
  },
  [projectMetricsPaths.PROJECT_METRICS_NEW_CUSTOMER_V2]: {
    name: 'New Customer',
    content: <NewCustomerPageV2 />
  },
  [projectMetricsPaths.PROJECT_METRICS_CUSTOMER_UPGRADE_V2]: {
    name: 'Customer Upgrade',
    content: <CustomerUpgradePageV2 />
  },
  [projectMetricsPaths.PROJECT_METRICS_CUSTOMER_DOWNGRADE_V2]: {
    name: 'Customer Downgrade',
    content: <CustomerDowngradePageV2 />
  },
  [projectMetricsPaths.PROJECT_METRICS_CUSTOMER_CANCELLATION_V2]: {
    name: 'Customer Cancellation',
    content: <CustomerCancellationPageV2 />
  },
  [projectMetricsPaths.PROJECT_METRICS_CUSTOMER_REACTIVATION_V2]: {
    name: 'Customer Reactivation',
    content: <CustomerReactivationPageV2 />
  },
  [projectMetricsPaths.PROJECT_METRICS_CUSTOMER_GROWTH_V2]: {
    name: 'Customer Growth',
    content: <CustomerGrowthPageV2 />
  },
  [projectMetricsPaths.PROJECT_METRICS_CONVERSION_RATE_V2]: {
    name: 'Conversion Rate',
    content: <ConversionRatePageV2 />
  },
  [projectMetricsPaths.PROJECT_METRICS_AVERAGE_CUSTOMER_LIFESPAN_V2]: {
    name: 'Average Customer Lifespan',
    content: <AverageCustomerLifespanPageV2 />
  },
  [projectMetricsPaths.PROJECT_METRICS_ACTIVE_USER_V2]: {
    name: 'Active User',
    content: <ActiveUserPageV2 />
  },
  [projectMetricsPaths.PROJECT_METRICS_USER_AND_CUSTOMER_OVERVIEW]: {
    name: 'Overview',
    content: <UserOverviewPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_LTV]: {
    name: 'Lifetime Value (LTV)',
    content: <LtvPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_ACTIVE_CUSTOMER]: {
    name: 'Active Customer',
    content: <ActiveCustomerPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_NEW_CUSTOMER]: {
    name: 'New Customer',
    content: <NewCustomerPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_CUSTOMER_UPGRADE]: {
    name: 'Customer Upgrade',
    content: <CustomerUpgradePage />
  },
  [projectMetricsPaths.PROJECT_METRICS_CUSTOMER_DOWNGRADE]: {
    name: 'Customer Downgrade',
    content: <CustomerDowngradePage />
  },
  [projectMetricsPaths.PROJECT_METRICS_CUSTOMER_CANCELLATION]: {
    name: 'Customer Cancellation',
    content: <CustomerCancellationPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_CUSTOMER_REACTIVATION]: {
    name: 'Customer Reactivation',
    content: <CustomerReactivationPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_CUSTOMER_GROWTH]: {
    name: 'Customer Growth',
    content: <CustomerGrowthPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_CONVERSION_RATE]: {
    name: 'Conversion Rate',
    content: <ConversionRatePage />
  },
  [projectMetricsPaths.PROJECT_METRICS_AVERAGE_CUSTOMER_LIFESPAN]: {
    name: 'Average Customer Lifespan',
    content: <AverageCustomerLifespanPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_ACTIVE_USER]: {
    name: 'Active User',
    content: <ActiveUserPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_SUBSCRIPTION]: {
    name: 'Subscription',
    content: <SubscriptionPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_PLAN_AND_LOCATION_OVERVIEW]: {
    name: 'Overview',
    content: <PlanAndLocationOverviewPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_APP_PLAN]: {
    name: 'App Plan',
    content: <AppPlanPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_APP_PLAN_CHURN]: {
    name: 'App Plan Churn',
    content: <AppPlanChurnPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_APP_PLAN_DETAIL]: {
    name: 'App Plan Detail',
    content: <AppPlanDetailPage />,
    goBack: projectMetricsPaths.PROJECT_METRICS_APP_PLAN
  },
  [projectMetricsPaths.PROJECT_METRICS_APP_PLAN_CHURN_DETAIL]: {
    name: 'App Plan Churn Detail',
    content: <AppPlanChurnDetailPage />,
    goBack: projectMetricsPaths.PROJECT_METRICS_APP_PLAN_CHURN
  },
  [projectMetricsPaths.PROJECT_METRICS_ACTIVITIES]: {
    name: 'Activity',
    content: <ActivityPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_SHOPIFY_PLAN]: {
    name: 'Shopify Plan',
    content: <ShopifyPlanPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_SHOPIFY_PLAN_CHURN]: {
    name: 'Shopify Plan Churn',
    content: <ShopifyPlanChurnPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_LOCATION]: {
    name: 'Location',
    content: <LocationPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_LOCATION_CHURN]: {
    name: 'Location Churn',
    content: <LocationChurnPage />
  },
  [projectMetricsPaths.PROJECT_METRICS_SHOPIFY_PLAN_DETAIL]: {
    name: 'Shopify Plan Detail',
    content: <ShopifyPlanDetailPage />,
    goBack: projectMetricsPaths.PROJECT_METRICS_SHOPIFY_PLAN
  },
  [projectMetricsPaths.PROJECT_METRICS_SHOPIFY_PLAN_CHURN_DETAIL]: {
    name: 'Shopify Plan Churn Detail',
    content: <ShopifyPlanChurnDetailPage />,
    goBack: projectMetricsPaths.PROJECT_METRICS_SHOPIFY_PLAN_CHURN
  },
  [projectMetricsPaths.PROJECT_METRICS_LOCATION_DETAIL]: {
    name: 'Location Detail',
    content: <LocationDetailPage />,
    goBack: projectMetricsPaths.PROJECT_METRICS_LOCATION
  },
  [projectMetricsPaths.PROJECT_METRICS_LOCATION_CHURN_DETAIL]: {
    name: 'Location Churn Detail',
    content: <LocationChurnDetailPage />,
    goBack: projectMetricsPaths.PROJECT_METRICS_LOCATION_CHURN
  }
};

const generateSidebarData = (projectId: string) => {
  return [
    {
      url: generatePath(projectMetricsPaths.PROJECT_METRICS_DASHBOARD, { projectId }),
      label: 'Dashboard',
      icon: GridIcon
    },
    {
      url: generatePath(projectMetricsPaths.PROJECT_METRICS_REVENUE, { projectId }),
      label: 'Revenue',
      icon: RevenueIcon,
      subItems: [
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_REVENUE_OVERVIEW, { projectId }),
          label: 'Overview'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_NET_REVENUE, {
            projectId
          }),
          label: 'Net Revenue'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_MRR, { projectId }),
          label: 'MRR'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_MRR_NEW, { projectId }),
          label: 'MRR New'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_MRR_UPGRADE, {
            projectId
          }),
          label: 'MRR Upgrade'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_MRR_DOWNGRADE, {
            projectId
          }),
          label: 'MRR Downgrade'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_MRR_REACTIVATION, {
            projectId
          }),
          label: 'MRR Reactivation'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_MRR_CANCELLATION, {
            projectId
          }),
          label: 'MRR Cancellation'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_MRR_CHURN, {
            projectId
          }),
          label: 'MRR Churn'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_ONE_TIME_CHARGES, {
            projectId
          }),
          label: 'One Time Charges'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_USAGE_CHARGES, {
            projectId
          }),
          label: 'Usage Charges'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_FAILED_CHARGES, {
            projectId
          }),
          label: 'Failed Charges'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_REFUNDS_AND_CREDITS, {
            projectId
          }),
          label: 'Refunds & Credits'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_COSTS, { projectId }),
          label: 'Costs'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_SUBSCRIPTION, {
            projectId
          }),
          label: 'Subscription'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_ADD_ON, {
            projectId
          }),
          label: 'Add-on'
        }
      ]
    },
    {
      url: generatePath(projectMetricsPaths.PROJECT_METRICS_INSTALL_AND_UNINSTALL, { projectId }),
      label: 'Install & Uninstall',
      icon: InstallIcon,
      subItems: [
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_INSTALL_AND_UNINSTALL_OVERVIEW, {
            projectId
          }),
          label: 'Overview'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_INSTALL, {
            projectId
          }),
          label: 'Install'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_REOPEN, {
            projectId
          }),
          label: 'Reopen'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_UNINSTALL, {
            projectId
          }),
          label: 'Uninstall'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_CLOSE, {
            projectId
          }),
          label: 'Close'
        }
      ]
    },
    {
      url: generatePath(projectMetricsPaths.PROJECT_METRICS_USER_AND_CUSTOMER, { projectId }),
      label: 'User & Customer',
      icon: UserIcon,
      subItems: [
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_USER_AND_CUSTOMER_OVERVIEW, {
            projectId
          }),
          label: 'Overview'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_ARPU, {
            projectId
          }),
          label: 'ARPU'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_LTV, {
            projectId
          }),
          label: 'LTV'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_ACTIVE_CUSTOMER, {
            projectId
          }),
          label: 'Active Customer'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_NEW_CUSTOMER, {
            projectId
          }),
          label: 'New Customer'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_CUSTOMER_UPGRADE, {
            projectId
          }),
          label: 'Customer Upgrade'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_CUSTOMER_DOWNGRADE, {
            projectId
          }),
          label: 'Customer Downgrade'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_CUSTOMER_REACTIVATION, {
            projectId
          }),
          label: 'Customer Reactivation'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_CUSTOMER_CANCELLATION, {
            projectId
          }),
          label: 'Customer Cancellation'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_CUSTOMER_CHURN, {
            projectId
          }),
          label: 'Customer Churn'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_CUSTOMER_GROWTH, {
            projectId
          }),
          label: 'Customer Growth'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_CONVERSION_RATE, {
            projectId
          }),
          label: 'Conversion Rate'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_AVERAGE_CUSTOMER_LIFESPAN, {
            projectId
          }),
          label: 'ACL'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_ACTIVE_USER, {
            projectId
          }),
          label: 'Active User'
        }
      ]
    },
    {
      url: generatePath(projectMetricsPaths.PROJECT_METRICS_USER_AND_CUSTOMER_V2, { projectId }),
      label: 'User & Customer V2',
      icon: UserIcon,
      subItems: [
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_USER_AND_CUSTOMER_OVERVIEW_V2, {
            projectId
          }),
          label: 'Overview'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_ARPU_V2, {
            projectId
          }),
          label: 'ARPU'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_LTV_V2, {
            projectId
          }),
          label: 'LTV'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_ACTIVE_CUSTOMER_V2, {
            projectId
          }),
          label: 'Active Customer'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_NEW_CUSTOMER_V2, {
            projectId
          }),
          label: 'New Customer'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_CUSTOMER_UPGRADE_V2, {
            projectId
          }),
          label: 'Customer Upgrade'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_CUSTOMER_DOWNGRADE_V2, {
            projectId
          }),
          label: 'Customer Downgrade'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_CUSTOMER_REACTIVATION_V2, {
            projectId
          }),
          label: 'Customer Reactivation'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_CUSTOMER_CANCELLATION_V2, {
            projectId
          }),
          label: 'Customer Cancellation'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_CUSTOMER_CHURN_V2, {
            projectId
          }),
          label: 'Customer Churn'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_CUSTOMER_GROWTH_V2, {
            projectId
          }),
          label: 'Customer Growth'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_CONVERSION_RATE_V2, {
            projectId
          }),
          label: 'Conversion Rate'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_AVERAGE_CUSTOMER_LIFESPAN_V2, {
            projectId
          }),
          label: 'ACL'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_ACTIVE_USER_V2, {
            projectId
          }),
          label: 'Active User'
        }
      ]
    },
    {
      url: generatePath(projectMetricsPaths.PROJECT_METRICS_ACTIVITIES, { projectId }),
      label: 'Activity',
      icon: GridIcon
    },
    {
      url: generatePath(projectMetricsPaths.PROJECT_METRICS_PLAN_AND_LOCATION, { projectId }),
      label: 'Plan & Location',
      icon: PlanIcon,
      subItems: [
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_PLAN_AND_LOCATION_OVERVIEW, {
            projectId
          }),
          label: 'Overview'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_APP_PLAN, {
            projectId
          }),
          label: 'App Plan'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_APP_PLAN_CHURN, {
            projectId
          }),
          label: 'App Plan Churn'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_SHOPIFY_PLAN, {
            projectId
          }),
          label: 'Shopify Plan'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_SHOPIFY_PLAN_CHURN, {
            projectId
          }),
          label: 'Shopify Plan Churn'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_LOCATION, {
            projectId
          }),
          label: 'Location'
        },
        {
          url: generatePath(projectMetricsPaths.PROJECT_METRICS_LOCATION_CHURN, {
            projectId
          }),
          label: 'Location Churn'
        }
      ]
    }
  ];
};

const ProjectMetrics = () => {
  const [smallScreenSidebarActive, setSmallScreenSidebarActive] = useState(false);
  const match = useRouteMatch();
  const { projectId, planName, shopifyPlanName, countryCode } =
    useParams<{ projectId: string; planName: string; shopifyPlanName: string; countryCode: string }>();
  const { data: currentProject } = useGetProjectBasicInfoQuery(projectId);
  const { name: currentProjectName } = (currentProject as Project) ?? { name: '' };
  /**
   * Auto fetching when data is processing to update status for customer
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [autoFetching, setAutoFetching] = useState(false);
  const { isLoading, data: syncStatusData } = useGetSyncStatusQuery(projectId, {
    refetchOnMountOrArgChange: true,
    pollingInterval: autoFetching ? 15000 : 0
  });
  const { syncStatus, progress } = syncStatusData || { syncStatus: null, progress: null };

  const { data: basicInfoWorkspace } = useGetWorkspaceBasicInfoQuery(undefined, {
    refetchOnMountOrArgChange: true
  });

  const { name: workspaceName } = (basicInfoWorkspace as Workspace) ?? { name: '' };

  const handleToggleSmallScreenSidebarActive = useCallback(() => {
    setSmallScreenSidebarActive(prevState => {
      const newState = !prevState;
      /**
       *  Reset scrolling position when sidebar is visible in mobile mode
       */
      if (newState) window.scrollTo(0, 0);

      return newState;
    });
  }, []);

  const handleDeactiveSmallScreenSidebar = useCallback(() => {
    setSmallScreenSidebarActive(false);
    window.scrollTo(0, 0);
  }, []);

  const pageName = _.get(PAGES_CONTENT[match.path], 'name', '');
  const pageContent = _.get(PAGES_CONTENT[match.path], 'content', null);
  const goBackLink = _.get(PAGES_CONTENT[match.path], 'goBack', '');

  const sidebarData = useMemo(() => {
    return generateSidebarData(projectId);
  }, [projectId]);

  let content = null;

  if (isLoading) {
    content = <FrameLoading />;
  } else if (syncStatus !== 'DONE') {
    content = (
      <BannerContainer>
        <SyncStatusBanner syncStatus={syncStatus as Common.SyncStatus} progress={progress as Common.MetricProgress} />
      </BannerContainer>
    );
  } else
    content = (
      <>
        <Sidebar
          pageName={pageName}
          smallScreenSidebarActive={smallScreenSidebarActive}
          onDeactivateSmallScreenSidebar={handleDeactiveSmallScreenSidebar}
          onToggleSmallScreenSidebarActive={handleToggleSmallScreenSidebarActive}>
          <Sidebar.Section items={sidebarData} />
        </Sidebar>
        <PageContainer sidebarActive={smallScreenSidebarActive}>
          {goBackLink ? (
            <HeaderWithBackButton>
              <NavLink to={generatePath(goBackLink, { projectId })}>
                <Button icon={<Icon source={ArrowLeftMinor} color="subdued" />} />
              </NavLink>
              <PageHeader
                name={planName || shopifyPlanName || countryCode}
                sidebarActive={smallScreenSidebarActive}
                onToggleSidebarActive={handleToggleSmallScreenSidebarActive}
              />
            </HeaderWithBackButton>
          ) : (
            <PageHeader
              name={pageName}
              sidebarActive={smallScreenSidebarActive}
              onToggleSidebarActive={handleToggleSmallScreenSidebarActive}
            />
          )}
          {pageContent}
        </PageContainer>
      </>
    );

  return (
    <ProjectLayout workspaceName={workspaceName}>
      <Helmet>
        <title>{currentProjectName && workspaceName && `${pageName} - ${currentProjectName} | ${workspaceName}`}</title>
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <MainContainer>{content}</MainContainer>
    </ProjectLayout>
  );
};

export default ProjectMetrics;
