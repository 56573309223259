/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Aug 12th 2021, 10:09:19 am
 *
 * *****************************************************
 */

import { useMemo, useEffect, useRef } from 'react';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { selectExpiredTime, selectJwtToken } from 'states/slices/auth/selectors';
import type { RootState, AppDispatch } from 'states/types';

import { isJWTExpired } from './jwt';

/**
 * A resolved-type useDispatch hook, use this instead of the original one (which is imported directly from 'react-redux' package)
 * @example
 * function Component() {
 *  const dispatch = useAppDispatch();
 *  // ...
 * }
 */
export const useAppDispatch = () => useDispatch<AppDispatch>();

/**
 * A resolved-type useSelector hook, use this instead of the original one (which is imported directly from 'react-redux' package)
 * @example
 * function Component() {
 *  const someState = useAppSelectr(state: RootState => state.auth.someState);
 *  // ...
 * }
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * Hook for access app authoridation data (token, user data, etc.)
 * @example
 * function Component() {
 *  const { jwtToken } = useAuth();
 *  // ...
 * }
 */
export const useAuth = () => {
  const jwtToken = useAppSelector(selectJwtToken);
  const expiredTime = useAppSelector(selectExpiredTime);

  const isExpired = isJWTExpired(expiredTime);

  return useMemo(() => ({ jwtToken, isExpired }), [jwtToken, isExpired]);
};

/**
 * a type-safe version of the `usePrevious` hook described here:
 * @see {@link https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state}
 */
export function usePrevious<T>(value: T): T {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref: any = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
