/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 22nd 2021, 10:29:48 am
 *
 * *****************************************************
 */

import Revenue from 'features/project/views/pages/ProjectMetric/MetricPage/DashboardPage/Revenue';

import { Container, Count, Name, RevenueContainer } from './styles';

type Props = {
  isTotal?: boolean;
  count: number | null;
  label: string;
  revenue: number | null;
};

const BreakdownItem = ({ count, label, revenue, isTotal = false }: Props): JSX.Element => {
  return (
    <Container isTotal={isTotal}>
      {count !== null && <Count>{count}</Count>}
      <Name isTotal={isTotal}>{label}</Name>
      <RevenueContainer>
        <Revenue value={revenue} />
      </RevenueContainer>
    </Container>
  );
};

export default BreakdownItem;
