/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 22nd 2021, 10:55:16 am
 *
 * *****************************************************
 */

import { currencySignDisplayExceptZeroFormatter } from 'constants/intl-number-formatter';

import { Value } from './styles';

type Props = {
  value: number | null;
};

const Revenue = ({ value }: Props): JSX.Element | null => {
  if (value === null) return null;

  return <Value value={value}>{currencySignDisplayExceptZeroFormatter.format(value)}</Value>;
};

export default Revenue;
