/* eslint-disable react/jsx-props-no-spreading */
/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 27th 2021, 1:01:00 pm
 *
 * *****************************************************
 */

import React, { useState, Children, useCallback } from 'react';

import { Button } from '@shopify/polaris';
import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { Form as FinalForm } from 'react-final-form';

import HeaderActions from './FullScreenModalFormHeaderActions';
import { Container, Content, FooterActions } from './styles';

type PageProps = {
  validate?: (values: object) => SubmissionErrors | undefined;
  children: JSX.Element;
};
const Page = ({ children }: PageProps): JSX.Element => children;

export type FooterActionConfig = {
  content: string;
  ariaDescribedBy?: string | null;
};

type FullScreenFormModalProps = {
  initialValues?: object;
  keysPerPage: string[][];
  footerActionConfigs?: FooterActionConfig[];
  children: React.ReactElement<PageProps> | React.ReactElement<PageProps>[];
  onFormSubmit: (values: object) => Promise<SubmissionErrors>;
  onCloseModal: () => void;
};

type FullScreenModalFormType = {
  (props: FullScreenFormModalProps): JSX.Element;
  Page: typeof Page;
};

type FormStateContextType = {
  submitting: boolean;
  submitErrors: SubmissionErrors;
};

export const FormStateContext = React.createContext<FormStateContextType>({
  submitting: false,
  submitErrors: undefined
});

/**
 * Final Form Wizard
 * made for full screen modal component
 */
const FullScreenModalForm: FullScreenModalFormType = ({
  initialValues,
  footerActionConfigs = [],
  keysPerPage,
  children,
  onFormSubmit,
  onCloseModal
}: FullScreenFormModalProps) => {
  /**
   * Current page index
   */
  const [page, setPage] = useState(0);

  /**
   * Form values
   */
  const [values, setValues] = useState(initialValues ?? {});
  /**
   * Submit errors organized by page
   * It will be stored in an array.
   * Each array item will contain submit errors of a page whose page index is equal to the array item index.
   */
  const [submitErrorsPerPage, setSubmitErrorsPerPage] = useState<SubmissionErrors[]>([]);

  /**
   * Number of page
   */
  const pageCount = Children.count(children);
  /**
   * Current showing page
   */
  const activePage = Children.toArray(children)[page] as React.ReactElement<PageProps>;
  /**
   * Is current page last page?
   */
  const isLastPage = page === pageCount - 1;

  /**
   * Current footer button content to be display
   */
  const currentFooterActionConfig = footerActionConfigs[page] ?? {
    content: isLastPage ? 'Submit' : 'Next'
  };

  /**
   * Get the index of the first page having submit errors
   */
  const getFirstPageHavingSubmitErrors = useCallback(
    submitErrors => {
      const lastPageIndex = pageCount - 1;
      let resultPageIndex = lastPageIndex;
      if (!submitErrors) return resultPageIndex;

      keysPerPage.forEach((keys, pageIndex) => {
        if (resultPageIndex === lastPageIndex) {
          keys.forEach(key => {
            if (Object.hasOwnProperty.call(submitErrors, key)) resultPageIndex = pageIndex;
          });
        }
      });
      return resultPageIndex;
    },
    [pageCount, keysPerPage]
  );

  /**
   * Extract submit errors corresponding to the given form page
   * This function will receive the full submit errors object
   * (contains submit errors of the whole form and all related form fields),
   * and a page index.
   * It will use the keys of each page (provided by the required keysPerPage props)
   * to compute the submit errors of the given page.
   * If not, a undefined value will be returned.
   */
  const extractSubmitErrorsOfPage = useCallback(
    (submitErrors, pageIndex) => {
      if (!submitErrors) return submitErrors;
      /**
       * There's two types of submit errors that belongs to a form page and need to be shown.
       * 1. Field submit errors:
       * E.g. A submitted field value is invalid
       * -> Submit errors of this type must only be shown to the relevant pages
       * which contain such fields
       * 2. Whole form submit error:
       * E.g. All field is valid but the submit still fails
       * -> Submit errors of this type must be shown to all form pages
       */
      const isFormFieldErrors = Object.keys(submitErrors).length > 1;

      /**
       * If the full submit errors objects is of field submit errors types
       */
      if (isFormFieldErrors) {
        const res: { [key: string]: string } = {
          [FORM_ERROR]: submitErrors[FORM_ERROR]
        };
        /**
         * Match any key is used to determine
         * whether the given page has any field submit errors
         */
        let matchAnyKey = false;
        keysPerPage[pageIndex].forEach(key => {
          const errorMessage = submitErrors[key];
          if (errorMessage !== undefined) {
            matchAnyKey = true;
            res[key] = errorMessage;
          }
        });
        /**
         * If the given page has any field submit errors,
         * return the computed page submit erros
         */
        if (matchAnyKey) return res;
        /**
         * Otherwise, skip this page by return undefined
         */
        return undefined;
      }
      /**
       * If whole form submit error,
       * return the submit error for every given page index
       */
      return submitErrors;
    },
    [keysPerPage]
  );

  /**
   * Create the submit errors per page for the whole form
   * The returned value is usually stored in the submitErrorsPerPage component state
   */
  const createSubmitErrorsPerPage = useCallback(
    submitErrors => {
      const res = [];
      for (let pageIndex = 0; pageIndex < pageCount; pageIndex += 1) {
        res[pageIndex] = extractSubmitErrorsOfPage(submitErrors, pageIndex);
      }
      return res;
    },
    [pageCount, extractSubmitErrorsOfPage]
  );

  /**
   * Going to the next page form handler
   */
  const handleNext = useCallback(
    submitValuesOnNext => {
      /**
       * Beside updating the current page index,
       * we will reset the submit errors of the current page if there's any.
       * The submit errors of each page will be persisted
       * until that page submits its values.
       */
      setPage(currentPageIndex => {
        setSubmitErrorsPerPage(prevState =>
          prevState.map((item, index) => {
            if (index === currentPageIndex) return undefined;
            if (item) return { ...item };
            return item;
          })
        );
        return Math.min(currentPageIndex + 1, pageCount - 1);
      });
      /**
       * Update the form values
       */
      setValues(prevState => ({ ...prevState, ...submitValuesOnNext }));
    },
    [pageCount]
  );

  /**
   * Going to the previous form page handler
   */
  const handlePrevious = useCallback(() => {
    setPage(prevState => Math.max(prevState - 1, 0));
  }, []);

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  const handleValidate = useCallback(
    validatingValues => {
      return activePage.props.validate ? activePage.props.validate(validatingValues) : {};
    },
    [activePage]
  );

  /**
   * Submitting form handlers
   */
  const handleFormSubmit = useCallback(
    async submitValues => {
      if (isLastPage) {
        setSubmitErrorsPerPage([]);
        const submitErrors = await onFormSubmit(submitValues);
        if (submitErrors) {
          /**
           * Organize the submit errors by page
           * then store the result into component state
           */
          setSubmitErrorsPerPage(createSubmitErrorsPerPage(submitErrors));
          /**
           * Navigate to first form page having submit errors
           */
          const pageIndex = getFirstPageHavingSubmitErrors(submitErrors);
          setPage(pageIndex);
        }
        return;
      }
      handleNext(submitValues);
    },
    [isLastPage, createSubmitErrorsPerPage, handleNext, getFirstPageHavingSubmitErrors, onFormSubmit]
  );

  return (
    <FinalForm initialValues={values} validate={handleValidate} onSubmit={handleFormSubmit}>
      {({ handleSubmit, submitting }) => (
        <FormStateContext.Provider
          value={{
            submitting,
            submitErrors: submitErrorsPerPage[page]
          }}>
          <form onSubmit={handleSubmit}>
            <Container>
              <HeaderActions
                hideBackButton={page === 0 || submitting}
                hideCloseButton={submitting}
                onBackButtonClick={handlePrevious}
                onCloseButtonClick={onCloseModal}
              />
              <Content>{activePage}</Content>
              <FooterActions>
                {!submitting && (
                  <Button submit primary ariaDescribedBy={currentFooterActionConfig.ariaDescribedBy ?? undefined}>
                    {currentFooterActionConfig.content}
                  </Button>
                )}
              </FooterActions>
            </Container>
          </form>
        </FormStateContext.Provider>
      )}
    </FinalForm>
  );
};

FullScreenModalForm.Page = Page;

export default FullScreenModalForm;
