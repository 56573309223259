/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 22nd 2021, 8:35:44 am
 *
 * *****************************************************
 */

import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import { Button, OptionList, Popover, SkeletonDisplayText, Card } from '@shopify/polaris';
import { useParams } from 'react-router-dom';

import { ACTIVITIES_POLLING_INTERVAL } from 'constants/polling-intervals';
import CardHeader from 'features/project/views/components/CardHeader';
import { useGetBreakdownDataQuery } from 'states/services/metrics/dashboard';
import { GetBreakdownDataResponse } from 'states/services/metrics/dashboard/types';

import Item from './Item';
import LoadingContent from './LoadingContent';
import { ButtonContainer, SkeletonContainer } from './styles';

type Props = {
  labelForBreakdownOption: string;
};

const RELATIVE_TIME_REFRESH_INTERVAL = 60 * 1e3;

const Breakdown = ({ labelForBreakdownOption }: Props) => {
  const { projectId } = useParams() as { projectId: string };
  const [, forceUpdate] = useReducer(x => x + 1, 1);
  const { isSuccess, isFetching, data } = useGetBreakdownDataQuery(
    { projectId },
    { pollingInterval: ACTIVITIES_POLLING_INTERVAL }
  );
  const { options: optionListData, data: breakdownDataByOption } = data || {
    options: [],
    data: {}
  };

  useEffect(() => {
    const intervalID = setInterval(() => {
      forceUpdate();
    }, RELATIVE_TIME_REFRESH_INTERVAL);

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  const [popoverActive, setPopoverActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(['']);

  const relativeOptions = optionListData.map(option => {
    if (option.value === 'TODAY') {
      return {
        ...option,
        label: `${option.label} ${labelForBreakdownOption ? `(updated ${labelForBreakdownOption})` : ''}`
      };
    }
    return {
      ...option
    };
  });

  const labelForActivator = relativeOptions.find(option => option.value === selectedOption[0])?.label;

  const handleTogglePopoverActive = useCallback(() => {
    setPopoverActive(prevState => !prevState);
  }, []);

  const handleSelectOption = useCallback(
    value => {
      setSelectedOption(value);
      handleTogglePopoverActive();
    },
    [handleTogglePopoverActive]
  );

  const activator = (
    <ButtonContainer>
      <Button onClick={handleTogglePopoverActive} disclosure>
        {labelForActivator}
      </Button>
    </ButtonContainer>
  );

  useEffect(() => {
    if (isSuccess) {
      const { options } = data as GetBreakdownDataResponse;
      const { value } = options[0];
      setSelectedOption([value]);
    }
  }, [isSuccess, data]);

  const content = useMemo(() => {
    const selectedData = breakdownDataByOption[selectedOption[0]] || [];
    const n = selectedData.length;
    return selectedData.map(({ id, label, count, revenue }, index) => (
      <Item key={id} label={label} count={count} revenue={revenue} isTotal={index === n - 1} />
    ));
  }, [breakdownDataByOption, selectedOption]);

  return (
    <Card>
      <CardHeader name="Breakdown">
        {isFetching ? (
          <SkeletonContainer>
            <SkeletonDisplayText size="medium" />
          </SkeletonContainer>
        ) : (
          <Popover active={popoverActive} activator={activator} onClose={handleTogglePopoverActive}>
            <OptionList onChange={handleSelectOption} options={relativeOptions} selected={selectedOption} />
          </Popover>
        )}
      </CardHeader>
      {isFetching ? <LoadingContent /> : content}
    </Card>
  );
};

export default Breakdown;
