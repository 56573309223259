/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Oct 4th 2021, 9:38:53 pm
 *
 * *****************************************************
 */

import { useState, useMemo } from 'react';

import { Card } from '@shopify/polaris';
import _ from 'lodash';

import BASE_BLOCKS, { MetricName } from 'constants/metrics/base-blocks';
import AdditionalChartBlock from 'features/metrics/views/components/cards/MetricChartCard/AdditionalChartBlock';
import { Option } from 'features/metrics/views/components/cards/MetricChartCard/OverviewSection/SearchBar';
import MetricChart from 'features/metrics/views/components/MetricChart';
import { Common } from 'states/services/metrics/models';

import MetricTableCard, { DateTableMapping, Props as TableProps } from '../MetricTableCard';
import AdditionalMetricBlock from './AdditionalMetricBlock';
import OverviewSection, { Props as OverviewSectionProps, OverviewType } from './OverviewSection';
import {
  CardContent,
  ChartContainer,
  AdditionalBlocksContainer,
  AdditionalChartsContainer,
  AdditionalTableContainer
} from './styles';

type AdditionalMetric = Partial<Record<MetricName, Common.NonChartMetric>>;
type AdditionalChart = Partial<Record<MetricName, Common.DoughnutChartMetric<Common.DoughnutChartItem>>>; // NOTE: right now, only support additional doughnut chart
export type SizeChart = 'small' | 'medium' | 'large';

type ChartConfig = {
  show: boolean;
  data: any;
  legends: string[];
};

type AdditionalMetricConfig = {
  show: boolean;
  data: AdditionalMetric;
  order?: MetricName[];
};

type AdditionalChartConfig = {
  show: boolean;
  data: AdditionalChart;
  legends: string[];
  order?: MetricName[];
  options?: string[]; // options for multi tags select
};

type AdditionalTableConfig = {
  show: boolean;
} & TableProps<DateTableMapping>;

type OverviewConfig = Partial<
  Pick<
    OverviewSectionProps,
    'hideMetricOverview' | 'alignLeftMovementIndicator' | 'hideViewDetails' | 'hideTimeFilter' | 'hideTooltip' | 'triggerFunction'
  >
> &
  (
    | {
        show?: boolean;
        type?: Extract<'view-details' | 'time-filter', OverviewType>;
        data?: Common.MetricOverviewData;
      }
    | {
        show?: boolean;
        type: Extract<'multiple-tags', OverviewType>;
        data?: Common.MetricOverviewData;
        options: Option[];
      }
  );

type Props = {
  size: SizeChart;
  metricName: MetricName;
  chartConfig?: ChartConfig;
  hasPreviousComparison: boolean;
  overviewConfig?: OverviewConfig;
  additionalChartConfig?: AdditionalChartConfig;
  additionalMetricConfig?: AdditionalMetricConfig;
  additionalTableConfig?: AdditionalTableConfig;
};

const MetricChartCard = ({
  metricName,
  size = 'medium',
  hasPreviousComparison,
  overviewConfig,
  chartConfig,
  additionalMetricConfig,
  additionalChartConfig,
  additionalTableConfig
}: Props) => {
  const [selectedTimeFilterValue, setTimeFilterValue] = useState<'day' | 'week' | 'month'>('day');

  /**
   * additional metric blocks section
   */
  const additionalMetrics = useMemo(() => {
    if (!additionalMetricConfig || !additionalMetricConfig.show) return null;
    const metricNames =
      additionalMetricConfig.order || (Object.keys(additionalMetricConfig.data) as MetricName[]).sort((a, b) => (a > b ? -1 : 1));
    return metricNames.map(name => {
      const metricData = additionalMetricConfig.data[name];
      if (!metricData) return null;
      return (
        <AdditionalMetricBlock
          key={name}
          metricName={name as MetricName}
          hasPreviousComparison={hasPreviousComparison}
          alignLeftMovementIndicator
          data={metricData.overview}
        />
      );
    });
  }, [additionalMetricConfig, hasPreviousComparison]);

  /**
   * additional chart blocks section
   */
  const additionalCharts = useMemo(() => {
    if (!additionalChartConfig || !additionalChartConfig.show) return null;
    const metricNames =
      additionalChartConfig.order || (Object.keys(additionalChartConfig.data) as MetricName[]).sort((a, b) => (a > b ? -1 : 1));
    return metricNames.map(name => {
      const metricData = additionalChartConfig.data[name];
      if (!metricData) return null;
      return (
        <AdditionalChartBlock
          key={name}
          metricName={name as MetricName}
          data={metricData}
          legends={additionalChartConfig.legends}
        />
      );
    });
  }, [additionalChartConfig]);

  /**
   * additional table blocks section
   */
  const additionalTable = useMemo(() => {
    if (!additionalTableConfig) return null;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MetricTableCard {...additionalTableConfig} isAdditional />;
  }, [additionalTableConfig]);

  let data = null;
  if (chartConfig) {
    switch (_.get(overviewConfig, 'type')) {
      case 'time-filter':
        data = chartConfig.data[selectedTimeFilterValue];
        break;
      case 'multiple-tags':
        data = _(chartConfig.data).map('data.day').value();
        break;
      default:
        data = chartConfig.data;
        break;
    }
  }
  const bigNumbers = useMemo(() => {
    if (!overviewConfig || !overviewConfig.data) return [];
    return [overviewConfig.data.bigNumber, overviewConfig.data.previousBigNumber];
  }, [overviewConfig]);

  const { chartConfig: config } = BASE_BLOCKS[metricName];
  if (!config) return null;

  return (
    <Card>
      <CardContent hasTable={!!(additionalTableConfig && additionalTableConfig.show)}>
        {overviewConfig && (
          <OverviewSection
            metricName={metricName}
            hideMetricOverview={!overviewConfig.show}
            data={overviewConfig.data}
            selectedTimeFilterValue={selectedTimeFilterValue}
            onTimeFilterChange={setTimeFilterValue}
            hasPreviousComparison={hasPreviousComparison}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...overviewConfig}
          />
        )}

        {chartConfig && chartConfig.show && data && (
          <ChartContainer size={size} type={config.type}>
            <MetricChart legends={chartConfig.legends} metricName={metricName} data={data} bigNumbers={bigNumbers} />
          </ChartContainer>
        )}

        {additionalChartConfig && additionalChartConfig.show && (
          <AdditionalChartsContainer>{additionalCharts}</AdditionalChartsContainer>
        )}

        {additionalMetricConfig && additionalMetricConfig.show && (
          <AdditionalBlocksContainer>{additionalMetrics}</AdditionalBlocksContainer>
        )}
        {additionalTableConfig && additionalTableConfig.show && (
          <AdditionalTableContainer>{additionalTable}</AdditionalTableContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default MetricChartCard;
