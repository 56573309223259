/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Aug 12th 2021, 9:51:29 am
 *
 * *****************************************************
 */

export const SORT_VALUE_NO_OPTION = '';
export const SORT_VALUE_NAME_ASCENDING = 'name-asc';
export const SORT_VALUE_NAME_DESCENDING = 'name-desc';
