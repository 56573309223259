/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Sep 30th 2021, 4:26:50 pm
 *
 * *****************************************************
 */

import { createContext } from 'react';

type SidebarContextType = {
  onDeactivateSmallScreenSidebar: () => void;
};

export const SidebarContext = createContext<SidebarContextType>({
  onDeactivateSmallScreenSidebar: () => {}
});
