/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 20th 2021, 11:23:28 pm
 *
 * *****************************************************
 */

type ValidationFunc = (value?: any) => string | undefined;

/**
 * Compose all Final Form validation function into one function.
 * @param validators Final Form validation functions
 * @returns Composed Final Form validation function
 * @example
 *  import { Field } from 'react-final-from';
 *  // ...
 *  composeValidators(required('Email'), mustBeEmail, maxLength('Emai', 256))
 */
export const composeValidators =
  (...validators: any) =>
  (value: any) =>
    validators.reduce((error: string | undefined, validator: ValidationFunc) => error ?? validator(value), undefined);

/**
 * Final Form validation function for required field
 * @example
 *  import { Field } from 'react-final-from';
 *  // ...
 *  <Field validation={required('Workspace name')} />
 */
export const required =
  (fieldName: string): ValidationFunc =>
  (value: any) =>
    value && value.trim().length ? undefined : `${fieldName} is required.`;

/**
 * Final Form validation function for verfication code field
 * @example
 *  import { Field } from 'react-final-from';
 *  // ...
 *  <Field validation={verificationCode} />
 */
export const verificationCode = (value: any) =>
  value && value.length === 6 ? undefined : `The code isn't valid. Please try another one!`;

/**
 * Final Form validation function for email typed imput
 * @example
 *  import { Field } from 'react-final-from';
 *  // ...
 *  <Field validation={mustBeEmail} />
 */
export const mustBeEmail: ValidationFunc = (value: string) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? undefined : 'A valid email address is required.';

/**
 * Final Form validation function for input max length
 * @example
 *  import { Field } from 'react-final-from';
 *  // ...
 *  <Field validation={maxLength('Workspace name')} />
 */
export const maxLength =
  (fieldName: string, max: number): ValidationFunc =>
  (value: string) =>
    value.trim().length <= max ? undefined : `${fieldName} has a maximum of ${max} characters.`;

/**
 * Final Form validation function for input min length
 * @example
 *  import { Field } from 'react-final-from';
 *  // ...
 *  <Field validation={minLength('Workspace name')} />
 */
export const minLength =
  (fieldName: string, min: number): ValidationFunc =>
  (value: string) =>
    value.trim().length >= min ? undefined : `${fieldName} must be at least ${min} characters.`;

/**
 * Final Form validation function for image input
 * @example
 *  import { Field } from 'react-final-from';
 *  // ...
 *  <Field validation={minLength('Workspace name')} />
 */
export const mustBeImageFile: ValidationFunc = (file: File) => {
  if (typeof file !== 'object') return undefined;
  return file && (file.type === 'image/jpeg' || file.type === 'image/png') ? undefined : 'Invalid image file type.';
};

/**
 * Final Form validation function for file size
 * @example
 *  import { Field } from 'react-final-from';
 *  // ...
 *  <Field validation={minLength('Workspace name')} />
 */
export const maxFileSizeInMB =
  (max: number): ValidationFunc =>
  (file: File | string) => {
    if (typeof file !== 'object') return undefined;
    return file && file.size / 1024 ** 2 <= max ? undefined : `The upload file must be no greater than ${max}MB.`;
  };
