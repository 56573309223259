/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Oct 6th 2021, 12:24:43 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  & > div {
    width: 100%;
  }
`;

export const EmptyText = styled.div`
  text-align: center;
  font-style: italic;
`;
