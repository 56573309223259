/* eslint-disable react/jsx-props-no-spreading */
/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Sep 21st 2021, 4:59:46 am
 *
 * *****************************************************
 */

import Item, { Props as ItemProps } from './Item';

export type Props = {
  items: ItemProps[];
};

const Section = ({ items }: Props) => {
  return (
    <ul>
      {items.map(item => (
        <Item key={item.url} {...item} />
      ))}
    </ul>
  );
};

export default Section;
