import styled from 'styled-components';

import focus2Texts from 'constants/focus-2-texts';

export const Container = styled.div`
  ${focus2Texts.website['Body 2 - 14px']}
  width: max-content;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1), inset 0px -1px 0px #e4e5e7;
  pointer-events: none;
  padding: 6px;
  font: italic bold 13px arial, serif;
`;

export const Table = styled.table`
  margin: 0;
`;

export const Body = styled.tbody``;

export const Row = styled.tr`
  display: flex;
  flex-direction: column;
  background-color: inherit;
  border-width: 0;
`;

export const Cell = styled.td``;
