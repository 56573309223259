/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Aug 27th 2021, 01:55:31 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: -1rem;

  @media (max-width: 64em) {
    width: 100%;
    display: inline-table;
    vertical-align: top;
  }
`;

export const Item = styled.div`
  padding-left: 4rem;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 3rem;
  flex-basis: 1;
  &:last-child > div > div:last-child > div:first-child:after {
    display: none;
  }

  & > div > div:first-child {
    display: none;
  }

  @media (max-width: 64em) {
    padding-left: 4rem;
    position: relative;
    overflow: hidden;
    padding-bottom: 2rem;

    &:not(:last-child) > div > div:first-child {
      display: block;
    }
  }
`;

export const Process = styled.div`
  flex-basis: 1;
  & > div:last-child > div:first-child:after {
    border-color: ${focus2Colors.border.disabled};
  }
  & > div:nth-child(2) > span {
    border-color: ${focus2Colors.text.default};
  }
`;

export const Done = styled.div`
  flex-basis: 1;
  color: ${focus2Colors.text.default};
  & > div:last-child > div:first-child:after {
    border-color: ${focus2Colors.text.highlight};
  }

  @media (max-width: 64em) {
    & > div:first-child {
      border-color: ${focus2Colors.text.highlight};
    }
  }
`;

export const Wait = styled.div`
  flex-basis: 1;
  color: ${focus2Colors.border.disabled};
  & > div:last-child > div:first-child:after {
    border-color: ${focus2Colors.border.disabled};
  }
  & > div:nth-child(2) > span {
    border-color: ${focus2Colors.text.disabled};
  }
`;

export const Failed = styled.div`
  flex-basis: 1;
  & > div:last-child > div:first-child:after {
    border-color: ${focus2Colors.border.disabled};
  }
  & > div:nth-child(2) > span {
    border-color: ${focus2Colors.text.disabled};
  }
`;

export const Content = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  height: 3rem;
`;

export const Title = styled.div`
  ${focus2Texts.website.heading['H5 - 14px']}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 1.6rem;
  font-weight: 600;
  height: 100%;
  padding-right: 1rem;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 1.5rem;
    left: 100%;
    width: 999rem;
    border-top-width: 0.2rem;
    border-top-style: solid;
    border-color: ${focus2Colors.border.default};
  }

  @media (max-width: 64rem) {
    &:after {
      display: none;
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  text-align: center;
  position: absolute;
  top: 0;
  border-radius: 50%;
  left: 0.5rem;
`;

export const Number = styled.span`
  width: 2rem;
  height: 2rem;
  border: 0.2rem solid;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Tail = styled.div`
  top: 3rem;
  bottom: 0;
  left: 1.9rem;
  border-left-width: 0.1rem;
  border-left-style: solid;
  position: absolute;
  border-color: ${focus2Colors.text.disabled};
`;
