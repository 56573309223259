/**
 * Copyright (C) Boost commerce
 * This file is part of commercial Boost commerce projects
 *
 * This file can not be copied and/or distributed without the express
 * permission of Boost commerce
 *
 * Created on Thu Jun 22 2023 01:38:17
 */

import moment from 'moment';

import { parseLocaleCurrency } from 'utils/project';

export type SortDirection = 'ascending' | 'descending' | 'none';

export function sortText(rows: Array<Record<string, any>>, colNumber: number, direction: SortDirection) {
  return [...rows].sort((rowA, rowB) => {
    const amountA = rowA[colNumber] as string;
    const amountB = rowB[colNumber] as string;

    return direction === 'descending' ? amountB.localeCompare(amountA) : amountA.localeCompare(amountB);
  });
}

export function sortNumber(rows: Array<Record<string, any>>, colNumber: number, direction: SortDirection) {
  return [...rows].sort((rowA, rowB) => {
    const amountA = rowA[colNumber];
    const amountB = rowB[colNumber];

    if (direction === 'descending') {
      return amountA > amountB ? -1 : 1;
    }

    return amountA > amountB ? 1 : -1;
  });
}

export function sortDate(rows: Array<Record<string, any>>, colNumber: number, direction: SortDirection) {
  return [...rows].sort((rowA, rowB) => {
    const amountA = moment(rowA[colNumber], 'MMM DD, YYYY');
    const amountB = moment(rowB[colNumber], 'MMM DD, YYYY');

    return direction === 'descending' ? amountB.diff(amountA) : amountA.diff(amountB);
  });
}

export function sortCurrency(rows: Array<Record<string, any>>, colNumber: number, direction: SortDirection) {
  return [...rows].sort((rowA, rowB) => {
    const amountA = parseLocaleCurrency(rowA[colNumber]);
    const amountB = parseLocaleCurrency(rowB[colNumber]);

    return direction === 'descending' ? amountB - amountA : amountA - amountB;
  });
}
