import { Endpoint } from 'states/services/types';

export const METRICS_CUSTOMER_CHURN_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/customer-churn/churn-chart',
  isPrivate: true
};

export const METRICS_CUSTOMER_CHURN_RATE_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/customer-churn/churn-rate-chart',
  isPrivate: true
};

export const METRICS_CUSTOMER_CHURN_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/customer-churn/breakdown',
  isPrivate: true
};

export const METRICS_CUSTOMER_CHURN_CHART_V2_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user-v2/customer-churn/churn-chart',
  isPrivate: true
};

export const METRICS_CUSTOMER_CHURN_RATE_CHART_V2_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user-v2/customer-churn/churn-rate-chart',
  isPrivate: true
};

export const METRICS_CUSTOMER_CHURN_BREAKDOWN_V2_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user-v2/customer-churn/breakdown',
  isPrivate: true
};
