/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 20th 2021, 3:21:01 pm
 *
 * *****************************************************
 */

const primary = {
  lightColoredAction: '#5850EC',
  action: '#5D59A2',
  actionHovered: '#4b44c9',
  actionPressed: '#3f39a8',
  actionDepressed: '#2F2E41',
  actionDisabled: '#3e3b6b',
  actionDisabledDarkMode: '#FAFBFB',
  actionDisabledBorderDarkMode: '#D2D5D8',
  surface: '#F6F6F7',
  loading: '#5850EC',
  subdued: '#4C4A6A',
  actionHoveredGrey: '#666482'
};

const onSurface = {
  textSubdued: '#6D7175',
  divider: '#E1E3E5',
  neutralIcon: '#F4F6F8',
  icon: '#C9CCCF',
  text: '#AAAAAA'
};

const interactive = {
  lightColoredDefault: '#5850EC',
  default: '#5D59A2',
  hovered: '#4b44c9',
  focused: '#847ff0'
};

const secondary = {
  actionHovered: '#F5F4FB'
};

const critical = {
  surfaceSubdued: '#FAE2E2',
  icon: '#BF0E08'
};

const overrides = {
  bannerBorderCritical: '#BF0E08'
};

const success = {
  bannerBorder: '#47B881',
  surfaceSubdued: '#F1FAF5'
};

const lineChartPalette = {
  current: '#8884d8',
  previous: '#919EAB'
};

export default {
  primary,
  onSurface,
  interactive,
  secondary,
  overrides,
  critical,
  success,
  lineChartPalette
};
