/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Oct 4th 2021, 9:50:40 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import colors from 'constants/colors';
import { ChartType } from 'constants/metrics/base-blocks';
import type { SizeChart } from 'features/project/views/pages/ProjectMetric/MetricCard/MetricChartCard';

export const CardContent = styled.div<{ hasTable: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ hasTable }) => (hasTable ? '' : 'padding-bottom: 2rem;')}
`;

const mappingHeight: Record<ChartType, Record<SizeChart, string>> = {
  LINE_CHART: {
    small: '19.2rem',
    medium: '26.4rem',
    large: '35.2rem'
  },
  BAR_CHART: {
    small: '19.2rem',
    medium: '26.4rem',
    large: '35.2rem'
  },
  DOUGHNUT_CHART: {
    small: '216px',
    medium: '216px',
    large: '216px'
  },
  DYNAMIC_LINE_CHART: {
    small: '19.2rem',
    medium: '26.4rem',
    large: '35.2rem'
  }
};

export const ChartContainer = styled.div<{
  size: SizeChart;
  type: ChartType;
}>`
  display: flex;
  justify-content: center;
  padding: 0 2rem;
  margin-top: 1.6rem;
  ${({ size, type }) => {
    return `height: ${mappingHeight[type][size]}`;
  }}
`;

export const AdditionalBlocksContainer = styled.div`
  padding: 1.6rem 2rem 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: -2.4rem;
  margin-left: -2.4rem;

  & > div {
    width: 30%;
    margin-left: 2.4rem;
    margin-top: 2.4rem;
  }

  @media (max-width: 43.75em) {
    & > div {
      width: 40%;
    }
  }

  @media (max-width: 30em) {
    & > div {
      width: 100%;
    }
  }
`;

export const AdditionalChartsContainer = styled.div`
  padding: 1.6rem 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  & > div {
    flex: 1 0 50%; // each flex item occupies 50% of row
    box-sizing: border-box;
    padding: 0 2rem 1.2rem 2rem;
  }

  & > div:nth-child(odd) {
    padding: 0 1.9rem 1.2rem 2rem; // -1px for border right
    border-right: 1px solid ${colors.onSurface.divider};
  }

  @media (max-width: 89.95em) {
    & > div {
      flex: 1 0 100%; // each flex item occupies 50% of row
      box-sizing: border-box;
      border-right: none !important;
      position: relative;
      width: 100%;
    }

    & > div:not(:last-child) {
      padding: 0 2rem 4rem 2rem !important;
    }

    & > div:not(:last-child)::after {
      content: '';
      display: block;
      position: absolute;
      width: calc(100% - 4rem);
      left: 50%;
      transform: translate(-50%, -50%);
      border-bottom: 1px solid ${colors.onSurface.divider};
      margin-top: 2rem;
    }
  }
`;

export const AdditionalTableContainer = styled.div`
  margin-top: 1.6rem;

  & > div.Polaris-Card {
    box-shadow: none;
  }
`;
