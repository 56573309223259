/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Jul 21st 2021, 11:53:31 pm
 *
 * *****************************************************
 */

/**
 * Max length for normal input value
 */
export const MAX_LENGTH_INPUT = 254;
/**
 * Max length for key-like value: token, key, ID
 */
export const MAX_LENGTH_KEY = 50;
