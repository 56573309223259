import { Endpoint } from 'states/services/types';

export const METRICS_MRR_CANCELLATION_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/mrr-cancellation/cancellation-chart',
  isPrivate: true
};

export const METRICS_OTHER_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/mrr-cancellation/other-chart',
  isPrivate: true
};

export const METRICS_MRR_CANCELLATION_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/mrr-cancellation/breakdown',
  isPrivate: true
};
