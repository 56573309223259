/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 20th 2021, 6:03:53 pm
 *
 * *****************************************************
 */

import { ReactComponent as SpinnerLogo } from 'assets/images/spinner.svg';

import { SpinnerContainer } from './styles';

type Props = {
  accessibilityLabel?: string | null;
};

/**
 * Spinner used for loading indicating purpose
 */

const Spinner = ({ accessibilityLabel = 'Loading' }: Props) => (
  <SpinnerContainer>
    <SpinnerLogo />
    <span role="status" className="Polaris-VisuallyHidden">
      {accessibilityLabel}
    </span>
  </SpinnerContainer>
);

export default Spinner;
