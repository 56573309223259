/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Sat, Jul 24th 2021, 5:33:48 pm
 *
 * *****************************************************
 */

import TopBarCompanyLogoButton from 'features/common/views/components/TopBarCompanyLogoButton';
import UserMenu from 'features/common/views/components/UserMenu';

import { LayoutContainer, TopBar, PageName } from './styles';

type Props = {
  pageName?: string | null;
  workspaceName: string;
  children: React.ReactNode;
};

const WorkspaceDashboardLayout = ({ pageName, children, workspaceName }: Props): JSX.Element => (
  <LayoutContainer>
    <TopBar>
      <TopBarCompanyLogoButton workspaceName={workspaceName} />
      {pageName && <PageName>{pageName}</PageName>}
      <UserMenu />
    </TopBar>
    <main>{children}</main>
  </LayoutContainer>
);

export default WorkspaceDashboardLayout;
