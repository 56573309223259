// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import { useParams } from 'react-router-dom';

import RelativeTime from 'features/common/views/components/RelativeTime';
import MetricChartCard from 'features/project/views/pages/ProjectMetric/MetricCard/MetricChartCard';
import MetricChartCardLoading from 'features/project/views/pages/ProjectMetric/MetricCardLoading/MetricChartCardLoading';
import DatePicker from 'features/project/views/pages/ProjectMetric/MetricDatePicker';
import {
  ChartContainer,
  DatePickerContainer,
  NoticeBlock,
  PageContentContainer
} from 'features/project/views/pages/ProjectMetric/MetricPage/PlanAndLocationPages/Overview/styles';
import { useGetPlanOverviewQuery } from 'states/services/metrics/hooks';
import { GetPlanOverviewResponse } from 'states/services/metrics/types';
import { createTextDatePickerRange, formatDate } from 'utils/project';

const PlanAndLocationOverviewPage = () => {
  const { projectId } = useParams() as { projectId: string };
  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedCurrentValue, setCommittedCurrentValue] = useState(() => {
    const last30days = {
      start: moment().subtract(30, 'day').toDate(),
      end: moment().subtract(1, 'day').toDate()
    };
    return last30days;
  });

  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedPreviousValue, setCommittedPreviousValue] = useState(() => {
    const last60days = {
      start: moment().subtract(60, 'day').toDate(),
      end: moment().subtract(31, 'day').toDate()
    };
    return last60days;
  });

  const [hasPreviousComparison, setHasPreviousComparison] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { isFetching, data = { data: null, metricCalculatedAt: null } } = useGetPlanOverviewQuery(
    {
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end)
    },
    { refetchOnMountOrArgChange: true }
  );

  const { metricCalculatedAt } = data as GetPlanOverviewResponse;

  const chartLegends = useMemo(() => {
    return [committedCurrentValue, hasPreviousComparison && committedPreviousValue].map(dateRange => {
      if (!dateRange) return '';
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    });
  }, [committedCurrentValue, committedPreviousValue, hasPreviousComparison]);

  const planChart = (() => {
    let children = null;
    if (isFetching) children = <MetricChartCardLoading size="large" hasChart />;
    else {
      const { appPlanSection } = data as GetPlanOverviewResponse;
      children = (
        <MetricChartCard
          metricName="appPlan"
          size="medium"
          hasPreviousComparison={hasPreviousComparison}
          overviewConfig={{
            type: 'view-details',
            hideViewDetails: true,
            hideTooltip: true
          }}
          additionalChartConfig={{
            show: true,
            legends: chartLegends,
            data: appPlanSection,
            order: ['appPlan', 'appPlanChurn']
          }}
        />
      );
    }

    return <ChartContainer>{children}</ChartContainer>;
  })();

  const shopifyPlanChart = (() => {
    let children = null;
    if (isFetching) children = <MetricChartCardLoading size="large" hasChart />;
    else {
      const { shopifyPlanSection } = data as GetPlanOverviewResponse;
      children = (
        <MetricChartCard
          metricName="shopifyPlan"
          size="medium"
          hasPreviousComparison={hasPreviousComparison}
          overviewConfig={{
            type: 'view-details',
            hideViewDetails: true,
            hideTooltip: true
          }}
          additionalChartConfig={{
            show: true,
            legends: chartLegends,
            data: shopifyPlanSection,
            order: ['shopifyPlan', 'shopifyPlanChurn']
          }}
        />
      );
    }

    return <ChartContainer>{children}</ChartContainer>;
  })();

  const locationChart = (() => {
    let children = null;
    if (isFetching) children = <MetricChartCardLoading size="large" hasChart />;
    else {
      const { locationSection } = data as GetPlanOverviewResponse;
      children = (
        <MetricChartCard
          metricName="location"
          size="medium"
          hasPreviousComparison={hasPreviousComparison}
          overviewConfig={{
            type: 'view-details',
            hideViewDetails: true,
            hideTooltip: true
          }}
          additionalChartConfig={{
            show: true,
            legends: chartLegends,
            data: locationSection,
            order: ['location', 'locationChurn']
          }}
        />
      );
    }

    return <ChartContainer>{children}</ChartContainer>;
  })();

  return (
    <PageContentContainer>
      <DatePickerContainer>
        <DatePicker
          hasPreviousComparison={hasPreviousComparison}
          setHasPreviousComparison={setHasPreviousComparison}
          committedCurrentValue={committedCurrentValue}
          setCommittedCurrentValue={setCommittedCurrentValue}
          committedPreviousValue={committedPreviousValue}
          setCommittedPreviousValue={setCommittedPreviousValue}
        />
      </DatePickerContainer>
      {planChart}
      {shopifyPlanChart}
      {locationChart}
      <NoticeBlock>
        <p>
          {isFetching ? (
            'Your data is loading...'
          ) : (
            <>
              Your data was last updated&nbsp;
              <RelativeTime value={metricCalculatedAt} />
            </>
          )}
        </p>
      </NoticeBlock>
    </PageContentContainer>
  );
};

export default PlanAndLocationOverviewPage;
