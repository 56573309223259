/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Oct 4th 2021, 3:19:58 pm
 *
 * *****************************************************
 */

import { Button, Icon, Tooltip, SkeletonDisplayText } from '@shopify/polaris';
import { CircleInformationMajor } from '@shopify/polaris-icons';
import _ from 'lodash';

import BASE_BLOCKS, { MetricName } from 'constants/metrics/base-blocks';
import MovementIndicator from 'features/project/views/pages/ProjectMetric/MovementIndicator';
import { Common } from 'states/services/metrics/models';

import {
  BigNumber,
  FirstRow,
  MovementIndicatorContainer,
  PreviousBigNumber,
  PreviousBigNumberContainer,
  SecondRow,
  Title,
  TooltipActivator,
  TooltipButtonContainer,
  TooltipContent,
  TooltipTitle,
  SkeletonContainer
} from './styles';

export type Properties = {
  metricName: MetricName;
  isFetching?: boolean;
  alignLeftMovementIndicator?: boolean;
  hideTooltip?: boolean;
  data: Common.MetricOverviewData;
};

const AdditionalMetricBlock = ({
  metricName,
  isFetching = false,
  alignLeftMovementIndicator = false,
  hideTooltip = false,
  data
}: Properties): JSX.Element => {
  const { title, tooltip, isIncreasePositiveMovement, metricValueFormatter } = BASE_BLOCKS[metricName];

  const tooltipTitle = _.get(tooltip, 'title', '');
  const tooltipDescription = _.get(tooltip, 'descriptions', '');
  const tooltipURL = _.get(tooltip, 'url', '');

  const { bigNumber, previousBigNumber } = data;
  if (isFetching) {
    return (
      <SkeletonContainer>
        <SkeletonDisplayText />
        <SkeletonDisplayText />
        <SkeletonDisplayText />
      </SkeletonContainer>
    );
  }
  return (
    <div>
      <FirstRow>
        <Title>{title}</Title>
        {!hideTooltip && (
          <Tooltip
            content={
              <TooltipContent>
                <TooltipTitle>{tooltipTitle}</TooltipTitle>
                <p>{tooltipDescription}</p>
                <TooltipButtonContainer>
                  <Button url={tooltipURL}>Learn more</Button>
                </TooltipButtonContainer>
              </TooltipContent>
            }
            preferredPosition="above">
            <TooltipActivator>
              <Icon source={CircleInformationMajor} color="subdued" />
            </TooltipActivator>
          </Tooltip>
        )}
      </FirstRow>
      <SecondRow>
        <BigNumber>{metricValueFormatter(bigNumber)}</BigNumber>
        {_.isNumber(previousBigNumber) && (
          <>
            <MovementIndicatorContainer alignLeftMovementIndicator={alignLeftMovementIndicator}>
              <MovementIndicator
                size="small"
                currentNumber={bigNumber}
                previousNumber={previousBigNumber}
                hasParentheses={alignLeftMovementIndicator}
                isIncreasePositiveMovement={isIncreasePositiveMovement}
              />
            </MovementIndicatorContainer>
          </>
        )}
      </SecondRow>
      {_.isNumber(previousBigNumber) && (
        <PreviousBigNumberContainer>
          from&nbsp;
          <PreviousBigNumber>{metricValueFormatter(previousBigNumber)}</PreviousBigNumber>
        </PreviousBigNumberContainer>
      )}
    </div>
  );
};

export default AdditionalMetricBlock;
