/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 29th 2021, 7:49:09 am
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';

export const Container = styled.div`
  padding-top: 8rem;
  padding-bottom: 20.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p:first-child {
    ${focus2Texts.website.heading['H4 - 16px']}
    margin-bottom: 1.1rem;
  }

  & > p:last-child {
    ${focus2Texts.website['Body 2 - 14px']}
    color: ${focus2Colors.text.subdued};
    max-width: 23rem;
    text-align: center;
  }
`;
