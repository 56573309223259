/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Oct 4th 2021, 3:34:36 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';

export const ViewDetailsTypedContainer = styled.div`
  padding: 2rem 2rem 0;
`;

export const TimeFilterTypedContainer = styled.div`
  padding: 2rem 2rem 0;
  display: flex;
  align-items: flex-start;
`;

export const FirstRow = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h2<{ hideMetricOverview: boolean }>`
  ${({ hideMetricOverview }) =>
    hideMetricOverview ? focus2Texts.website.heading['H3 - 20px'] : focus2Texts.website.heading['H4 - 16px']}
  margin-right: 0.8rem;
`;

export const TooltipActivator = styled.div`
  .Polaris-Icon {
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
  }
`;

export const LinkContainer = styled.div`
  margin-left: auto;
`;

export const TimeFilterOnLargeScreenContainer = styled.div`
  margin-left: auto;

  display: block;

  @media (max-width: 64em) {
    display: none;
  }
`;

export const TimeFilterOnSmallScreenContainer = styled.div`
  margin-top: 1.2rem;

  display: none;

  @media (max-width: 64em) {
    display: block;
  }
`;

export const SecondRow = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 0.8rem;
`;

export const BigNumber = styled.p`
  ${focus2Texts.chart['Number - 28px']}
  margin-right: 0.4rem;
`;

export const PreviousBigNumberContainer = styled.p`
  ${focus2Texts.website['Body 2 - 14px']}
  color: ${focus2Colors.text.subdued};
`;

export const PreviousBigNumber = styled.span`
  ${focus2Texts.website.heading['H5 - 14px']}
`;

export const TooltipContent = styled.div`
  padding: 1.6rem 1.2rem;
`;

export const TooltipTitle = styled.div`
  ${focus2Texts.website.heading['H4 - 16px']}
  margin-bottom: 0.4rem;
`;

export const TooltipButtonContainer = styled.div`
  margin-top: 2rem;
`;

export const MovementIndicatorContainer = styled.div<{ alignLeftMovementIndicator: boolean }>`
  margin-left: ${({ alignLeftMovementIndicator }) => (alignLeftMovementIndicator ? '' : 'auto')};
`;

export const SearchTypedContainer = styled.div`
  padding: 2rem 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const SearchTypedLeftSide = styled.div`
  display: flex;
  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;
  }
`;

export const SearchBarContainer = styled.div`
  display: flex;
  justify-content: end;
  flex: 1 0 60%; // spend around 60% of row
  & > div {
    width: 50%;
    max-width: 40rem;
  }
  @media (max-width: 64em) {
    margin-top: 1.6rem;
    flex: 1 0 100%;
    & > div {
      width: 100%;
      max-width: none;
    }
  }
`;

export const TagMarkupContainer = styled.div<{ amount: number }>`
  margin-top: 2rem;
  flex: 1 0 100%; // in a separate row
  display: ${({ amount }) => (amount ? 'block' : 'none')};
`;
