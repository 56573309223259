/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Aug 2nd 2021, 01:24:03 pm
 *
 * *****************************************************
 */

import { Endpoint } from 'states/services/types';

// SYNC STATUS
export const SYNC_STATUS_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/sync-status',
  isPrivate: true
};

export const METRICS_SUBSCRIPTION_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/subscription',
  isPrivate: true
};
export const METRICS_ACTIVITIES_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/activity',
  isPrivate: true
};
export const METRICS_PLAN_AND_LOCATION_OVERVIEW_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/plan-location/overview',
  isPrivate: true
};
export const METRICS_LIST_APP_PLAN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/plan-location/app/plans',
  isPrivate: true
};
export const METRICS_LIST_APP_PLAN_CHURN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/plan-location/app/plan-churns',
  isPrivate: true
};
export const METRICS_APP_PLAN_OVERVIEW_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/plan-location/app/plans/:planName/overview',
  isPrivate: true
};
export const METRICS_APP_PLAN_CHURN_OVERVIEW_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/plan-location/app/plan-churns/:planName/overview',
  isPrivate: true
};
export const METRICS_APP_PLAN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/plan-location/app/plans/:planName',
  isPrivate: true
};
export const METRICS_APP_PLAN_CHURN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/plan-location/app/plan-churns/:planName',
  isPrivate: true
};
export const METRICS_LIST_SHOPIFY_PLAN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/plan-location/shopify/plans',
  isPrivate: true
};
export const METRICS_SHOPIFY_PLAN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/plan-location/shopify/plans/:shopifyPlanName',
  isPrivate: true
};
export const METRICS_SHOPIFY_PLAN_OVERVIEW_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/plan-location/shopify/plans/:shopifyPlanName/overview',
  isPrivate: true
};
export const METRICS_LIST_SHOPIFY_PLAN_CHURN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/plan-location/shopify/plan-churns',
  isPrivate: true
};
export const METRICS_SHOPIFY_PLAN_CHURN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/plan-location/shopify/plan-churns/:shopifyPlanName',
  isPrivate: true
};
export const METRICS_SHOPIFY_PLAN_CHURN_OVERVIEW_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/plan-location/shopify/plan-churns/:shopifyPlanName/overview',
  isPrivate: true
};
export const METRICS_LIST_LOCATION_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/plan-location/locations',
  isPrivate: true
};
export const METRICS_LIST_LOCATION_CHURN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/plan-location/location-churns',
  isPrivate: true
};
export const METRICS_LOCATION_OVERVIEW_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/plan-location/locations/:countryCode/overview',
  isPrivate: true
};
export const METRICS_LOCATION_CHURN_OVERVIEW_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/plan-location/location-churns/:countryCode/overview',
  isPrivate: true
};
export const METRICS_LOCATION_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/plan-location/locations/:countryCode',
  isPrivate: true
};
export const METRICS_LOCATION_CHURN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/plan-location/location-churns/:countryCode',
  isPrivate: true
};
