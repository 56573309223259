/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Sep 16th 2021, 8:56:19 am
 *
 * *****************************************************
 */

const website = {
  display: {
    'DisplayXLarge-Semibold': `
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: 600;
      font-size: 3.2rem;
      line-height: 3.6rem;
    `,
    'DisplayLarge-Semibold': `
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: 600;
      font-size: 2.8rem;
      line-height: 3.2rem;
    `,
    'DisplayMedium-Regular': `
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: normal;
      font-size: 2.6rem;
      line-height: 3.2rem;
    `,
    'DisplaySmall-Regular': `
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: 600;
      font-size: 2rem;
      line-height: 2.8rem;
    `
  },
  heading: {
    'H1-32px': `
      font-family: SF Pro Text;
      font-style: normal;
      font-weight: 600;
      font-size: 3.2rem;
      line-height: 4rem;
    `,
    'H2 - 24px': `
      font-family: SF Pro Text;
      font-style: normal;
      font-weight: 600;
      font-size: 2.4rem;
      line-height: 3.2rem;
    `,
    'H3 - 20px': `
      font-family: SF Pro Text;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    `,
    'H4 - 16px': `
      font-family: SF Pro Text;
      font-style: normal;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.4rem;
    `,
    'H5 - 14px': `
      font-family: SF Pro Text;
      font-style: normal;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 2rem;
    `,
    // for movement indicator
    // TODO: update order of styles heading
    'H6 - 18px': ` 
      font-family: SF Pro Text;
      font-style: normal;
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 16px;
    `
  },
  'SUBHEADING-Semibold': `
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
  `,
  'Button-Medium': `
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
  `,
  'Body 1 - 16px': `
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.4rem;
  `,
  'Body 2 - 14px': `
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2rem;
  `,
  'Caption-Regular': `
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.6rem;
  `
};

const chart = {
  'Number - 28px': `
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 3.6rem;
  `
};

export default {
  website,
  chart
};
