/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 27th 2021, 11:00:30 am
 *
 * *****************************************************
 */

import { useContext } from 'react';

import { Field } from 'react-final-form';

import { MAX_LENGTH_PROJECT_NAME } from 'constants/form/project';
import FormErrorBanner from 'features/common/views/components/FormErrorBanner';
import { FormStateContext } from 'features/common/views/components/FullScreenModalForm';
import CreateProjectContent from 'features/project/views/components/CreateProjectModal/CreateProjectContent';
import CreateProjectModalTextFieldAdapter from 'features/project/views/components/CreateProjectModal/CreateProjectModalTextFieldAdapter';
import { composeValidators, required, maxLength } from 'utils/form/validation';

import { Container, Description } from './styles';

/**
 * Create project modal content - Step 1
 */
const Step1 = (): JSX.Element => {
  const { submitErrors } = useContext(FormStateContext);
  return (
    <CreateProjectContent
      title="Create Project"
      description={
        <Description>
          A project allows you to connect data sources, gather data insights and more. It is best organized around a single app.
        </Description>
      }>
      <Container>
        {submitErrors && <FormErrorBanner data={submitErrors} />}
        <Field
          name="name"
          component={CreateProjectModalTextFieldAdapter}
          label="Project name"
          requiredIndicator
          placeholder="Enter Project name"
          validate={composeValidators(required('Project name'), maxLength('Project name', MAX_LENGTH_PROJECT_NAME))}
        />
      </Container>
    </CreateProjectContent>
  );
};

export default Step1;
