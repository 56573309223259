/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Sat, Jul 24th 2021, 5:33:48 pm
 *
 * *****************************************************
 */

import { ReactNode, useCallback, useState } from 'react';

import { Stack, Link } from '@shopify/polaris';
import { generatePath, useParams } from 'react-router-dom';

import TopBarCompanyLogoButton from 'features/common/views/components/TopBarCompanyLogoButton';
import UserMenu from 'features/common/views/components/UserMenu';
import { PROJECT_METRICS_DASHBOARD, PROJECT_SETTINGS_INFO } from 'features/project/routes/paths';
import CreateProjectModal from 'features/project/views/components/CreateProjectModal';

import ProjectListDropdown from './ProjectListDropdown';
import { LayoutContainer, TopBar, LogoButtonContainer, Nav } from './styles';

type Props = {
  children: ReactNode;
  workspaceName: string;
};

const ProjectLayout = ({ children, workspaceName }: Props): JSX.Element => {
  const { projectId } = useParams<{ projectId: string }>();
  const [createProjectModalActive, setCreateProjectModalActive] = useState(false);

  const toggleCreateProjectModalShowing = useCallback(() => setCreateProjectModalActive(prevState => !prevState), []);

  return (
    <LayoutContainer>
      <TopBar>
        <LogoButtonContainer>
          <Stack alignment="center" spacing="tight" wrap={false}>
            <TopBarCompanyLogoButton hideWorkspaceName workspaceName={workspaceName} />
            <ProjectListDropdown onOpenCreateProjectModal={toggleCreateProjectModalShowing} />
          </Stack>
          <Nav>
            <Link removeUnderline url={generatePath(PROJECT_METRICS_DASHBOARD, { projectId })}>
              Metrics
            </Link>
            <Link removeUnderline url={generatePath(PROJECT_SETTINGS_INFO, { projectId })}>
              Settings
            </Link>
          </Nav>
        </LogoButtonContainer>
        <UserMenu />
      </TopBar>
      <main>{children}</main>
      <CreateProjectModal open={createProjectModalActive} onClose={toggleCreateProjectModalShowing} />
    </LayoutContainer>
  );
};

export default ProjectLayout;
