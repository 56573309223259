/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Jul 22nd 2021, 9:33:34 pm
 *
 * *****************************************************
 */

import Illustration from 'assets/images/404.svg';
import ErrorPageLayout from 'features/common/views/layouts/ErrorPageLayout';

const Page404 = () => (
  <ErrorPageLayout
    title="The page you requested is not available."
    description="Maybe a typo, no permission or it doesn’t exist."
    imgSrc={Illustration}
  />
);

export default Page404;
