import { Endpoint } from 'states/services/types';

export const METRICS_FAILED_CHARGE_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/failed-charges/chart',
  isPrivate: true
};

export const METRICS_FAILED_CHARGE_GROWTH_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/failed-charges/growth-chart',
  isPrivate: true
};

export const METRICS_FAILED_CHARGE_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/failed-charges/breakdown',
  isPrivate: true
};
