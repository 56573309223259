/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Sep 21st 2021, 4:51:56 am
 *
 * *****************************************************
 */

import PageHeader from 'features/project/views/pages/ProjectMetric/PageHeader';

import { SidebarContext } from './context';
import Section from './Section';
import { SidebarContainer, ListContainer, PageHeaderContainer } from './styles';

type Props = {
  smallScreenSidebarActive: boolean;
  pageName: string;
  onDeactivateSmallScreenSidebar: () => void;
  onToggleSmallScreenSidebarActive: () => void;
  children: React.ReactNode;
};

type SideBarType = {
  (props: Props): JSX.Element;
  Section: typeof Section;
};

const Sidebar: SideBarType = ({
  smallScreenSidebarActive,
  pageName,
  onDeactivateSmallScreenSidebar,
  onToggleSmallScreenSidebarActive,
  children
}: Props) => {
  return (
    <SidebarContext.Provider value={{ onDeactivateSmallScreenSidebar }}>
      <SidebarContainer active={smallScreenSidebarActive}>
        {smallScreenSidebarActive && (
          <PageHeaderContainer>
            <PageHeader
              name={pageName}
              sidebarActive={smallScreenSidebarActive}
              onToggleSidebarActive={onToggleSmallScreenSidebarActive}
            />
          </PageHeaderContainer>
        )}
        <ListContainer>{children}</ListContainer>
      </SidebarContainer>
    </SidebarContext.Provider>
  );
};

Sidebar.Section = Section;

export default Sidebar;
