/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Oct 1st 2021, 1:27:52 pm
 *
 * *****************************************************
 */

import { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import RelativeTime from 'features/common/views/components/RelativeTime';
import ForecastChartCard from 'features/project/views/pages/ProjectMetric/MetricCard/ForecastChartCard';
import MetricTableCard from 'features/project/views/pages/ProjectMetric/MetricCard/MetricTableCard';
import MetricChartCardLoading from 'features/project/views/pages/ProjectMetric/MetricCardLoading/MetricChartCardLoading';
import MetricTableCardLoading from 'features/project/views/pages/ProjectMetric/MetricCardLoading/MetricTableCardLoading';
import DatePicker from 'features/project/views/pages/ProjectMetric/MetricDatePicker';
import {
  ChartContainer,
  DatePickerContainer,
  NoticeBlock,
  PageContentContainer,
  PlanTableCardContainer,
  BreakdownTableCardContainer
} from 'features/project/views/pages/ProjectMetric/MetricPage/UserAndCustomerPageGroup/CustomerDowngrade/styles';
import {
  useFetchCustomerDowngradeBreakdownQuery,
  useFetchCustomerDowngradeChartQuery
} from 'states/services/metrics/user/customer-downgrade';
import {
  CustomerDowngradeBreakdownResponse,
  CustomerDowngradeChartResponse
} from 'states/services/metrics/user/customer-downgrade/types';
import { createTextDatePickerRange, formatCurrency, formatDate } from 'utils/project';

const INITIAL_FORM = {
  forecast: false,
  regression: 'linear',
  frameWidth: 'six_month',
  forecastFrom: moment().startOf('day').toISOString(),
  forecastTo: moment().startOf('day').add(6, 'months').toISOString()
};

const CustomerDowngradePage = () => {
  const { projectId } = useParams() as { projectId: string };
  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedCurrentValue, setCommittedCurrentValue] = useState(() => {
    const last30days = {
      start: moment().subtract(30, 'day').toDate(),
      end: moment().subtract(1, 'day').toDate()
    };
    return last30days;
  });

  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedPreviousValue, setCommittedPreviousValue] = useState(() => {
    const last60days = {
      start: moment().subtract(60, 'day').toDate(),
      end: moment().subtract(31, 'day').toDate()
    };
    return last60days;
  });

  const [hasPreviousComparison, setHasPreviousComparison] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [forecastForm, setForecastForm] = useState(INITIAL_FORM);

  const onForecastFormSubmit = useCallback(partialForm => {
    setForecastForm(prev => {
      return { ...prev, ...partialForm };
    });
  }, []);

  /**
   * initial data before calling api
   * data chart fetching by projectId, fromDate, toDate
   */
  const chartResponse = useFetchCustomerDowngradeChartQuery(
    {
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end),
      ...forecastForm
    },
    { refetchOnMountOrArgChange: true }
  );

  const breakdownResponse = useFetchCustomerDowngradeBreakdownQuery(
    {
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end)
    },
    { refetchOnMountOrArgChange: true }
  );

  const metricCalculatedAt = _.get(chartResponse, 'data.metricCalculatedAt', null);

  const chartLegends = useMemo(() => {
    const { forecast, forecastFrom, forecastTo } = forecastForm;
    if (forecast) {
      return [createTextDatePickerRange(moment(forecastFrom).toDate(), moment(forecastTo).toDate())];
    }
    return [committedCurrentValue, hasPreviousComparison && committedPreviousValue].map(dateRange => {
      if (!dateRange) return '';
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    });
  }, [committedCurrentValue, committedPreviousValue, forecastForm, hasPreviousComparison]);

  const customerDowngradeChart = (() => {
    let children = null;
    const { isLoading, isFetching, data } = chartResponse;
    if (isLoading) children = <MetricChartCardLoading hasOverview hasChart size="large" />;
    else {
      const { customerDowngrade } = data as CustomerDowngradeChartResponse;
      const { overview: metricOverviewData, data: metricChartData } = customerDowngrade;
      children = (
        <ForecastChartCard
          metricName="customerDowngrade"
          size="large"
          isFetching={isFetching}
          overviewConfig={{
            type: 'time-filter',
            forecastForm,
            updateFormCallback: onForecastFormSubmit,
            data: metricOverviewData
          }}
          chartConfig={{
            visible: true,
            data: metricChartData,
            legends: chartLegends
          }}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  })();

  const planTable = useMemo(() => {
    let children = null;
    const { isFetching, data } = breakdownResponse;
    if (isFetching) children = <MetricTableCardLoading />;
    else {
      const { plan } = data as CustomerDowngradeBreakdownResponse;
      const dataTableMapping = {
        planName: {
          tableIndex: 0
        },
        interval: {
          tableIndex: 1
        },
        activeCustomers: {
          tableIndex: 2
        },
        downgradeTo: {
          tableIndex: 3
        }
      };

      const tableSortFunction = (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => {
        if (index === 0)
          return [...rows].sort((rowA, rowB) => {
            const amountA = rowA[index] as string;
            const amountB = rowB[index] as string;

            return direction === 'descending' ? amountB.localeCompare(amountA) : amountA.localeCompare(amountB);
          });
        if (index === 1)
          return [...rows].sort((rowA, rowB) => {
            const amountA = moment(rowA[index], 'MMM DD, YYYY');
            const amountB = moment(rowB[index], 'MMM DD, YYYY');

            return direction === 'descending' ? amountB.diff(amountA) : amountA.diff(amountB);
          });
        return rows;
      };

      children = (
        <MetricTableCard
          cardTitle="Plans"
          columnContentTypes={['text', 'text', 'numeric', 'numeric']}
          headings={['Plan', 'Interval', 'Active customer', 'Customer Downgrade']}
          totalsName={{ singular: 'Summary', plural: 'Summary' }}
          sortable={[true, false, false, false]}
          defaultSortDirection="descending"
          data={plan}
          dataTableMapping={dataTableMapping}
          sortFunction={tableSortFunction}
        />
      );
    }
    return <PlanTableCardContainer>{children}</PlanTableCardContainer>;
  }, [breakdownResponse]);

  const customerTable = useMemo(() => {
    let children = null;
    const { isFetching, data } = breakdownResponse;
    if (isFetching) children = <MetricTableCardLoading />;
    else {
      const { breakdown } = data as CustomerDowngradeBreakdownResponse;
      const dataTableMapping = {
        shopName: {
          tableIndex: 0
        },
        eventDate: {
          tableIndex: 1
        },
        oldPlan: {
          tableIndex: 2
        },
        newPlan: {
          tableIndex: 3
        },
        mrr: {
          tableIndex: 4,
          valueFormatter: formatCurrency
        }
      };

      const tableSortFunction = (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => {
        if (index === 0)
          return [...rows].sort((rowA, rowB) => {
            const amountA = rowA[index] as string;
            const amountB = rowB[index] as string;

            return direction === 'descending' ? amountB.localeCompare(amountA) : amountA.localeCompare(amountB);
          });
        if (index === 1)
          return [...rows].sort((rowA, rowB) => {
            const amountA = moment(rowA[index], 'MMM DD, YYYY');
            const amountB = moment(rowB[index], 'MMM DD, YYYY');

            return direction === 'descending' ? amountB.diff(amountA) : amountA.diff(amountB);
          });
        return rows;
      };

      children = (
        <MetricTableCard
          cardTitle="Customers"
          columnContentTypes={['text', 'text', 'text', 'text', 'numeric']}
          headings={['Name', 'Date ', 'Old Plan', 'New Plan', 'MRR Downgrade']}
          totalsName={{ singular: 'Summary', plural: 'Summary' }}
          sortable={[true, false, false, false, false]}
          defaultSortDirection="descending"
          data={breakdown}
          dataTableMapping={dataTableMapping}
          sortFunction={tableSortFunction}
        />
      );
    }
    return <BreakdownTableCardContainer>{children}</BreakdownTableCardContainer>;
  }, [breakdownResponse]);

  return (
    <PageContentContainer>
      <DatePickerContainer>
        <DatePicker
          hasPreviousComparison={hasPreviousComparison}
          setHasPreviousComparison={setHasPreviousComparison}
          committedCurrentValue={committedCurrentValue}
          setCommittedCurrentValue={setCommittedCurrentValue}
          committedPreviousValue={committedPreviousValue}
          setCommittedPreviousValue={setCommittedPreviousValue}
        />
      </DatePickerContainer>
      {customerDowngradeChart}
      {planTable}
      {customerTable}
      <NoticeBlock>
        <p>
          {chartResponse.isFetching ? (
            'Your data is loading...'
          ) : (
            <>
              Your data was last updated&nbsp;
              <RelativeTime value={metricCalculatedAt} />
            </>
          )}
        </p>
      </NoticeBlock>
    </PageContentContainer>
  );
};

export default CustomerDowngradePage;
