import { Endpoint } from 'states/services/types';

export const METRICS_USAGE_MRR_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/usage-mrr/chart',
  isPrivate: true
};

export const METRICS_USAGE_MRR_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/usage-mrr/breakdown',
  isPrivate: true
};
