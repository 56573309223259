/**
 * Copyright (C) Boost commerce
 * This file is part of commercial Boost commerce projects
 *
 * This file can not be copied and/or distributed without the express
 * permission of Boost commerce
 *
 * Created on Thu Jan 11 2024 17:56:29
 */

export const AGGREGATED_METRICS_DASHBOARD = '/f/metrics/dashboard';

export const AGGREGATED_METRICS_REVENUE = '/f/metrics/revenue';
export const AGGREGATED_METRICS_REVENUE_OVERVIEW = '/f/metrics/revenue/overview';
export const AGGREGATED_METRICS_NET_REVENUE = '/f/metrics/revenue/net-revenue';
export const AGGREGATED_METRICS_MRR = '/f/metrics/revenue/mrr';
export const AGGREGATED_METRICS_MRR_NEW = '/f/metrics/revenue/mrr-new';
export const AGGREGATED_METRICS_MRR_UPGRADE = '/f/metrics/revenue/mrr-upgrade';
export const AGGREGATED_METRICS_MRR_DOWNGRADE = '/f/metrics/revenue/mrr-downgrade';
export const AGGREGATED_METRICS_MRR_REACTIVATION = '/f/metrics/revenue/mrr-reactivation';
export const AGGREGATED_METRICS_MRR_CANCELLATION = '/f/metrics/revenue/mrr-cancellation';
export const AGGREGATED_METRICS_MRR_CHURN = '/f/metrics/revenue/mrr-churn';
export const AGGREGATED_METRICS_ONE_TIME_CHARGES = '/f/metrics/revenue/one-time-charges';
export const AGGREGATED_METRICS_USAGE_CHARGES = '/f/metrics/revenue/usage-charges';
export const AGGREGATED_METRICS_ADD_ON = '/f/metrics/revenue/add-on';
export const AGGREGATED_METRICS_FAILED_CHARGES = '/f/metrics/revenue/failed-charges';
export const AGGREGATED_METRICS_REFUNDS_AND_CREDITS = '/f/metrics/revenue/refunds-and-credits';
export const AGGREGATED_METRICS_COSTS = '/f/metrics/revenue/costs';
export const AGGREGATED_METRICS_INSTALL_AND_UNINSTALL = '/f/metrics/install-uninstall';
export const AGGREGATED_METRICS_INSTALL_AND_UNINSTALL_OVERVIEW = '/f/metrics/install-uninstall/overview';
export const AGGREGATED_METRICS_INSTALL = '/f/metrics/install-uninstall/install';
export const AGGREGATED_METRICS_REOPEN = '/f/metrics/install-uninstall/reopen';
export const AGGREGATED_METRICS_UNINSTALL = '/f/metrics/install-uninstall/uninstall';
export const AGGREGATED_METRICS_CLOSE = '/f/metrics/install-uninstall/close';
export const AGGREGATED_METRICS_USER_AND_CUSTOMER = '/f/metrics/user';
export const AGGREGATED_METRICS_USER_AND_CUSTOMER_OVERVIEW = '/f/metrics/user/overview';
export const AGGREGATED_METRICS_CUSTOMER_CHURN = '/f/metrics/user/customer-churn';
export const AGGREGATED_METRICS_ARPU = '/f/metrics/user/arpu';
export const AGGREGATED_METRICS_LTV = '/f/metrics/user/ltv';
export const AGGREGATED_METRICS_ACTIVE_CUSTOMER = '/f/metrics/user/active-customer';
export const AGGREGATED_METRICS_NEW_CUSTOMER = '/f/metrics/user/new-customer';
export const AGGREGATED_METRICS_CUSTOMER_UPGRADE = '/f/metrics/user/customer-upgrade';
export const AGGREGATED_METRICS_CUSTOMER_DOWNGRADE = '/f/metrics/user/customer-downgrade';
export const AGGREGATED_METRICS_CUSTOMER_CANCELLATION = '/f/metrics/user/customer-cancellation';
export const AGGREGATED_METRICS_CUSTOMER_REACTIVATION = '/f/metrics/user/customer-reactivation';
export const AGGREGATED_METRICS_CUSTOMER_GROWTH = '/f/metrics/user/customer-growth';
export const AGGREGATED_METRICS_CONVERSION_RATE = '/f/metrics/user/conversion-rate';
export const AGGREGATED_METRICS_AVERAGE_CUSTOMER_LIFESPAN = '/f/metrics/user/average-customer-lifespan';
export const AGGREGATED_METRICS_ACTIVE_USER = '/f/metrics/user/active-user';
export const AGGREGATED_METRICS_USER_AND_CUSTOMER_V2 = '/f/metrics/user-v2';
export const AGGREGATED_METRICS_USER_AND_CUSTOMER_OVERVIEW_V2 = '/f/metrics/user-v2/overview';
export const AGGREGATED_METRICS_CUSTOMER_CHURN_V2 = '/f/metrics/user-v2/customer-churn';
export const AGGREGATED_METRICS_ARPU_V2 = '/f/metrics/user-v2/arpu';
export const AGGREGATED_METRICS_LTV_V2 = '/f/metrics/user-v2/ltv';
export const AGGREGATED_METRICS_ACTIVE_CUSTOMER_V2 = '/f/metrics/user-v2/active-customer';
export const AGGREGATED_METRICS_NEW_CUSTOMER_V2 = '/f/metrics/user-v2/new-customer';
export const AGGREGATED_METRICS_CUSTOMER_UPGRADE_V2 = '/f/metrics/user-v2/customer-upgrade';
export const AGGREGATED_METRICS_CUSTOMER_DOWNGRADE_V2 = '/f/metrics/user-v2/customer-downgrade';
export const AGGREGATED_METRICS_CUSTOMER_CANCELLATION_V2 = '/f/metrics/user-v2/customer-cancellation';
export const AGGREGATED_METRICS_CUSTOMER_REACTIVATION_V2 = '/f/metrics/user-v2/customer-reactivation';
export const AGGREGATED_METRICS_CUSTOMER_GROWTH_V2 = '/f/metrics/user-v2/customer-growth';
export const AGGREGATED_METRICS_CONVERSION_RATE_V2 = '/f/metrics/user-v2/conversion-rate';
export const AGGREGATED_METRICS_AVERAGE_CUSTOMER_LIFESPAN_V2 = '/f/metrics/user-v2/average-customer-lifespan';
export const AGGREGATED_METRICS_ACTIVE_USER_V2 = '/f/metrics/user-v2/active-user';
export const AGGREGATED_METRICS_SUBSCRIPTION = '/f/metrics/revenue/subscription';
export const AGGREGATED_METRICS_PLAN_AND_LOCATION = '/f/metrics/plan-location';
export const AGGREGATED_METRICS_PLAN_AND_LOCATION_OVERVIEW = '/f/metrics/plan-location/overview';
export const AGGREGATED_METRICS_APP_PLAN = '/f/metrics/plan-location/app-plan';
export const AGGREGATED_METRICS_APP_PLAN_CHURN = '/f/metrics/plan-location/app-plan-churn';
export const AGGREGATED_METRICS_APP_PLAN_DETAIL = '/f/metrics/plan-location/app-plan/:planName';
export const AGGREGATED_METRICS_APP_PLAN_CHURN_DETAIL = '/f/metrics/plan-location/app-plan-churn/:planName';
export const AGGREGATED_METRICS_ACTIVITIES = '/f/metrics/activity';
export const AGGREGATED_METRICS_SHOPIFY_PLAN = '/f/metrics/plan-location/shopify-plan';
export const AGGREGATED_METRICS_SHOPIFY_PLAN_CHURN = '/f/metrics/plan-location/shopify-plan-churn';
export const AGGREGATED_METRICS_LOCATION = '/f/metrics/plan-location/location';
export const AGGREGATED_METRICS_LOCATION_CHURN = '/f/metrics/plan-location/location-churn';
export const AGGREGATED_METRICS_SHOPIFY_PLAN_DETAIL = '/f/metrics/plan-location/shopify-plan/:shopifyPlanName';
export const AGGREGATED_METRICS_SHOPIFY_PLAN_CHURN_DETAIL = '/f/metrics/plan-location/shopify-plan-churn/:shopifyPlanName';
export const AGGREGATED_METRICS_LOCATION_DETAIL = '/f/metrics/plan-location/location/:countryCode';
export const AGGREGATED_METRICS_LOCATION_CHURN_DETAIL = '/f/metrics/plan-location/location-churn/:countryCode';
