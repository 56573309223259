/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Sat, Jul 24th 2021, 10:43:57 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';

export const Container = styled.div`
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;

    padding: 0;
    padding-right: 0.2rem;
    padding-left: 0.8rem;

    height: 4rem;

    border: none;

    svg {
      margin-left: 0.4rem;
    }

    &:hover {
      path {
        fill: #fff;
      }
    }
  }

  /* OVERRIDEN CSS */
  --p-action-secondary-hovered: ${focus2Colors.onDark.hovered};
  --p-action-secondary-pressed: ${focus2Colors.onDark.pressed};
  --p-button-drop-shadow: none;

  .Polaris-TopBar-Menu__ActivatorWrapper {
    margin-right: -0.6rem;
  }
`;
