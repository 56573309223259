/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Sep 21st 2021, 1:46:54 pm
 *
 * *****************************************************
 */

import { ReactComponent as CloseHamburgerMenuIcon } from 'assets/images/close-hamburger-menu.svg';
import { ReactComponent as HamburgerMenuIcon } from 'assets/images/hamburger-menu.svg';

import { Container } from './styles';

interface Props {
  name: string;
  sidebarActive: boolean;
  onToggleSidebarActive: () => void;
}

const PageHeader = ({ name, sidebarActive, onToggleSidebarActive }: Props): JSX.Element => {
  return (
    <Container>
      <h1>{name}</h1>
      <button
        type="button"
        onClick={onToggleSidebarActive}
        aria-label={sidebarActive ? 'Hide navigation menu' : 'Show navigation menu'}>
        {sidebarActive ? <CloseHamburgerMenuIcon /> : <HamburgerMenuIcon />}
      </button>
    </Container>
  );
};

export default PageHeader;
