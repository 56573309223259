/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Aug 6th 2021, 3:13:09 pm
 *
 * *****************************************************
 */

import React, { useState, useCallback } from 'react';

import { Popover, Button, ActionList, ActionListItemDescriptor } from '@shopify/polaris';
import { HomeMajor, AppsMajor, SendMajor, AffiliateMajor } from '@shopify/polaris-icons';

import { ReactComponent as LogoIcon } from 'assets/images/logoWhite.svg';
import { SWITCH_WORKSPACE_LINK } from 'constants/links';
import { AGGREGATED_METRICS_DASHBOARD } from 'features/metrics/routes/paths';
import CreateProjectModal from 'features/project/views/components/CreateProjectModal';
import { DEFAULT } from 'features/workspace-dashboard/routes/paths';
import { redirectToExternalPage } from 'utils/redirect';

import { Container, WorkspaceName } from './styles';

type Props = {
  hideWorkspaceName?: boolean;
  workspaceName: string;
};

const TopBarCompanyLogoButton = ({ hideWorkspaceName = false, workspaceName }: Props): JSX.Element => {
  const [createProjectModalActive, setCreateProjectModalActive] = useState(false);
  const [popoverActive, setPopoverActive] = useState(false);

  /**
   * Handlers
   */
  const togglePopoverActive = useCallback(() => setPopoverActive(prevState => !prevState), []);

  const handleToggleCreateProjectModalActive = useCallback(() => setCreateProjectModalActive(prevState => !prevState), []);

  const handleSwitchWorkspace = useCallback(() => {
    redirectToExternalPage(SWITCH_WORKSPACE_LINK);
  }, []);

  const actionListItemsData: ActionListItemDescriptor[] = [
    {
      content: 'Home',
      url: DEFAULT,
      icon: HomeMajor
    },
    {
      content: 'New Project',
      icon: AppsMajor,
      onAction: handleToggleCreateProjectModalActive
    },
    {
      content: 'Aggregated Project',
      icon: AffiliateMajor,
      url: AGGREGATED_METRICS_DASHBOARD
    },
    {
      content: 'Switch Workspace',
      icon: SendMajor,
      onAction: handleSwitchWorkspace
    }
  ];

  return (
    <>
      <Container>
        <Popover
          active={popoverActive}
          activator={
            <div className="Polaris-TopBar-Menu__ActivatorWrapper">
              <Button icon={<LogoIcon />} onClick={togglePopoverActive} />
            </div>
          }
          onClose={togglePopoverActive}>
          <ActionList onActionAnyItem={togglePopoverActive} items={actionListItemsData} />
        </Popover>
        {!hideWorkspaceName && <WorkspaceName>{workspaceName}</WorkspaceName>}
      </Container>
      <CreateProjectModal open={createProjectModalActive} onClose={handleToggleCreateProjectModalActive} />
    </>
  );
};

export default TopBarCompanyLogoButton;
