/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 26th 2021, 10:56:19 am
 *
 * *****************************************************
 */

import { emptyAPI } from 'states/services';
import { Workspace } from 'states/slices/workspace/types';

import * as workspaceEndpoints from './endpoints';

/**
 * An api for current workspace
 * create by extending (injecting) empty api with current workspace endpoints
 */
export const workspaceAPI = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    getWorkspaceBasicInfo: builder.query<Workspace, void>({
      query: () => workspaceEndpoints.WORKSPACE_BASIC_INFO_ENDPOINT.url,
      extraOptions: {
        isPrivate: workspaceEndpoints.WORKSPACE_BASIC_INFO_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Workspace }) => response.data
    }),
    getWorkspaceSettings: builder.query<Workspace, void>({
      query: () => workspaceEndpoints.WORKSPACE_SETTING_ENDPOINT.url,
      extraOptions: {
        isPrivate: workspaceEndpoints.WORKSPACE_SETTING_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Workspace }) => response.data
    })
  })
});
