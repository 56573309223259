/**
 * Copyright (C) Boost commerce
 * This file is part of commercial Boost commerce projects
 *
 * This file can not be copied and/or distributed without the express
 * permission of Boost commerce
 *
 * Created on Mon Jan 15 2024 17:18:37
 */

import { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';

import RelativeTime from 'features/common/views/components/RelativeTime';
import ForecastChartCard from 'features/metrics/views/components/cards/ForecastChartCard';
import MetricTableCard from 'features/metrics/views/components/cards/MetricTableCard';
import DatePicker from 'features/metrics/views/components/DatePicker';
import MetricChartCardLoading from 'features/metrics/views/components/MetricCardLoading/MetricChartCardLoading';
import MetricTableCardLoading from 'features/metrics/views/components/MetricCardLoading/MetricTableCardLoading';
import { MrrDowngrade } from 'states/services/metrics/models';
import {
  useFetchMRRDowngradeBreakdownQuery,
  useFetchMRRDowngradeChartQuery
} from 'states/services/metrics/revenue/mrr-downgrade';
import { MRRDowngradeBreakdownResponse, MRRDowngradeChartResponse } from 'states/services/metrics/revenue/mrr-downgrade/types';
import { createTextDatePickerRange, formatCurrency, formatDate, formatDecimalNumber, formatPercent } from 'utils/project';

import {
  PageContentContainer,
  DatePickerContainer,
  ChartContainer,
  NoticeBlock,
  DowngradesCardContainer,
  PlansCardContainer
} from './styles';

const INITIAL_FORM = {
  forecast: false,
  regression: 'linear',
  frameWidth: 'six_month',
  forecastFrom: moment().startOf('day').toISOString(),
  forecastTo: moment().startOf('day').add(6, 'months').toISOString()
};

const MRRDowngradePage = () => {
  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedCurrentValue, setCommittedCurrentValue] = useState(() => {
    const last30days = {
      start: moment().subtract(30, 'day').toDate(),
      end: moment().subtract(1, 'day').toDate()
    };
    return last30days;
  });

  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedPreviousValue, setCommittedPreviousValue] = useState(() => {
    const last60days = {
      start: moment().subtract(60, 'day').toDate(),
      end: moment().subtract(31, 'day').toDate()
    };
    return last60days;
  });

  const [hasPreviousComparison, setHasPreviousComparison] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [forecastForm, setForecastForm] = useState(INITIAL_FORM);

  const onForecastFormSubmit = useCallback(partialForm => {
    setForecastForm(prev => {
      return { ...prev, ...partialForm };
    });
  }, []);

  /**
   * initial data before calling api
   * data chart fetching by projectId, fromDate, toDate
   */
  const chartResponse = useFetchMRRDowngradeChartQuery(
    {
      projectId: 'aggregated',
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end),
      ...forecastForm
    },
    { refetchOnMountOrArgChange: true }
  );

  const breakdownResponse = useFetchMRRDowngradeBreakdownQuery(
    {
      projectId: 'aggregated',
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end)
    },
    { refetchOnMountOrArgChange: true }
  );

  const metricCalculatedAt = _.get(chartResponse, 'data.metricCalculatedAt', null);

  const chartLegends = useMemo(() => {
    const { forecast, forecastFrom, forecastTo } = forecastForm;
    if (forecast) {
      return [createTextDatePickerRange(moment(forecastFrom).toDate(), moment(forecastTo).toDate())];
    }
    return [committedCurrentValue, hasPreviousComparison && committedPreviousValue].map(dateRange => {
      if (!dateRange) return '';
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    });
  }, [committedCurrentValue, committedPreviousValue, forecastForm, hasPreviousComparison]);

  const mrrDowngradeChart = (() => {
    let children = null;
    if (chartResponse.isLoading) children = <MetricChartCardLoading hasOverview hasChart size="large" />;
    else {
      const { mrrDowngrade } = chartResponse.data as MRRDowngradeChartResponse;
      const { overview: metricOverviewData, data: metricChartData } = mrrDowngrade;
      children = (
        <ForecastChartCard
          metricName="mrrDowngrade"
          size="large"
          isFetching={chartResponse.isFetching}
          overviewConfig={{
            type: 'time-filter',
            forecastForm,
            updateFormCallback: onForecastFormSubmit,
            data: metricOverviewData
          }}
          chartConfig={{
            visible: true,
            data: metricChartData,
            legends: chartLegends
          }}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  })();

  const downgradesTable = useMemo(() => {
    let children = null;
    if (breakdownResponse.isFetching) children = <MetricTableCardLoading />;
    else {
      const { breakdown } = breakdownResponse.data as MRRDowngradeBreakdownResponse;
      const dataTableMapping = {
        shopName: {
          tableIndex: 0
        },
        eventDate: {
          tableIndex: 1
        },
        oldPlan: {
          tableIndex: 2
        },
        newPlan: {
          tableIndex: 3
        },
        mrr: {
          tableIndex: 4,
          valueFormatter: formatCurrency
        }
      };

      const tableSortFunction = (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => {
        if (index === 1)
          return [...rows].sort((rowA, rowB) => {
            const amountA = moment(rowA[index], 'MMM DD, YYYY');
            const amountB = moment(rowB[index], 'MMM DD, YYYY');

            return direction === 'descending' ? amountB.diff(amountA) : amountA.diff(amountB);
          });

        if (index === 0 || index === 2 || index === 3)
          return [...rows].sort((rowA, rowB) => {
            const amountA = rowA[index] as string;
            const amountB = rowB[index] as string;

            return direction === 'descending' ? amountB.localeCompare(amountA) : amountA.localeCompare(amountB);
          });

        return rows;
      };

      children = (
        <MetricTableCard
          cardTitle="Downgrades"
          columnContentTypes={['text', 'text', 'text', 'text', 'numeric']}
          headings={['Name', 'Date', 'Old Plan', 'New Plan', 'MRR Lost']}
          totalsName={{ singular: 'Summary', plural: 'Summary' }}
          sortable={[true, true, true, true, false]}
          defaultSortDirection="descending"
          data={breakdown}
          dataTableMapping={dataTableMapping}
          sortFunction={tableSortFunction}
        />
      );
    }
    return <DowngradesCardContainer>{children}</DowngradesCardContainer>;
  }, [breakdownResponse.data, breakdownResponse.isFetching]);

  const plansTable = useMemo(() => {
    let children = null;
    if (breakdownResponse.isFetching) children = <MetricTableCardLoading />;
    else {
      const { plan } = breakdownResponse.data as MRRDowngradeBreakdownResponse;
      const dataTableMapping = {
        planName: {
          tableIndex: 0
        },
        interval: {
          tableIndex: 1
        },
        activeCustomers: {
          tableIndex: 2,
          valueFormatter: formatDecimalNumber
        },
        downgradeFrom: {
          tableIndex: 3,
          valueFormatter: (value: number, rowData: MrrDowngrade.PlanItem) => {
            let suffix = '';
            if (rowData) {
              const { downgradeFromPercent } = rowData;
              suffix = ` (${formatPercent(downgradeFromPercent / 100)})`;
            }
            return `${formatDecimalNumber(value)}${suffix}`;
          }
        },
        downgradeTo: {
          tableIndex: 4,
          valueFormatter: (value: number, rowData: MrrDowngrade.PlanItem) => {
            let suffix = '';
            if (rowData) {
              const { downgradeToPercent } = rowData;
              suffix = ` (${formatPercent(downgradeToPercent / 100)})`;
            }
            return `${formatDecimalNumber(value)}${suffix}`;
          }
        }
      };

      const tableSortFunction = (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => {
        if (index === 0)
          return [...rows].sort((rowA, rowB) => {
            const amountA = rowA[index] as string;
            const amountB = rowB[index] as string;

            return direction === 'descending' ? amountB.localeCompare(amountA) : amountA.localeCompare(amountB);
          });

        return rows;
      };

      children = (
        <MetricTableCard
          cardTitle="Plans"
          columnContentTypes={['text', 'text', 'numeric', 'numeric', 'numeric']}
          headings={['Plan', 'Interval', 'Active Customers', 'Downgrade From', 'Downgrade To']}
          totalsName={{ singular: 'Summary', plural: 'Summary' }}
          sortable={[true, false, false, false, false, false]}
          defaultSortDirection="descending"
          data={plan}
          dataTableMapping={dataTableMapping}
          sortFunction={tableSortFunction}
        />
      );
    }
    return <PlansCardContainer>{children}</PlansCardContainer>;
  }, [breakdownResponse.data, breakdownResponse.isFetching]);

  return (
    <PageContentContainer>
      <DatePickerContainer>
        <DatePicker
          hasPreviousComparison={hasPreviousComparison}
          setHasPreviousComparison={setHasPreviousComparison}
          committedCurrentValue={committedCurrentValue}
          setCommittedCurrentValue={setCommittedCurrentValue}
          committedPreviousValue={committedPreviousValue}
          setCommittedPreviousValue={setCommittedPreviousValue}
        />
      </DatePickerContainer>
      {mrrDowngradeChart}
      {downgradesTable}
      {plansTable}
      <NoticeBlock>
        <p>
          {chartResponse.isFetching ? (
            'Your data is loading...'
          ) : (
            <>
              Your data was last updated&nbsp;
              <RelativeTime value={metricCalculatedAt} />
            </>
          )}
        </p>
      </NoticeBlock>
    </PageContentContainer>
  );
};

export default MRRDowngradePage;
