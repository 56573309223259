/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Oct 4th 2021, 3:19:58 pm
 *
 * *****************************************************
 */

import { useCallback, useMemo, useState } from 'react';

import { Button, Icon, Tooltip, Popover, ActionList, SkeletonDisplayText } from '@shopify/polaris';
import { CircleInformationMajor, CaretDownMinor } from '@shopify/polaris-icons';
import _ from 'lodash';

import BASE_BLOCKS, { MetricName } from 'constants/metrics/base-blocks';
import { TimeFilterValue } from 'features/project/views/pages/ProjectMetric/MetricCard/ForecastChartCard';
import ForecastForm from 'features/project/views/pages/ProjectMetric/MetricCard/ForecastChartCard/ForecastForm';
import MovementIndicator from 'features/project/views/pages/ProjectMetric/MovementIndicator';
import { Common } from 'states/services/metrics/models';

import {
  BigNumber,
  Container,
  Header,
  PreviousBigNumber,
  Overview,
  Title,
  Dropdown,
  InformationSection,
  TimeFilterSection,
  ForecastFormContainer,
  Skeleton,
  Tooltip as StyledTooltip
} from './styles';
import TimeFilter from './TimeFilter';

type Properties = {
  metricName: MetricName;
  selectedTimeFilterValue: TimeFilterValue;
  onTimeFilterChange: (timeFilterValue: TimeFilterValue) => void;
  isFetching?: boolean;
  data?: Common.MetricOverviewData;
  updateFormCallback?: (params: any) => void;
  forecastForm: {
    forecast: boolean;
    regression: string;
    frameWidth: string;
    forecastFrom: string;
    forecastTo: string;
  };
};

const TimeFilterOverview = (props: Properties) => {
  const { metricName, selectedTimeFilterValue, onTimeFilterChange, data } = props;
  const { isFetching = false, updateFormCallback, forecastForm } = props;

  const { forecast: isForecast, forecastFrom, forecastTo, frameWidth, regression } = forecastForm;

  const [dropdownActive, setDropdownActive] = useState(false);
  const { title, tooltip, isIncreasePositiveMovement, metricValueFormatter } = BASE_BLOCKS[metricName];

  const bigNumber = _.get(data, 'bigNumber', null);
  const previousBigNumber = _.get(data, 'previousBigNumber', null);

  const toggleDropdown = useCallback(() => {
    setDropdownActive(prev => !prev);
  }, [setDropdownActive]);

  const changeAction = useCallback(
    (value: boolean) => {
      if (updateFormCallback) {
        updateFormCallback({ forecast: value });
      }
      toggleDropdown();
    },
    [toggleDropdown, updateFormCallback]
  );

  const dropdownActivator = (
    <Dropdown onClick={toggleDropdown}>
      <Button icon={CaretDownMinor} />
    </Dropdown>
  );

  const actionItems = useMemo(() => {
    return [
      { content: title, onAction: () => changeAction(false) },
      { content: `${title} Forecast`, onAction: () => changeAction(true) }
    ];
  }, [changeAction, title]);

  const timeFilterComponent = useMemo(() => {
    const smallFilter = (
      <TimeFilterSection mode="small">
        <TimeFilter value={selectedTimeFilterValue} onChange={onTimeFilterChange} />
      </TimeFilterSection>
    );

    const largeFilter = (
      <TimeFilterSection mode="large">
        <TimeFilter value={selectedTimeFilterValue} onChange={onTimeFilterChange} />
      </TimeFilterSection>
    );

    return {
      small: smallFilter,
      large: largeFilter
    };
  }, [onTimeFilterChange, selectedTimeFilterValue]);

  const tooltipComponent = useMemo(() => {
    if (!tooltip) return null;

    return (
      <Tooltip
        content={
          <StyledTooltip.Content>
            <StyledTooltip.Title>{tooltip.title}</StyledTooltip.Title>
            <StyledTooltip.Description>{tooltip.descriptions}</StyledTooltip.Description>
            <StyledTooltip.Button>
              <Button url={tooltip.url}>Learn more</Button>
            </StyledTooltip.Button>
          </StyledTooltip.Content>
        }
        preferredPosition="above">
        <StyledTooltip.Activator>
          <Icon source={CircleInformationMajor} color="subdued" />
        </StyledTooltip.Activator>
      </Tooltip>
    );
  }, [tooltip]);

  if (isFetching) {
    return (
      <Container>
        <InformationSection>
          <Header>
            <Title>{isForecast ? `${title} Forecast` : title}</Title>
            {tooltipComponent}
            <Popover active={dropdownActive} activator={dropdownActivator} autofocusTarget="first-node" onClose={toggleDropdown}>
              <ActionList items={actionItems} />
            </Popover>
          </Header>
          {isForecast && (
            <ForecastFormContainer>
              <ForecastForm
                forecastFrom={forecastFrom}
                forecastTo={forecastTo}
                frameWidth={frameWidth}
                regression={regression}
                updateFormCallback={updateFormCallback}
              />
            </ForecastFormContainer>
          )}
          <Skeleton.Container>
            <Skeleton.LeftSide>
              <Skeleton.LeftTop>
                <SkeletonDisplayText />
              </Skeleton.LeftTop>
              <Skeleton.LeftBottom>
                <SkeletonDisplayText />
              </Skeleton.LeftBottom>
            </Skeleton.LeftSide>
            {isForecast && (
              <Skeleton.RightSide>
                <SkeletonDisplayText />
              </Skeleton.RightSide>
            )}
          </Skeleton.Container>
        </InformationSection>
        {!isForecast && (
          <Skeleton.RightSide>
            <SkeletonDisplayText />
          </Skeleton.RightSide>
        )}
      </Container>
    );
  }

  return (
    <Container>
      <InformationSection>
        <Header>
          <Title>{isForecast ? `${title} Forecast` : title}</Title>
          {tooltipComponent}
          <Popover active={dropdownActive} activator={dropdownActivator} autofocusTarget="first-node" onClose={toggleDropdown}>
            <ActionList items={actionItems} />
          </Popover>
        </Header>
        {isForecast && (
          <ForecastFormContainer>
            <ForecastForm
              forecastFrom={forecastFrom}
              forecastTo={forecastTo}
              frameWidth={frameWidth}
              regression={regression}
              updateFormCallback={updateFormCallback}
            />
          </ForecastFormContainer>
        )}
        <Overview.Container>
          <Overview.LeftSide>
            <Overview.LeftTop>
              <BigNumber>{metricValueFormatter(bigNumber)}</BigNumber>
              {_.isNumber(previousBigNumber) && (
                <MovementIndicator
                  currentNumber={bigNumber}
                  previousNumber={previousBigNumber}
                  hasParentheses
                  isIncreasePositiveMovement={isIncreasePositiveMovement}
                />
              )}
            </Overview.LeftTop>
            {_.isNumber(previousBigNumber) && (
              <Overview.LeftBottom>
                from&nbsp;
                <PreviousBigNumber>{metricValueFormatter(previousBigNumber)}</PreviousBigNumber>
              </Overview.LeftBottom>
            )}
          </Overview.LeftSide>
          <Overview.RightSide>{isForecast && timeFilterComponent.large}</Overview.RightSide>
        </Overview.Container>
        {timeFilterComponent.small}
      </InformationSection>
      {!isForecast && timeFilterComponent.large}
    </Container>
  );
};

export default TimeFilterOverview;
