/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Aug 23rd 2021, 5:22:16 pm
 *
 * *****************************************************
 */

type DecodedJWT = {
  header: {
    alg: string;
    typ: string;
  };
  payload: {
    data: object;
    iat: number;
    exp: number;
  };
  secret: string;
};

export const decodeJWT = (jwtToken: string): DecodedJWT => {
  const arr = jwtToken.split('.');
  return {
    header: JSON.parse(window.atob(arr[0])),
    payload: JSON.parse(window.atob(arr[1])),
    secret: arr[2]
  };
};

export const isJWTExpired = (expiredTime: number): boolean => {
  /**
   * Get current time in seconds
   */
  const currentTime = Math.floor(new Date().getTime() / 1e3) - 10;

  /**
   * Intentionally consider an expiration to happen 10 seconds early
   */
  return expiredTime - currentTime <= 10;
};
