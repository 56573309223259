/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Aug 12th 2021, 1:43:36 pm
 *
 * *****************************************************
 */

import { useEffect } from 'react';

import {
  GetAppPlanChurnOverviewResponse,
  GetAppPlanRequest,
  GetAppPlanOverviewResponse,
  GetShopifyPlanOverviewResponse,
  GetShopifyPlanChurnOverviewResponse,
  GetLocationOverviewResponse,
  GetLocationRequest,
  GetLocationChurnOverviewResponse,
  GetShopifyPlanRequest
} from 'states/services/metrics/types';
import { addItem } from 'states/slices/metrics';
import { useAppDispatch } from 'utils/hooks';

import { metricsAPI } from './index';

const {
  useGetSyncStatusQuery,
  useGetPlanOverviewQuery,
  useGetAppPlanQuery,
  useGetAppPlanOverviewQuery: useGetAppPlanGrowthQueryOriginal,
  useGetSubscriptionQuery,
  useGetActivitiesQuery,
  useGetAppPlanDetailQuery,
  useGetAppPlanChurnQuery,
  useGetAppPlanChurnOverviewQuery: useGetAppPlanChurnGrowthQueryOriginal,
  useGetAppPlanChurnDetailQuery,
  useGetShopifyPlanQuery,
  useGetShopifyPlanChurnQuery,
  useGetLocationQuery,
  useGetLocationChurnQuery,
  useGetShopifyPlanOverviewQuery: useGetShopifyPlanOverviewQueryOriginal,
  useGetShopifyPlanChurnOverviewQuery: useGetShopifyPlanChurnOverviewQueryOriginal,
  useGetLocationOverviewQuery: useGetLocationOverviewQueryOriginal,
  useGetLocationChurnOverviewQuery: useGetLocationChurnOverviewQueryOriginal,
  useGetLocationChurnDetailQuery,
  useGetLocationDetailQuery,
  useGetShopifyPlanChurnDetailQuery,
  useGetShopifyPlanDetailQuery
} = metricsAPI;

const useGetAppPlanOverviewQuery: typeof useGetAppPlanGrowthQueryOriginal = (...args) => {
  const query = useGetAppPlanGrowthQueryOriginal(...args);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { isSuccess, isFetching } = query;
    if (isSuccess && !isFetching) {
      const requestBody = args[0] as GetAppPlanRequest;
      const { planName } = requestBody;
      const responseData = query.data as GetAppPlanOverviewResponse;
      const { planGrowthSection } = responseData;

      dispatch(addItem({ id: planName, body: planGrowthSection }));
    }
  }, [args, query, query.data, dispatch]);

  return query;
};

const useGetShopifyPlanOverviewQuery: typeof useGetShopifyPlanOverviewQueryOriginal = (...args) => {
  const query = useGetShopifyPlanOverviewQueryOriginal(...args);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { isSuccess, isFetching } = query;
    if (isSuccess && !isFetching) {
      const requestBody = args[0] as GetShopifyPlanRequest;
      const { shopifyPlanName } = requestBody;
      const responseData = query.data as GetShopifyPlanOverviewResponse;
      const { shopifyPlanGrowthSection } = responseData;

      dispatch(addItem({ id: shopifyPlanName, body: shopifyPlanGrowthSection }));
    }
  }, [args, query, query.data, dispatch]);

  return query;
};

const useGetLocationOverviewQuery: typeof useGetLocationOverviewQueryOriginal = (...args) => {
  const query = useGetLocationOverviewQueryOriginal(...args);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { isSuccess, isFetching } = query;
    if (isSuccess && !isFetching) {
      const requestBody = args[0] as GetLocationRequest;
      const { countryCode } = requestBody;
      const responseData = query.data as GetLocationOverviewResponse;
      const { locationGrowthSection } = responseData;
      dispatch(addItem({ id: countryCode, body: locationGrowthSection }));
    }
  }, [args, query, query.data, dispatch]);

  return query;
};

const useGetLocationChurnOverviewQuery: typeof useGetLocationChurnOverviewQueryOriginal = (...args) => {
  const query = useGetLocationChurnOverviewQueryOriginal(...args);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { isSuccess, isFetching } = query;
    if (isSuccess && !isFetching) {
      const requestBody = args[0] as GetLocationRequest;
      const { countryCode } = requestBody;
      const responseData = query.data as GetLocationChurnOverviewResponse;
      const { locationChurnSection } = responseData;
      dispatch(addItem({ id: countryCode, body: locationChurnSection }));
    }
  }, [args, query, query.data, dispatch]);

  return query;
};

const useGetShopifyPlanChurnOverviewQuery: typeof useGetShopifyPlanChurnOverviewQueryOriginal = (...args) => {
  const query = useGetShopifyPlanChurnOverviewQueryOriginal(...args);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { isSuccess, isFetching } = query;
    if (isSuccess && !isFetching) {
      const requestBody = args[0] as GetShopifyPlanRequest;
      const { shopifyPlanName } = requestBody;
      const responseData = query.data as GetShopifyPlanChurnOverviewResponse;
      const { shopifyPlanChurnSection } = responseData;
      dispatch(addItem({ id: shopifyPlanName, body: shopifyPlanChurnSection }));
    }
  }, [args, query, query.data, dispatch]);

  return query;
};

const useGetAppPlanChurnOverviewQuery: typeof useGetAppPlanChurnGrowthQueryOriginal = (...args) => {
  const query = useGetAppPlanChurnGrowthQueryOriginal(...args);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { isSuccess, isFetching } = query;
    if (isSuccess && !isFetching) {
      const requestBody = args[0] as GetAppPlanRequest;
      const { planName } = requestBody;
      const responseData = query.data as GetAppPlanChurnOverviewResponse;
      const { planChurnSection } = responseData;
      dispatch(addItem({ id: planName, body: planChurnSection }));
    }
  }, [args, query, query.data, dispatch]);

  return query;
};

export {
  useGetSyncStatusQuery,
  useGetPlanOverviewQuery,
  useGetAppPlanQuery,
  useGetAppPlanOverviewQuery,
  useGetSubscriptionQuery,
  useGetActivitiesQuery,
  useGetAppPlanDetailQuery,
  useGetAppPlanChurnQuery,
  useGetAppPlanChurnOverviewQuery,
  useGetAppPlanChurnDetailQuery,
  useGetShopifyPlanQuery,
  useGetShopifyPlanChurnQuery,
  useGetLocationQuery,
  useGetLocationChurnQuery,
  useGetShopifyPlanOverviewQuery,
  useGetShopifyPlanChurnOverviewQuery,
  useGetLocationOverviewQuery,
  useGetLocationChurnOverviewQuery,
  useGetLocationChurnDetailQuery,
  useGetLocationDetailQuery,
  useGetShopifyPlanChurnDetailQuery,
  useGetShopifyPlanDetailQuery
};
