/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 27th 2021, 6:05:29 pm
 *
 * *****************************************************
 */

import { VisuallyHidden } from '@shopify/polaris';
import { FieldRenderProps } from 'react-final-form';

import { Container, Name } from './styles';

type Props = FieldRenderProps<any> & {
  icon: React.ReactNode;
  label: string;
  id: string;
  disabled?: boolean;
};

/**
 * Radio button adapter for React Final Form's field
 * (used for create project modal ONLY)
 */
const CustomRadioButtonAdapter = ({ icon, id, label, disabled, input }: Props): JSX.Element => {
  const { onChange, name, checked, value } = input;
  return (
    <Container checked={checked} disabled={disabled}>
      <label htmlFor={id}>
        {icon}
        <Name>{label}</Name>
      </label>
      <VisuallyHidden>
        <input type="radio" value={value} name={name} id={id} disabled={disabled} checked={checked} onChange={onChange} />
      </VisuallyHidden>
    </Container>
  );
};

export default CustomRadioButtonAdapter;
