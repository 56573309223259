/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 19th 2021, 5:01:19 pm
 *
 * *****************************************************
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuthenticationResponse } from 'states/services/auth/types';
import { AuthState } from 'states/slices/auth/types';
import { decodeJWT } from 'utils/jwt';

/**
 * Redux slice - Authentication
 */
const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null, jwtToken: null, expiredTime: 0 } as AuthState,
  reducers: {
    authenticationDataReceived: (state, action: PayloadAction<AuthenticationResponse>) => {
      const { jwtToken, user } = action.payload;
      const { payload } = decodeJWT(jwtToken!);
      const { exp } = payload;
      state.jwtToken = jwtToken;
      state.expiredTime = exp;
      state.user = user;
    }
  }
});

/**
 * Redux actions - Authentication slice
 */
export const { authenticationDataReceived } = authSlice.actions;

/**
 * Redux reducer - Authentication slice
 */
export default authSlice.reducer;
