import BASE_BLOCKS, { MetricName } from 'constants/metrics/base-blocks';

import { Container, Table, Cell, Row, Body } from './styles';

type Props = {
  metricName: string;
  legends: string[];
  data: number[];
};

const Tooltip = ({ metricName, legends, data }: Props) => {
  const { title } = BASE_BLOCKS[metricName as MetricName];
  return (
    <Container>
      <Table>
        <Body>
          {legends.map((legend, index) => {
            if (!data[index]) return null;
            return (
              <Row key={index.toString()}>
                {index !== 0 && (
                  <Cell>
                    <hr />
                  </Cell>
                )}
                <Cell>{legend}</Cell>
                <Cell>{`${title}: ${data[index]}`}</Cell>
              </Row>
            );
          })}
        </Body>
      </Table>
    </Container>
  );
};

export default Tooltip;
