import { Endpoint } from 'states/services/types';

export const METRICS_ACTIVE_CUSTOMER_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/active-customer/chart',
  isPrivate: true
};

export const METRICS_ACTIVE_CUSTOMER_GROWTH_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/active-customer/growth-chart',
  isPrivate: true
};

export const METRICS_ACTIVE_CUSTOMER_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/active-customer/breakdown',
  isPrivate: true
};

export const METRICS_ACTIVE_CUSTOMER_CHART_V2_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user-v2/active-customer/chart',
  isPrivate: true
};

export const METRICS_ACTIVE_CUSTOMER_GROWTH_CHART_V2_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user-v2/active-customer/growth-chart',
  isPrivate: true
};

export const METRICS_ACTIVE_CUSTOMER_BREAKDOWN_V2_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user-v2/active-customer/breakdown',
  isPrivate: true
};
