/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Jul 29th 2021, 8:44:07 am
 *
 * *****************************************************
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProjectsState } from './types';

/**
 * Redux slice - Project list
 */
const projectsSlice = createSlice({
  name: 'projects',
  initialState: { totalCount: 0 } as ProjectsState,
  reducers: {
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload;
    }
  }
});

/**
 * Redux actions - Project list slice
 */
export const { setTotalCount } = projectsSlice.actions;

/**
 * Redux reducer - Project list slice
 */
export default projectsSlice.reducer;
