/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Aug 23rd 2021, 4:28:54 pm
 *
 * *****************************************************
 */

export const WORKSPACE_DASHBOARD_PROJECT_LIST_LENGTH = 5;
