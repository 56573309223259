import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import { buildParams } from 'states/services/metrics';
import { GetMetricRequest } from 'states/services/metrics/types';

import * as Endpoints from './endpoints';
import * as Types from './types';

const netRevenueApi = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    fetchNetRevenueChart: builder.query<Types.NetRevenueChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_NET_REVENUE_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_NET_REVENUE_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.NetRevenueChartResponse }) => {
        return response.data;
      }
    }),
    fetchNetRevenueBreakdown: builder.query<Types.NetRevenueBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_NET_REVENUE_BREAKDOWN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_NET_REVENUE_BREAKDOWN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.NetRevenueBreakdownResponse }) => {
        return response.data;
      }
    }),
    fetchNetRevenueGrowthChart: builder.query<Types.NetRevenueGrowthChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_NET_REVENUE_GROWTH_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_NET_REVENUE_GROWTH_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.NetRevenueGrowthChartResponse }) => {
        return response.data;
      }
    })
  })
});

export const { useFetchNetRevenueBreakdownQuery, useFetchNetRevenueChartQuery, useFetchNetRevenueGrowthChartQuery } =
  netRevenueApi;
