/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Aug 2nd 2021, 01:14:15 pm
 *
 * *****************************************************
 */

import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';

import * as metricEndpoints from './endpoints';
import * as metricAPITypes from './types';

/**
 * An api for current workspace's project list
 * create by extending (injecting) empty api with project list endpoints
 */

export const buildParams = (params: any) => {
  const { hasPreviousComparison, fromDate, toDate, previousFromDate, previousToDate, ...rest } = params;
  if (hasPreviousComparison) {
    return new URLSearchParams({ fromDate, toDate, previousFromDate, previousToDate, ...rest });
  }
  return new URLSearchParams({ fromDate, toDate, ...rest });
};

export const metricsAPI = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    // SYNC STATUS
    getSyncStatus: builder.query<metricAPITypes.GetSyncStatusDataResponse, string>({
      query: projectId => generatePath(metricEndpoints.SYNC_STATUS_ENDPOINT.url, { projectId }),
      extraOptions: {
        isPrivate: metricEndpoints.SYNC_STATUS_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetSyncStatusDataResponse }) => response.data
    }),
    getSubscription: builder.query<metricAPITypes.GetSubscriptionResponse, metricAPITypes.GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_SUBSCRIPTION_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_SUBSCRIPTION_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetSubscriptionResponse }) => {
        return response.data;
      }
    }),
    getActivities: builder.query<metricAPITypes.GetActivityResponse, metricAPITypes.GetActivityRequest>({
      query: ({ projectId, fromDate, toDate }) => {
        const params = new URLSearchParams({
          fromDate,
          toDate
        });
        return `${generatePath(metricEndpoints.METRICS_ACTIVITIES_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_ACTIVITIES_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetActivityResponse }) => {
        return response.data;
      }
    }),
    getPlanOverview: builder.query<metricAPITypes.GetPlanOverviewResponse, metricAPITypes.GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_PLAN_AND_LOCATION_OVERVIEW_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_PLAN_AND_LOCATION_OVERVIEW_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetPlanOverviewResponse }) => {
        return response.data;
      }
    }),
    getAppPlan: builder.query<metricAPITypes.GetAppPlanResponse, metricAPITypes.GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_LIST_APP_PLAN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_LIST_APP_PLAN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetAppPlanResponse }) => {
        return response.data;
      }
    }),
    getAppPlanChurn: builder.query<metricAPITypes.GetAppPlanChurnResponse, metricAPITypes.GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_LIST_APP_PLAN_CHURN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_LIST_APP_PLAN_CHURN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetAppPlanChurnResponse }) => {
        return response.data;
      }
    }),
    getAppPlanOverview: builder.query<metricAPITypes.GetAppPlanOverviewResponse, metricAPITypes.GetAppPlanRequest>({
      query: ({ projectId, planName, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_APP_PLAN_OVERVIEW_ENDPOINT.url, {
          projectId,
          planName
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_APP_PLAN_OVERVIEW_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetAppPlanOverviewResponse }) => {
        return response.data;
      }
    }),
    getAppPlanChurnOverview: builder.query<metricAPITypes.GetAppPlanChurnOverviewResponse, metricAPITypes.GetAppPlanRequest>({
      query: ({ projectId, planName, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_APP_PLAN_CHURN_OVERVIEW_ENDPOINT.url, {
          projectId,
          planName
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_APP_PLAN_CHURN_OVERVIEW_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetAppPlanChurnOverviewResponse }) => {
        return response.data;
      }
    }),
    getAppPlanDetail: builder.query<metricAPITypes.GetAppPlanDetailResponse, metricAPITypes.GetAppPlanRequest>({
      query: ({ projectId, planName, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_APP_PLAN_ENDPOINT.url, {
          projectId,
          planName
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_APP_PLAN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetAppPlanDetailResponse }) => {
        return response.data;
      }
    }),
    getAppPlanChurnDetail: builder.query<metricAPITypes.GetAppPlanChurnDetailResponse, metricAPITypes.GetAppPlanRequest>({
      query: ({ projectId, planName, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_APP_PLAN_CHURN_ENDPOINT.url, {
          projectId,
          planName
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_APP_PLAN_CHURN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetAppPlanChurnDetailResponse }) => {
        return response.data;
      }
    }),
    getShopifyPlan: builder.query<metricAPITypes.GetShopifyPlanResponse, metricAPITypes.GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_LIST_SHOPIFY_PLAN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_LIST_SHOPIFY_PLAN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetShopifyPlanResponse }) => {
        return response.data;
      }
    }),
    getShopifyPlanChurn: builder.query<metricAPITypes.GetShopifyPlanChurnResponse, metricAPITypes.GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_LIST_SHOPIFY_PLAN_CHURN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_LIST_SHOPIFY_PLAN_CHURN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetShopifyPlanChurnResponse }) => {
        return response.data;
      }
    }),
    getLocation: builder.query<metricAPITypes.GetLocationResponse, metricAPITypes.GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_LIST_LOCATION_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_LIST_LOCATION_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetLocationResponse }) => {
        return response.data;
      }
    }),
    getLocationChurn: builder.query<metricAPITypes.GetLocationChurnResponse, metricAPITypes.GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_LIST_LOCATION_CHURN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_LIST_LOCATION_CHURN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetLocationChurnResponse }) => {
        return response.data;
      }
    }),
    getShopifyPlanOverview: builder.query<metricAPITypes.GetShopifyPlanOverviewResponse, metricAPITypes.GetShopifyPlanRequest>({
      query: ({ projectId, shopifyPlanName, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_SHOPIFY_PLAN_OVERVIEW_ENDPOINT.url, {
          projectId,
          shopifyPlanName
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_SHOPIFY_PLAN_OVERVIEW_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetShopifyPlanOverviewResponse }) => {
        return response.data;
      }
    }),
    getShopifyPlanChurnOverview: builder.query<
      metricAPITypes.GetShopifyPlanChurnOverviewResponse,
      metricAPITypes.GetShopifyPlanRequest
    >({
      query: ({ projectId, shopifyPlanName, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_SHOPIFY_PLAN_CHURN_OVERVIEW_ENDPOINT.url, {
          projectId,
          shopifyPlanName
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_SHOPIFY_PLAN_CHURN_OVERVIEW_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetShopifyPlanChurnOverviewResponse }) => {
        return response.data;
      }
    }),
    getLocationOverview: builder.query<metricAPITypes.GetLocationOverviewResponse, metricAPITypes.GetLocationRequest>({
      query: ({ projectId, countryCode, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_LOCATION_OVERVIEW_ENDPOINT.url, {
          projectId,
          countryCode
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_LOCATION_OVERVIEW_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetLocationOverviewResponse }) => {
        return response.data;
      }
    }),
    getLocationChurnOverview: builder.query<metricAPITypes.GetLocationChurnOverviewResponse, metricAPITypes.GetLocationRequest>({
      query: ({ projectId, countryCode, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_LOCATION_CHURN_OVERVIEW_ENDPOINT.url, {
          projectId,
          countryCode
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_LOCATION_CHURN_OVERVIEW_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetLocationChurnOverviewResponse }) => {
        return response.data;
      }
    }),
    getShopifyPlanDetail: builder.query<metricAPITypes.GetShopifyPlanDetailResponse, metricAPITypes.GetShopifyPlanRequest>({
      query: ({ projectId, shopifyPlanName, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_SHOPIFY_PLAN_ENDPOINT.url, {
          projectId,
          shopifyPlanName
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_SHOPIFY_PLAN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetShopifyPlanDetailResponse }) => {
        return response.data;
      }
    }),
    getShopifyPlanChurnDetail: builder.query<
      metricAPITypes.GetShopifyPlanChurnDetailResponse,
      metricAPITypes.GetShopifyPlanRequest
    >({
      query: ({ projectId, shopifyPlanName, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_SHOPIFY_PLAN_CHURN_ENDPOINT.url, {
          projectId,
          shopifyPlanName
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_SHOPIFY_PLAN_CHURN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetShopifyPlanChurnDetailResponse }) => {
        return response.data;
      }
    }),
    getLocationDetail: builder.query<metricAPITypes.GetLocationDetailResponse, metricAPITypes.GetLocationRequest>({
      query: ({ projectId, countryCode, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_LOCATION_ENDPOINT.url, {
          projectId,
          countryCode
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_LOCATION_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetLocationDetailResponse }) => {
        return response.data;
      }
    }),
    getLocationChurnDetail: builder.query<metricAPITypes.GetLocationChurnDetailResponse, metricAPITypes.GetLocationRequest>({
      query: ({ projectId, countryCode, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(metricEndpoints.METRICS_LOCATION_CHURN_ENDPOINT.url, {
          projectId,
          countryCode
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: metricEndpoints.METRICS_LOCATION_CHURN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: metricAPITypes.GetLocationChurnDetailResponse }) => {
        return response.data;
      }
    })
  })
});
