import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import { buildParams } from 'states/services/metrics';
import { GetMetricRequest } from 'states/services/metrics/types';

import * as Endpoints from './endpoints';
import * as Types from './types';

const mrrUpgradeApi = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    fetchMRRUpgradeChart: builder.query<Types.MRRUpgradeChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_MRR_UPGRADE_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_MRR_UPGRADE_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.MRRUpgradeChartResponse }) => {
        return response.data;
      }
    }),
    fetchMRRUpgradeBreakdown: builder.query<Types.MRRUpgradeBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_MRR_UPGRADE_BREAKDOWN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_MRR_UPGRADE_BREAKDOWN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.MRRUpgradeBreakdownResponse }) => {
        return response.data;
      }
    })
  })
});

export const { useFetchMRRUpgradeBreakdownQuery, useFetchMRRUpgradeChartQuery } = mrrUpgradeApi;
