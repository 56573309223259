/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Oct 4th 2021, 3:19:58 pm
 *
 * *****************************************************
 */

import { useCallback, useState } from 'react';

import { Button, Icon, Tooltip, Link, Stack, Tag } from '@shopify/polaris';
import { CircleInformationMajor } from '@shopify/polaris-icons';
import _ from 'lodash';

import BASE_BLOCKS, { MetricName } from 'constants/metrics/base-blocks';
import SearchBar, {
  Option
} from 'features/project/views/pages/ProjectMetric/MetricCard/MetricChartCard/OverviewSection/SearchBar';
import MovementIndicator from 'features/project/views/pages/ProjectMetric/MovementIndicator';
import { Common } from 'states/services/metrics/models';

import {
  BigNumber,
  ViewDetailsTypedContainer,
  TimeFilterTypedContainer,
  FirstRow,
  TimeFilterOnLargeScreenContainer,
  TimeFilterOnSmallScreenContainer,
  PreviousBigNumber,
  LinkContainer,
  PreviousBigNumberContainer,
  SecondRow,
  Title,
  TooltipActivator,
  TooltipButtonContainer,
  TooltipContent,
  TooltipTitle,
  MovementIndicatorContainer,
  SearchBarContainer,
  TagMarkupContainer,
  SearchTypedContainer,
  SearchTypedLeftSide
} from './styles';
import TimeFilter from './TimeFilter';

export const OVERVIEW_TYPE_VIEW_DETAILS = 'view-details';
export const OVERVIEW_TYPE_MULTIPLE_TAGS = 'multiple-tags';
export const OVERVIEW_TYPE_TIME_FILTER = 'time-filter';

export type OverviewType =
  | typeof OVERVIEW_TYPE_VIEW_DETAILS
  | typeof OVERVIEW_TYPE_TIME_FILTER
  | typeof OVERVIEW_TYPE_MULTIPLE_TAGS;

export type Props = {
  metricName: MetricName;
  type?: OverviewType;
  selectedTimeFilterValue: 'day' | 'week' | 'month';
  onTimeFilterChange: (value: 'day' | 'week' | 'month') => void;
  hideTimeFilter?: boolean;
  hasPreviousComparison: boolean;
  data?: Common.MetricOverviewData;
  hideMetricOverview?: boolean;
  hideViewDetails?: boolean;
  hideTooltip?: boolean;
  alignLeftMovementIndicator?: boolean;
  options?: Option[];
  triggerFunction?: (x: { option: string; action: 'ADD' | 'REMOVE' }) => void;
};

const OverviewSection = ({
  metricName,
  type,
  selectedTimeFilterValue,
  onTimeFilterChange,
  hideTimeFilter = false,
  hideMetricOverview = false,
  hasPreviousComparison,
  hideViewDetails = false,
  hideTooltip = false,
  alignLeftMovementIndicator = false,
  data,
  options = [],
  triggerFunction
}: Props): JSX.Element | null => {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  const removeTag = useCallback(
    tag => () => {
      const cloneOptions = _(selectedOptions).clone();
      const index = cloneOptions.findIndex(o => o.value === tag);
      cloneOptions.splice(index, 1);
      if (triggerFunction) {
        triggerFunction({
          option: tag,
          action: 'REMOVE'
        });
      }
      setSelectedOptions(cloneOptions);
    },
    [selectedOptions, triggerFunction]
  );

  const tagsMarkup = _(selectedOptions)
    .map(option => {
      return (
        <Tag key={option.value} onRemove={removeTag(option.value)}>
          {option.label}
        </Tag>
      );
    })
    .value();

  const { title, tooltip, aggregatedUrl, isIncreasePositiveMovement, metricValueFormatter } = BASE_BLOCKS[metricName];

  const {
    title: tooltipTitle,
    descriptions: tooltipDescription,
    url: tooltipURL
  } = tooltip || {
    title: '',
    descriptions: '',
    url: ''
  };

  const { bigNumber, previousBigNumber } = data || { bigNumber: null, previousBigNumber: null };

  if (type === OVERVIEW_TYPE_TIME_FILTER) {
    return (
      <TimeFilterTypedContainer>
        <div>
          <FirstRow>
            <Title hideMetricOverview={hideMetricOverview}>{title}</Title>
            {!hideTooltip && (
              <Tooltip
                content={
                  <TooltipContent>
                    <TooltipTitle>{tooltipTitle}</TooltipTitle>
                    <p>{tooltipDescription}</p>
                    <TooltipButtonContainer>
                      <Button url={tooltipURL}>Learn more</Button>
                    </TooltipButtonContainer>
                  </TooltipContent>
                }
                preferredPosition="above">
                <TooltipActivator>
                  <Icon source={CircleInformationMajor} color="subdued" />
                </TooltipActivator>
              </Tooltip>
            )}
          </FirstRow>
          {hideMetricOverview || (
            <SecondRow>
              <BigNumber>{metricValueFormatter(bigNumber)}</BigNumber>
              {hasPreviousComparison && (
                <MovementIndicator
                  currentNumber={bigNumber}
                  previousNumber={previousBigNumber}
                  hasParentheses
                  isIncreasePositiveMovement={isIncreasePositiveMovement}
                />
              )}
            </SecondRow>
          )}
          {hideMetricOverview ||
            (hasPreviousComparison && (
              <PreviousBigNumberContainer>
                from&nbsp;
                <PreviousBigNumber>{metricValueFormatter(previousBigNumber)}</PreviousBigNumber>
              </PreviousBigNumberContainer>
            ))}
          {!hideTimeFilter && (
            <TimeFilterOnSmallScreenContainer>
              <TimeFilter value={selectedTimeFilterValue} onChange={onTimeFilterChange} />
            </TimeFilterOnSmallScreenContainer>
          )}
        </div>
        {!hideTimeFilter && (
          <TimeFilterOnLargeScreenContainer>
            <TimeFilter value={selectedTimeFilterValue} onChange={onTimeFilterChange} />
          </TimeFilterOnLargeScreenContainer>
        )}
      </TimeFilterTypedContainer>
    );
  }

  if (type === OVERVIEW_TYPE_VIEW_DETAILS) {
    return (
      <ViewDetailsTypedContainer>
        <FirstRow>
          <Title hideMetricOverview={hideMetricOverview}>{title}</Title>
          {hideTooltip || (
            <Tooltip
              content={
                <TooltipContent>
                  <TooltipTitle>{tooltipTitle}</TooltipTitle>
                  <p>{tooltipDescription}</p>
                  <TooltipButtonContainer>
                    <Button url={tooltipURL}>Learn more</Button>
                  </TooltipButtonContainer>
                </TooltipContent>
              }
              preferredPosition="above">
              <TooltipActivator>
                <Icon source={CircleInformationMajor} color="subdued" />
              </TooltipActivator>
            </Tooltip>
          )}
          {hideViewDetails || (
            <LinkContainer>
              <Link url={aggregatedUrl} removeUnderline>
                View details
              </Link>
            </LinkContainer>
          )}
        </FirstRow>
        {hideMetricOverview || (
          <SecondRow>
            <BigNumber>{metricValueFormatter(bigNumber)}</BigNumber>
            {hasPreviousComparison && (
              <>
                <MovementIndicatorContainer alignLeftMovementIndicator={alignLeftMovementIndicator}>
                  <MovementIndicator
                    currentNumber={bigNumber}
                    previousNumber={previousBigNumber}
                    hasParentheses={alignLeftMovementIndicator}
                    isIncreasePositiveMovement={isIncreasePositiveMovement}
                  />
                </MovementIndicatorContainer>
              </>
            )}
          </SecondRow>
        )}
        {hideMetricOverview ||
          (hasPreviousComparison && (
            <PreviousBigNumberContainer>
              from&nbsp;
              <PreviousBigNumber>{metricValueFormatter(previousBigNumber)}</PreviousBigNumber>
            </PreviousBigNumberContainer>
          ))}
      </ViewDetailsTypedContainer>
    );
  }

  if (type === OVERVIEW_TYPE_MULTIPLE_TAGS) {
    return (
      <SearchTypedContainer>
        <SearchTypedLeftSide>
          <FirstRow>
            <Title hideMetricOverview={hideMetricOverview}>{title}</Title>
            {hideTooltip || (
              <Tooltip
                content={
                  <TooltipContent>
                    <TooltipTitle>{tooltipTitle}</TooltipTitle>
                    <p>{tooltipDescription}</p>
                    <TooltipButtonContainer>
                      <Button url={tooltipURL}>Learn more</Button>
                    </TooltipButtonContainer>
                  </TooltipContent>
                }
                preferredPosition="above">
                <TooltipActivator>
                  <Icon source={CircleInformationMajor} color="subdued" />
                </TooltipActivator>
              </Tooltip>
            )}
          </FirstRow>
          {hideMetricOverview || (
            <SecondRow>
              <BigNumber>{metricValueFormatter(bigNumber)}</BigNumber>
              {hasPreviousComparison && (
                <MovementIndicator
                  currentNumber={bigNumber}
                  previousNumber={previousBigNumber}
                  hasParentheses
                  isIncreasePositiveMovement={isIncreasePositiveMovement}
                />
              )}
            </SecondRow>
          )}
          {hideMetricOverview ||
            (hasPreviousComparison && (
              <PreviousBigNumberContainer>
                from&nbsp;
                <PreviousBigNumber>{metricValueFormatter(previousBigNumber)}</PreviousBigNumber>
              </PreviousBigNumberContainer>
            ))}
        </SearchTypedLeftSide>
        <SearchBarContainer>
          <SearchBar
            selectedOptions={selectedOptions}
            updateSelectedOptions={setSelectedOptions}
            triggerFunction={triggerFunction}
            defaultOptions={options}
          />
        </SearchBarContainer>
        <TagMarkupContainer amount={tagsMarkup.length}>
          <Stack>{tagsMarkup}</Stack>
        </TagMarkupContainer>
      </SearchTypedContainer>
    );
  }
  return null;
};

export default OverviewSection;
