/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Oct 4th 2021, 1:53:02 pm
 *
 * *****************************************************
 */

import { useMemo } from 'react';

import { SkeletonDisplayText } from '@shopify/polaris';

import BASE_BLOCKS, { MetricName } from 'constants/metrics/base-blocks';
import BarChart, { BarChartData } from 'features/project/views/components/BarChart';
import DoughnutChart, { DoughnutChartData } from 'features/project/views/components/DoughnutChart';
import DynamicLineChart, { DynamicLineChartData } from 'features/project/views/components/DynamicLineChart';
import LineChart, { LineChartData } from 'features/project/views/components/LineChart';
import { SizeChart } from 'features/project/views/pages/ProjectMetric/MetricCard/MetricChartCard';
import { Skeleton, ChartContainer } from 'features/project/views/pages/ProjectMetric/MetricChartV2/styles';

export type MetricChartData =
  | LineChartData // [{key: '2021-01-01' data: 12}]
  | BarChartData // [{key: '2021-01-01' data: 12}]
  | DoughnutChartData // [{key: '2021-01-01' data: 12}]
  | DynamicLineChartData; // [[{key: '2021-01-01' data: 12}], [{key: '2021-01-01' data: 12}]]

export type Properties = {
  metricName: MetricName;
  data: MetricChartData;
  legends: string[];
  isFetching?: boolean;
  bigNumbers?: number[]; // for doughnut chart
  size?: SizeChart;
};

const MetricChart = (props: Properties): JSX.Element | null => {
  const { metricName, data, legends } = props;
  const { bigNumbers = [], size = 'medium', isFetching = false } = props;
  const { chartConfig, metricValueFormatter } = BASE_BLOCKS[metricName];

  const chartComponent = useMemo(() => {
    if (!chartConfig) return null;

    let component = null;
    switch (chartConfig.type) {
      case 'LINE_CHART':
        component = <LineChart metricValueFormatter={metricValueFormatter} data={data} legends={legends} config={chartConfig} />;
        break;
      case 'BAR_CHART':
        component = <BarChart metricValueFormatter={metricValueFormatter} data={data} legends={legends} config={chartConfig} />;
        break;
      case 'DOUGHNUT_CHART':
        component = (
          <DoughnutChart
            bigNumbers={bigNumbers}
            metricValueFormatter={metricValueFormatter}
            data={data}
            config={chartConfig}
            size={size}
            legends={legends}
            metricName={metricName}
          />
        );
        break;
      case 'DYNAMIC_LINE_CHART':
        component = (
          <DynamicLineChart
            legends={legends}
            config={chartConfig}
            data={data as DynamicLineChartData}
            metricValueFormatter={metricValueFormatter}
          />
        );
        break;
      default:
        component = null;
        break;
    }

    if (!component) return null;

    return (
      <ChartContainer size={size} type={chartConfig.type}>
        {component}
      </ChartContainer>
    );
  }, [bigNumbers, chartConfig, data, legends, metricName, metricValueFormatter, size]);

  if (isFetching) {
    return (
      <Skeleton.Container>
        <Skeleton.Chart size={size}>
          <SkeletonDisplayText />
        </Skeleton.Chart>
        <Skeleton.Legend>
          <SkeletonDisplayText />
          <SkeletonDisplayText />
        </Skeleton.Legend>
      </Skeleton.Container>
    );
  }

  return chartComponent;
};

export default MetricChart;
