/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 26th 2021, 4:33:17 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

export const Container = styled.div`
  width: 26.2rem;
  position: relative;

  @media (max-width: 38.1875em) {
    width: 18rem;
  }

  /* OVERRIDEN CSS */
  .Polaris-Icon {
    position: absolute;
    top: 50%;
    left: 0.8rem;
    transform: translateY(-50%);
    z-index: 31;
  }
  .Polaris-TextField__Input {
    padding-left: 3.6rem;
  }
`;
