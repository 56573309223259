/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 26th 2021, 4:04:10 pm
 *
 * *****************************************************
 */

import { useCallback, useState } from 'react';

import { Button, Card, Pagination, ResourceItem, ResourceList, SkeletonBodyText, Stack, TextStyle } from '@shopify/polaris';
import { generatePath } from 'react-router-dom';

import { SORT_VALUE_NAME_ASCENDING, SORT_VALUE_NAME_DESCENDING, SORT_VALUE_NO_OPTION } from 'constants/sorting';
import { WORKSPACE_DASHBOARD_PROJECT_LIST_LENGTH } from 'constants/workspace_dashboard';
import ItemAvatar from 'features/common/views/components/ItemAvatar';
import { PROJECT_METRICS_DASHBOARD, PROJECT_SETTINGS_INFO } from 'features/project/routes/paths';
import CreateProjectModal from 'features/project/views/components/CreateProjectModal';
import { useGetProjectListQuery } from 'states/services/projects/hooks';
import { GetProjectListResponse } from 'states/services/projects/types';
import { selectTotalCount } from 'states/slices/projects/selectors';
import { useAppSelector } from 'utils/hooks';

import FilterAndSortButton from './FilterAndSortButton';
import ProjectListEmptyContent from './ProjectListEmptyContent';
import SearchTextField from './SearchTextField';
import SortSelect from './SortSelect';
import { BlockContainer, CardHeaderActionContainer, ProjectListContainer, SkeletonContainer } from './styles';

const ProjectList = (): JSX.Element => {
  const [createProjectModalActive, setCreateProjectModalActive] = useState(false);
  const [filterAndSortExpanded, setFilterAndSortExpanded] = useState(false);
  const [sortValue, setSortValue] = useState(SORT_VALUE_NO_OPTION);

  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const { data, isFetching, isLoading, isSuccess } = useGetProjectListQuery(
    {
      page,
      limit: WORKSPACE_DASHBOARD_PROJECT_LIST_LENGTH,
      search
    },
    { refetchOnMountOrArgChange: true }
  );
  const { projects, count, totalPages } = (data as GetProjectListResponse) ?? {
    projects: [],
    count: 0,
    totalPages: 0
  };
  const totalCount = useAppSelector(selectTotalCount);
  const haveNoProjects = totalCount === 0;

  const handleSetSearch = useCallback((value: string) => {
    setPage(1);
    setSearch(value);
  }, []);

  const handlePageNext = useCallback(() => {
    setPage(currentPage => currentPage + 1);
  }, []);

  const handlePagePrevious = useCallback(() => {
    setPage(currentPage => currentPage - 1);
  }, []);

  const handleToogleFilterAndSortExpanded = useCallback(() => {
    setFilterAndSortExpanded(prevState => !prevState);
  }, []);

  const handleToggleCreateProjectModalShowing = useCallback(() => setCreateProjectModalActive(prevState => !prevState), []);

  /**
   * Clone projects and save in a modifiable variable
   */
  const sortedProjectsData: typeof projects = [];
  projects.forEach(item => {
    sortedProjectsData.push({ ...item });
  });

  switch (sortValue) {
    case SORT_VALUE_NAME_ASCENDING: {
      sortedProjectsData.sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB));
      break;
    }
    case SORT_VALUE_NAME_DESCENDING: {
      sortedProjectsData.sort(({ name: nameA }, { name: nameB }) => nameB.localeCompare(nameA));
      break;
    }
    default:
      break;
  }

  const renderProjectListItem = useCallback(item => {
    const { id, name, color, image } = item;
    const media = <ItemAvatar name={name} color={color} source={image && image.url} />;
    const shortcutActions = [{ content: 'Settings', url: generatePath(PROJECT_SETTINGS_INFO, { projectId: id }) }];
    return (
      <ResourceItem
        id={id}
        url={generatePath(PROJECT_METRICS_DASHBOARD, { projectId: id })}
        media={media}
        verticalAlignment="center"
        shortcutActions={shortcutActions}
        persistActions>
        <h3>
          <TextStyle variation="strong">{name}</TextStyle>
        </h3>
      </ResourceItem>
    );
  }, []);

  let card = null;

  if (isLoading)
    card = (
      <Card sectioned>
        <SkeletonBodyText lines={5} />
      </Card>
    );
  if (isSuccess) {
    if (haveNoProjects)
      card = (
        <Card sectioned>
          <ProjectListEmptyContent onClick={handleToggleCreateProjectModalShowing} />
        </Card>
      );
    else {
      card = (
        <Card>
          <ProjectListContainer key="project-list-block-project-list">
            <Card.Header title="Projects">
              <CardHeaderActionContainer>
                <FilterAndSortButton isExpanded={filterAndSortExpanded} onClick={handleToogleFilterAndSortExpanded} />
                <Button
                  primary
                  ariaDescribedBy="workspace-dashboard|create-project"
                  onClick={handleToggleCreateProjectModalShowing}>
                  Create project
                </Button>
              </CardHeaderActionContainer>
            </Card.Header>
            {filterAndSortExpanded && (
              <Card.Section>
                <Stack alignment="center" distribution="equalSpacing">
                  <Stack.Item>{`Showing ${projects.length}/${count} projects`}</Stack.Item>
                  <Stack.Item>
                    <Stack>
                      <SearchTextField
                        label="Search"
                        labelHidden
                        autoComplete=""
                        placeholder="Filter projects"
                        value={search}
                        onChange={handleSetSearch}
                      />
                      <SortSelect value={sortValue} onChange={setSortValue} />
                    </Stack>
                  </Stack.Item>
                </Stack>
              </Card.Section>
            )}
            {isFetching ? (
              <SkeletonContainer>
                <SkeletonBodyText />
              </SkeletonContainer>
            ) : (
              <>
                <ResourceList items={sortedProjectsData} renderItem={renderProjectListItem} />
                <Card.Section>
                  <Stack distribution="center">
                    <Pagination
                      label={page}
                      hasPrevious={page > 1}
                      onPrevious={handlePagePrevious}
                      hasNext={page < totalPages}
                      onNext={handlePageNext}
                    />
                  </Stack>
                </Card.Section>
              </>
            )}
          </ProjectListContainer>
        </Card>
      );
    }
  }

  return (
    <BlockContainer>
      {card}
      <CreateProjectModal open={createProjectModalActive} onClose={handleToggleCreateProjectModalShowing} />
    </BlockContainer>
  );
};

export default ProjectList;
