/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 19th 2021, 4:38:56 pm
 *
 * *****************************************************
 */

import { RootState } from 'states/types';

/**
 * Redux selectors - Authentication slice - Select the JWT token
 */
export const selectJwtToken = (state: RootState) => state.auth.jwtToken;

/**
 * Redux selectors - Authentication slice - Select the expired time
 */
export const selectExpiredTime = (state: RootState) => state.auth.expiredTime;

/**
 * Redux selectors - Authentication slice - Select the authenticated email
 */
export const selectCurrentUser = (state: RootState) => state.auth.user;
