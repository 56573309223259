/**
 * Copyright (C) Boost commerce
 * This file is part of commercial Boost commerce projects
 *
 * This file can not be copied and/or distributed without the express
 * permission of Boost commerce
 *
 * Created on Thu Jan 11 2024 17:55:38
 */

import PageLoading from 'features/metrics/views/pages/PageLoading';
import PageMetric from 'features/metrics/views/pages/PageMetric';
import { RouteDef } from 'utils/routes';

import * as projectPaths from './paths';

/**
 * Route definitions for projects page group
 */
const metricRouteDefs: RouteDef[] = [
  {
    path: projectPaths.AGGREGATED_METRICS_REVENUE,
    exact: true,
    isPrivate: true,
    silentRefreshLoadingComponent: PageLoading,
    redirect: projectPaths.AGGREGATED_METRICS_REVENUE_OVERVIEW
  },
  {
    path: projectPaths.AGGREGATED_METRICS_INSTALL_AND_UNINSTALL,
    exact: true,
    isPrivate: true,
    silentRefreshLoadingComponent: PageLoading,
    redirect: projectPaths.AGGREGATED_METRICS_INSTALL_AND_UNINSTALL_OVERVIEW
  },
  {
    path: projectPaths.AGGREGATED_METRICS_USER_AND_CUSTOMER_V2,
    exact: true,
    isPrivate: true,
    silentRefreshLoadingComponent: PageLoading,
    redirect: projectPaths.AGGREGATED_METRICS_USER_AND_CUSTOMER_OVERVIEW_V2
  },
  {
    path: projectPaths.AGGREGATED_METRICS_USER_AND_CUSTOMER,
    exact: true,
    isPrivate: true,
    silentRefreshLoadingComponent: PageLoading,
    redirect: projectPaths.AGGREGATED_METRICS_USER_AND_CUSTOMER_OVERVIEW
  },
  {
    path: projectPaths.AGGREGATED_METRICS_PLAN_AND_LOCATION,
    exact: true,
    isPrivate: true,
    silentRefreshLoadingComponent: PageLoading,
    redirect: projectPaths.AGGREGATED_METRICS_PLAN_AND_LOCATION_OVERVIEW
  },
  {
    path: [
      projectPaths.AGGREGATED_METRICS_DASHBOARD,
      projectPaths.AGGREGATED_METRICS_REVENUE_OVERVIEW,
      projectPaths.AGGREGATED_METRICS_NET_REVENUE,
      projectPaths.AGGREGATED_METRICS_MRR,
      projectPaths.AGGREGATED_METRICS_MRR_NEW,
      projectPaths.AGGREGATED_METRICS_MRR_UPGRADE,
      projectPaths.AGGREGATED_METRICS_MRR_DOWNGRADE,
      projectPaths.AGGREGATED_METRICS_MRR_REACTIVATION,
      projectPaths.AGGREGATED_METRICS_MRR_CANCELLATION,
      projectPaths.AGGREGATED_METRICS_MRR_CHURN,
      projectPaths.AGGREGATED_METRICS_ONE_TIME_CHARGES,
      projectPaths.AGGREGATED_METRICS_USAGE_CHARGES,
      projectPaths.AGGREGATED_METRICS_ADD_ON,
      projectPaths.AGGREGATED_METRICS_FAILED_CHARGES,
      projectPaths.AGGREGATED_METRICS_REFUNDS_AND_CREDITS,
      projectPaths.AGGREGATED_METRICS_COSTS,
      projectPaths.AGGREGATED_METRICS_INSTALL_AND_UNINSTALL_OVERVIEW,
      projectPaths.AGGREGATED_METRICS_INSTALL,
      projectPaths.AGGREGATED_METRICS_REOPEN,
      projectPaths.AGGREGATED_METRICS_UNINSTALL,
      projectPaths.AGGREGATED_METRICS_CLOSE,
      projectPaths.AGGREGATED_METRICS_USER_AND_CUSTOMER_OVERVIEW_V2,
      projectPaths.AGGREGATED_METRICS_CUSTOMER_CHURN_V2,
      projectPaths.AGGREGATED_METRICS_ARPU_V2,
      projectPaths.AGGREGATED_METRICS_LTV_V2,
      projectPaths.AGGREGATED_METRICS_ACTIVE_CUSTOMER_V2,
      projectPaths.AGGREGATED_METRICS_NEW_CUSTOMER_V2,
      projectPaths.AGGREGATED_METRICS_CUSTOMER_UPGRADE_V2,
      projectPaths.AGGREGATED_METRICS_CUSTOMER_DOWNGRADE_V2,
      projectPaths.AGGREGATED_METRICS_CUSTOMER_CANCELLATION_V2,
      projectPaths.AGGREGATED_METRICS_CUSTOMER_REACTIVATION_V2,
      projectPaths.AGGREGATED_METRICS_CUSTOMER_GROWTH_V2,
      projectPaths.AGGREGATED_METRICS_CONVERSION_RATE_V2,
      projectPaths.AGGREGATED_METRICS_AVERAGE_CUSTOMER_LIFESPAN_V2,
      projectPaths.AGGREGATED_METRICS_ACTIVE_USER_V2,
      projectPaths.AGGREGATED_METRICS_USER_AND_CUSTOMER_OVERVIEW,
      projectPaths.AGGREGATED_METRICS_CUSTOMER_CHURN,
      projectPaths.AGGREGATED_METRICS_ARPU,
      projectPaths.AGGREGATED_METRICS_LTV,
      projectPaths.AGGREGATED_METRICS_ACTIVE_CUSTOMER,
      projectPaths.AGGREGATED_METRICS_NEW_CUSTOMER,
      projectPaths.AGGREGATED_METRICS_CUSTOMER_UPGRADE,
      projectPaths.AGGREGATED_METRICS_CUSTOMER_DOWNGRADE,
      projectPaths.AGGREGATED_METRICS_CUSTOMER_CANCELLATION,
      projectPaths.AGGREGATED_METRICS_CUSTOMER_REACTIVATION,
      projectPaths.AGGREGATED_METRICS_CUSTOMER_GROWTH,
      projectPaths.AGGREGATED_METRICS_CONVERSION_RATE,
      projectPaths.AGGREGATED_METRICS_AVERAGE_CUSTOMER_LIFESPAN,
      projectPaths.AGGREGATED_METRICS_ACTIVE_USER,
      projectPaths.AGGREGATED_METRICS_SUBSCRIPTION,
      projectPaths.AGGREGATED_METRICS_PLAN_AND_LOCATION_OVERVIEW,
      projectPaths.AGGREGATED_METRICS_APP_PLAN,
      projectPaths.AGGREGATED_METRICS_APP_PLAN_DETAIL,
      projectPaths.AGGREGATED_METRICS_APP_PLAN_CHURN,
      projectPaths.AGGREGATED_METRICS_APP_PLAN_CHURN_DETAIL,
      projectPaths.AGGREGATED_METRICS_SHOPIFY_PLAN,
      projectPaths.AGGREGATED_METRICS_SHOPIFY_PLAN_CHURN,
      projectPaths.AGGREGATED_METRICS_LOCATION,
      projectPaths.AGGREGATED_METRICS_LOCATION_CHURN,
      projectPaths.AGGREGATED_METRICS_ACTIVITIES,
      projectPaths.AGGREGATED_METRICS_SHOPIFY_PLAN_DETAIL,
      projectPaths.AGGREGATED_METRICS_SHOPIFY_PLAN_CHURN_DETAIL,
      projectPaths.AGGREGATED_METRICS_LOCATION_DETAIL,
      projectPaths.AGGREGATED_METRICS_LOCATION_CHURN_DETAIL
    ],
    exact: true,
    component: PageMetric,
    isPrivate: true,
    silentRefreshLoadingComponent: PageLoading
  }
];

export default metricRouteDefs;
