/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Sun, Jul 29th 2021, 17:22:45 pm
 *
 * *****************************************************
 */

import moment from 'moment';

import { currencyFormatter, decimalNumberFormatter, numberFormatter, percentFormatter } from 'constants/intl-number-formatter';
import { RECENT_PROJECTS_LOCAL_STORAGE_KEY } from 'constants/local-storage';
import { MONTHS } from 'constants/months';
import { Project } from 'states/slices/projects/types';

/**
 * transform number to string number with commas
 * fixedNumber is number of digits after floating point
 */
export const numberWithCommas = (num: number, fixedNumber: number = 0): string => {
  if (Number.isInteger(fixedNumber)) {
    return num
      ?.toFixed(fixedNumber)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Transform number to locale currency string number
 */
export const formatCurrency = (value: number | null): string => {
  if (value === null) return '';
  return currencyFormatter.format(value);
};

/**
 * Transform number to locale percent string number
 */
export const formatPercent = (value: number | null): string => {
  if (value === null) return '';
  return percentFormatter.format(value);
};

/**
 * Transform number to locale string number
 */
export const formatNumber = (value: number | null): string => {
  if (value === null) return '';
  return numberFormatter.format(value);
};

/**
 * Transform number to locale decimal string number
 */
export const formatDecimalNumber = (value: number | null): string => {
  if (value === null) return '';
  return decimalNumberFormatter.format(value);
};

/**
 * format YYYY-MM-DD datetime
 */
export const formatDate = (date: string | Date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();
  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  return [year, month, day].join('-');
};

export const parseLocaleNumber = (value: string): number | typeof NaN => {
  const formatParts = numberFormatter.formatToParts(1111.11);
  const groupSeparator = formatParts.find(part => part.type === 'group')?.value || '';
  const decimalSeparator = formatParts.find(part => part.type === 'decimal')?.value || '';

  const parsedValue = value
    .replace(new RegExp(`[\\${groupSeparator}]`, 'g'), '')
    .replace(new RegExp(`[\\${decimalSeparator}]`, 'g'), '.');

  return Number.isNaN(parsedValue) ? NaN : +parsedValue;
};

export const parseLocaleCurrency = (value: string): number | typeof NaN => {
  const formatParts = currencyFormatter.formatToParts(1111.11);
  const groupSeparator = formatParts.find(part => part.type === 'group')?.value || '';
  const decimalSeparator = formatParts.find(part => part.type === 'decimal')?.value || '';
  const currencySeparator = formatParts.find(part => part.type === 'currency')?.value || '';

  const parsedValue = value
    .replace(new RegExp(`[\\${groupSeparator}\\${currencySeparator}]`, 'g'), '')
    .replace(new RegExp(`[\\${decimalSeparator}]`, 'g'), '.');

  return Number.isNaN(parsedValue) ? NaN : +parsedValue;
};

export const parseLocalePercent = (value: string): number | typeof NaN => {
  const formatParts = percentFormatter.formatToParts(1111.11);
  const groupSeparator = formatParts.find(part => part.type === 'group')?.value || '';
  const decimalSeparator = formatParts.find(part => part.type === 'decimal')?.value || '';
  const percentSign = formatParts.find(part => part.type === 'percentSign')?.value || '';

  const parsedValue = value
    .replace(new RegExp(`[\\${groupSeparator}\\${percentSign}]`, 'g'), '')
    .replace(new RegExp(`[\\${decimalSeparator}]`, 'g'), '.');

  return Number.isNaN(parsedValue) ? NaN : +parsedValue;
};

export const calculateMovementValue = (currentValue: number, previousValue: number): number =>
  (currentValue - previousValue) / previousValue;

/**
 * Generate label for button show date-picker
 */
export const createTextDatePickerRange = (start: Date, end: Date) => {
  const momentStart = moment(start);
  const momentEnd = moment(end);
  if (momentStart.year() !== momentEnd.year()) {
    return `${momentStart.format('MMM DD, YYYY')} - ${momentEnd.format('MMM DD, YYYY')}`;
  }
  if (start.toString() === end.toString()) {
    return `${momentEnd.format('MMM DD, YYYY')}`;
  }
  return `${momentStart.format('MMM DD')} - ${momentEnd.format('MMM DD, YYYY')}`;
};

/**
 * format date from 2021-05-01 into May 01
 */
export const formatDateAndMonthString = (date: string | Date) => {
  const d = new Date(date);
  const month = MONTHS[d.getMonth()];
  const day = d.getDate();
  return `${month.slice(0, 3)} ${day}`;
};

export type RecentProjectsOfWorkspacesInLocalStorage = {
  [workspaceId: string]: Project[];
};

/**
 * Update recent project list in localStorage with the given new recent project
 * The given recent project will be appended to the begining of the recent project list
 * as it's the most recent one.
 * @param workspaceId - ID of the workspace which the new recent project belongs to
 * @param recentProject - The new recent project data
 * @returns The most up-to-date recent project list
 */
export const updateRecentProjectsInLocalStorage = (workspaceId: string, recentProject: Project): Project[] => {
  const RECENT_PROJECT_LIST_MAX_LENGTH = 4;
  const stringifiedData = localStorage.getItem(RECENT_PROJECTS_LOCAL_STORAGE_KEY);
  let recentProjects: Project[] = [];
  let parsedData: RecentProjectsOfWorkspacesInLocalStorage = {};
  if (stringifiedData) {
    parsedData = JSON.parse(stringifiedData) as RecentProjectsOfWorkspacesInLocalStorage;

    if (Array.isArray(parsedData)) {
      /**
       * Handle legacy logic which store recent projects as an array
       */
      localStorage.removeItem(RECENT_PROJECTS_LOCAL_STORAGE_KEY);
      parsedData = {};
    }

    /**
     * Check if there's recent projects for current workspace
     */
    if (Object.hasOwnProperty.call(parsedData, workspaceId)) {
      recentProjects = parsedData[workspaceId];

      const recentProjectIndex = recentProjects.findIndex(({ id }) => id === recentProject.id);
      /**
       * If new recent project DOES exist in the cached list
       */
      if (recentProjectIndex !== -1) {
        /**
         * If new recent project DOES exist in the cached list,
         * remove the existed project element in the cached list
         */
        recentProjects.splice(recentProjectIndex, 1);
      }
    }
  }
  /**
   * Append new recent project to the beginning of the list
   */
  recentProjects.unshift(recentProject);
  /**
   * Limit the length of the list to a defined value
   */
  recentProjects = recentProjects.slice(0, RECENT_PROJECT_LIST_MAX_LENGTH);

  localStorage.setItem(
    RECENT_PROJECTS_LOCAL_STORAGE_KEY,
    JSON.stringify({
      ...parsedData,
      [workspaceId]: recentProjects
    })
  );
  return recentProjects;
};
