/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 20th 2021, 10:55:09 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

export const Container = styled.div`
  .Polaris-Label__RequiredIndicator:after {
    color: #000;
  }
  .Polaris-Label__Text {
    font-weight: 500;
  }
`;
