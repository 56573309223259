/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 26th 2021, 5:11:49 pm
 *
 * *****************************************************
 */

import { Container, NameContent } from './styles';

type Props = {
  name: string;
  color: string;
  source?: string | null;
  size?: 'tiny' | 'small' | 'medium' | 'large';
};
/**
 * General avatar item
 * Description: Has square shape with curve edges;
 * it backgrounds will be in a placeholder background color with a content of the first letter of item name in uppercase format
 * in case of provided avatar image source
 */
const ItemAvatar = ({ name, color, source, size = 'medium' }: Props): JSX.Element => {
  return (
    <Container size={size}>
      {source ? (
        <img src={source} alt="" role="presentation" />
      ) : (
        <NameContent color={color}>{name ? name[0].toLocaleUpperCase() : ''}</NameContent>
      )}
    </Container>
  );
};

export default ItemAvatar;
