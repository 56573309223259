/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 26th 2021, 4:05:56 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import colors from 'constants/colors';

export const BlockContainer = styled.div`
  width: 100%;
`;

export const ProjectListContainer = styled.div`
  /* OVERRIDEN CSS */
  .Polaris-Card__Header {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;

    .Polaris-Button--plain .Polaris-Button__Text {
      font-weight: 500;
      font-size: 1.5rem;
      margin-right: 0.3rem;
    }
  }
  .Polaris-Card__Header + .Polaris-Card__Section {
    padding-top: 0.6rem;
    padding-bottom: 1rem;
  }
  .Polaris-Card__Section {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
  }
  .Polaris-ResourceList > .Polaris-ResourceItem__ListItem:first-child {
    border-top: 0.1rem solid var(--p-divider);
  }
  .Polaris-ResourceList > .Polaris-ResourceItem__ListItem:last-child {
    border-bottom: 0.1rem solid var(--p-divider);
    border-radius: 0;
  }
  .Polaris-ResourceItem__Content h3 {
    color: ${colors.interactive.default};
  }

  .Polaris-ResourceItem__Container .Polaris-ResourceItem__Disclosure {
    top: 0;
  }
  .Polaris-ResourceItem__Container .Polaris-ResourceItem__Disclosure > div {
    display: flex;
  }
`;

export const CardHeaderActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -1.4rem;

  & > * {
    margin-left: 1.4rem;
  }
`;

export const SkeletonContainer = styled.div`
  padding: 2rem;
`;
