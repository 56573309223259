/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Oct 1st 2021, 1:27:52 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';

export const PageContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ChartContainer = styled.div`
  margin-bottom: 2.4rem;
`;

export const TableCardContainer = styled.div`
  margin-bottom: 2.4rem;

  thead > tr:nth-child(2) > *:nth-child(1) {
    text-align: left;
  }
`;

export const DatePickerContainer = styled.div`
  padding-top: 1.2rem;
  padding-bottom: 2.4rem;
`;

export const NoticeBlock = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 0.6rem;
  p {
    ${focus2Texts.website['Body 2 - 14px']}
    color: ${focus2Colors.text.subdued};
  }
`;
