/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 27th 2021, 6:24:38 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import colors from 'constants/colors';

export const Container = styled.div<{ checked?: boolean; disabled?: boolean }>`
  label {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 2.6rem 2.4rem 3.5rem;
    text-align: center;
    color: ${colors.primary.actionDepressed};
    border: 0.2rem solid ${colors.primary.actionDepressed};
    border-radius: 0.6rem;

    cursor: pointer;

    svg {
      width: 6.4rem;
      height: auto;
      path {
        fill: ${colors.primary.actionDepressed};
      }
    }

    &:hover {
      border-color: ${colors.primary.actionHovered};
      color: ${colors.primary.actionHovered};
      svg {
        path {
          fill: ${colors.primary.actionHovered};
        }
      }
    }

    ${({ checked }) =>
      checked &&
      `
      border-color: ${colors.primary.action};
      color: ${colors.primary.action};
      svg {
        path {
          fill: ${colors.primary.action};
        }
      }

      &:hover {
        border-color: ${colors.primary.action};
        color: ${colors.primary.action};
        svg {
          path {
            fill: ${colors.primary.action};
          }
        }
      }
    `}

    ${({ disabled }) =>
      disabled &&
      `
      border-color: ${colors.onSurface.divider} !important;
      color: ${colors.onSurface.text} !important;
      svg {
        path {
          fill: ${colors.onSurface.icon} !important;
        }
      }

      cursor: default;
    `}
  }
`;

export const Name = styled.span`
  font-weight: 500;
  font-size: 1.6rem;
  line-heigh: 2rem;
  margin-top: 2.2rem;
`;
