/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 22nd 2021, 10:55:20 am
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';

export const Value = styled.span<{ value: number }>`
  ${focus2Texts.website.heading['H5 - 14px']}
  ${({ value }) =>
    value > 0 &&
    `
    color: ${focus2Colors.text.positive};
  `}
  ${({ value }) =>
    value < 0 &&
    `
    color: ${focus2Colors.text.negative};
  `}
  white-space: nowrap;
`;
