/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Jul 30th 2021, 10:40:22 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import texts from 'constants/texts';

export const TabsContainer = styled.div`
  height: calc(100vh - 5.6rem - 4.3rem);

  & > * {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  /* OVERRIDEN CSS */
  .Polaris-Tabs__Wrapper {
    padding: 0 2rem;
  }
  .Polaris-Tabs__Panel {
    flex-grow: 1;
  }
  .Polaris-Tabs__Tab {
    padding-left: 0;
    padding-right: 3.2rem;
  }
  .Polaris-Tabs__Title {
    padding-left: 0;
    padding-right: 0;
  }
  .Polaris-Tabs__Title::before {
    border-radius: 0;
    height: 0.5rem;
  }

  .Polaris-Tabs__Tab--selected .Polaris-Tabs__Title::before {
    background-color: ${focus2Colors.base.primary};
  }

  .Polaris-Tabs__Tab--selected:hover .Polaris-Tabs__Title::before {
    background-color: var(--p-action-primary);
  }
`;

export const PageHeader = styled.div`
  padding-left: 2rem;
  padding-top: 1.9rem;
  h1 {
    ${texts.heading['Heading 2 - 24px - SB']};
  }
`;
