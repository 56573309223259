/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 26th 2021, 2:14:17 pm
 *
 * *****************************************************
 */

import { Button } from '@shopify/polaris';

import Illustration from 'assets/images/getStarted.png';
import { workspaceAPI } from 'states/services/workspace';
import { Workspace } from 'states/slices/workspace/types';

import { Container } from './styles';

type Props = {
  onClick?: () => void;
};

const ProjectListEmptyContent = ({ onClick }: Props): JSX.Element => {
  const { isSuccess, data } = workspaceAPI.endpoints.getWorkspaceBasicInfo.useQueryState();
  const { name: workspaceName } = (data as Workspace) ?? { name: '' };
  return (
    <Container>
      <img src={Illustration} alt="" />
      <header>
        <h2>{`Create a project ${isSuccess ? `of ${workspaceName}` : ''}`}</h2>
        <span>Get started with your first project for Boost Commerce.</span>
      </header>
      <Button primary onClick={onClick} ariaDescribedBy="workspace-dashboard|create-project">
        Create Project
      </Button>
    </Container>
  );
};

export default ProjectListEmptyContent;
