/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 22nd 2021, 9:12:12 am
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Texts from 'constants/focus-2-texts';

export const Container = styled.div`
  padding: 2rem 2rem 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    ${focus2Texts.website.heading['H3 - 20px']}
  }
`;
