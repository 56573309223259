/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 22nd 2021, 10:29:48 am
 *
 * *****************************************************
 */

import RelativeTime from 'features/common/views/components/RelativeTime';
import Revenue from 'features/project/views/pages/ProjectMetric/MetricPage/DashboardPage/Revenue';

import { Container, ShopName, Message, CreatedAt } from './styles';

type Props = {
  shopName: string;
  message: string | null;
  amount: number | null;
  createdAt: string;
  isLast?: boolean;
};

const Activity = ({ shopName, message, amount, createdAt, isLast = false }: Props): JSX.Element => {
  return (
    <Container isLast={isLast}>
      <div>
        <ShopName>{shopName}</ShopName>
        <Revenue value={amount} />
      </div>
      <div>
        <Message>{message}</Message>
        <CreatedAt>
          <RelativeTime value={createdAt} />
        </CreatedAt>
      </div>
    </Container>
  );
};

export default Activity;
