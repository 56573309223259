import { Endpoint } from 'states/services/types';

export const METRICS_NET_REVENUE_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/net-revenue/chart',
  isPrivate: true
};

export const METRICS_NET_REVENUE_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/net-revenue/breakdown',
  isPrivate: true
};

export const METRICS_NET_REVENUE_GROWTH_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/net-revenue/growth-chart',
  isPrivate: true
};
