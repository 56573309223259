/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 22nd 2021, 8:35:44 am
 *
 * *****************************************************
 */

import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, OptionList, Popover, SkeletonDisplayText, Card } from '@shopify/polaris';
import { useParams } from 'react-router-dom';

import { ACTIVITIES_POLLING_INTERVAL } from 'constants/polling-intervals';
import CardHeader from 'features/project/views/components/CardHeader';
import { useGetActivitiesDataQuery } from 'states/services/metrics/dashboard';
import { GetActivitiesDataResponse } from 'states/services/metrics/dashboard/types';

import Activity from './Activity';
import EmptyContent from './EmptyContent';
import LoadingContent from './LoadingContent';
import { ButtonContainer, SkeletonContainer } from './styles';

const Activities = () => {
  const { projectId } = useParams() as { projectId: string };
  const { isSuccess, isFetching, data } = useGetActivitiesDataQuery(
    { projectId },
    { pollingInterval: ACTIVITIES_POLLING_INTERVAL }
  );
  const { options: optionListData, data: activitiesDataByOption } = data || {
    options: [],
    data: {}
  };

  const [popoverActive, setPopoverActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(['']);

  const handleTogglePopoverActive = useCallback(() => {
    setPopoverActive(prevState => !prevState);
  }, []);

  const handleSelectOption = useCallback(
    value => {
      setSelectedOption(value);
      handleTogglePopoverActive();
    },
    [handleTogglePopoverActive]
  );

  const optionListHashTable: { [index: string]: string } = useMemo(() => {
    return optionListData.reduce((accumulator, { label, value }) => {
      return {
        ...accumulator,
        [value]: label
      };
    }, {});
  }, [optionListData]);

  const activator = (
    <ButtonContainer>
      <Button onClick={handleTogglePopoverActive} disclosure>
        {optionListHashTable[selectedOption[0]]}
      </Button>
    </ButtonContainer>
  );

  useEffect(() => {
    if (isSuccess) {
      const { options } = data as GetActivitiesDataResponse;
      const { value } = options[0];
      setSelectedOption([value]);
    }
  }, [isSuccess, data]);

  const content = useMemo(() => {
    const selectedData = activitiesDataByOption[selectedOption[0]] || [];
    const n = selectedData.length;
    if (n === 0) return <EmptyContent />;
    return selectedData.map(({ id, shopName, message, amount, createdAt }, index) => (
      <Activity key={id} shopName={shopName} message={message} amount={amount} createdAt={createdAt} isLast={index === n - 1} />
    ));
  }, [activitiesDataByOption, selectedOption]);

  return (
    <Card>
      <CardHeader name="Activities">
        {isFetching ? (
          <SkeletonContainer>
            <SkeletonDisplayText size="medium" />
          </SkeletonContainer>
        ) : (
          <Popover active={popoverActive} activator={activator} onClose={handleTogglePopoverActive}>
            <OptionList onChange={handleSelectOption} options={optionListData} selected={selectedOption} />
          </Popover>
        )}
      </CardHeader>
      {isFetching ? <LoadingContent /> : content}
    </Card>
  );
};

export default Activities;
