/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Sep 21st 2021, 5:27:20 am
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';

export const Content = styled.div<{ level?: number }>`
  display: flex;
  align-items: center;
  padding: 0.6rem 1.2rem;
  padding-left: ${({ level }) => level && `calc(4.8rem * ${level})`};

  margin: 0 0.8rem;
  position: relative;

  color: ${focus2Colors.text.subdued};
`;

export const Container = styled.li<{ hasSubItems: boolean }>`
  a {
    text-decoration: none;
    ${focus2Texts.website['Button-Medium']}
  }

  & > a:hover {
    .BC-WiseIO-Sidebar--navigationActive ${Content}, ${Content} {
      background-color: ${focus2Colors.background.hovered};
    }
  }

  ul {
    display: none;
  }

  .BC-WiseIO-Sidebar--navigationActive + ul {
    display: block;
  }

  .BC-WiseIO-Sidebar--navigationActive {
    ${({ hasSubItems }) =>
      !hasSubItems &&
      `
      ${Content} {
        color: ${focus2Colors.interactive.primary.default};
        background-color: ${focus2Colors.background.pressed};
      }

      ${Content}::before {
        content: '';
        position: absolute;
        top: 0;
        left: -0.8rem;
        height: 100%;
        width: 0.3rem;
        background-color: ${focus2Colors.interactive.primary.default};
        border-top-right-radius: var(--p-border-radius-base);
        border-bottom-right-radius: var(--p-border-radius-base);
      }
    `}
  }
`;
