import styled from 'styled-components';

import { ChartType } from 'constants/metrics/base-blocks';
import { SizeChart } from 'features/project/views/pages/ProjectMetric/MetricCard/MetricChartCard';

export const Skeleton: Record<string, any> = {};

Skeleton.Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .Polaris-SkeletonDisplayText--sizeMedium:after {
    border-radius: var(--p-border-radius-base);
  }
`;

Skeleton.Chart = styled.div<{ size: SizeChart }>`
  margin-bottom: 2rem;
  margin-top: 1.6rem;
  ${({ size }) => {
    switch (size) {
      case 'small':
        return `
        & > .Polaris-SkeletonDisplayText--sizeMedium {
          height: 19.2rem;
        }
      `;
      case 'medium':
        return `
        & > .Polaris-SkeletonDisplayText--sizeMedium {
          height: 26.4rem;
        }
      `;
      case 'large':
        return `
        & > .Polaris-SkeletonDisplayText--sizeMedium {
          height: 35.2rem;
        }
      `;
      default:
        return '';
    }
  }}

  & > .Polaris-SkeletonDisplayText--sizeMedium {
    width: 100%;
    max-width: 100%;
  }
`;

Skeleton.Legend = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > .Polaris-SkeletonDisplayText--sizeMedium {
    width: 9.6rem;
    height: 1.2rem;
  }

  & > *:first-child {
    margin-right: 1.6rem;
  }
`;

const mappingHeight: Record<ChartType, Record<SizeChart, string>> = {
  LINE_CHART: {
    small: '19.2rem',
    medium: '26.4rem',
    large: '35.2rem'
  },
  BAR_CHART: {
    small: '19.2rem',
    medium: '26.4rem',
    large: '35.2rem'
  },
  DOUGHNUT_CHART: {
    small: '216px',
    medium: '216px',
    large: '216px'
  },
  DYNAMIC_LINE_CHART: {
    small: '19.2rem',
    medium: '26.4rem',
    large: '35.2rem'
  }
};

export const ChartContainer = styled.div<{
  size: SizeChart;
  type: ChartType;
}>`
  display: flex;
  justify-content: center;
  margin-top: 1.6rem;
  ${({ size, type }) => {
    return `height: ${mappingHeight[type][size]}`;
  }}
`;
