/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Sep 21st 2021, 1:46:59 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    ${focus2Texts.website.heading['H2 - 24px']}
  }

  button {
    padding: 0.4rem;
    border-radius: 0.6rem;
    cursor: pointer;

    display: none;

    /* OVERRIDEN CSS */
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  @media (max-width: 64em) {
    button {
      display: flex;
    }

    button:hover {
      background-color: ${focus2Colors.surface.normal.pressed};
    }
  }
`;
