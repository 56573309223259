/* eslint-disable @typescript-eslint/explicit-function-return-type */
/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Aug 5th 2021, 12:27:19 pm
 *
 * *****************************************************
 */

import { useEffect } from 'react';

import WorkspaceDashboardLoading from 'features/workspace-dashboard/views/pages/WorkspaceDashboardLoading';
import { useLazyRefreshTokenQuery } from 'states/services/auth/hooks';
import { useAuth } from 'utils/hooks';

/**
 * HOC providing the given component with silent refresh logic
 */
const withSilentRefresh = <P extends object>(
  Component: { (props: P): JSX.Element },
  SilentRefreshLoadingComponent?: React.ComponentType<any>
) => {
  const WithSilentRefresh = (props: P): JSX.Element => {
    const { isExpired } = useAuth();
    const [refreshToken] = useLazyRefreshTokenQuery();
    const LoadingComponent = SilentRefreshLoadingComponent ?? WorkspaceDashboardLoading;

    useEffect(() => {
      if (isExpired) {
        refreshToken();
      }
    }, [isExpired, refreshToken]);
    // eslint-disable-next-line react/jsx-props-no-spreading
    return isExpired ? <LoadingComponent /> : <Component {...props} />;
  };
  return WithSilentRefresh;
};

export default withSilentRefresh;
