/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 26th 2021, 4:42:41 pm
 *
 * *****************************************************
 */

import { Select } from '@shopify/polaris';

import { SORT_VALUE_NAME_ASCENDING, SORT_VALUE_NAME_DESCENDING } from 'constants/sorting';

import { Container } from './styles';

type Props = {
  value: string;
  onChange: (value: string) => void;
};

const SortSelect = ({ value, onChange }: Props): JSX.Element => {
  const options = [
    { label: 'Name ascending', value: SORT_VALUE_NAME_ASCENDING },
    { label: 'Name descending', value: SORT_VALUE_NAME_DESCENDING }
  ];
  return (
    <Container>
      <Select label="Sort" labelHidden placeholder="Sort" options={options} value={value} onChange={onChange} />
    </Container>
  );
};

export default SortSelect;
