/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 29th 2021, 5:54:54 pm
 *
 * *****************************************************
 */

import { Frame, Loading } from '@shopify/polaris';

import { FrameContainer } from './styles';

const FrameLoading = () => {
  return (
    <FrameContainer>
      <Frame>
        <Loading />
      </Frame>
    </FrameContainer>
  );
};

export default FrameLoading;
