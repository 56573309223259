/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Aug 12th 2021, 10:03:57 am
 *
 * *****************************************************
 */

import { FORM_ERROR, SubmissionErrors } from 'final-form';

type FieldError = {
  field: string;
  errors: string[];
};

export type ErrorObject = {
  data: {
    message: string;
    errors?: FieldError[] | null;
  };
  error?: any;
};

export type SubmissionResponse = {
  error?: ErrorObject;
  data?: object;
};

/**
 * Create submission error object (which will contain form error and field errors if any) from API response.
 * This function is intended to generate returned value for Final Form's onSubmit method to trigger submission errors.
 *
 * @param res - API error object in {@link https://redux-toolkit.js.org/rtk-query/api/created-api/hooks#signature-1 UseMutationResult<T>} type
 * @returns Submission error objects in the same shape as the values of the Final Form, as described {@link https://final-form.org/docs/react-final-form/types/FormProps#submission-errors here}
 */
export const convertApiErrorsToFinalFormSubmitErrors = (error: ErrorObject): SubmissionErrors => {
  if (!error) return error;
  const { data: expectedErrorData, error: unexpectedErrorData } = error;
  if (expectedErrorData) {
    const { errors, message } = expectedErrorData ?? {};
    if (errors) {
      return errors.reduce(
        (accumulator, { field, errors: errorValues }) => {
          return {
            ...accumulator,
            [field]: errorValues[0]
          };
        },
        {
          [FORM_ERROR]: message
        }
      );
    }
    return {
      [FORM_ERROR]: message
    };
  }
  if (unexpectedErrorData) {
    return {
      [FORM_ERROR]: 'Error(s) happened. Please try again later.'
    };
  }
  return undefined;
};
