/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 29th 2021, 7:56:19 pm
 *
 * *****************************************************
 */

import { Banner } from '@shopify/polaris';
import { generatePath, useParams } from 'react-router-dom';

import { PROJECT_METRICS_SETTINGS } from 'features/project/routes/paths';
import { Common } from 'states/services/metrics/models';

import ProgressBar from './ProgressBar';
import { ProgressBarContainer } from './styles';

type Props = {
  syncStatus: Common.SyncStatus;
  progress: Common.MetricProgress;
};

const SyncStatusBanner: React.FC<Props> = ({ syncStatus, progress }: Props): JSX.Element | null => {
  const { projectId } = useParams() as { projectId: string };

  if (['NEW', 'IN_QUEUE'].includes(syncStatus!)) {
    return (
      <Banner
        status="success"
        title="We are processing the data..."
        action={{ content: 'Back to Workspace Dashboard', url: '/' }}>
        <p>
          WiseIO has connected to your Shopify Partner API successfully. <br />
          The data is processing and your app’s metrics will be available soon. Please come back later.
        </p>
        <ProgressBarContainer>
          <ProgressBar progress={progress!} />
        </ProgressBarContainer>
      </Banner>
    );
  }
  if (syncStatus === 'FAILED') {
    return (
      <Banner
        status="warning"
        title="We have encountered a problem while processing your project’s metrics."
        action={{
          content: 'Check the Shopify Partner API Settings',
          url: generatePath(PROJECT_METRICS_SETTINGS, { projectId })
        }}>
        <p>We could not connect to your Shopify Partner API. Please check the API Settings & Permissions.</p>
        <ProgressBarContainer>
          <ProgressBar progress={progress!} />
        </ProgressBarContainer>
      </Banner>
    );
  }
  return null;
};

export default SyncStatusBanner;
