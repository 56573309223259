import { ChartOptions } from 'chart.js';
import _ from 'lodash';
import { Bar } from 'react-chartjs-2';

import { ChartConfig, MetricValueFormatter } from 'constants/metrics/base-blocks';

export type BarChartData = Record<string, any>;

type Props = {
  legends: string[];
  data: BarChartData;
  config: ChartConfig;
  metricValueFormatter: MetricValueFormatter;
};

const BarChart = ({ legends, data, config, metricValueFormatter }: Props) => {
  if (config.type !== 'BAR_CHART') return null;
  const barOptions: ChartOptions = {
    plugins: {
      legend: { position: 'bottom', align: 'end', labels: { boxWidth: 12 } },
      tooltip: {
        callbacks: {
          label: context => {
            let label = _.get(context, 'dataset.label', '');
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += metricValueFormatter(context.parsed.y);
            }
            return label;
          }
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        stacked: true,
        ticks: {
          autoSkipPadding: 8
        },
        suggestedMin: 0,
        suggestedMax: 10
      },
      x: {
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          autoSkipPadding: 15,
          backdropPadding: 10
        },
        stacked: true
      }
    }
  };

  const labels: string[] = _(data).map(config.labelField).value();
  const datasets = _(config.datasets)
    .map(({ isPositiveMetric, dataField, ...restOfConfig }, index) => {
      return {
        label: legends[index],
        data: data.map((item: any) => (isPositiveMetric || item[dataField] === 0 ? item[dataField] : -item[dataField])),
        ...restOfConfig
      };
    })
    .value();

  return <Bar data={{ labels, datasets }} options={barOptions} />;
};

export default BarChart;
