import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import { buildParams } from 'states/services/metrics';
import { GetMetricRequest } from 'states/services/metrics/types';

import * as Endpoints from './endpoints';
import * as Types from './types';

const newCustomerApi = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    fetchNewCustomerChart: builder.query<Types.NewCustomerChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_NEW_CUSTOMER_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_NEW_CUSTOMER_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.NewCustomerChartResponse }) => {
        return response.data;
      }
    }),
    fetchNewCustomerBreakdown: builder.query<Types.NewCustomerBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_NEW_CUSTOMER_BREAKDOWN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_NEW_CUSTOMER_BREAKDOWN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.NewCustomerBreakdownResponse }) => {
        return response.data;
      }
    }),
    fetchNewCustomerChartV2: builder.query<Types.NewCustomerChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_NEW_CUSTOMER_CHART_V2_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_NEW_CUSTOMER_CHART_V2_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.NewCustomerChartResponse }) => {
        return response.data;
      }
    }),
    fetchNewCustomerBreakdownV2: builder.query<Types.NewCustomerBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_NEW_CUSTOMER_BREAKDOWN_V2_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_NEW_CUSTOMER_BREAKDOWN_V2_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.NewCustomerBreakdownResponse }) => {
        return response.data;
      }
    })
  })
});

export const {
  useFetchNewCustomerBreakdownQuery,
  useFetchNewCustomerChartQuery,
  useFetchNewCustomerBreakdownV2Query,
  useFetchNewCustomerChartV2Query
} = newCustomerApi;
