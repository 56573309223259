/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 22nd 2021, 9:12:09 am
 *
 * *****************************************************
 */

import { Container } from './styles';

type Props = {
  name: string;
  children: React.ReactNode;
};

const CardHeader = ({ name, children }: Props): JSX.Element => {
  return (
    <Container>
      <h2>{name}</h2>
      {children}
    </Container>
  );
};

export default CardHeader;
