/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 29th 2021, 7:48:51 am
 *
 * *****************************************************
 */

import { Container } from './styles';

const EmptyContent = () => {
  return (
    <Container>
      <p>No activities</p>
      <p>WiseIO auto update your data every 15 mins.</p>
    </Container>
  );
};

export default EmptyContent;
