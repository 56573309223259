/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 27th 2021, 02:53:44 pm
 *
 * *****************************************************
 */

import { useState, useCallback, useEffect } from 'react';

import { Button, Checkbox, Popover, Scrollable, Select, DatePicker as ShopifyDatePicker } from '@shopify/polaris';
import { CalendarMajor } from '@shopify/polaris-icons';
// import _ from 'lodash';
import moment from 'moment';

import { createTextDatePickerRange } from 'utils/project';

import {
  CheckBoxContainer,
  ComparedBlock,
  DatePickerContainer,
  DateRangeContainer,
  FooterActionContainer,
  PickerContainer,
  Title
} from './styles';

const OPTIONS: string[] = [
  'Today',
  'Yesterday',
  'Last 7 days',
  'Last 30 days',
  'Last 90 days',
  'Last month',
  'Last year',
  'Week to date',
  'Month to date',
  'Quarter to date',
  'Year to date'
];
const FORMAT_DATE = 'MMM DD, YYYY';
const DEFAULT_CURRENT_OPTION = 'Last 30 days';
const DEFAULT_PREVIOUS_OPTION = 'Preceding period';
const CUSTOM = 'Custom';

const today = new Date();
const lastMonth = moment(today).subtract(1, 'month').toDate();
const coupleLastMonth = moment(today).subtract(2, 'month').toDate();

export type DateRange = {
  start: Date;
  end: Date;
};

type SetStateFunction = (value: any) => void;

type Props = {
  hidePreviousComparison?: boolean;
  hasPreviousComparison: boolean;
  committedCurrentValue: DateRange;
  setHasPreviousComparison: SetStateFunction;
  committedPreviousValue: DateRange;
  setCommittedCurrentValue: SetStateFunction;
  setCommittedPreviousValue: SetStateFunction;
};

type Option = {
  label: string;
  value: string;
  from?: string;
  to?: string;
};

const DatePicker = ({
  hasPreviousComparison,
  hidePreviousComparison = false,
  setHasPreviousComparison,
  committedCurrentValue,
  setCommittedCurrentValue,
  committedPreviousValue,
  setCommittedPreviousValue
}: Props) => {
  const [committedOption, setCommittedOption] = useState(DEFAULT_CURRENT_OPTION);
  const [selectedCurrentOption, setSelectedCurrentOption] = useState(committedOption);
  const [selectedPreviousOption, setSelectedPreviousOption] = useState(DEFAULT_PREVIOUS_OPTION);
  const [hasUncommittedPreviousComparison, setHasUncommittedPreviousComparison] = useState(hasPreviousComparison);
  const [datePickerActive, setDatePickerActive] = useState(false);

  /**
   * Uncommitted date data is used to keep track of user's date selection before submitting
   */
  const [uncommittedCurrentValue, setUncommittedCurrentValue] = useState({
    start: committedCurrentValue.start,
    end: committedCurrentValue.end
  });

  /**
   * Uncommitted date data is used to keep track of user's date selection before submitting
   */
  const [uncommittedPreviousValue, setUncommittedPreviousValue] = useState({
    start: committedPreviousValue.start,
    end: committedPreviousValue.end
  });

  const [{ currentMonth, currentYear }, setCurrentMonth] = useState({
    currentMonth: lastMonth.getMonth(),
    currentYear: lastMonth.getFullYear()
  });

  const [{ previousMonth, previousYear }, setPreviousMonth] = useState({
    previousMonth: coupleLastMonth.getMonth(),
    previousYear: coupleLastMonth.getFullYear()
  });

  const findOptionByValueOption = useCallback((iterable: Option[], value: string) => {
    return iterable.find(element => element.value === value);
  }, []);

  const findOptionByDateRange = useCallback((iterable: Option[], from: Date, to: Date) => {
    const formattedTime = {
      from: moment(from).format(FORMAT_DATE),
      to: moment(to).format(FORMAT_DATE)
    };
    return iterable.find(element => element.from === formattedTime.from && element.to === formattedTime.to);
  }, []);

  /**
   * create option for 4 latest quarter from to now
   */
  const createLast4Quarter = useCallback((time: Date) => {
    const previousMomentQuarter = [
      moment(time).subtract(1, 'quarter'),
      moment(time).subtract(2, 'quarter'),
      moment(time).subtract(3, 'quarter'),
      moment(time).subtract(4, 'quarter')
    ];
    return previousMomentQuarter.map((momentQuarter: moment.Moment) => {
      const from = momentQuarter.startOf('quarter').format(FORMAT_DATE);
      const to = momentQuarter.endOf('quarter').format(FORMAT_DATE);
      switch (momentQuarter.quarter()) {
        case 1:
          return { label: `1st Quarter (${from} - ${to})`, value: '1st Quarter', from, to };
        case 2:
          return { label: `2nd Quarter (${from} - ${to})`, value: '2nd Quarter', from, to };
        case 3:
          return { label: `3rd Quarter (${from} - ${to})`, value: '3rd Quarter', from, to };
        default:
          return { label: `4th Quarter (${from} - ${to})`, value: '4th Quarter', from, to };
      }
    });
  }, []);

  /**
   * generate date which will be showed in label of select option
   */
  const generateDateForCurrentOptions = useCallback((option: string, pivot: Date) => {
    const dateInLastMonth = moment(pivot).subtract(1, 'month');
    const dateInLastYear = moment(pivot).subtract(1, 'year');
    switch (option) {
      case 'Today':
        return {
          from: moment(pivot).startOf('day').format(FORMAT_DATE),
          to: moment(pivot).endOf('day').format(FORMAT_DATE)
        };
      case 'Yesterday':
        return {
          from: moment(pivot).subtract(1, 'day').startOf('day').format(FORMAT_DATE),
          to: moment(pivot).subtract(1, 'day').endOf('day').format(FORMAT_DATE)
        };
      case 'Last 7 days':
        return {
          from: moment(pivot).subtract(7, 'day').format(FORMAT_DATE),
          to: moment(pivot).subtract(1, 'day').format(FORMAT_DATE)
        };
      case 'Last 30 days':
        return {
          from: moment(pivot).subtract(30, 'day').format(FORMAT_DATE),
          to: moment(pivot).subtract(1, 'day').format(FORMAT_DATE)
        };
      case 'Last 90 days':
        return {
          from: moment(pivot).subtract(90, 'day').format(FORMAT_DATE),
          to: moment(pivot).subtract(1, 'day').format(FORMAT_DATE)
        };
      case 'Last month':
        return {
          from: moment(dateInLastMonth).startOf('month').format(FORMAT_DATE),
          to: moment(dateInLastMonth).endOf('month').format(FORMAT_DATE)
        };
      case 'Last year':
        return {
          from: moment(dateInLastYear).startOf('year').format(FORMAT_DATE),
          to: moment(dateInLastYear).endOf('year').format(FORMAT_DATE)
        };
      case 'Week to date':
        return {
          from: moment(pivot).startOf('isoWeek').format(FORMAT_DATE),
          to: moment(pivot).format(FORMAT_DATE)
        };
      case 'Month to date':
        return {
          from: moment(pivot).startOf('month').format(FORMAT_DATE),
          to: moment(pivot).format(FORMAT_DATE)
        };
      case 'Quarter to date':
        return {
          from: moment(pivot).startOf('quarter').format(FORMAT_DATE),
          to: moment(pivot).format(FORMAT_DATE)
        };
      case 'Year to date':
        return {
          from: moment(pivot).startOf('year').format(FORMAT_DATE),
          to: moment(pivot).format(FORMAT_DATE)
        };
      default:
        return {
          from: moment(pivot).startOf('day').format(FORMAT_DATE),
          to: moment(pivot).endOf('day').format(FORMAT_DATE)
        };
    }
  }, []);

  const generateDateForPreviousOptions = useCallback((option: string, pivot: DateRange) => {
    const dateInLastMonth = moment(pivot.start).subtract(1, 'month');
    const dateInLastYear = moment(pivot.start).subtract(1, 'year');
    const diffDay = moment(pivot.end).diff(pivot.start, 'days');
    const previousQuarter = moment(pivot.start).subtract(1, 'quarter');
    switch (option) {
      case 'Last month':
        return {
          from: moment(dateInLastMonth).startOf('month').format(FORMAT_DATE),
          to: moment(dateInLastMonth).endOf('month').format(FORMAT_DATE)
        };
      case 'Last year':
        return {
          from: moment(dateInLastYear).startOf('year').format(FORMAT_DATE),
          to: moment(dateInLastYear).endOf('year').format(FORMAT_DATE)
        };
      case '1st Quarter':
      case '2nd Quarter':
      case '3rd Quarter':
      case '4th Quarter':
        return {
          from: previousQuarter.startOf('quarter').format(FORMAT_DATE),
          to: previousQuarter.endOf('quarter').format(FORMAT_DATE)
        };
      default:
        return {
          from: moment(pivot.start)
            .subtract(diffDay + 1, 'days')
            .format(FORMAT_DATE),
          to: moment(pivot.start).subtract(1, 'day').format(FORMAT_DATE)
        };
    }
  }, []);

  const [currentOptions] = useState<Option[]>(
    OPTIONS.map<Option>((value: string) => {
      const { from, to } = generateDateForCurrentOptions(value, today);
      const dateRange = from === to ? from : `${from} - ${to}`;
      const label = value === 'Today' ? value : `${value} (${dateRange})`;
      return { label, value, from, to };
    })
      .concat(createLast4Quarter(today))
      .concat({ label: CUSTOM, value: CUSTOM })
  );

  const [previousOptions, setPreviousOptions] = useState<Option[]>(() => {
    const precedingOption = {
      label: DEFAULT_PREVIOUS_OPTION,
      value: DEFAULT_PREVIOUS_OPTION,
      ...generateDateForPreviousOptions(selectedCurrentOption, uncommittedCurrentValue)
    };
    return [precedingOption, { label: CUSTOM, value: CUSTOM }];
  });

  const toggleDatePickerShowing = useCallback(() => setDatePickerActive(prevState => !prevState), []);

  /**
   * Rollback state if not commit
   */
  const handleCloseDatePicker = () => {
    setHasUncommittedPreviousComparison(hasPreviousComparison);

    const currentOption = findOptionByDateRange(currentOptions, committedCurrentValue.start, committedCurrentValue.end);
    setSelectedCurrentOption((currentOption && currentOption.value) || CUSTOM);
    if (
      uncommittedCurrentValue.start !== committedCurrentValue.start ||
      uncommittedCurrentValue.end !== committedCurrentValue.end
    ) {
      setUncommittedCurrentValue({
        start: committedCurrentValue.start,
        end: committedCurrentValue.end
      });
    }

    const previousOption = findOptionByDateRange(previousOptions, committedPreviousValue.start, committedPreviousValue.end);
    setSelectedPreviousOption((previousOption && previousOption.value) || CUSTOM);
    if (
      uncommittedPreviousValue.start !== committedPreviousValue.start ||
      uncommittedPreviousValue.end !== committedPreviousValue.end
    ) {
      setUncommittedPreviousValue({
        start: committedPreviousValue.start,
        end: committedPreviousValue.end
      });
    }

    /**
     * update month and year for calendar
     */
    const currentDay = moment(committedCurrentValue.end).subtract(1, 'month').toDate();
    const previousDay = moment(committedPreviousValue.end).subtract(1, 'month').toDate();
    if (currentMonth !== currentDay.getMonth() || currentYear !== currentDay.getFullYear()) {
      setCurrentMonth({
        currentMonth: currentDay.getMonth(),
        currentYear: currentDay.getFullYear()
      });
    }

    if (previousMonth !== previousDay.getMonth() || previousYear !== previousDay.getFullYear()) {
      setPreviousMonth({
        previousMonth: previousDay.getMonth(),
        previousYear: previousDay.getFullYear()
      });
    }
    toggleDatePickerShowing();
  };

  useEffect(() => {
    const precedingOption = {
      label: DEFAULT_PREVIOUS_OPTION,
      value: DEFAULT_PREVIOUS_OPTION,
      ...generateDateForPreviousOptions(selectedCurrentOption, uncommittedCurrentValue)
    };
    const newPreviousOptions: Option[] = [precedingOption, { label: CUSTOM, value: CUSTOM }];

    const selectedOption = newPreviousOptions.find(option => option.value === selectedPreviousOption);

    setPreviousOptions(newPreviousOptions);

    if (selectedOption && selectedOption.from && selectedOption.to) {
      setUncommittedPreviousValue({
        start: moment(selectedOption.from).toDate(),
        end: moment(selectedOption.to).toDate()
      });

      setPreviousMonth({
        previousMonth: moment(selectedOption.to).subtract(1, 'month').toDate().getMonth(),
        previousYear: moment(selectedOption.to).subtract(1, 'month').toDate().getFullYear()
      });
    }
  }, [uncommittedCurrentValue, selectedPreviousOption, selectedCurrentOption, generateDateForPreviousOptions]);

  /**
   * handle change select option
   */
  const handleChangeCurrentSelect = useCallback(
    (valueOption: string) => {
      setSelectedCurrentOption(valueOption);
      // Update uncommitted date picker state
      const foundOption = findOptionByValueOption(currentOptions, valueOption);
      if (foundOption && foundOption.from && foundOption.to) {
        const start = new Date(foundOption.from);
        const end = new Date(foundOption.to);
        setUncommittedCurrentValue({ start, end });
        setCurrentMonth({
          currentMonth: moment(end).subtract(1, 'month').toDate().getMonth(),
          currentYear: moment(end).subtract(1, 'month').toDate().getFullYear()
        });
      }
    },
    [currentOptions, findOptionByValueOption]
  );

  const handleChangePreviousSelect = useCallback(
    (valueOption: string) => {
      setSelectedPreviousOption(valueOption);
      /**
       * after updating select option, also update uncommitted date picker state
       */
      const foundOption = findOptionByValueOption(previousOptions, valueOption);
      if (foundOption && foundOption.from && foundOption.to) {
        const start = new Date(foundOption.from);
        const end = new Date(foundOption.to);
        setUncommittedPreviousValue({ start, end });
        setPreviousMonth({
          previousMonth: moment(end).subtract(1, 'month').toDate().getMonth(),
          previousYear: moment(end).subtract(1, 'month').toDate().getFullYear()
        });
      }
    },
    [findOptionByValueOption, previousOptions]
  );

  /**
   * Update month state for it to be displayed on date-picker
   */
  const handleChangeCurrentMonth = useCallback((newMonthValue: number, newYearValue: number) => {
    setCurrentMonth({
      currentMonth: newMonthValue,
      currentYear: newYearValue
    });
  }, []);

  const handleChangePreviousMonth = useCallback((newMonthValue: number, newYearValue: number) => {
    setPreviousMonth({
      previousMonth: newMonthValue,
      previousYear: newYearValue
    });
  }, []);

  /**
   * Save user's changes on date picker
   */
  const commitDatePickerValue = () => {
    /**
     * Commit data to parent's state
     */
    setCommittedCurrentValue(uncommittedCurrentValue);
    setHasPreviousComparison(hasUncommittedPreviousComparison);
    setCommittedPreviousValue(uncommittedPreviousValue);

    setCommittedOption(selectedCurrentOption);

    /**
     * Close the date picker
     */
    toggleDatePickerShowing();
  };

  /**
   * before change uncommitted date-picker value, check and update selected option
   */
  const changeUncommittedCurrentValue = useCallback(
    (dateRange: DateRange) => {
      const foundOption = findOptionByDateRange(currentOptions, dateRange.start, dateRange.end);
      setSelectedCurrentOption((foundOption && foundOption.value) || CUSTOM);
      setUncommittedCurrentValue(dateRange);
    },
    [currentOptions, findOptionByDateRange]
  );

  /**
   * before change uncommitted date-picker value, check and update selected option
   */
  const changeUncommittedPreviousValue = useCallback(
    (dateRange: DateRange) => {
      const foundOption = findOptionByDateRange(previousOptions, dateRange.start, dateRange.end);
      setSelectedPreviousOption((foundOption && foundOption.value) || CUSTOM);
      setUncommittedPreviousValue(dateRange);
    },
    [findOptionByDateRange, previousOptions]
  );

  /**
   * generate displayed text for by committed date range and list options
   */
  const generateDisplayText = useCallback(
    (options: Option[], dateRange: DateRange, label?: string) => {
      if (label) {
        const foundOption = findOptionByValueOption(options, label);
        if (foundOption && foundOption.from && foundOption.to) {
          return foundOption.label;
        }
      }
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    },
    [findOptionByValueOption]
  );

  /**
   * Date picker status that shows if user has selected new data for the date picker (not submitted yet)
   */
  let isDatePickerValueDirty = false;
  /**
   * dirty previous date-picker in use-case if checkbox has previous comparison have been checked
   */
  if (
    committedPreviousValue.start !== uncommittedPreviousValue.start ||
    committedPreviousValue.end !== uncommittedPreviousValue.end
  ) {
    isDatePickerValueDirty = hasUncommittedPreviousComparison;
  }

  if (
    /**
     * dirty current date-picker
     */
    committedCurrentValue.start !== uncommittedCurrentValue.start ||
    committedCurrentValue.end !== uncommittedCurrentValue.end ||
    /**
     * dirty checkbox has previous comparison
     */
    hasUncommittedPreviousComparison !== hasPreviousComparison
  ) {
    isDatePickerValueDirty = true;
  }

  return (
    <DatePickerContainer>
      <Popover
        active={datePickerActive}
        preferredAlignment="left"
        activator={
          <Button icon={CalendarMajor} onClick={toggleDatePickerShowing}>
            {generateDisplayText(currentOptions, committedCurrentValue, committedOption)}
          </Button>
        }
        onClose={handleCloseDatePicker}
        fluidContent>
        <Popover.Pane fixed>
          <Scrollable shadow focusable className="metric-date-picker__scrollable">
            <Popover.Section>
              <DateRangeContainer>
                <Select
                  label={<Title>Date range</Title>}
                  options={currentOptions}
                  value={selectedCurrentOption}
                  onChange={handleChangeCurrentSelect}
                />
              </DateRangeContainer>
              <PickerContainer>
                <ShopifyDatePicker
                  month={currentMonth}
                  year={currentYear}
                  onChange={changeUncommittedCurrentValue}
                  onMonthChange={handleChangeCurrentMonth}
                  selected={uncommittedCurrentValue}
                  disableDatesAfter={today}
                  weekStartsOn={1}
                  multiMonth
                  allowRange
                />
              </PickerContainer>
              {!hidePreviousComparison && (
                <>
                  <CheckBoxContainer isChecked={hasUncommittedPreviousComparison}>
                    <Checkbox
                      label="Compare to previous dates"
                      checked={hasUncommittedPreviousComparison}
                      onChange={() => setHasUncommittedPreviousComparison(!hasUncommittedPreviousComparison)}
                    />
                  </CheckBoxContainer>
                  {hasUncommittedPreviousComparison && (
                    <>
                      <DateRangeContainer>
                        <Select
                          label={<Title>Compare to</Title>}
                          options={previousOptions}
                          value={selectedPreviousOption}
                          onChange={handleChangePreviousSelect}
                        />
                      </DateRangeContainer>
                      <PickerContainer>
                        <ShopifyDatePicker
                          month={previousMonth}
                          year={previousYear}
                          onChange={changeUncommittedPreviousValue}
                          onMonthChange={handleChangePreviousMonth}
                          selected={uncommittedPreviousValue}
                          disableDatesAfter={today}
                          weekStartsOn={1}
                          multiMonth
                          allowRange
                        />
                      </PickerContainer>
                    </>
                  )}
                </>
              )}
            </Popover.Section>
          </Scrollable>
        </Popover.Pane>
        <Popover.Pane fixed>
          <Popover.Section>
            <FooterActionContainer>
              <Button onClick={handleCloseDatePicker}>Cancel</Button>
              <Button primary disabled={!isDatePickerValueDirty} onClick={commitDatePickerValue}>
                Apply
              </Button>
            </FooterActionContainer>
          </Popover.Section>
        </Popover.Pane>
      </Popover>
      {!hidePreviousComparison && hasPreviousComparison && (
        <ComparedBlock>compared to {generateDisplayText(previousOptions, committedPreviousValue)}</ComparedBlock>
      )}
    </DatePickerContainer>
  );
};

export default DatePicker;
