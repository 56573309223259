/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Sep 28th 2021, 8:32:02 pm
 *
 * *****************************************************
 */
import { useMemo } from 'react';

import { Card, SkeletonDisplayText } from '@shopify/polaris';

import { SizeChart } from 'features/project/views/pages/ProjectMetric/MetricCard/MetricChartCard';

import {
  Content,
  CardTitleRow,
  ChartRow,
  ChartLegendRow,
  OverviewFirstRow,
  OverviewSecondRow,
  AdditionalBlock,
  AdditionalBlocksRow,
  BreakdownContainer
} from './styles';

type Props = {
  hasOverview?: boolean;
  hasChart?: boolean;
  hasBreakdown?: boolean;
  size?: SizeChart;
  hasAdditionBlocks?: boolean;
  additionBlocksCount?: number;
};
// TODO: update card loading
const MetricChartCardLoading = ({
  hasOverview = false,
  hasChart = false,
  hasBreakdown = false,
  size = 'medium',
  hasAdditionBlocks = false,
  additionBlocksCount = 0
}: Props): JSX.Element => {
  const additionBlocks = useMemo(() => {
    if (!hasAdditionBlocks) return null;
    return Array.from({ length: additionBlocksCount }).map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <AdditionalBlock key={index}>
        <SkeletonDisplayText />
        <SkeletonDisplayText />
        <SkeletonDisplayText />
      </AdditionalBlock>
    ));
  }, [hasAdditionBlocks, additionBlocksCount]);
  return (
    <Card sectioned>
      <Content>
        <CardTitleRow hasOverview={hasOverview}>
          <SkeletonDisplayText />
          <SkeletonDisplayText />
        </CardTitleRow>
        {hasOverview && (
          <>
            <OverviewFirstRow>
              <SkeletonDisplayText />
              <SkeletonDisplayText />
            </OverviewFirstRow>
            <OverviewSecondRow>
              <SkeletonDisplayText />
            </OverviewSecondRow>
          </>
        )}
        {hasChart && (
          <>
            <ChartRow size={size}>
              <SkeletonDisplayText />
            </ChartRow>
            <ChartLegendRow>
              <SkeletonDisplayText />
              <SkeletonDisplayText />
            </ChartLegendRow>
          </>
        )}
        {hasAdditionBlocks && <AdditionalBlocksRow>{additionBlocks}</AdditionalBlocksRow>}
        {hasBreakdown && (
          <BreakdownContainer>
            <SkeletonDisplayText />
            <SkeletonDisplayText />
            <SkeletonDisplayText />
            <SkeletonDisplayText />
            <SkeletonDisplayText />
            <SkeletonDisplayText />
            <SkeletonDisplayText />
            <SkeletonDisplayText />
            <SkeletonDisplayText />
            <SkeletonDisplayText />
            <SkeletonDisplayText />
            <SkeletonDisplayText />
            <SkeletonDisplayText />
          </BreakdownContainer>
        )}
      </Content>
    </Card>
  );
};

export default MetricChartCardLoading;
