/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Jul 28th 2021, 3:11:51 pm
 *
 * *****************************************************
 */

/**
 * Helpers
 */
/**
 * Create list typed tags -
 * {@link https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#abstracting-common-providesinvalidates-usage Reference}
 */
export function providesList<R extends { id: string | number }[], T extends string>(resultsWithIds: R | undefined, tagType: T) {
  return resultsWithIds
    ? [{ type: tagType, id: 'LIST' }, ...resultsWithIds.map(({ id }) => ({ type: tagType, id }))]
    : [{ type: tagType, id: 'LIST' }];
}

/**
 * Tag types
 */

export const PROJECT_TAG = 'Project';
export const PROJECT_METRICS_SETTINGS_TAG = 'ProjectMetricsSettings';
