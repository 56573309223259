/* eslint-disable react/jsx-props-no-spreading */
/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 20th 2021, 4:30:18 pm
 *
 * *****************************************************
 */

import React from 'react';

import { Link as ReactRouterLink } from 'react-router-dom';

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

/**
 * A custom Link that will be used whenever a Link component is imported from @shopify/polaris package
 * Override the default @shopify/polaris' Link component with this one
 * will give us the ability to make use of react-router-dom's Link component
 * with the applied styling of Polaris
 */
const CustomPolarisLink = ({ children, url = '', external, ...rest }: any) => {
  // react-router only supports links to pages it can handle itself. It does not
  // support arbirary links, so anything that is not a path-based link should
  // use a reglar old `a` tag
  if (external ?? IS_EXTERNAL_LINK_REGEX.test(url)) {
    rest.target = '_blank';
    rest.rel = 'noopener noreferrer';
    return (
      <a {...rest} href={url}>
        {children}
      </a>
    );
  }

  return (
    <ReactRouterLink to={url} {...rest}>
      {children}
    </ReactRouterLink>
  );
};

export default CustomPolarisLink;
