/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 27th 2021, 11:00:30 am
 *
 * *****************************************************
 */

import { useContext, useState } from 'react';

import { Button, Link, Stack, TextStyle } from '@shopify/polaris';
import { BackspaceMajor } from '@shopify/polaris-icons';
import _ from 'lodash';
import { Field, useFormState } from 'react-final-form';

import { MAX_LENGTH_KEY } from 'constants/form';
import { SHOPIFY_PARTNER_ACCESS_TOKEN_LEARN_MORE_LINK } from 'constants/links';
import FormErrorBanner from 'features/common/views/components/FormErrorBanner';
import { FormStateContext } from 'features/common/views/components/FullScreenModalForm';
import Spinner from 'features/common/views/components/Spinner';
import CreateProjectContent from 'features/project/views/components/CreateProjectModal/CreateProjectContent';
import CreateProjectModalTextFieldAdapter from 'features/project/views/components/CreateProjectModal/CreateProjectModalTextFieldAdapter';
import PaymentTypeSelector from 'features/project/views/components/CreateProjectModal/PaymentTypeSelector';
import { deformatKeyOrID, formatKeyOrID } from 'utils/form/format';
import { composeValidators, required, maxLength } from 'utils/form/validation';

import { Container } from './styles';

const Step3 = (): JSX.Element => {
  const { submitting, submitErrors } = useContext(FormStateContext);

  const { values: formValues } = useFormState();

  const [stripeApiKeys, setStripeApiKeys] = useState(['stripeApiKeys-0']);

  const onRemoveKey = (key: string) => {
    setStripeApiKeys(_.reject(stripeApiKeys, stripeApiKey => stripeApiKey === key));
  };

  if (submitting)
    return (
      <CreateProjectContent>
        <Stack>
          <Spinner />
          <TextStyle variation="subdued">Setting up your new project...</TextStyle>
        </Stack>
      </CreateProjectContent>
    );

  return (
    <CreateProjectContent
      title="Connect to Shopify Partner Platform"
      description={
        <>
          Visit your <TextStyle variation="strong">Shopify Partner Admin &gt; Settings</TextStyle> for the below information.
        </>
      }>
      <Container>
        <Field name="paymentType" component={PaymentTypeSelector} initialValue="shopify_payment" />

        {submitErrors && <FormErrorBanner data={submitErrors} />}

        <Field
          name="shopifyOrganizationId"
          component={CreateProjectModalTextFieldAdapter}
          label="Shopify Organization ID"
          requiredIndicator
          placeholder="Enter Shopify Organization ID"
          helpText="If your partner dashboard URL is partners.shopify.com/1234/apps/5678/, your Shopify Organization ID is 1234."
          format={formatKeyOrID}
          parse={deformatKeyOrID}
          validate={composeValidators(required('Shopify Organization ID'), maxLength('Shopify Organization ID', MAX_LENGTH_KEY))}
        />
        <Field
          name="shopifyAppId"
          component={CreateProjectModalTextFieldAdapter}
          label="Shopify App ID"
          requiredIndicator
          placeholder="Enter Shopify App ID"
          helpText="If your partner dashboard URL is partners.shopify.com/1234/apps/5678/, your Shopify App ID is 5678."
          format={formatKeyOrID}
          parse={deformatKeyOrID}
          validate={composeValidators(required('Shopify App ID'), maxLength('Shopify App ID', MAX_LENGTH_KEY))}
        />
        <Field
          name="shopifyApiKey"
          component={CreateProjectModalTextFieldAdapter}
          label="Shopify Partner Access Token"
          requiredIndicator
          placeholder="Enter Shopify Partner Access Token"
          helpText={
            <span>
              A Partner API Client is required with the permissions: View financials and Manage apps.{' '}
              <Link removeUnderline url={SHOPIFY_PARTNER_ACCESS_TOKEN_LEARN_MORE_LINK}>
                <TextStyle variation="strong">Learn more.</TextStyle>
              </Link>
            </span>
          }
          format={formatKeyOrID}
          parse={deformatKeyOrID}
          validate={composeValidators(
            required('Shopify Partner Access Token'),
            maxLength('Shopify Partner Access Token', MAX_LENGTH_KEY)
          )}
        />

        {formValues?.paymentType === 'stripe_payment' && (
          <>
            {stripeApiKeys.map((key, index) => {
              const number = index + 1;
              return (
                <div key={key} style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', gap: 8 }}>
                  <div style={{ width: '100%' }}>
                    <Field
                      key={key}
                      name={key}
                      component={CreateProjectModalTextFieldAdapter}
                      label={`Stripe API key ${number}`}
                      requiredIndicator
                      placeholder={`Enter Stripe API key ${number}`}
                      helpText=""
                      format={formatKeyOrID}
                      parse={deformatKeyOrID}
                      validate={composeValidators(
                        required('Stripe Partner Access Token'),
                        maxLength('Stripe Partner Access Token', 200)
                      )}
                    />
                  </div>
                  <Button icon={BackspaceMajor} onClick={() => onRemoveKey(key)} />
                </div>
              );
            })}
            <div>
              <Button onClick={() => setStripeApiKeys([...stripeApiKeys, `stripeApiKeys-${stripeApiKeys.length}`])}>
                + Add stripe key
              </Button>
            </div>
          </>
        )}
      </Container>
    </CreateProjectContent>
  );
};

export default Step3;
