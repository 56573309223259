/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Aug 12th 2021, 9:57:59 am
 *
 * *****************************************************
 */

import { LOGOUT_LOCAL_STORAGE_KEY, RECENT_PROJECTS_LOCAL_STORAGE_KEY } from 'constants/local-storage';

/**
 *  Trigger sync logging out by setting a flag in localStorage of other document
 */
export const syncLogout = async () => {
  localStorage.setItem(LOGOUT_LOCAL_STORAGE_KEY, Date.now().toString());
  /**
   * Remove cached recent project list of current session
   */
  localStorage.removeItem(RECENT_PROJECTS_LOCAL_STORAGE_KEY);
};
