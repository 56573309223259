import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import { buildParams } from 'states/services/metrics';
import { GetMetricRequest } from 'states/services/metrics/types';

import * as Endpoints from './endpoints';
import * as Types from './types';

const mrrReactivationApi = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    fetchMRRReactivationChart: builder.query<Types.MRRReactivationChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_MRR_REACTIVATION_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_MRR_REACTIVATION_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.MRRReactivationChartResponse }) => {
        return response.data;
      }
    }),
    fetchReactivationOtherChart: builder.query<Types.OtherChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_OTHER_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_OTHER_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.OtherChartResponse }) => {
        return response.data;
      }
    }),
    fetchMRRReactivationBreakdown: builder.query<Types.MRRReactivationBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_MRR_REACTIVATION_BREAKDOWN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_MRR_REACTIVATION_BREAKDOWN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.MRRReactivationBreakdownResponse }) => {
        return response.data;
      }
    })
  })
});

export const { useFetchMRRReactivationBreakdownQuery, useFetchMRRReactivationChartQuery, useFetchReactivationOtherChartQuery } =
  mrrReactivationApi;
