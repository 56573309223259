/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 26th 2021, 5:11:58 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';

export const Container = styled.div<{ size: 'tiny' | 'small' | 'medium' | 'large' }>`
  position: relative;

  ${({ size }) => {
    switch (size) {
      case 'tiny':
        return `
          ${focus2Texts.website['SUBHEADING-Semibold']}
          width: 2em;
          height: 2rem;
        `;
      case 'small':
        return `
          ${focus2Texts.website['SUBHEADING-Semibold']}
          width: 2.4rem;
          height: 2.4rem;
        `;
      case 'medium':
        return `
          ${focus2Texts.website.heading['H4 - 16px']}
          width: 4rem;
          height: 4rem;
        `;
      default:
        return `
          ${focus2Texts.website.heading['H3 - 20px']}
          width: 5rem;
          height: 5rem;
        `;
    }
  }}

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: var(--p-background);
    border-radius: var(--p-border-radius-base);
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
`;

export const NameContent = styled.div<{ color: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${focus2Colors.decorative.text.one};
  border-radius: var(--p-border-radius-base);
  background-color: ${({ color }) => color};
`;
