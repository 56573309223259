/* eslint-disable react/jsx-props-no-spreading */
/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Oct 4th 2021, 9:38:53 pm
 *
 * *****************************************************
 */

import { useState, useMemo } from 'react';

import { Card } from '@shopify/polaris';
import _ from 'lodash';

import { MetricName } from 'constants/metrics/base-blocks';
import AdditionalChartBlock from 'features/project/views/pages/ProjectMetric/MetricCard/ForecastChartCard/AdditionalChartBlock';
import TimeFilterOverview from 'features/project/views/pages/ProjectMetric/MetricCard/ForecastChartCard/TimeFilterOverview';
import MetricChart from 'features/project/views/pages/ProjectMetric/MetricChartV2';
import { Common } from 'states/services/metrics/models';

import MetricTableCard, { DateTableMapping, Props as TableProps } from '../MetricTableCard';
import AdditionalMetricBlock from './AdditionalMetricBlock';
import { CardContent, AdditionalBlocksContainer, AdditionalChartsContainer, AdditionalTableContainer } from './styles';

type AdditionalMetric = Partial<Record<MetricName, Common.NonChartMetric>>;
type AdditionalChart = Partial<Record<MetricName, Common.DoughnutChartMetric<Common.DoughnutChartItem>>>;
export type SizeChart = 'small' | 'medium' | 'large';

type ChartConfig = {
  visible: boolean;
  data: any;
  legends: string[];
};

type AdditionalMetricConfig = {
  visible: boolean;
  data: AdditionalMetric;
  order?: MetricName[];
};

type AdditionalChartConfig = {
  visible: boolean;
  data: AdditionalChart;
  legends: string[];
  order?: MetricName[];
  options?: string[]; // options for multi tags select
};

type AdditionalTableConfig = {
  visible: boolean;
} & TableProps<DateTableMapping>;

type OverviewType = 'view-details' | 'time-filter' | 'multiple-tags';

type OverviewConfig = {
  updateFormCallback?: (params: any) => void;
  data?: Common.MetricOverviewData;
  type: OverviewType;
  forecastForm: {
    forecast: boolean;
    regression: string;
    frameWidth: string;
    forecastFrom: string;
    forecastTo: string;
  };
};

type Properties = {
  size: SizeChart;
  metricName: MetricName;
  isFetching?: boolean;
  chartConfig?: ChartConfig;
  overviewConfig?: OverviewConfig;
  additionalChartConfig?: AdditionalChartConfig;
  additionalMetricConfig?: AdditionalMetricConfig;
  additionalTableConfig?: AdditionalTableConfig;
};

export type TimeFilterValue = 'day' | 'week' | 'month';

const ForecastChartCard = ({
  metricName,
  size = 'medium',
  isFetching = false,
  overviewConfig,
  chartConfig,
  additionalMetricConfig,
  additionalChartConfig,
  additionalTableConfig
}: Properties) => {
  const [selectedTimeFilterValue, setTimeFilterValue] = useState<TimeFilterValue>('day');

  /**
   * additional metric blocks section
   */
  const additionalMetrics = useMemo(() => {
    if (!additionalMetricConfig || !additionalMetricConfig.visible) return null;
    const metricNames =
      additionalMetricConfig.order || (Object.keys(additionalMetricConfig.data) as MetricName[]).sort((a, b) => (a > b ? -1 : 1));
    return metricNames.map(name => {
      const metricData = additionalMetricConfig.data[name];
      if (!metricData) return null;
      return (
        <AdditionalMetricBlock
          key={name}
          metricName={name as MetricName}
          alignLeftMovementIndicator
          data={metricData.overview}
          isFetching={isFetching}
        />
      );
    });
  }, [additionalMetricConfig, isFetching]);

  /**
   * additional chart blocks section
   */
  const additionalCharts = useMemo(() => {
    if (!additionalChartConfig || !additionalChartConfig.visible) return null;
    const metricNames =
      additionalChartConfig.order || (Object.keys(additionalChartConfig.data) as MetricName[]).sort((a, b) => (a > b ? -1 : 1));
    return metricNames.map(name => {
      const metricData = additionalChartConfig.data[name];
      if (!metricData) return null;
      return (
        <AdditionalChartBlock
          key={name}
          metricName={name as MetricName}
          data={metricData}
          legends={additionalChartConfig.legends}
        />
      );
    });
  }, [additionalChartConfig]);

  /**
   * additional table blocks section
   */
  const additionalTable = useMemo(() => {
    if (!additionalTableConfig) return null;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MetricTableCard {...additionalTableConfig} isAdditional />;
  }, [additionalTableConfig]);

  let data = null;
  if (chartConfig) {
    switch (_.get(overviewConfig, 'type')) {
      case 'time-filter':
        data = chartConfig.data[selectedTimeFilterValue];
        break;
      case 'multiple-tags':
        data = _(chartConfig.data).map('data.day').value();
        break;
      default:
        data = chartConfig.data;
        break;
    }
  }
  const bigNumbers = useMemo(() => {
    if (!overviewConfig || !overviewConfig.data) return [];
    return [overviewConfig.data.bigNumber, overviewConfig.data.previousBigNumber];
  }, [overviewConfig]);

  const overviewComponent = useMemo(() => {
    if (!overviewConfig) return null;
    const { type } = overviewConfig;
    if (type === 'time-filter') {
      return (
        <TimeFilterOverview
          isFetching={isFetching}
          metricName={metricName}
          selectedTimeFilterValue={selectedTimeFilterValue}
          onTimeFilterChange={setTimeFilterValue}
          forecastForm={overviewConfig.forecastForm}
          updateFormCallback={overviewConfig.updateFormCallback}
          data={overviewConfig.data}
        />
      );
    }

    return null;
  }, [isFetching, metricName, overviewConfig, selectedTimeFilterValue]);

  return (
    <Card>
      <CardContent>
        {overviewComponent}

        {chartConfig && chartConfig.visible && data && (
          <MetricChart
            size={size}
            isFetching={isFetching}
            legends={chartConfig.legends}
            metricName={metricName}
            data={data}
            bigNumbers={bigNumbers}
          />
        )}

        {additionalChartConfig && additionalChartConfig.visible && (
          <AdditionalChartsContainer>{additionalCharts}</AdditionalChartsContainer>
        )}

        {additionalMetricConfig && additionalMetricConfig.visible && (
          <AdditionalBlocksContainer>{additionalMetrics}</AdditionalBlocksContainer>
        )}
        {additionalTableConfig && additionalTableConfig.visible && (
          <AdditionalTableContainer>{additionalTable}</AdditionalTableContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default ForecastChartCard;
