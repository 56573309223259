/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Jul 29th 2021, 8:44:07 am
 *
 * *****************************************************
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { MetricState } from './types';

const initialState: MetricState = {
  listItems: []
};

/**
 * Redux slice - Project list
 */
const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {
    addItem: (state, action: PayloadAction<{ id: string; body: any }>) => {
      const { id, body } = action.payload;
      const existedGrowthPlan = state.listItems.find(p => p.id === id);
      if (_.isEmpty(existedGrowthPlan)) {
        state.listItems.push({ id, data: body });
      }
    },
    deleteItem: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      const indexOfGrowthPlan = state.listItems.findIndex(p => p.id === id);
      if (indexOfGrowthPlan !== -1) {
        state.listItems.splice(indexOfGrowthPlan, 1);
      }
    },
    clearItems: state => {
      state.listItems.splice(0, state.listItems.length);
    }
  }
});

/**
 * Redux actions - Project list slice
 */
export const { addItem, deleteItem, clearItems } = metricsSlice.actions;

/**
 * Redux reducer - Project list slice
 */
export default metricsSlice.reducer;
