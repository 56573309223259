import { Endpoint } from 'states/services/types';

export const METRICS_ONE_TIME_CHARGE_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/one-time-charges/chart',
  isPrivate: true
};

export const METRICS_ONE_TIME_CHARGE_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/one-time-charges/breakdown',
  isPrivate: true
};
