/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Aug 10th 2021, 12:57:22 pm
 *
 * *****************************************************
 */

import { useCallback, useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import { PROJECT_VISIBILITY_VALUE_PUBLIC } from 'constants/project';
import FullScreenModal from 'features/common/views/components/FullScreenModal';
import FullScreenModalForm, { FooterActionConfig } from 'features/common/views/components/FullScreenModalForm';
import { DEFAULT } from 'features/workspace-dashboard/routes/paths';
import { useCreateProjectMutation } from 'states/services/projects/hooks';
import { convertApiErrorsToFinalFormSubmitErrors, ErrorObject, SubmissionResponse } from 'utils/form/convert';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

type Props = {
  open?: boolean;
  onClose: () => void;
};

const CreateProjectModal = ({ open = false, onClose }: Props): JSX.Element => {
  const history = useHistory();
  const [createProject] = useCreateProjectMutation();

  const footerActionContents: FooterActionConfig[] = useMemo(
    () => [
      {
        content: 'Next'
      },
      {
        content: 'Next'
      },
      { content: 'Create Project', ariaDescribedBy: 'workspace-dashboard|submit-create-project' }
    ],
    []
  );

  const keysPerPage = useMemo(
    () => [
      ['name'],
      ['visibility'],
      [
        'shopifyOrganizationId',
        'shopifyAppId',
        'shopifyApiKey',
        'shopifyCredentials',
        'stripeApiKeys-0',
        'stripeApiKeys-1',
        'stripeApiKeys-2',
        'stripeApiKeys-3',
        'stripeApiKeys-4',
        'stripeApiKeys-5',
        'stripeApiKeys-6',
        'stripeApiKeys-7',
        'stripeApiKeys-8',
        'stripeApiKeys-9',
        'stripeApiKeys-10',
        'stripeAppId',
        'stripeOrganizationId'
      ]
    ],
    []
  );

  const handleFormSubmit = useCallback(
    async values => {
      const valueSubmit = { ...values };

      if (values.paymentType === 'stripe_payment') {
        valueSubmit.stripeApiKeys = Object.keys(values)
          .filter(key => key.includes('stripeApiKeys'))
          .map(key => values[key]);
      }

      // eslint-disable-next-line
      const res = (await createProject(valueSubmit)) as SubmissionResponse;

      if (res.error) {
        /**
         * Covert the raw error data from server response to a well-format form
         * that React Final Form can consume
         */
        const normalSubmissionErrors = convertApiErrorsToFinalFormSubmitErrors(res.error as ErrorObject);

        /**
         * Display the submission errors
         */
        return normalSubmissionErrors;
      }

      /**
       * If no error (aka success), redirect to workspace dashboard
       */
      onClose();
      history.push(DEFAULT);

      return undefined;
    },
    [createProject, history, onClose]
  );

  return (
    <FullScreenModal open={open}>
      <FullScreenModalForm
        initialValues={{
          visibility: PROJECT_VISIBILITY_VALUE_PUBLIC
        }}
        keysPerPage={keysPerPage}
        footerActionConfigs={footerActionContents}
        onFormSubmit={handleFormSubmit}
        onCloseModal={onClose}>
        <FullScreenModalForm.Page>
          <Step1 />
        </FullScreenModalForm.Page>
        <FullScreenModalForm.Page>
          <Step2 />
        </FullScreenModalForm.Page>
        <FullScreenModalForm.Page>
          <Step3 />
        </FullScreenModalForm.Page>
      </FullScreenModalForm>
    </FullScreenModal>
  );
};

export default CreateProjectModal;
