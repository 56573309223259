/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Sat, Jul 24th 2021, 10:54:28 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import colors from 'constants/colors';

export const Container = styled.span<{ imageAvailable: boolean }>`
  display: block;
  overflow: hidden;
  width: 3.2rem;
  height: 3.2rem;
  background: ${colors.primary.subdued};
  color: #fff;
  border-radius: 50%;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  ${({ imageAvailable }) =>
    !imageAvailable &&
    `
      border: 0.2rem solid #FFF;
    `}
`;
