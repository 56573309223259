import { Endpoint } from 'states/services/types';

export const METRICS_CUSTOMER_UPGRADE_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/customer-upgrade/chart',
  isPrivate: true
};

export const METRICS_CUSTOMER_UPGRADE_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/customer-upgrade/breakdown',
  isPrivate: true
};

export const METRICS_CUSTOMER_UPGRADE_CHART_V2_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/customer-upgrade/chart',
  isPrivate: true
};

export const METRICS_CUSTOMER_UPGRADE_BREAKDOWN_V2_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/customer-upgrade/breakdown',
  isPrivate: true
};
