/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Sat, Jul 24th 2021, 4:41:40 pm
 *
 * *****************************************************
 */

export const ERROR_404 = '/404';
export const ERROR_500 = '/500';
