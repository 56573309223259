/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Oct 7th 2021, 7:57:29 am
 *
 * *****************************************************
 */

import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Card, DataTable, DataTableProps, Link, Pagination } from '@shopify/polaris';
import _ from 'lodash';

import { CardHeader, Content, PaginationContainer } from './styles';

type TableCardData<T> = {
  overview: Partial<T>;
  data: T[];
};

export type DateTableMapping = {
  [index: string]: {
    tableIndex: number;
    link?: string;
    valueFormatter?: (value: any, rowData?: any) => string;
  };
};

export type Props<T extends DateTableMapping> = {
  cardTitle: string;
  sortFunction: (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => any[];
  dataTableMapping: T;
  data: TableCardData<Record<keyof T, any>>;
  hidePagination?: boolean;
  rowPerPage?: number;
  hideTotals?: boolean;
  isAdditional?: boolean;
} & Pick<DataTableProps, 'columnContentTypes' | 'headings' | 'totalsName' | 'sortable' | 'defaultSortDirection'>;

const MetricTableCard = <T extends DateTableMapping>({
  cardTitle,
  columnContentTypes,
  headings,
  hideTotals = false,
  totalsName,
  defaultSortDirection,
  sortable,
  sortFunction,
  dataTableMapping,
  data,
  hidePagination = false,
  rowPerPage = 10,
  isAdditional = false
}: PropsWithChildren<Props<T>>): JSX.Element => {
  const { overview: overviewData, data: tableData } = data;

  const tableKeys = useMemo(() => {
    return Object.keys(dataTableMapping);
  }, [dataTableMapping]);

  const [currentPage, setCurrentPage] = useState(0);

  const transformRow = useCallback(
    (row: any) => {
      const n = tableKeys.length;
      return row.map((rowData: any) => {
        const res: any[] = Array.from({ length: n });
        tableKeys.forEach(key => {
          const { tableIndex, valueFormatter, link } = dataTableMapping[key];
          res[tableIndex] = valueFormatter ? valueFormatter(rowData[key], rowData) : rowData[key];
          if (link) {
            res[tableIndex] = (
              <Link removeUnderline url={`${link}/${res[tableIndex]}`}>
                {res[tableIndex]}
              </Link>
            );
          }
        });
        return res;
      });
    },
    [dataTableMapping, tableKeys]
  );

  const transformedData = transformRow(tableData);

  const tableRef = useRef(transformedData);

  const [allRows, setAllRows] = useState<any[]>(transformedData);

  useEffect(() => {
    const dataFromProps = transformRow(tableData);
    if (!_(dataFromProps).isEqual(tableRef.current)) {
      setAllRows(dataFromProps);
      tableRef.current = dataFromProps;
    }
  }, [dataTableMapping, tableData, tableKeys, transformRow]);

  const totalPages = useMemo(() => {
    const numberOfDataRecord = tableData.length;
    return Math.ceil(numberOfDataRecord / rowPerPage);
  }, [tableData, rowPerPage]);

  const totals = useMemo(() => {
    if (hideTotals) return undefined;
    const n = tableKeys.length;
    const res: any[] = Array.from({ length: n }).fill('—');
    tableKeys.forEach(key => {
      if (overviewData && Object.prototype.hasOwnProperty.call(overviewData, key)) {
        const { tableIndex, valueFormatter } = dataTableMapping[key];
        res[tableIndex] = valueFormatter ? valueFormatter(overviewData[key]) : overviewData[key];
      }
    });
    return res;
  }, [hideTotals, dataTableMapping, tableKeys, overviewData]);

  const handleSort = useCallback(
    (index, direction) => setAllRows(prevState => sortFunction(prevState, index, direction)),
    [sortFunction]
  );

  const handlePreviousPage = useCallback(() => {
    setCurrentPage(prevPage => Math.max(0, prevPage - 1));
  }, []);

  const handleNextPage = useCallback(() => {
    setCurrentPage(prevPage => Math.min(totalPages - 1, prevPage + 1));
  }, [totalPages]);

  const displayedRows = useMemo(() => {
    return allRows.slice(currentPage * rowPerPage, (currentPage + 1) * rowPerPage);
  }, [allRows, currentPage, rowPerPage]);

  const hasPreviousPage = currentPage !== 0;
  const hasNextPage = currentPage !== totalPages - 1;

  return (
    <Card>
      <CardHeader isAdditional={isAdditional}>
        <h2>{cardTitle}</h2>
      </CardHeader>
      <Content columnContentTypes={columnContentTypes}>
        <DataTable
          columnContentTypes={columnContentTypes}
          headings={headings}
          rows={displayedRows}
          totals={totals}
          totalsName={totalsName}
          defaultSortDirection={defaultSortDirection}
          sortable={sortable}
          onSort={handleSort}
        />
      </Content>
      {!hidePagination && totalPages > 1 && (
        <PaginationContainer>
          <Pagination
            hasPrevious={hasPreviousPage}
            onPrevious={handlePreviousPage}
            hasNext={hasNextPage}
            onNext={handleNextPage}
          />
        </PaginationContainer>
      )}
    </Card>
  );
};

export default MetricTableCard;
