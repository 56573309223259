/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Oct 4th 2021, 3:54:13 pm
 *
 * *****************************************************
 */

import { calculateMovementValue, formatPercent } from 'utils/project';

import { Container } from './styles';

type Props = {
  currentNumber: number | null;
  previousNumber: number | null;
  isIncreasePositiveMovement: boolean;
  size?: 'small' | 'medium';
  hasParentheses: boolean;
};

const MovementIndicator = ({
  currentNumber,
  previousNumber,
  isIncreasePositiveMovement,
  size = 'medium',
  hasParentheses = false
}: Props): JSX.Element => {
  const positiveColor = isIncreasePositiveMovement ? 'success' : 'critical';
  const negativeColor = isIncreasePositiveMovement ? 'critical' : 'success';

  let displayedValue = '';
  let color: 'success' | 'critical' = 'success';

  /**
   * If previousNumber is zero, display a positive arrow up
   */
  if (previousNumber !== 0 && previousNumber !== null && currentNumber !== null) {
    const movementValue = calculateMovementValue(currentNumber, previousNumber);

    const isPositiveChange = movementValue > 0;
    displayedValue = formatPercent(Math.abs(movementValue));

    /**
     * If the change is O%, display a positive arrow up along with a 0% value
     */
    if (movementValue !== 0) {
      color = isPositiveChange ? positiveColor : negativeColor;
      displayedValue = isPositiveChange ? `↑${displayedValue}` : `↓${displayedValue}`;
    }
  }

  if (hasParentheses) displayedValue = `${displayedValue}`;

  return (
    <Container color={color} size={size}>
      <p>{displayedValue}</p>
    </Container>
  );
};

export default MovementIndicator;
