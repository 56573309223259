/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 22nd 2021, 4:30:09 pm
 *
 * *****************************************************
 */

export const BREAKDOWN_POLLING_INTERVAL = 15 * 60 * 1e3;
export const ACTIVITIES_POLLING_INTERVAL = 15 * 60 * 1e3;
