/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 19th 2021, 3:32:30 pm
 *
 * *****************************************************
 */

import { emptyAPI } from 'states/services'; // Import empty api

import * as authEndpoints from './endpoints';
import { AuthenticationResponse } from './types';

/**
 * An authentication api
 * create by extending (injecting) empty api with authentication endpoints
 */
export const authAPI = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    refreshToken: builder.query<AuthenticationResponse, void>({
      query: () => authEndpoints.REFRESH_TOKEN_ENDPOINT.url,
      extraOptions: {
        isPrivate: authEndpoints.REFRESH_TOKEN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: AuthenticationResponse }) => response.data
    })
  }),
  overrideExisting: true
});
