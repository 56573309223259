/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Oct 1st 2021, 1:27:52 pm
 *
 * *****************************************************
 */

import { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import RelativeTime from 'features/common/views/components/RelativeTime';
import ForecastChartCard from 'features/project/views/pages/ProjectMetric/MetricCard/ForecastChartCard';
import MetricChartCard from 'features/project/views/pages/ProjectMetric/MetricCard/MetricChartCard';
import MetricTableCard from 'features/project/views/pages/ProjectMetric/MetricCard/MetricTableCard';
import MetricChartCardLoading from 'features/project/views/pages/ProjectMetric/MetricCardLoading/MetricChartCardLoading';
import MetricTableCardLoading from 'features/project/views/pages/ProjectMetric/MetricCardLoading/MetricTableCardLoading';
import DatePicker from 'features/project/views/pages/ProjectMetric/MetricDatePicker';
import {
  ChartContainer,
  DatePickerContainer,
  NoticeBlock,
  PageContentContainer,
  PlanTableCardContainer,
  BreakdownTableCardContainer
} from 'features/project/views/pages/ProjectMetric/MetricPage/UserAndCustomerPageGroup/ActiveCustomer/styles';
import {
  useFetchActiveCustomerBreakdownQuery,
  useFetchActiveCustomerChartQuery,
  useFetchActiveCustomerGrowthChartQuery
} from 'states/services/metrics/user/active-customer';
import {
  ActiveCustomerBreakdownResponse,
  ActiveCustomerChartResponse,
  ActiveCustomerGrowthChartResponse
} from 'states/services/metrics/user/active-customer/types';
import { createTextDatePickerRange, formatDate } from 'utils/project';

const INITIAL_FORM = {
  forecast: false,
  regression: 'linear',
  frameWidth: 'six_month',
  forecastFrom: moment().startOf('day').toISOString(),
  forecastTo: moment().startOf('day').add(6, 'months').toISOString()
};

const ActiveCustomerPage = () => {
  const { projectId } = useParams() as { projectId: string };
  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedCurrentValue, setCommittedCurrentValue] = useState(() => {
    const last30days = {
      start: moment().subtract(30, 'day').toDate(),
      end: moment().subtract(1, 'day').toDate()
    };
    return last30days;
  });

  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedPreviousValue, setCommittedPreviousValue] = useState(() => {
    const last60days = {
      start: moment().subtract(60, 'day').toDate(),
      end: moment().subtract(31, 'day').toDate()
    };
    return last60days;
  });

  const [hasPreviousComparison, setHasPreviousComparison] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [forecastForm, setForecastForm] = useState(INITIAL_FORM);

  const onForecastFormSubmit = useCallback(partialForm => {
    setForecastForm(prev => {
      return { ...prev, ...partialForm };
    });
  }, []);

  /**
   * initial data before calling api
   * data chart fetching by projectId, fromDate, toDate
   */
  const chartResponse = useFetchActiveCustomerChartQuery(
    {
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end),
      ...forecastForm
    },
    { refetchOnMountOrArgChange: true }
  );

  const growthChartResponse = useFetchActiveCustomerGrowthChartQuery(
    {
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end)
    },
    { refetchOnMountOrArgChange: true }
  );

  const breakdownResponse = useFetchActiveCustomerBreakdownQuery(
    {
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end)
    },
    { refetchOnMountOrArgChange: true }
  );

  const metricCalculatedAt = _.get(chartResponse, 'data.metricCalculatedAt', null);

  const chartLegends = useMemo(() => {
    const { forecast, forecastFrom, forecastTo } = forecastForm;
    if (forecast) {
      return [createTextDatePickerRange(moment(forecastFrom).toDate(), moment(forecastTo).toDate())];
    }
    return [committedCurrentValue, hasPreviousComparison && committedPreviousValue].map(dateRange => {
      if (!dateRange) return '';
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    });
  }, [committedCurrentValue, committedPreviousValue, forecastForm, hasPreviousComparison]);

  const activeCustomerChart = (() => {
    let children = null;
    const { isLoading, isFetching, data } = chartResponse;
    if (isLoading)
      children = <MetricChartCardLoading hasOverview hasChart size="large" additionBlocksCount={3} hasAdditionBlocks />;
    else {
      const { activeCustomer, newCustomer, customerReactivation, customerCancellation } = data as ActiveCustomerChartResponse;
      const additionalBlocksData = { newCustomer, customerReactivation, customerCancellation };
      const { overview: metricOverviewData, data: metricChartData } = activeCustomer;
      children = (
        <ForecastChartCard
          metricName="activeSubscriptions"
          size="large"
          isFetching={isFetching}
          overviewConfig={{
            type: 'time-filter',
            forecastForm,
            updateFormCallback: onForecastFormSubmit,
            data: metricOverviewData
          }}
          chartConfig={{
            visible: true,
            data: metricChartData,
            legends: chartLegends
          }}
          additionalMetricConfig={{
            visible: true,
            data: additionalBlocksData
          }}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  })();

  const dailyGrowthChart = useMemo(() => {
    let children = null;
    const { isFetching, data } = growthChartResponse;
    if (isFetching) children = <MetricChartCardLoading hasChart size="large" />;
    else {
      const { growth } = data as ActiveCustomerGrowthChartResponse;
      const { data: metricChartData } = growth;
      children = (
        <MetricChartCard
          metricName="growthSubscriptionBar"
          size="large"
          overviewConfig={{
            type: 'time-filter',
            hideTooltip: true
          }}
          chartConfig={{
            show: true,
            data: metricChartData,
            legends: []
          }}
          hasPreviousComparison={hasPreviousComparison}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  }, [hasPreviousComparison, growthChartResponse]);

  const planTable = useMemo(() => {
    let children = null;
    const { isFetching, data } = breakdownResponse;
    if (isFetching) children = <MetricTableCardLoading />;
    else {
      const { plan } = data as ActiveCustomerBreakdownResponse;
      const dataTableMapping = {
        planName: {
          tableIndex: 0
        },
        interval: {
          tableIndex: 1
        },
        activeCustomers: {
          tableIndex: 2
        }
      };

      const tableSortFunction = (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => {
        if (index === 0)
          return [...rows].sort((rowA, rowB) => {
            const amountA = rowA[index] as string;
            const amountB = rowB[index] as string;

            return direction === 'descending' ? amountB.localeCompare(amountA) : amountA.localeCompare(amountB);
          });
        if (index === 1)
          return [...rows].sort((rowA, rowB) => {
            const amountA = moment(rowA[index], 'MMM DD, YYYY');
            const amountB = moment(rowB[index], 'MMM DD, YYYY');

            return direction === 'descending' ? amountB.diff(amountA) : amountA.diff(amountB);
          });
        return rows;
      };

      children = (
        <MetricTableCard
          cardTitle="Plans"
          columnContentTypes={['text', 'text', 'numeric']}
          headings={['Plan', 'Interval', 'Active customer']}
          totalsName={{ singular: 'Summary', plural: 'Summary' }}
          sortable={[true, false, false]}
          defaultSortDirection="descending"
          data={plan}
          dataTableMapping={dataTableMapping}
          sortFunction={tableSortFunction}
        />
      );
    }
    return <PlanTableCardContainer>{children}</PlanTableCardContainer>;
  }, [breakdownResponse]);

  const breakdownTable = useMemo(() => {
    let children = null;
    const { isFetching, data } = breakdownResponse;
    if (isFetching) children = <MetricTableCardLoading />;
    else {
      const { breakdown } = data as ActiveCustomerBreakdownResponse;
      const dataTableMapping = {
        key: {
          tableIndex: 0
        },
        customerReactivation: {
          tableIndex: 1
        },
        newCustomer: {
          tableIndex: 2
        },
        customerCancellation: {
          tableIndex: 3
        },
        customerGrowth: {
          tableIndex: 4
        }
      };

      const tableSortFunction = (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => {
        if (index === 0)
          return [...rows].sort((rowA, rowB) => {
            const amountA = rowA[index] as string;
            const amountB = rowB[index] as string;

            return direction === 'descending' ? amountB.localeCompare(amountA) : amountA.localeCompare(amountB);
          });
        if (index === 1)
          return [...rows].sort((rowA, rowB) => {
            const amountA = moment(rowA[index], 'MMM DD, YYYY');
            const amountB = moment(rowB[index], 'MMM DD, YYYY');

            return direction === 'descending' ? amountB.diff(amountA) : amountA.diff(amountB);
          });
        return rows;
      };

      children = (
        <MetricTableCard
          cardTitle="Breakdown"
          columnContentTypes={['text', 'numeric', 'numeric', 'numeric', 'numeric']}
          headings={['Date', 'Customer Reactivation', 'New Customer', 'Customer Cancellation', 'Growth']}
          totalsName={{ singular: 'Summary', plural: 'Summary' }}
          sortable={[true, false, false]}
          defaultSortDirection="descending"
          data={breakdown}
          dataTableMapping={dataTableMapping}
          sortFunction={tableSortFunction}
        />
      );
    }
    return <BreakdownTableCardContainer>{children}</BreakdownTableCardContainer>;
  }, [breakdownResponse]);

  return (
    <PageContentContainer>
      <DatePickerContainer>
        <DatePicker
          hasPreviousComparison={hasPreviousComparison}
          setHasPreviousComparison={setHasPreviousComparison}
          committedCurrentValue={committedCurrentValue}
          setCommittedCurrentValue={setCommittedCurrentValue}
          committedPreviousValue={committedPreviousValue}
          setCommittedPreviousValue={setCommittedPreviousValue}
        />
      </DatePickerContainer>
      {activeCustomerChart}
      {dailyGrowthChart}
      {breakdownTable}
      {planTable}
      <NoticeBlock>
        <p>
          {chartResponse.isFetching ? (
            'Your data is loading...'
          ) : (
            <>
              Your data was last updated&nbsp;
              <RelativeTime value={metricCalculatedAt} />
            </>
          )}
        </p>
      </NoticeBlock>
    </PageContentContainer>
  );
};

export default ActiveCustomerPage;
