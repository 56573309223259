import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import { buildParams } from 'states/services/metrics';
import { GetMetricRequest } from 'states/services/metrics/types';

import * as Endpoints from './endpoints';
import * as Types from './types';

const activeCustomerApi = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    fetchActiveCustomerChart: builder.query<Types.ActiveCustomerChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_ACTIVE_CUSTOMER_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_ACTIVE_CUSTOMER_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.ActiveCustomerChartResponse }) => {
        return response.data;
      }
    }),
    fetchActiveCustomerGrowthChart: builder.query<Types.ActiveCustomerGrowthChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_ACTIVE_CUSTOMER_GROWTH_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_ACTIVE_CUSTOMER_GROWTH_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.ActiveCustomerGrowthChartResponse }) => {
        return response.data;
      }
    }),
    fetchActiveCustomerBreakdown: builder.query<Types.ActiveCustomerBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_ACTIVE_CUSTOMER_BREAKDOWN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_ACTIVE_CUSTOMER_BREAKDOWN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.ActiveCustomerBreakdownResponse }) => {
        return response.data;
      }
    }),
    fetchActiveCustomerChartV2: builder.query<Types.ActiveCustomerChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_ACTIVE_CUSTOMER_CHART_V2_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_ACTIVE_CUSTOMER_CHART_V2_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.ActiveCustomerChartResponse }) => {
        return response.data;
      }
    }),
    fetchActiveCustomerGrowthChartV2: builder.query<Types.ActiveCustomerGrowthChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_ACTIVE_CUSTOMER_GROWTH_CHART_V2_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_ACTIVE_CUSTOMER_GROWTH_CHART_V2_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.ActiveCustomerGrowthChartResponse }) => {
        return response.data;
      }
    }),
    fetchActiveCustomerBreakdownV2: builder.query<Types.ActiveCustomerBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_ACTIVE_CUSTOMER_BREAKDOWN_V2_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_ACTIVE_CUSTOMER_BREAKDOWN_V2_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.ActiveCustomerBreakdownResponse }) => {
        return response.data;
      }
    })
  })
});

export const {
  useFetchActiveCustomerBreakdownQuery,
  useFetchActiveCustomerChartQuery,
  useFetchActiveCustomerGrowthChartQuery,
  useFetchActiveCustomerBreakdownV2Query,
  useFetchActiveCustomerChartV2Query,
  useFetchActiveCustomerGrowthChartV2Query
} = activeCustomerApi;
