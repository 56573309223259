/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Sat, Jul 24th 2021, 5:17:53 pm
 *
 * *****************************************************
 */

const heading = {
  'Heading 1 - 32px - B': `
    font-weight: 700;
    font-style: normal;
    font-size: 3.2rem;
    line-height: 3.2rem;
  `,
  'Heading 2 - 24px - SB': `
    font-weight: 600;
    font-style: normal;
    font-size: 2.4rem;
    line-height: 2.4rem;
  `,
  'Heading 3 - 20px - SB': `
    font-weight: 600;
    font-style: normal;
    font-size: 2rem;
    line-height: 2.4rem;
  `
};

const body = {
  'Body 1 - 14px - R': `
    font-weight: 400;
    font-style: normal;
    font-size: 1.4rem;
    line-height: 2rem;
  `,
  'Body 1 - 14px - SB': `
    font-weight: 600;
    font-style: normal;
    font-size: 1.4rem;
    line-height: 2rem;
  `,
  'Body 1 - 14px - B': `
    font-weight: 700;
    font-style: normal;
    font-size: 1.4rem;
    line-height: 2.8rem;
  `,
  'Body 2 - 17px - SB': `
    font-weight: 600;
    font-style: normal;
    font-size: 1.7rem;
    line-height: 2.4rem;
  `
};

export default {
  heading,
  body
};
