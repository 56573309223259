import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import { buildParams } from 'states/services/metrics';
import { GetMetricRequest } from 'states/services/metrics/types';

import * as Endpoints from './endpoints';
import * as Types from './types';

const dashboardApi = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    getMetrics: builder.query<Types.GetMetricDashboardResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_DASHBOARD_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_DASHBOARD_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.GetMetricDashboardResponse }) => {
        return response.data;
      }
    }),
    getBreakdownData: builder.query<Types.GetBreakdownDataResponse, Types.GetBreakdownDataRequest>({
      query: ({ projectId }) => generatePath(Endpoints.BREAKDOWN_ENDPOINT.url, { projectId }),
      extraOptions: {
        isPrivate: Endpoints.BREAKDOWN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.GetBreakdownDataResponse }) => {
        return response.data;
      }
    }),
    getActivitiesData: builder.query<Types.GetActivitiesDataResponse, Types.GetActivitiesDataRequest>({
      query: ({ projectId }) => generatePath(Endpoints.ACTIVITIES_ENDPOINT.url, { projectId }),
      extraOptions: {
        isPrivate: Endpoints.ACTIVITIES_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.GetActivitiesDataResponse }) => {
        return response.data;
      }
    })
  })
});

export const { useGetMetricsQuery, useGetBreakdownDataQuery, useGetActivitiesDataQuery } = dashboardApi;
