/**
 * Copyright (C) Boost commerce
 * This file is part of commercial Boost commerce projects
 *
 * This file can not be copied and/or distributed without the express
 * permission of Boost commerce
 *
 * Created on Thu Jan 11 2024 18:04:58
 */

import { ReactNode, useCallback, useState } from 'react';

import { Stack } from '@shopify/polaris';

import TopBarCompanyLogoButton from 'features/common/views/components/TopBarCompanyLogoButton';
import UserMenu from 'features/common/views/components/UserMenu';
import ActionList from 'features/metrics/views/components/ActionList';
import CreateProjectModal from 'features/project/views/components/CreateProjectModal';

import { LayoutContainer, TopBar, LogoButtonContainer } from './styles';

type Props = {
  children: ReactNode;
  workspaceName: string;
};

const MetricLayout = ({ children, workspaceName }: Props): JSX.Element => {
  const [createProjectModalActive, setCreateProjectModalActive] = useState(false);

  const toggleCreateProjectModalShowing = useCallback(() => setCreateProjectModalActive(prevState => !prevState), []);

  return (
    <LayoutContainer>
      <TopBar>
        <LogoButtonContainer>
          <Stack alignment="center" spacing="tight" wrap={false}>
            <TopBarCompanyLogoButton hideWorkspaceName workspaceName={workspaceName} />
            <ActionList onOpenCreateProjectModal={toggleCreateProjectModalShowing} />
          </Stack>
        </LogoButtonContainer>
        <UserMenu />
      </TopBar>
      <main>{children}</main>
      <CreateProjectModal open={createProjectModalActive} onClose={toggleCreateProjectModalShowing} />
    </LayoutContainer>
  );
};

export default MetricLayout;
