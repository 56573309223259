/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Oct 1st 2021, 1:27:52 pm
 *
 * *****************************************************
 */

import { useEffect, useMemo, useState } from 'react';

import moment from 'moment';

import RelativeTime from 'features/common/views/components/RelativeTime';
import MetricChartCard from 'features/metrics/views/components/cards/MetricChartCard';
import DatePicker from 'features/metrics/views/components/DatePicker';
import MetricChartCardLoading from 'features/metrics/views/components/MetricCardLoading/MetricChartCardLoading';
import { useFetchUserOverviewQuery } from 'states/services/metrics/user/overview';
import { UserOverviewResponse } from 'states/services/metrics/user/overview/types';
import { createTextDatePickerRange, formatDate } from 'utils/project';

import {
  DatePickerContainer,
  NoticeBlock,
  ChargesContainer,
  ChargesSubContainer,
  PageContentContainer,
  VisibleLargeScreenBlock,
  VisibleSmallScreenBlock,
  ChargesSubSmallContainer,
  ChargesSubLargeContainer
} from './styles';

const UserOverviewPage = () => {
  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedCurrentValue, setCommittedCurrentValue] = useState(() => {
    const last30days = {
      start: moment().subtract(30, 'day').toDate(),
      end: moment().subtract(1, 'day').toDate()
    };
    return last30days;
  });

  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedPreviousValue, setCommittedPreviousValue] = useState(() => {
    const last60days = {
      start: moment().subtract(60, 'day').toDate(),
      end: moment().subtract(31, 'day').toDate()
    };
    return last60days;
  });

  const [hasPreviousComparison, setHasPreviousComparison] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * initial data before calling api
   * data chart fetching by projectId: 'aggregated', fromDate, toDate
   */
  const { isFetching, data = { syncStatus: null, data: null } } = useFetchUserOverviewQuery(
    {
      projectId: 'aggregated',
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end)
    },
    { refetchOnMountOrArgChange: true }
  );

  const { metricCalculatedAt } = data as UserOverviewResponse | { metricCalculatedAt: '' };

  const chartLegends = useMemo(() => {
    return [committedCurrentValue, hasPreviousComparison && committedPreviousValue].map(dateRange => {
      if (!dateRange) return '';
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    });
  }, [committedCurrentValue, committedPreviousValue, hasPreviousComparison]);

  const firstTwoBlocks = (() => {
    let children = null;
    if (isFetching) {
      children = (
        <>
          <ChargesSubSmallContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCardLoading hasOverview size="small" />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCardLoading hasOverview size="large" />
            </VisibleSmallScreenBlock>
          </ChargesSubSmallContainer>
          <ChargesSubSmallContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCardLoading hasOverview size="small" />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCardLoading hasOverview size="large" />
            </VisibleSmallScreenBlock>
          </ChargesSubSmallContainer>
        </>
      );
    } else {
      const { ltvSection, arpuSection } = data as UserOverviewResponse;
      const {
        ltv: { overview: ltvOverview }
      } = ltvSection;
      const {
        arpu: { overview: arpuOverview }
      } = arpuSection;
      children = (
        <>
          <ChargesSubSmallContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCard
                metricName="ltv"
                size="small"
                hasPreviousComparison={hasPreviousComparison}
                overviewConfig={{
                  type: 'view-details',
                  show: true,
                  alignLeftMovementIndicator: true,
                  data: ltvOverview
                }}
              />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCard
                metricName="ltv"
                size="large"
                hasPreviousComparison={hasPreviousComparison}
                overviewConfig={{
                  type: 'view-details',
                  show: true,
                  alignLeftMovementIndicator: true,
                  data: ltvOverview
                }}
              />
            </VisibleSmallScreenBlock>
          </ChargesSubSmallContainer>
          <ChargesSubSmallContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCard
                metricName="arpu"
                size="small"
                hasPreviousComparison={hasPreviousComparison}
                overviewConfig={{
                  type: 'view-details',
                  show: true,
                  alignLeftMovementIndicator: true,
                  data: arpuOverview
                }}
              />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCard
                metricName="arpu"
                size="large"
                hasPreviousComparison={hasPreviousComparison}
                overviewConfig={{
                  type: 'view-details',
                  show: true,
                  alignLeftMovementIndicator: true,
                  data: arpuOverview
                }}
              />
            </VisibleSmallScreenBlock>
          </ChargesSubSmallContainer>
        </>
      );
    }
    return <ChargesContainer>{children}</ChargesContainer>;
  })();

  const GrowthAndChurnChart = (() => {
    let children = null;
    if (isFetching) {
      children = (
        <>
          <ChargesSubContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCardLoading hasOverview hasChart size="small" />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCardLoading hasOverview hasChart size="large" />
            </VisibleSmallScreenBlock>
          </ChargesSubContainer>
          <ChargesSubContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCardLoading hasOverview hasChart size="small" />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCardLoading hasOverview hasChart size="large" />
            </VisibleSmallScreenBlock>
          </ChargesSubContainer>
        </>
      );
    } else {
      const { customerGrowthSection, customerChurnRateSection } = data as UserOverviewResponse;
      const { customerGrowth } = customerGrowthSection;
      const { customerChurnRate } = customerChurnRateSection;
      const { overview: growthOverview, data: growthData } = customerGrowth;
      const { overview: churnOverview, data: churnData } = customerChurnRate;
      children = (
        <>
          <ChargesSubContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCard
                metricName="growthSubscription"
                size="small"
                overviewConfig={{
                  type: 'view-details',
                  show: true,
                  data: growthOverview,
                  alignLeftMovementIndicator: true
                }}
                chartConfig={{
                  show: true,
                  data: growthData.day,
                  legends: chartLegends
                }}
                hasPreviousComparison={hasPreviousComparison}
              />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCard
                metricName="growthSubscription"
                size="large"
                overviewConfig={{
                  type: 'view-details',
                  show: true,
                  data: growthOverview,
                  alignLeftMovementIndicator: true
                }}
                chartConfig={{
                  show: true,
                  data: growthData.day,
                  legends: chartLegends
                }}
                hasPreviousComparison={hasPreviousComparison}
              />
            </VisibleSmallScreenBlock>
          </ChargesSubContainer>
          <ChargesSubContainer>
            <VisibleLargeScreenBlock>
              <MetricChartCard
                metricName="customerChurnRate"
                size="small"
                overviewConfig={{
                  data: churnOverview,
                  type: 'view-details',
                  show: true,
                  alignLeftMovementIndicator: true
                }}
                chartConfig={{
                  show: true,
                  data: churnData.day,
                  legends: chartLegends
                }}
                hasPreviousComparison={hasPreviousComparison}
              />
            </VisibleLargeScreenBlock>
            <VisibleSmallScreenBlock>
              <MetricChartCard
                metricName="customerChurnRate"
                size="large"
                overviewConfig={{
                  data: churnOverview,
                  type: 'view-details',
                  show: true,
                  alignLeftMovementIndicator: true
                }}
                chartConfig={{
                  show: true,
                  data: churnData.day,
                  legends: chartLegends
                }}
                hasPreviousComparison={hasPreviousComparison}
              />
            </VisibleSmallScreenBlock>
          </ChargesSubContainer>
        </>
      );
    }
    return <ChargesContainer>{children}</ChargesContainer>;
  })();

  const activeCustomerChart = (() => {
    let children = null;
    if (isFetching) {
      children = <MetricChartCardLoading hasOverview hasChart size="large" hasAdditionBlocks additionBlocksCount={7} />;
    } else {
      const { activeCustomerSection } = data as UserOverviewResponse;
      const { activeCustomer, ...additionalBlocksData } = activeCustomerSection;
      const { overview, data: activeCustomerData } = activeCustomer;
      children = (
        <>
          <MetricChartCard
            metricName="activeSubscriptions"
            size="large"
            overviewConfig={{
              type: 'view-details',
              show: true,
              data: overview,
              alignLeftMovementIndicator: true
            }}
            chartConfig={{
              show: true,
              data: activeCustomerData.day,
              legends: chartLegends
            }}
            additionalMetricConfig={{
              show: true,
              data: additionalBlocksData,
              order: [
                'newCustomer',
                'customerUpgrade',
                'customerReactivation',
                'customerDowngrade',
                'customerCancellation',
                'customerFrozen',
                'customerUnfrozen'
              ]
            }}
            hasPreviousComparison={hasPreviousComparison}
          />
        </>
      );
    }
    return (
      <ChargesContainer>
        <ChargesSubLargeContainer>{children}</ChargesSubLargeContainer>
      </ChargesContainer>
    );
  })();

  const userActiveChart = (() => {
    let children = null;
    if (isFetching) {
      children = <MetricChartCardLoading hasOverview hasChart size="large" />;
    } else {
      const { userActiveSection } = data as UserOverviewResponse;
      const { userActive } = userActiveSection;
      const { overview, data: userActiveData } = userActive;
      children = (
        <>
          <MetricChartCard
            metricName="activeUser"
            size="large"
            overviewConfig={{
              type: 'view-details',
              data: overview,
              show: true,
              alignLeftMovementIndicator: true
            }}
            chartConfig={{
              show: true,
              data: userActiveData.day,
              legends: chartLegends
            }}
            hasPreviousComparison={hasPreviousComparison}
          />
        </>
      );
    }
    return (
      <ChargesContainer>
        <ChargesSubLargeContainer>{children}</ChargesSubLargeContainer>
      </ChargesContainer>
    );
  })();

  return (
    <PageContentContainer>
      <DatePickerContainer>
        <DatePicker
          hasPreviousComparison={hasPreviousComparison}
          setHasPreviousComparison={setHasPreviousComparison}
          committedCurrentValue={committedCurrentValue}
          setCommittedCurrentValue={setCommittedCurrentValue}
          committedPreviousValue={committedPreviousValue}
          setCommittedPreviousValue={setCommittedPreviousValue}
        />
      </DatePickerContainer>
      {firstTwoBlocks}
      {activeCustomerChart}
      {GrowthAndChurnChart}
      {userActiveChart}
      <NoticeBlock>
        <p>
          {isFetching ? (
            'Your data is loading...'
          ) : (
            <>
              Your data was last updated&nbsp;
              <RelativeTime value={metricCalculatedAt} />
            </>
          )}
        </p>
      </NoticeBlock>
    </PageContentContainer>
  );
};

export default UserOverviewPage;
