/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 20th 2021, 6:00:21 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  main {
    width: 100%;
    min-height: calc(100vh - 5.6rem);
  }
`;

export const TopBar = styled.div`
  position: relative;
  width: 100%;
  height: 5.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.2rem;
  background-color: ${focus2Colors.onDark.default};
`;

export const TopBarCompanyLogoButtonContainer = styled.div`
  display: flex;
  align-items: center;

  /* OVERRIDEN CSS */
  --p-surface: ${focus2Colors.onDark.normal};
  --p-action-secondary-hovered: ${focus2Colors.onDark.hovered};
  --p-action-secondary-pressed: ${focus2Colors.onDark.pressed};

  .Polaris-Button {
    border: none;
  }

  .Polaris-Button--iconOnly {
    padding: 0.8rem 0.8rem;
  }

  .Polaris-Button__Icon {
    display: flex;
  }
`;

export const SkeletonContainer = styled.div`
  width: 18.4rem;

  /* OVERRIDEN CSS */
  .Polaris-SkeletonDisplayText--sizeMedium {
    height: 3.6rem !important;
  }

  .Polaris-SkeletonDisplayText__DisplayText {
    max-width: 100%;
  }

  @media (max-width: 45em) {
    width: 12rem;
  }
`;
