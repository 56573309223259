import { Endpoint } from 'states/services/types';

export const METRICS_CUSTOMER_DOWNGRADE_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/customer-downgrade/chart',
  isPrivate: true
};

export const METRICS_CUSTOMER_DOWNGRADE_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/customer-downgrade/breakdown',
  isPrivate: true
};

export const METRICS_CUSTOMER_DOWNGRADE_CHART_V2_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user-v2/customer-downgrade/chart',
  isPrivate: true
};

export const METRICS_CUSTOMER_DOWNGRADE_BREAKDOWN_V2_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user-v2/customer-downgrade/breakdown',
  isPrivate: true
};
