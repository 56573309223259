/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Oct 6th 2021, 4:52:40 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.4rem 2rem;

  & > .Polaris-SkeletonDisplayText--sizeMedium {
    margin-bottom: 4.2rem;
    height: 1.4rem;
    border-radius: var(--p-border-radius-base);
    width: 100%;
    max-width: 100%;
  }

  & > .Polaris-SkeletonDisplayText--sizeMedium:first-child {
    margin-bottom: 3.2rem;
    width: 8.8rem;
    height: 2rem;
  }

  & > .Polaris-SkeletonDisplayText--sizeMedium:last-child {
    margin: 0 auto;
    max-width: 12rem;
    width: 12rem;
    height: 3.6rem;
  }
`;
