import { Endpoint } from 'states/services/types';

export const METRICS_MRR_NEW_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/mrr-new/chart',
  isPrivate: true
};

export const METRICS_MRR_NEW_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/mrr-new/breakdown',
  isPrivate: true
};
