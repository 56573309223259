import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import { buildParams } from 'states/services/metrics';
import { GetMetricRequest } from 'states/services/metrics/types';

import * as Endpoints from './endpoints';
import * as Types from './types';

const mrrNewApi = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    fetchMRRNewChart: builder.query<Types.MRRNewChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_MRR_NEW_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_MRR_NEW_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.MRRNewChartResponse }) => {
        return response.data;
      }
    }),
    fetchMRRNewBreakdown: builder.query<Types.MRRNewBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_MRR_NEW_BREAKDOWN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_MRR_NEW_BREAKDOWN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.MRRNewBreakdownResponse }) => {
        return response.data;
      }
    })
  })
});

export const { useFetchMRRNewBreakdownQuery, useFetchMRRNewChartQuery } = mrrNewApi;
