/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Sat, Jul 24th 2021, 4:33:26 pm
 *
 * *****************************************************
 */

import { configureStore, combineReducers } from '@reduxjs/toolkit';

import { emptyAPI } from './services';
import sliceReducers from './slices';

/**
 * Redux store's root reducer
 */
const rootReducer = combineReducers({
  [emptyAPI.reducerPath]: emptyAPI.reducer, // RTK api reducers
  ...sliceReducers // Redux slice reducers
});

/**
 * Redux store configuration.
 * No new configuration is needed when new Redux slices and RTK APIs are added,
 * but new Redux middlewares will required some changes (feel free to apply).
 */
const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat([emptyAPI.middleware])
});

export default store;
