/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Sat, Jul 31st 2021, 12:38:45 pm
 *
 * *****************************************************
 */

import { Container } from './styles';

type Props = {
  children: React.ReactNode;
};

/**
 * Inner layout for project settings
 */
const ProjectSettingsTabContent = ({ children }: Props): JSX.Element => <Container>{children}</Container>;
export default ProjectSettingsTabContent;
