/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Oct 6th 2021, 12:24:43 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';

export const Container = styled.div`
  display: flex;
  align-items: center;

  p {
    ${focus2Texts.website['Body 2 - 14px']}
    margin-right: 0.8rem;
  }

  .Polaris-Button--outline {
    background-color: ${focus2Colors.surface.neutral.disabled};
    border: 1px solid var(--p-border-neutral-subdued);
  }
`;
