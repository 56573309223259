/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 22nd 2021, 10:29:52 am
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';

export const Container = styled.div<{ isLast: boolean }>`
  padding: 1.2rem 2rem;
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.4rem;
  }

  & > div:last-child {
    margin-bottom: 0;
  }

  ${({ isLast }) =>
    !isLast &&
    `
    box-shadow: inset 0px -1px 0px ${focus2Colors.divider.bottom};
  `}
`;
export const ShopName = styled.span`
  ${focus2Texts.website.heading['H5 - 14px']}
  margin-right: 1.2rem;
`;

export const Message = styled.span`
  ${focus2Texts.website['Body 2 - 14px']}
  color: ${focus2Colors.text.subdued};
  margin-right: 1.2rem;
`;

export const CreatedAt = styled.span`
  ${focus2Texts.website['Caption-Regular']}
  color: ${focus2Colors.text.subdued};
  white-space: nowrap;
`;
