import { Endpoint } from 'states/services/types';

export const METRICS_CREDIT_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/credits/chart',
  isPrivate: true
};

export const METRICS_CREDIT_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/credits/breakdown',
  isPrivate: true
};
