/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Jul 21st 2021, 10:07:48 am
 *
 * *****************************************************
 */

import styled from 'styled-components';

export const Container = styled.div`
  /* OVERRIDES CSS */
  .Polaris-List {
    padding-left: 1.6rem;
  }
`;
