/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Jul 30th 2021, 11:05:06 pm
 *
 * *****************************************************
 */

import { useCallback, useRef, useEffect } from 'react';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { Layout, FormLayout, Form, Button, SkeletonBodyText, TextStyle, Link, Banner, ContextualSaveBar } from '@shopify/polaris';
import { Field, Form as FinalForm } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { MAX_LENGTH_KEY } from 'constants/form';
import { SHOPIFY_PARTNER_ACCESS_TOKEN_LEARN_MORE_LINK } from 'constants/links';
import FormErrorBanner from 'features/common/views/components/FormErrorBanner';
import TextFieldAdapter from 'features/common/views/components/TextFieldAdapter';
import ProjectSettingsTabContent from 'features/project/views/pages/ProjectSettings/ProjectSettingsTabContent';
import { useGetProjectMetricsSettingsQuery, useUpdateProjectMetricsSettingsMutation } from 'states/services/projects/hooks';
import { getFetchBaseQueryErrorStatusCode } from 'utils/fetch-base-query';
import { convertApiErrorsToFinalFormSubmitErrors, ErrorObject, SubmissionResponse } from 'utils/form/convert';
import { deformatKeyOrID, formatKeyOrID } from 'utils/form/format';
import { composeValidators, required, maxLength } from 'utils/form/validation';

const MetricsSettingsTabContent = () => {
  const { projectId } = useParams() as { projectId: string };
  const getProjectMetricsSettingsDataStatus = useGetProjectMetricsSettingsQuery(projectId, {
    refetchOnMountOrArgChange: true
  });
  const [updateProjectMetricsSettings, updateProjectMetricsSettingsStatus] = useUpdateProjectMetricsSettingsMutation();
  const isDataBeingProcessed = Boolean(
    getProjectMetricsSettingsDataStatus.isError &&
      getProjectMetricsSettingsDataStatus.error &&
      getFetchBaseQueryErrorStatusCode(getProjectMetricsSettingsDataStatus.error as FetchBaseQueryError) === 422
  );
  const unsubscribeUpdateMetricsSettingsCache = useRef<() => void>();

  /**
   * Handlers
   */
  const handleFormSubmit = useCallback(
    async values => {
      const updateProjectMetricsSettingsTrigger = updateProjectMetricsSettings({
        id: projectId,
        ...values
      });
      const res = (await updateProjectMetricsSettingsTrigger) as SubmissionResponse;
      unsubscribeUpdateMetricsSettingsCache.current = updateProjectMetricsSettingsTrigger.unsubscribe;

      if (res.error) return convertApiErrorsToFinalFormSubmitErrors(res.error as ErrorObject);

      return undefined;
    },
    [projectId, updateProjectMetricsSettings]
  );

  useEffect(() => {
    /**
     * Switching project won't navigate users to other location
     * but have them be on the same page with new data of the selected project.
     * This behavior won't force useMutation to unsubscribe its lasted mutation data
     * since we're actually still on a same page.
     * Therefore, we have to unsubscribe manually to get rid of the old mutation data
     * which belongs to the previous selected project.
     */
    if (unsubscribeUpdateMetricsSettingsCache && unsubscribeUpdateMetricsSettingsCache.current)
      unsubscribeUpdateMetricsSettingsCache.current();
  }, [projectId]);

  return (
    <FinalForm
      initialValues={getProjectMetricsSettingsDataStatus.data}
      onSubmit={handleFormSubmit}
      render={({ form, values, pristine, submitting, hasSubmitErrors, submitErrors, handleSubmit }) => (
        <ProjectSettingsTabContent>
          <Form onSubmit={handleSubmit}>
            <Layout>
              {getProjectMetricsSettingsDataStatus.isLoading && (
                <Layout.Section>
                  <SkeletonBodyText lines={10} />
                </Layout.Section>
              )}
              {getProjectMetricsSettingsDataStatus.isError && isDataBeingProcessed && (
                <>
                  <div className="banner-container">
                    <Banner status="warning" title="The settings are being used for data processing.">
                      <p>
                        WiseIO needs to keep the settings unchanged while processing your data. The settings will be available
                        once the process is finished.
                      </p>
                    </Banner>
                  </div>
                  <Layout.AnnotatedSection
                    title="Shopify Partner API Connection"
                    description="We need these information to connect to your Shopify Partner API for metrics calculation.">
                    <FormLayout>
                      <Field
                        name="shopifyOrganizationId"
                        label="Shopify Organization ID"
                        helpText="If your partner dashboard URL is partners.shopify.com/1234/apps/5678/, your Shopify Organization ID is 1234."
                        requiredIndicator
                        component={TextFieldAdapter}
                        placeholder="Enter Shopify Organization ID"
                        format={formatKeyOrID}
                        parse={deformatKeyOrID}
                        disabled
                        validate={composeValidators(
                          required('Shopify Organization ID'),
                          maxLength('Shopify Organization ID', MAX_LENGTH_KEY)
                        )}
                      />
                      <Field
                        name="shopifyAppId"
                        component={TextFieldAdapter}
                        label="Shopify App ID"
                        requiredIndicator
                        placeholder="Enter Shopify App ID"
                        helpText="If your partner dashboard URL is partners.shopify.com/1234/apps/5678/, your Shopify App ID is 5678."
                        format={formatKeyOrID}
                        parse={deformatKeyOrID}
                        disabled
                        validate={composeValidators(required('Shopify App ID'), maxLength('Shopify App ID', MAX_LENGTH_KEY))}
                      />
                      <Field
                        name="shopifyApiKey"
                        component={TextFieldAdapter}
                        label="Shopify Partner Access Token"
                        requiredIndicator
                        placeholder="Enter Shopify Partner Access Token"
                        helpText={
                          <span>
                            A Partner API Client is required with the permissions: View financials and Manage apps.{' '}
                            <TextStyle variation="strong">
                              <Link removeUnderline url={SHOPIFY_PARTNER_ACCESS_TOKEN_LEARN_MORE_LINK}>
                                Learn more.
                              </Link>
                            </TextStyle>
                          </span>
                        }
                        disabled
                        format={formatKeyOrID}
                        parse={deformatKeyOrID}
                        validate={composeValidators(
                          required('Shopify Partner Access Token'),
                          maxLength('Shopify Partner Access Token', MAX_LENGTH_KEY)
                        )}
                      />
                    </FormLayout>
                  </Layout.AnnotatedSection>
                  <Layout.AnnotatedSection>
                    <Button primary submit loading={submitting} disabled ariaDescribedBy="project-setting|save-metric-setting">
                      Save Changes
                    </Button>
                    <div className="submit-button-fill-the-rest" />
                  </Layout.AnnotatedSection>
                </>
              )}
              {getProjectMetricsSettingsDataStatus.isSuccess && (
                <>
                  {updateProjectMetricsSettingsStatus.isSuccess && (
                    <div className="banner-container">
                      <Banner status="success" title={`${values.name} info has been updated successfully.`} />
                    </div>
                  )}
                  {hasSubmitErrors && (
                    <div className="banner-container">
                      <FormErrorBanner data={submitErrors} />
                    </div>
                  )}
                  <Layout.AnnotatedSection
                    title="Shopify Partner API Connection"
                    description="We need these information to connect to your Shopify Partner API for metrics calculation.">
                    <FormLayout>
                      <Field
                        name="shopifyOrganizationId"
                        label="Shopify Organization ID"
                        helpText="If your partner dashboard URL is partners.shopify.com/1234/apps/5678/, your Shopify Organization ID is 1234."
                        requiredIndicator
                        component={TextFieldAdapter}
                        placeholder="Enter Shopify Organization ID"
                        format={formatKeyOrID}
                        parse={deformatKeyOrID}
                        validate={composeValidators(
                          required('Shopify Organization ID'),
                          maxLength('Shopify Organization ID', MAX_LENGTH_KEY)
                        )}
                      />
                      <Field
                        name="shopifyAppId"
                        component={TextFieldAdapter}
                        label="Shopify App ID"
                        requiredIndicator
                        placeholder="Enter Shopify App ID"
                        helpText="If your partner dashboard URL is partners.shopify.com/1234/apps/5678/, your Shopify App ID is 5678."
                        format={formatKeyOrID}
                        parse={deformatKeyOrID}
                        disabled
                        validate={composeValidators(required('Shopify App ID'), maxLength('Shopify App ID', MAX_LENGTH_KEY))}
                      />
                      <Field
                        name="shopifyApiKey"
                        component={TextFieldAdapter}
                        label="Shopify Partner Access Token"
                        requiredIndicator
                        placeholder="Enter Shopify Partner Access Token"
                        helpText={
                          <span>
                            A Partner API Client is required with the permissions: View financials and Manage apps.{' '}
                            <TextStyle variation="strong">
                              <Link removeUnderline url={SHOPIFY_PARTNER_ACCESS_TOKEN_LEARN_MORE_LINK}>
                                Learn more.
                              </Link>
                            </TextStyle>
                          </span>
                        }
                        format={formatKeyOrID}
                        parse={deformatKeyOrID}
                        validate={composeValidators(
                          required('Shopify Partner Access Token'),
                          maxLength('Shopify Partner Access Token', MAX_LENGTH_KEY)
                        )}
                      />
                    </FormLayout>
                  </Layout.AnnotatedSection>
                  <Layout.AnnotatedSection>
                    <Button
                      primary
                      submit
                      loading={submitting}
                      disabled={submitting || pristine}
                      ariaDescribedBy="project-setting|save-metric-setting">
                      Save Changes
                    </Button>
                    <div className="submit-button-fill-the-rest" />
                  </Layout.AnnotatedSection>
                  {!pristine && (
                    <ContextualSaveBar
                      message="Unsaved changes"
                      saveAction={{
                        onAction: handleSubmit,
                        loading: submitting,
                        disabled: submitting
                      }}
                      discardAction={{
                        onAction: form.reset,
                        disabled: submitting
                      }}
                    />
                  )}
                </>
              )}
            </Layout>
          </Form>
        </ProjectSettingsTabContent>
      )}
    />
  );
};

export default MetricsSettingsTabContent;
