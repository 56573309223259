/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commerical BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Jul 15th 2021, 8:50:28 am
 *
 * *****************************************************
 */

import React from 'react';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import { OverlayProvider } from 'react-aria';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import CustomPolarisLink from 'features/common/views/components/CustomPolarisLink';
import store from 'states/store';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalStyle from './styles';
import '@shopify/polaris/build/esm/styles.css';

const REACT_APP_ENV = process.env.REACT_APP_ENV!;

const listSupportedEnvironmentForSentry: string[] = ['staging', 'production'];
if (listSupportedEnvironmentForSentry.includes(REACT_APP_ENV)) {
  Sentry.init({
    dsn: 'https://aa5a6e80ff274aa0821c1ca403d5f3e7@o877112.ingest.sentry.io/5980433',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENV,

    tracesSampleRate: 1.0
  });
}

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/browser');
  worker.start({
    onUnhandledRequest: 'bypass'
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppProvider i18n={enTranslations} linkComponent={CustomPolarisLink}>
        <GlobalStyle />
        <OverlayProvider>
          <Router>
            <App />
          </Router>
        </OverlayProvider>
      </AppProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
