import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';

export const Container = styled.div`
  background: ${focus2Colors.background.default};
  padding: 2rem;
  border-radius: 0.8rem;
`;

export const SelectGroup = styled.div`
  display: flex;
  gap: 1.2rem;
  & > div {
    flex: 1 1 0px;
  }
`;

export const ActivatorContainer = styled.div`
  & > button > .Polaris-Button__Content {
    font-weight: 400;
    justify-content: start;
  }
`;

export const Label = styled.div`
  margin-bottom: 0.4rem;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 0.8rem;
  margin-top: 2rem;
  justify-content: end;
`;

export const PopoverFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DatePickerContainer = styled.div`
  max-width: 25rem;
  @media (max-width: 64em) {
    width: 100%;
  }
`;
