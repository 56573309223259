import { Endpoint } from 'states/services/types';

export const METRICS_ACTIVE_USER_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/active-user/active-user-chart',
  isPrivate: true
};

export const METRICS_NEW_USER_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/active-user/new-user-chart',
  isPrivate: true
};
