/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 26th 2021, 1:24:49 pm
 *
 * *****************************************************
 */

import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import {
  GetProjectListResquest,
  GetProjectListResponse,
  CreateProjectRequest,
  UpdateProjectQueryObject,
  UpdateProjectMetricsSettingQueryObject
} from 'states/services/projects/types';
import { providesList, PROJECT_TAG, PROJECT_METRICS_SETTINGS_TAG } from 'states/services/tags';
import { ProjectMetricsSettings, Project } from 'states/slices/projects/types';

import * as projectsEndpoints from './endpoints';

/**
 * An api for current workspace's project list
 * create by extending (injecting) empty api with project list endpoints
 */
export const projectsAPI = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    getProjectList: builder.query<GetProjectListResponse, GetProjectListResquest>({
      query: ({ page, limit, search }) => {
        const params = new URLSearchParams({
          page: page.toString(),
          limit: limit.toString(),
          search
        });
        return `${projectsEndpoints.PROJECTS_ENDPOINT.url}?${params.toString()}`;
      },
      extraOptions: {
        isPrivate: projectsEndpoints.PROJECTS_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: GetProjectListResponse }) => response.data,
      providesTags: result => providesList(result && result.projects, PROJECT_TAG)
    }),
    getProject: builder.query<Project, string>({
      query: id => `${projectsEndpoints.PROJECTS_ENDPOINT.url}/${id}`,
      extraOptions: {
        isPrivate: projectsEndpoints.PROJECTS_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Project }) => response.data,
      providesTags: (result, error, id) => [{ type: PROJECT_TAG, id }]
    }),
    createProject: builder.mutation<Project, CreateProjectRequest>({
      query: body => ({
        url: projectsEndpoints.PROJECTS_ENDPOINT.url,
        method: 'POST',
        body
      }),
      extraOptions: {
        isPrivate: projectsEndpoints.PROJECTS_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Project }) => response.data,
      invalidatesTags: (result, error) => (!error ? [{ type: PROJECT_TAG, id: 'LIST' }] : [])
    }),
    updateProject: builder.mutation<Project, UpdateProjectQueryObject>({
      query: data => {
        const { id, ...rest } = data;
        const form = new FormData();
        Object.entries(rest).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach(item => form.append(`${key}[]`, item));
          } else form.append(key, value);
        });
        return {
          url: generatePath(projectsEndpoints.PROJECTS_GENERAL_SETTINGS_ENDPOINT.url, {
            projectId: id
          }),
          method: 'PUT',
          body: form
        };
      },
      extraOptions: { isPrivate: projectsEndpoints.PROJECTS_GENERAL_SETTINGS_ENDPOINT.isPrivate },
      transformResponse: (response: { data: Project }) => response.data,
      invalidatesTags: (result, error, { id }) => (!error ? [{ type: PROJECT_TAG, id }] : [])
    }),
    getProjectMetricsSettings: builder.query<ProjectMetricsSettings, string>({
      query: id => generatePath(projectsEndpoints.PROJECTS_METRICS_SETTINGS_ENDPOINT.url, { projectId: id }),
      extraOptions: {
        isPrivate: projectsEndpoints.PROJECTS_METRICS_SETTINGS_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: ProjectMetricsSettings }) => response.data,
      providesTags: (result, error, id) => [{ type: PROJECT_METRICS_SETTINGS_TAG, id }]
    }),
    updateProjectMetricsSettings: builder.mutation<ProjectMetricsSettings, UpdateProjectMetricsSettingQueryObject>({
      query: data => {
        const { id, ...rest } = data;
        const form = new FormData();
        Object.entries(rest).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach(item => form.append(`${key}[]`, item));
          } else form.append(key, value);
        });
        return {
          url: generatePath(projectsEndpoints.PROJECTS_METRICS_SETTINGS_ENDPOINT.url, {
            projectId: id
          }),
          method: 'PUT',
          body: form
        };
      },
      extraOptions: {
        isPrivate: projectsEndpoints.PROJECTS_METRICS_SETTINGS_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: ProjectMetricsSettings }) => response.data,
      invalidatesTags: (result, error, { id }) => (!error ? [{ type: PROJECT_METRICS_SETTINGS_TAG, id }] : [])
    }),
    getProjectBasicInfo: builder.query<Project, string>({
      query: id => generatePath(projectsEndpoints.PROJECTS_BASIC_INFO_ENDPOINT.url, { projectId: id }),
      extraOptions: {
        isPrivate: projectsEndpoints.PROJECTS_BASIC_INFO_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Project }) => response.data,
      providesTags: (result, error, id) => [{ type: PROJECT_TAG, id }]
    })
  })
});
