import { Endpoint } from 'states/services/types';

export const METRICS_UNINSTALL_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/installation/uninstall/chart',
  isPrivate: true
};

export const METRICS_UNINSTALL_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/installation/uninstall/breakdown',
  isPrivate: true
};
