/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 26th 2021, 10:40:18 pm
 *
 * *****************************************************
 */

export const SWITCH_WORKSPACE_LINK = `${process.env.HTTPS ? 'https' : 'http'}://${
  process.env.REACT_APP_AUTH_DOMAIN
}/select-workspace`;
export const HELP_CENTER_LINK = 'https://intercom.help/wiseio/en/';
export const SHOPIFY_PARTNER_ACCESS_TOKEN_LEARN_MORE_LINK =
  'https://intercom.help/wiseio/en/articles/5504532-connect-to-shopify-partners-apps';
export const REPORT_A_BUG_LINK = 'https://wise.canny.io/bug-report';
export const SUGGEST_A_FEATURE_LINK = 'https://wise.canny.io/feature-requests';
