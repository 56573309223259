/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Jul 30th 2021, 4:26:01 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';

export const Container = styled.div`
  /* OVERRIDEN CSS */
  --p-surface: ${focus2Colors.onDark.normal};
  --p-action-secondary-hovered: ${focus2Colors.onDark.hovered};
  --p-action-secondary-pressed: ${focus2Colors.onDark.pressed};
  --p-text: ${focus2Colors.textOn.critical};
  --p-icon: ${focus2Colors.icon.disabled};

  .Polaris-Button:hover .Polaris-Icon svg {
    fill: white;
  }

  .Polaris-Button {
    height: 3.6rem;
    border: none;
    padding: 0.6rem 1.2rem;
  }

  .Polaris-Button__Content {
    justify-content: flex-start;
  }

  .Polaris-Button__Content > *:last-child {
    margin-left: auto;
    margin-right: -0.4rem;
  }

  .Polaris-Button__Icon + *:not(.Polaris-Button__Icon) {
    margin-left: 0.8rem;
  }

  .Polaris-Button__Text {
    max-width: calc(100% - 5.1rem);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (min-width: 32.4375em) {
    .Polaris-Button {
      width: 18.4rem;
    }
  }
  @media (max-width: 32.4375em) {
    .Polaris-Button {
      max-width: 23.6rem;
    }
    .Polaris-Button__Text {
      max-width: calc(23.6rem - 5.1rem);
    }
  }

  @media (max-width: 23.6875em) {
    .Polaris-Button {
      max-width: 18.5rem;
    }
    .Polaris-Button__Text {
      max-width: calc(18.5rem - 5.1rem);
    }
  }
`;

export const SkeletonContainer = styled.div`
  width: 18.4rem;

  /* OVERRIDEN CSS */
  .Polaris-SkeletonDisplayText--sizeMedium {
    height: 3.6rem !important;
  }

  .Polaris-SkeletonDisplayText__DisplayText {
    max-width: 100%;
  }

  @media (max-width: 45em) {
    width: 12rem;
  }
`;

export const AvatarPrefixContainer = styled.div`
  & > div {
    width: 2rem;
    height: 2rem;
  }
`;
