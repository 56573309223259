/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Oct 4th 2021, 1:53:02 pm
 *
 * *****************************************************
 */

import BASE_BLOCKS, { MetricName } from 'constants/metrics/base-blocks';
import BarChart, { BarChartData } from 'features/project/views/components/BarChart';
import DoughnutChart, { DoughnutChartData } from 'features/project/views/components/DoughnutChart';
import DynamicLineChart, { DynamicLineChartData } from 'features/project/views/components/DynamicLineChart';
import LineChart, { LineChartData } from 'features/project/views/components/LineChart';
import { SizeChart } from 'features/project/views/pages/ProjectMetric/MetricCard/MetricChartCard';

export type MetricChartData =
  | LineChartData // [{key: '2021-01-01' data: 12}]
  | BarChartData // [{key: '2021-01-01' data: 12}]
  | DoughnutChartData // [{key: '2021-01-01' data: 12}]
  | DynamicLineChartData; // [[{key: '2021-01-01' data: 12}], [{key: '2021-01-01' data: 12}]]

export type Props = {
  metricName: MetricName;
  data: MetricChartData;
  legends: string[];
  bigNumbers?: number[]; // for doughnut chart
  size?: SizeChart;
};

const MetricChart = ({ metricName, data, legends, bigNumbers = [], size = 'medium' }: Props): JSX.Element | null => {
  const { chartConfig, metricValueFormatter } = BASE_BLOCKS[metricName];

  if (!chartConfig) return null;

  switch (chartConfig.type) {
    case 'LINE_CHART':
      return <LineChart metricValueFormatter={metricValueFormatter} data={data} legends={legends} config={chartConfig} />;
    case 'BAR_CHART':
      return <BarChart metricValueFormatter={metricValueFormatter} data={data} legends={legends} config={chartConfig} />;
    case 'DOUGHNUT_CHART':
      return (
        <DoughnutChart
          bigNumbers={bigNumbers}
          metricValueFormatter={metricValueFormatter}
          data={data}
          config={chartConfig}
          size={size}
          legends={legends}
          metricName={metricName}
        />
      );
    case 'DYNAMIC_LINE_CHART':
      return (
        <DynamicLineChart
          legends={legends}
          config={chartConfig}
          data={data as DynamicLineChartData}
          metricValueFormatter={metricValueFormatter}
        />
      );
    default:
      return null;
  }
};

export default MetricChart;
