/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Aug 12th 2021, 12:53:13 pm
 *
 * *****************************************************
 */

import { useEffect } from 'react';

import { setTotalCount } from 'states/slices/projects';
import { useAppDispatch } from 'utils/hooks';

import { projectsAPI } from './index';
import { GetProjectListResquest, GetProjectListResponse } from './types';

const {
  useGetProjectListQuery: useGetProjectListQueryOriginal,
  useGetProjectQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useGetProjectMetricsSettingsQuery,
  useUpdateProjectMetricsSettingsMutation,
  useGetProjectBasicInfoQuery
} = projectsAPI;

const useGetProjectListQuery: typeof useGetProjectListQueryOriginal = (...args) => {
  const getProjectListQueryStatus = useGetProjectListQueryOriginal(...args);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const requestedData = args[0] as GetProjectListResquest;
    if (requestedData.search === '' && getProjectListQueryStatus.isSuccess) {
      const responseData = getProjectListQueryStatus.data as GetProjectListResponse;
      const { count } = responseData;
      dispatch(setTotalCount(count));
    }
  }, [args, getProjectListQueryStatus.isSuccess, getProjectListQueryStatus.data, dispatch]);

  return getProjectListQueryStatus;
};

export {
  useGetProjectQuery,
  useGetProjectListQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useGetProjectMetricsSettingsQuery,
  useUpdateProjectMetricsSettingsMutation,
  useGetProjectBasicInfoQuery
};
