import { Endpoint } from 'states/services/types';

export const METRICS_MRR_REACTIVATION_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/mrr-reactivation/reactivation-chart',
  isPrivate: true
};

export const METRICS_OTHER_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/mrr-reactivation/other-chart',
  isPrivate: true
};

export const METRICS_MRR_REACTIVATION_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/revenue/mrr-reactivation/breakdown',
  isPrivate: true
};
