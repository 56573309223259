/**
 * Copyright (C) Boost commerce
 * This file is part of commercial Boost commerce projects
 *
 * This file can not be copied and/or distributed without the express
 * permission of Boost commerce
 *
 * Created on Thu Jan 11 2024 18:04:25
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import texts from 'constants/texts';

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  main {
    width: 100%;
    min-height: calc(100vh - 5.6rem);
    padding-top: 5.6rem;
  }
`;

export const TopBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
  z-index: 50;
  height: 5.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.2rem;
  background-color: ${focus2Colors.onDark.default};
`;

export const LogoButtonContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 23.6875em) {
    flex-grow: 1;

    .Polaris-Stack {
      width: 100%;
    }

    .Polaris-Stack > *:last-child {
      flex-grow: 1;
    }
  }
`;

export const Nav = styled.nav`
  ${texts.body['Body 1 - 14px - SB']}
  margin-left: 4.4rem;

  display: flex;

  & > *:not(:first-child) {
    margin-left: 2rem;
  }

  @media (max-width: 32.4375em) {
    display: none;
  }

  /* OVERRIDEN CSS */
  --p-interactive: ${focus2Colors.textOn.critical};
  --p-interactive-hovered: ${focus2Colors.base.label};
  --p-interactive-pressed: ${focus2Colors.base.label};
`;
