/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Aug 6th 2021, 3:16:33 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import texts from 'constants/texts';

export const Container = styled.div`
  display: flex;
  align-items: center;

  /* OVERRIDEN CSS */
  --p-surface: ${focus2Colors.onDark.normal};
  --p-action-secondary-hovered: ${focus2Colors.onDark.hovered};
  --p-action-secondary-pressed: ${focus2Colors.onDark.pressed};

  .Polaris-Button {
    border: none;
  }

  .Polaris-Button--iconOnly {
    padding: 0.8rem 0.8rem;
  }

  .Polaris-Button__Icon {
    display: flex;
  }
`;

export const WorkspaceName = styled.span`
  ${texts.body['Body 1 - 14px - SB']}
  color: #fff;
  margin-left: 1rem;

  @media (max-width: 47.5em) {
    display: none;
  }
`;
