/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Sun, Jul 25th 2021, 9:10:25 am
 *
 * *****************************************************
 */
export const AUTH_TYPE = 'Bearer';
