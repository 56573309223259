/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Oct 7th 2021, 7:57:36 am
 *
 * *****************************************************
 */

import styled from 'styled-components';

// import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';
import { SizeChart } from 'features/project/views/pages/ProjectMetric/MetricCard/MetricChartCard';

export const Container = styled.div<{ size: SizeChart }>`
  display: flex;
  /* justify-content: center;
  align-items: center; */
  flex-wrap: wrap;
  ${({ size }) => {
    switch (size) {
      case 'small':
        return 'gap: 3.2rem;';
      case 'medium':
        return 'gap: 8rem;';
      case 'large':
        return 'gap: 8rem;';
      default:
        return '';
    }
  }};

  @media (max-width: 37.5em) {
    width: 100%;
  }
`;

export const NodataBlock = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChartContainer = styled.div`
  width: 216px;
  position: relative;
`;

export const Total = styled.div`
  &:hover > div {
    display: block;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${focus2Texts.website.heading['H1-32px']}
`;

export const TooltipContainer = styled.div`
  position: absolute;
  top: 45%;
  left: 45%;

  display: none;
`;

export const Legend: Record<string, any> = {};

Legend.Table = styled.div`
  width: 240px;
  display: flex;
  flex-direction: column;

  @media (max-width: 37.5em) {
    width: 100%;
  }
`;

Legend.Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  width: 100%;
`;

Legend.Label = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${focus2Texts.website['Body 2 - 14px']}
`;

Legend.Value = styled.div`
  ${focus2Texts.website.heading['H5 - 14px']}
  width: 63px;
  margin-left: auto;
  text-align: end;
`;

Legend.Color = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;
