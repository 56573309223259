/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Jul 26th 2021, 2:17:21 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import texts from 'constants/texts';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 1.3rem;
  padding-bottom: 2.2rem;

  img {
    width: 20rem;
    height: auto;
    margin-bottom: 1.5rem;
  }

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;
    h2 {
      ${texts.body['Body 2 - 17px - SB']}
      margin-bottom: 1rem;
    }
  }
`;
