/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Sun, Jul 25th 2021, 11:50:10 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import illustrationPath from 'assets/images/welcome.png';
import texts from 'constants/texts';

export const Container = styled.section`
  margin-bottom: -1.5rem;
  width: 100%;
`;

export const SkeletonContainer = styled.header`
  width: 100%;
  margin-top: 1.5rem;

  margin-bottom: 1.5rem;
`;

export const ContentContainer = styled.header`
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  padding-bottom: 6.4rem;

  h1 {
    ${texts.heading['Heading 2 - 24px - SB']}
    margin-bottom: 0.5rem;
  }

  background-image: url(${illustrationPath});
  background-size: 30% auto;
  background-position: right bottom;
  background-repeat: no-repeat;

  @media (max-width: 47.5em) {
    padding-bottom: 1.5rem;
    background-size: contain;
  }
  @media (max-width: 34.25em) {
    background: none;
  }
`;
