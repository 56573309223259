import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import { buildParams } from 'states/services/metrics';
import { GetMetricRequest } from 'states/services/metrics/types';

import * as Endpoints from './endpoints';
import * as Types from './types';

const customerCancellationApi = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    fetchCustomerCancellationChart: builder.query<Types.CustomerCancellationChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_CUSTOMER_CANCELLATION_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_CUSTOMER_CANCELLATION_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.CustomerCancellationChartResponse }) => {
        return response.data;
      }
    }),
    fetchCustomerCancellationOtherChart: builder.query<Types.OtherChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_CUSTOMER_CANCELLATION_OTHER_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_CUSTOMER_CANCELLATION_OTHER_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.OtherChartResponse }) => {
        return response.data;
      }
    }),
    fetchCustomerCancellationBreakdown: builder.query<Types.CustomerCancellationBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_CUSTOMER_CANCELLATION_BREAKDOWN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_CUSTOMER_CANCELLATION_BREAKDOWN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.CustomerCancellationBreakdownResponse }) => {
        return response.data;
      }
    }),
    fetchCustomerCancellationChartV2: builder.query<Types.CustomerCancellationChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_CUSTOMER_CANCELLATION_CHART_V2_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_CUSTOMER_CANCELLATION_CHART_V2_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.CustomerCancellationChartResponse }) => {
        return response.data;
      }
    }),
    fetchCustomerCancellationOtherChartV2: builder.query<Types.OtherChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_CUSTOMER_CANCELLATION_OTHER_CHART_V2_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_CUSTOMER_CANCELLATION_OTHER_CHART_V2_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.OtherChartResponse }) => {
        return response.data;
      }
    }),
    fetchCustomerCancellationBreakdownV2: builder.query<Types.CustomerCancellationBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_CUSTOMER_CANCELLATION_BREAKDOWN_V2_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_CUSTOMER_CANCELLATION_BREAKDOWN_V2_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.CustomerCancellationBreakdownResponse }) => {
        return response.data;
      }
    })
  })
});

export const {
  useFetchCustomerCancellationBreakdownQuery,
  useFetchCustomerCancellationChartQuery,
  useFetchCustomerCancellationOtherChartQuery,
  useFetchCustomerCancellationBreakdownV2Query,
  useFetchCustomerCancellationChartV2Query,
  useFetchCustomerCancellationOtherChartV2Query
} = customerCancellationApi;
