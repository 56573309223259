/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Jul 22nd 2021, 9:37:44 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import colors from 'constants/colors';
import texts from 'constants/texts';

export const LayoutContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 3.8rem;
  }
  @media (max-width: 35em) {
    main {
      padding-left: 1.8rem;
      padding-right: 1.8rem;
    }
  }

  /* OVERRIDEN CSS */
  --p-action-primary: ${colors.primary.lightColoredAction};
  --p-interactive: ${colors.interactive.lightColoredDefault};
`;

export const LayoutHeader = styled.header`
  padding: 6.3rem 0 4.5rem;
  svg {
    width: 12.9rem;
    height: auto;
  }
  @media (max-width: 35em) {
    padding-top: 3.8rem;
  }
`;

export const LayoutBlock = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: auto;
    margin-bottom: 3rem;
  }

  header {
    text-align: center;
    margin-bottom: 3rem;

    h1 {
      ${texts.heading['Heading 2 - 24px - SB']}
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 35em) {
    width: 100%;
  }

  @media (max-width: 35em) {
    img {
      width: 60%;
    }
  }
`;
