/* eslint-disable no-useless-return */
import { memo } from 'react';

import { ChartOptions } from 'chart.js';
import _ from 'lodash';
import { Doughnut } from 'react-chartjs-2';

import { ChartConfig, MetricValueFormatter } from 'constants/metrics/base-blocks';
import {
  ChartContainer,
  Container,
  Legend,
  Total,
  TooltipContainer,
  NodataBlock
} from 'features/project/views/components/DoughnutChart/styles';
import Tooltip from 'features/project/views/components/DoughnutChart/Tooltip';
import { SizeChart } from 'features/project/views/pages/ProjectMetric/MetricCard/MetricChartCard';

export type DoughnutChartData = Record<string, any>;

type Props = {
  size: SizeChart;
  legends: string[];
  metricName: string;
  config: ChartConfig;
  bigNumbers: number[];
  data: DoughnutChartData;
  metricValueFormatter: MetricValueFormatter;
};

const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
  }
  tooltipEl.style.background = '#fff';
  tooltipEl.style.borderRadius = '4px';
  tooltipEl.style.color = 'black';
  tooltipEl.style.boxShadow = '0px 0px 5px 2px rgba(0, 0, 0, 0.1), inset 0px -1px 0px #E4E5E7';
  tooltipEl.style.opacity = 1;
  tooltipEl.style.zIndex = 456;
  tooltipEl.style.width = 'max-content';
  tooltipEl.style.pointerEvents = 'none';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.transform = 'translate(-50%, 0)';
  tooltipEl.style.transition = 'all .1s ease';
  let table = tooltipEl.querySelector('table');
  if (!table) {
    table = document.createElement('table');
  }
  table.style.margin = '0px';
  tooltipEl.appendChild(table);
  chart.canvas.parentNode.appendChild(tooltipEl);
  return tooltipEl;
};

/**
 * only support one datasets on this version
 */
const DoughnutChart = ({ metricValueFormatter, data, config, bigNumbers, size, legends, metricName }: Props) => {
  if (config.type !== 'DOUGHNUT_CHART') return null;
  const labelForDataField = _.get(config, 'labelField', '');
  const labels = _(data).map(labelForDataField).value();

  const datasets = _(config.datasets)
    .map(({ dataField, backgroundColors }, index) => {
      return {
        data: _(data).map(dataField).value(),
        legend: legends[index],
        backgroundColor: backgroundColors,
        borderWidth: 0,
        dataField
      };
    })
    .compact()
    .value();

  /**
   * configuration for doughnut chart
   */
  const options: ChartOptions = {
    responsive: true,
    plugins: {
      /**
       * default legend of chart.js doesn't meet the design, have to custom legend
       */
      legend: { display: false },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external(context) {
          const chart = _.get(context, 'chart');
          const tooltip = _.get(context, 'tooltip');
          const tooltipElement = getOrCreateTooltip(chart);

          // hide if no tooltip
          if (tooltip.opacity === 0) {
            tooltipElement.style.opacity = 0;
            return;
          }
          if (tooltip.dataPoints) {
            const titleLines = _.get(tooltip, 'title', []);
            const bodyLines = _(tooltip.dataPoints)
              .map(({ label }) => {
                const index = _(labels).indexOf(label);
                return _(datasets)
                  .map(({ legend, data: dataChart }) => ({
                    label,
                    data: dataChart[index],
                    legend
                  }))
                  .value();
              })
              .value();
            const tableHead = document.createElement('thead');
            titleLines.forEach(title => {
              const tr = document.createElement('tr');
              tr.style.borderWidth = '0px';
              const th = document.createElement('th');
              th.style.borderWidth = '0px';
              const text = document.createTextNode(title);
              th.appendChild(text);
              tr.appendChild(th);
              tableHead.appendChild(tr);
            });

            const tableBody = document.createElement('tbody');
            bodyLines.forEach((body, i) => {
              const background = _.get(tooltip, `labelColors[${i}].backgroundColor`);
              const borderColor = _.get(tooltip, `labelColors[${i}].borderColor`);
              body.forEach(({ data: dataChart, legend }, index) => {
                const span = document.createElement('span');
                span.style.background = background;
                span.style.borderColor = borderColor;
                span.style.borderRadius = '2px';
                span.style.borderWidth = '2px';
                span.style.marginRight = '5px';
                span.style.height = '12px';
                span.style.width = '12px';
                span.style.display = 'inline-block';

                const row = document.createElement('tr');
                row.style.backgroundColor = 'inherit';
                row.style.borderWidth = '0px';
                const labelTd = document.createElement('td');
                if (index !== 0) {
                  labelTd.appendChild(document.createElement('hr'));
                }
                labelTd.appendChild(document.createTextNode(`${legend}`));
                labelTd.appendChild(document.createElement('br'));
                labelTd.appendChild(span);
                labelTd.appendChild(document.createTextNode(`: ${metricValueFormatter(dataChart)}`));
                row.appendChild(labelTd);
                tableBody.appendChild(row);
              });
            });
            const tableRoot = tooltipElement.querySelector('table');

            // Remove old children
            while (tableRoot.firstChild) {
              tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot.appendChild(tableHead);
            tableRoot.appendChild(tableBody);
          }
          const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
          // Display, position, and set styles for font
          tooltipElement.style.opacity = '1px';
          tooltipElement.style.left = `${positionX + tooltip.caretX}px`;
          tooltipElement.style.top = `${positionY + tooltip.caretY}px`;
          tooltipElement.style.font = 'italic bold 13px arial,serif';
          tooltipElement.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;
        }
      }
    }
  };

  /**
   * custom legends for doughnut chart
   */
  const customLegends = (() => {
    /**
     * every legend table has 6 item, chunk it into many table if has more than 6 item
     */
    const backgroundColors = _.get(datasets, '0.backgroundColor', []);
    const dataField = _.get(datasets, '0.dataField', []);
    const legendGroup = _(data)
      .map((item, index) => ({ ...item, color: backgroundColors[+index] }))
      .chunk(6)
      .value();
    return legendGroup.map((group, index) => {
      return (
        <Legend.Table key={index.toString()}>
          {group.map((item, ind) => {
            return (
              <Legend.Row key={ind.toString()}>
                <Legend.Color color={item.color} />
                <Legend.Label>{item.key}</Legend.Label>
                <Legend.Value>{metricValueFormatter(item[dataField])}</Legend.Value>
              </Legend.Row>
            );
          })}
        </Legend.Table>
      );
    });
  })();

  if (_.isEmpty(_.get(datasets, '0.data'))) {
    return (
      <Container size={size}>
        <NodataBlock>Has no data</NodataBlock>
      </Container>
    );
  }

  return (
    <Container size={size}>
      <ChartContainer>
        <div>
          <Doughnut options={options} data={{ labels, datasets: [datasets[0]] }} />
        </div>
        {_.isNumber(bigNumbers[0]) && (
          <Total>
            {bigNumbers[0]}
            <TooltipContainer>
              <Tooltip metricName={metricName} data={bigNumbers} legends={legends} />
            </TooltipContainer>
          </Total>
        )}
      </ChartContainer>
      {customLegends}
    </Container>
  );
};

export default memo(DoughnutChart);
