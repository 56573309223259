import { Endpoint } from 'states/services/types';

export const METRICS_INSTALL_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/installation/install/chart',
  isPrivate: true
};

export const METRICS_INSTALL_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/installation/install/breakdown',
  isPrivate: true
};

export const METRICS_INSTALL_GROWTH_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/installation/install/growth-chart',
  isPrivate: true
};
