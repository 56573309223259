import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import { buildParams } from 'states/services/metrics';
import { GetMetricRequest } from 'states/services/metrics/types';

import * as Endpoints from './endpoints';
import * as Types from './types';

const activeUserApi = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    fetchActiveUserChart: builder.query<Types.ActiveUserChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_ACTIVE_USER_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_ACTIVE_USER_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.ActiveUserChartResponse }) => {
        return response.data;
      }
    }),
    fetchNewUserChart: builder.query<Types.NewUserChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_NEW_USER_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_NEW_USER_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.NewUserChartResponse }) => {
        return response.data;
      }
    })
  })
});

export const { useFetchActiveUserChartQuery, useFetchNewUserChartQuery } = activeUserApi;
