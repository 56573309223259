/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Aug 10th 2021, 4:42:20 pm
 *
 * *****************************************************
 */

import React, { useRef } from 'react';

import { FocusScope, OverlayContainer, useDialog, useModal, usePreventScroll } from 'react-aria';

import { Container } from './styles';

type FullScreenModalContentProps = {
  children: JSX.Element;
};

const FullScreenModalContent = ({ children }: FullScreenModalContentProps): JSX.Element => {
  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll();
  return children;
};

type FullScreenModalContextType = {
  titleProps: object;
};

export const FullScreenModalContext = React.createContext<FullScreenModalContextType>({
  titleProps: {}
});

type Props = {
  open?: boolean;
  children: JSX.Element;
};

/**
 * Full screen modal component
 */
const FullScreenModal = ({ open = false, children }: Props): JSX.Element | null => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const { dialogProps, titleProps } = useDialog({}, dialogRef);
  const { modalProps } = useModal();

  if (!open) return null;

  return (
    <OverlayContainer portalContainer={document.getElementById('PolarisPortalsContainer')!}>
      <FocusScope contain restoreFocus>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Container ref={dialogRef} {...dialogProps} {...modalProps}>
          <FullScreenModalContext.Provider
            value={{
              titleProps
            }}>
            <FullScreenModalContent>{children}</FullScreenModalContent>
          </FullScreenModalContext.Provider>
        </Container>
      </FocusScope>
    </OverlayContainer>
  );
};

export default FullScreenModal;
