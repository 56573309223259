import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import { buildParams } from 'states/services/metrics';
import { GetMetricRequest } from 'states/services/metrics/types';

import * as Endpoints from './endpoints';
import * as Types from './types';

const lifetimeValueApi = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    fetchLifetimeValueChart: builder.query<Types.LifetimeValueChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_LIFETIME_VALUE_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_LIFETIME_VALUE_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.LifetimeValueChartResponse }) => {
        return response.data;
      }
    }),
    fetchLifetimeValueBreakdown: builder.query<Types.LifetimeValueBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_LIFETIME_VALUE_BREAKDOWN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_LIFETIME_VALUE_BREAKDOWN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.LifetimeValueBreakdownResponse }) => {
        return response.data;
      }
    })
  })
});

export const { useFetchLifetimeValueBreakdownQuery, useFetchLifetimeValueChartQuery } = lifetimeValueApi;
