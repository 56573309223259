import { Endpoint } from 'states/services/types';

export const METRICS_REOPEN_CHART_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/installation/reopen/chart',
  isPrivate: true
};

export const METRICS_REOPEN_BREAKDOWN_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/installation/reopen/breakdown',
  isPrivate: true
};
