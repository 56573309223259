/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Thu, Sep 30th 2021, 4:28:23 pm
 *
 * *****************************************************
 */
import { useContext, ComponentProps } from 'react';

import { NavLink as ReactRouterNavLink } from 'react-router-dom';

import { SidebarContext } from 'features/project/views/pages/ProjectMetric/Sidebar/context';

type Props = ComponentProps<typeof ReactRouterNavLink>;

const NavLink: React.FC<Props> = (props: Props) => {
  const { onDeactivateSmallScreenSidebar } = useContext(SidebarContext);
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ReactRouterNavLink {...props} onClick={onDeactivateSmallScreenSidebar} />;
};

export default NavLink;
