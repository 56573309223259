/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Sep 21st 2021, 5:27:17 am
 *
 * *****************************************************
 */

import NavLink from 'features/project/views/pages/ProjectMetric/Sidebar/NavLink';

import { Container, Content } from './styles';
import SubItem, { Props as SubItemProps } from './SubItem';

export type Props = {
  icon: React.ElementType;
  label: string;
  url: string;
  subItems?: SubItemProps[];
};

const Item = ({ icon: Icon, label, url, subItems }: Props): JSX.Element => {
  const hasSubItems = !!subItems?.length;

  return (
    <Container hasSubItems={hasSubItems}>
      <NavLink to={url} activeClassName="BC-WiseIO-Sidebar--navigationActive">
        <Content>
          <Icon />
          <span>{label}</span>
        </Content>
      </NavLink>
      {hasSubItems && (
        <ul>
          {subItems!.map(subItem => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <SubItem key={subItem.url} {...subItem} />
          ))}
        </ul>
      )}
    </Container>
  );
};
export default Item;
