/* eslint-disable react/jsx-props-no-spreading */
/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 20th 2021, 9:54:06 am
 *
 * *****************************************************
 */

import { ComponentType } from 'react';

import { Route, Redirect, generatePath } from 'react-router-dom';

import withSilentRefresh from 'features/common/views/HOCs/withSilentRefresh';

/**
 * Route definition
 */
export type RouteDef = {
  path: string | string[]; // Matched path
  exact?: boolean; // Match path exactly,
  component?: { (props: any): JSX.Element }; // Component to render
  redirect?: string | null; // Redirecting path
  isPrivate?: boolean; // Is private route,
  silentRefreshLoadingComponent?: ComponentType<any>; // Component to show when a private component is getting new credentials
};

/**
 * Create an array of Route components based on the given route definitions
 * @example
 * import { Switch } from 'react-router-dom';
 * import WorkspaceDashboard from '...';
 * //...
 * const routeDefs = [
 *  {
 *    path: '/',
 *    exact: true,
 *    component: WorkspaceDashboard,
 *  },
 *  // other route definition objects
 * ]
 * //...
 * function App() {
 *  return <Switch>{renderRoutes(routeDefs)}</Switch>
 * }
 */

export const renderRoutes = (routeDefs: RouteDef[]): JSX.Element[] => {
  return routeDefs.map(({ path, exact = true, component, redirect = null, isPrivate = false, silentRefreshLoadingComponent }) => {
    if (redirect !== null) {
      return (
        <Route
          key={path.toString()}
          exact={exact}
          path={path}
          render={({ match }): JSX.Element => <Redirect to={generatePath(redirect, match.params)} />}
        />
      );
    }
    const Component = isPrivate ? withSilentRefresh(component!, silentRefreshLoadingComponent) : component!;
    return <Route key={path.toString()} exact={exact} path={path} render={(props): JSX.Element => <Component {...props} />} />;
  });
};
