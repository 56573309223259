/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 27th 2021, 11:00:30 am
 *
 * *****************************************************
 */
import { useContext } from 'react';

import { Stack } from '@shopify/polaris';
import { Field } from 'react-final-form';

import { ReactComponent as PublicLogo } from 'assets/images/group.svg';
import { ReactComponent as PrivateLogo } from 'assets/images/lock.svg';
import { PROJECT_VISIBILITY_VALUE_PRIVATE, PROJECT_VISIBILITY_VALUE_PUBLIC } from 'constants/project';
import FormErrorBanner from 'features/common/views/components/FormErrorBanner';
import { FormStateContext } from 'features/common/views/components/FullScreenModalForm';
import CreateProjectContent from 'features/project/views/components/CreateProjectModal/CreateProjectContent';
import { workspaceAPI } from 'states/services/workspace';
import { Workspace } from 'states/slices/workspace/types';

import CustomRadioButtonAdapter from './CustomRadioButtonAdapter';
import { Container } from './styles';

const Step2 = (): JSX.Element => {
  const { data: workspaceData } = workspaceAPI.endpoints.getWorkspaceBasicInfo.useQueryState();
  const { name: workspaceName } = (workspaceData as Workspace) ?? { name: '' };
  const { submitErrors } = useContext(FormStateContext);

  return (
    <CreateProjectContent
      title="Configure sharing & permissions"
      description="When a project is set to private, it can only be accessed by invitation.">
      <Container>
        {submitErrors && <FormErrorBanner data={submitErrors} />}
        <Stack distribution="fillEvenly">
          <Field
            name="visibility"
            type="radio"
            value={PROJECT_VISIBILITY_VALUE_PUBLIC}
            label={`${workspaceName} Workspace`}
            id="workspace-visibility-public"
            component={CustomRadioButtonAdapter}
            icon={<PublicLogo />}
          />
          <Field
            name="visibility"
            type="radio"
            value={PROJECT_VISIBILITY_VALUE_PRIVATE}
            label="Private"
            id="workspace-visibility-private"
            component={CustomRadioButtonAdapter}
            icon={<PrivateLogo />}
          />
        </Stack>
      </Container>
    </CreateProjectContent>
  );
};

export default Step2;
