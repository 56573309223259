import { Endpoint } from 'states/services/types';

export const METRICS_USER_OVERVIEW_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user/overview',
  isPrivate: true
};

export const METRICS_USER_OVERVIEW_V2_ENDPOINT: Endpoint = {
  url: '/metrics/:projectId/user-v2/overview',
  isPrivate: true
};
