/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Fri, Oct 1st 2021, 1:27:52 pm
 *
 * *****************************************************
 */

import { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import RelativeTime from 'features/common/views/components/RelativeTime';
import ForecastChartCard from 'features/project/views/pages/ProjectMetric/MetricCard/ForecastChartCard';
import MetricChartCardLoading from 'features/project/views/pages/ProjectMetric/MetricCardLoading/MetricChartCardLoading';
import DatePicker from 'features/project/views/pages/ProjectMetric/MetricDatePicker';
import {
  ChartContainer,
  DatePickerContainer,
  NoticeBlock,
  PageContentContainer
} from 'features/project/views/pages/ProjectMetric/MetricPage/UserAndCustomerPageGroup/AverageCustomerLifespan/styles';
import { useFetchActiveUserChartQuery, useFetchNewUserChartQuery } from 'states/services/metrics/user/active-user';
import { ActiveUserChartResponse, NewUserChartResponse } from 'states/services/metrics/user/active-user/types';
import { createTextDatePickerRange, formatDate } from 'utils/project';

const INITIAL_FORM = {
  forecast: false,
  regression: 'linear',
  frameWidth: 'six_month',
  forecastFrom: moment().startOf('day').toISOString(),
  forecastTo: moment().startOf('day').add(6, 'months').toISOString()
};

const ActiveUserPage = () => {
  const { projectId } = useParams() as { projectId: string };
  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedCurrentValue, setCommittedCurrentValue] = useState(() => {
    const last30days = {
      start: moment().subtract(30, 'day').toDate(),
      end: moment().subtract(1, 'day').toDate()
    };
    return last30days;
  });

  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedPreviousValue, setCommittedPreviousValue] = useState(() => {
    const last60days = {
      start: moment().subtract(60, 'day').toDate(),
      end: moment().subtract(31, 'day').toDate()
    };
    return last60days;
  });

  const [hasPreviousComparison, setHasPreviousComparison] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeUserForm, setActiveUserForm] = useState(INITIAL_FORM);
  const [newUserForm, setNewUserForm] = useState(INITIAL_FORM);

  const onActiveUserFormSubmit = useCallback(partialForm => {
    setActiveUserForm(prev => {
      return { ...prev, ...partialForm };
    });
  }, []);

  const onNewUserFormSubmit = useCallback(partialForm => {
    setNewUserForm(prev => {
      return { ...prev, ...partialForm };
    });
  }, []);

  /**
   * initial data before calling api
   * data chart fetching by projectId, fromDate, toDate
   */
  const activeUserChartResponse = useFetchActiveUserChartQuery(
    {
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end),
      ...activeUserForm
    },
    { refetchOnMountOrArgChange: true }
  );

  const newUserChartResponse = useFetchNewUserChartQuery(
    {
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end),
      ...newUserForm
    },
    { refetchOnMountOrArgChange: true }
  );

  const metricCalculatedAt = _.get(activeUserChartResponse, 'data.metricCalculatedAt', null);

  const activeUserLegends = useMemo(() => {
    const { forecast, forecastFrom, forecastTo } = activeUserForm;
    if (forecast) {
      return [createTextDatePickerRange(moment(forecastFrom).toDate(), moment(forecastTo).toDate())];
    }
    return [committedCurrentValue, hasPreviousComparison && committedPreviousValue].map(dateRange => {
      if (!dateRange) return '';
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    });
  }, [activeUserForm, committedCurrentValue, committedPreviousValue, hasPreviousComparison]);

  const newUserLegends = useMemo(() => {
    const { forecast, forecastFrom, forecastTo } = newUserForm;
    if (forecast) {
      return [createTextDatePickerRange(moment(forecastFrom).toDate(), moment(forecastTo).toDate())];
    }
    return [committedCurrentValue, hasPreviousComparison && committedPreviousValue].map(dateRange => {
      if (!dateRange) return '';
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    });
  }, [committedCurrentValue, committedPreviousValue, hasPreviousComparison, newUserForm]);

  const activeUserChart = (() => {
    let children = null;
    const { isLoading, isFetching, data } = activeUserChartResponse;
    if (isLoading) children = <MetricChartCardLoading hasOverview hasChart size="large" />;
    else {
      const { activeUser } = data as ActiveUserChartResponse;
      const { overview: metricOverviewData, data: metricChartData } = activeUser;
      children = (
        <ForecastChartCard
          metricName="activeUser"
          size="large"
          isFetching={isFetching}
          overviewConfig={{
            type: 'time-filter',
            forecastForm: activeUserForm,
            updateFormCallback: onActiveUserFormSubmit,
            data: metricOverviewData
          }}
          chartConfig={{
            visible: true,
            data: metricChartData,
            legends: activeUserLegends
          }}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  })();

  const newUserChart = (() => {
    let children = null;
    const { isLoading, isFetching, data } = newUserChartResponse;
    if (isLoading) children = <MetricChartCardLoading hasOverview hasChart size="large" />;
    else {
      const { newUser } = data as NewUserChartResponse;

      const { overview: metricOverviewData, data: metricChartData } = newUser;
      children = (
        <ForecastChartCard
          metricName="newUser"
          size="large"
          isFetching={isFetching}
          overviewConfig={{
            type: 'time-filter',
            forecastForm: newUserForm,
            updateFormCallback: onNewUserFormSubmit,
            data: metricOverviewData
          }}
          chartConfig={{
            visible: true,
            data: metricChartData,
            legends: newUserLegends
          }}
        />
      );
    }
    return <ChartContainer>{children}</ChartContainer>;
  })();

  return (
    <PageContentContainer>
      <DatePickerContainer>
        <DatePicker
          hasPreviousComparison={hasPreviousComparison}
          setHasPreviousComparison={setHasPreviousComparison}
          committedCurrentValue={committedCurrentValue}
          setCommittedCurrentValue={setCommittedCurrentValue}
          committedPreviousValue={committedPreviousValue}
          setCommittedPreviousValue={setCommittedPreviousValue}
        />
      </DatePickerContainer>
      {activeUserChart}
      {newUserChart}
      <NoticeBlock>
        <p>
          {activeUserChartResponse.isFetching ? (
            'Your data is loading...'
          ) : (
            <>
              Your data was last updated&nbsp;
              <RelativeTime value={metricCalculatedAt} />
            </>
          )}
        </p>
      </NoticeBlock>
    </PageContentContainer>
  );
};

export default ActiveUserPage;
