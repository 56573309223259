/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 27th 2021, 10:38:36 am
 *
 * *****************************************************
 */

import { useContext } from 'react';

import { TextStyle } from '@shopify/polaris';

import { FullScreenModalContext } from 'features/common/views/components/FullScreenModal';

import { Container } from './styles';

type Props = {
  source?: string;
  title?: string;
  description?: React.ReactNode;
  children: React.ReactNode;
};

/**
 * Inner layout for create project modal content
 */
const CreateProjectContent = ({ source, title, description, children }: Props): JSX.Element => {
  // Dialog title props for accessibility
  const { titleProps } = useContext(FullScreenModalContext);
  return (
    <Container>
      {source && <img src={source} alt="" role="presentation" />}
      <header>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {title && <h2 {...titleProps}>{title}</h2>}
        {description && <TextStyle variation="subdued">{description}</TextStyle>}
      </header>
      {children}
    </Container>
  );
};

export default CreateProjectContent;
