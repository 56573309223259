/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Tue, Jul 27th 2021, 10:38:40 am
 *
 * *****************************************************
 */

import styled from 'styled-components';

import texts from 'constants/texts';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 58.1rem;
  margin: 0 auto;

  img {
    width: 31.8rem;
    height: auto;
  }

  header {
    text-align: center;
    margin-top: 2.5rem;
    margin-bottom: 3rem;
    h2 {
      ${texts.heading['Heading 1 - 32px - B']}
      margin-bottom: 1.1rem;
    }
    p {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2rem;

      /* OVERRIDEN CSS */
      .Polaris-TextStyle--variationStrong {
        font-weight: 700;
      }
    }
  }

  @media (max-width: 38.8125em) {
    width: 100%;
  }
`;
