/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Oct 4th 2021, 3:19:58 pm
 *
 * *****************************************************
 */

import { Button, Icon, Link, Tooltip } from '@shopify/polaris';
import { CircleInformationMajor } from '@shopify/polaris-icons';
import _ from 'lodash';
import { generatePath, useRouteMatch } from 'react-router-dom';

import BASE_BLOCKS, { MetricName } from 'constants/metrics/base-blocks';
import MetricChart from 'features/project/views/pages/ProjectMetric/MetricChart';

import {
  TitleSection,
  Title,
  TooltipActivator,
  TooltipButtonContainer,
  TooltipContent,
  TooltipTitle,
  ChartContainer,
  LinkContainer
} from './styles';

export type Props = {
  metricName: MetricName;
  hideTooltip?: boolean;
  hideViewDetails?: boolean;
  data: any;
  legends: string[];
};

const AdditionalChartBlock = ({ metricName, hideTooltip = false, hideViewDetails = false, data, legends }: Props) => {
  const { title, tooltip, chartConfig, url } = BASE_BLOCKS[metricName];
  const match = useRouteMatch();
  if (!chartConfig) return null;

  const tooltipTitle = _.get(tooltip, 'title', '');
  const tooltipDescription = _.get(tooltip, 'descriptions', '');
  const tooltipURL = _.get(tooltip, 'url', '');

  const bigNumbers: number[] = (() => {
    const overviewData = _.get(data, 'overview');
    if (!overviewData) return [];
    return [overviewData.bigNumber, overviewData.previousBigNumber];
  })();

  return (
    <div>
      <TitleSection>
        <Title>{title}</Title>
        {hideTooltip || (
          <Tooltip
            content={
              <TooltipContent>
                <TooltipTitle>{tooltipTitle}</TooltipTitle>
                <p>{tooltipDescription}</p>
                <TooltipButtonContainer>
                  <Button url={tooltipURL}>Learn more</Button>
                </TooltipButtonContainer>
              </TooltipContent>
            }
            preferredPosition="above">
            <TooltipActivator>
              <Icon source={CircleInformationMajor} color="subdued" />
            </TooltipActivator>
          </Tooltip>
        )}
        {(hideViewDetails && url) || (
          <LinkContainer>
            <Link url={generatePath(url, match.params)} removeUnderline>
              View details
            </Link>
          </LinkContainer>
        )}
      </TitleSection>
      <ChartContainer size="small" type={chartConfig.type}>
        <MetricChart metricName={metricName} data={data.data} size="small" bigNumbers={bigNumbers} legends={legends} />
      </ChartContainer>
    </div>
  );
};

export default AdditionalChartBlock;
