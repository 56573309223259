// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useCallback, useEffect, useMemo, useState } from 'react';

// import _ from 'lodash';
import _ from 'lodash';
import moment from 'moment';
import { generatePath, useParams, useRouteMatch } from 'react-router-dom';

import RelativeTime from 'features/common/views/components/RelativeTime';
import MetricChartCard from 'features/project/views/pages/ProjectMetric/MetricCard/MetricChartCard';
import MetricChartCardLoading from 'features/project/views/pages/ProjectMetric/MetricCardLoading/MetricChartCardLoading';
import DatePicker from 'features/project/views/pages/ProjectMetric/MetricDatePicker';
import {
  ChartContainer,
  DatePickerContainer,
  NoticeBlock,
  PageContentContainer
} from 'features/project/views/pages/ProjectMetric/MetricPage/PlanAndLocationPages/AppPlan/styles';
import { useGetAppPlanChurnOverviewQuery, useGetAppPlanChurnQuery } from 'states/services/metrics/hooks';
import { AppPlanChurn } from 'states/services/metrics/models';
import { GetAppPlanChurnResponse } from 'states/services/metrics/types';
import { clearItems, deleteItem } from 'states/slices/metrics';
import { selectItems } from 'states/slices/metrics/selectors';
import { useAppDispatch, useAppSelector } from 'utils/hooks';
import { createTextDatePickerRange, formatCurrency, formatDate, formatPercent } from 'utils/project';

const AppPlanChurnPage = () => {
  const { projectId } = useParams() as { projectId: string };
  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedCurrentValue, setCommittedCurrentValue] = useState(() => {
    const last30days = {
      start: moment().subtract(30, 'day').toDate(),
      end: moment().subtract(1, 'day').toDate()
    };
    return last30days;
  });

  /**
   * Committed date data is one that user has submitted by clicking the Apply button
   */
  const [committedPreviousValue, setCommittedPreviousValue] = useState(() => {
    const last60days = {
      start: moment().subtract(60, 'day').toDate(),
      end: moment().subtract(31, 'day').toDate()
    };
    return last60days;
  });

  const [hasPreviousComparison, setHasPreviousComparison] = useState(true);

  const [latestSelectedOption, setLatestSelectedOption] = useState<{ option: string; action: 'ADD' | 'REMOVE' }>();

  const dispatch = useAppDispatch();
  const match = useRouteMatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      dispatch(clearItems());
    };
  }, [dispatch]);

  const { isFetching, data = { data: null, metricCalculatedAt: null } } = useGetAppPlanChurnQuery(
    {
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end)
    },
    { refetchOnMountOrArgChange: true }
  );

  const handleUpdateLatestSelectedOption = useCallback(
    (params: { option: string; action: 'ADD' | 'REMOVE' }) => {
      const { option, action } = params;
      if (action === 'REMOVE') {
        dispatch(deleteItem({ id: option }));
      }
      setLatestSelectedOption(params);
    },
    [dispatch]
  );

  const growthPlans = useAppSelector(selectItems);

  useGetAppPlanChurnOverviewQuery(
    {
      planName: _.get(latestSelectedOption, 'option', ''),
      projectId,
      hasPreviousComparison,
      fromDate: formatDate(committedCurrentValue.start),
      toDate: formatDate(committedCurrentValue.end),
      previousFromDate: formatDate(committedPreviousValue.start),
      previousToDate: formatDate(committedPreviousValue.end)
    },
    {
      refetchOnMountOrArgChange: true,
      skip: _.get(latestSelectedOption, 'action', 'REMOVE') === 'REMOVE' || _.get(latestSelectedOption, 'option', '') === ''
    }
  );

  const { metricCalculatedAt } = data as GetAppPlanChurnResponse;

  const handleUpdateCommittedCurrentValue = (params: { start: Date; end: Date; option: string }) => {
    /**
     * clear plans on redux store
     */
    // const planNames = growthPlans.map(p => p.planName);

    setLatestSelectedOption(undefined);
    setCommittedCurrentValue(params);
  };

  const chartLegends = useMemo(() => {
    return [committedCurrentValue, hasPreviousComparison && committedPreviousValue].map(dateRange => {
      if (!dateRange) return '';
      return createTextDatePickerRange(dateRange.start, dateRange.end);
    });
  }, [committedCurrentValue, committedPreviousValue, hasPreviousComparison]);

  const churnPercentageChart = useMemo(() => {
    let children = null;
    if (isFetching) {
      children = <MetricChartCardLoading hasChart hasOverview hasBreakdown size="large" />;
    } else {
      const { churnPercentageSection } = data as GetAppPlanChurnResponse;
      const { churnPercentage, breakdown } = churnPercentageSection;
      const { data: metricChartData, overview: metricOverviewData } = churnPercentage;
      const dataTableMapping = {
        planName: {
          tableIndex: 0,
          link: match.url
        },
        churns: {
          tableIndex: 1
        },
        mrr: {
          tableIndex: 2,
          valueFormatter: formatCurrency
        },
        percent: {
          tableIndex: 3,
          valueFormatter: (value: number, rowData: AppPlanChurn.BreakdownItem) => {
            if (rowData) {
              const { percent } = rowData;
              return `${formatPercent(percent / 100)}`;
            }
            return `${formatPercent(value / 100)}`;
          }
        }
      };

      const tableSortFunction = (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => {
        if (index === 1)
          return [...rows].sort((rowA, rowB) => {
            const amountA = rowA[index] as number;
            const amountB = rowB[index] as number;

            return direction === 'descending' ? amountB - amountA : amountA - amountB;
          });
        return rows;
      };
      children = (
        <MetricChartCard
          metricName="churnPercentage"
          size="medium"
          hasPreviousComparison={hasPreviousComparison}
          overviewConfig={{
            type: 'view-details',
            hideViewDetails: true,
            data: metricOverviewData
          }}
          chartConfig={{
            show: true,
            data: metricChartData,
            legends: chartLegends
          }}
          additionalTableConfig={{
            show: true,
            cardTitle: 'Breakdown',
            dataTableMapping,
            data: breakdown,
            columnContentTypes: ['text', 'numeric', 'numeric', 'numeric'],
            headings: ['App Plan', 'Customer Churn', 'MRR Lost', 'Churn Percentage'],
            totalsName: { singular: 'Summary', plural: 'Summary' },
            sortable: [false, true, false, false],
            defaultSortDirection: 'descending',
            sortFunction: tableSortFunction
          }}
        />
      );
    }

    return <ChartContainer>{children}</ChartContainer>;
  }, [chartLegends, data, hasPreviousComparison, isFetching, match]);

  const planGrowthChart = useMemo(() => {
    let children = null;
    if (isFetching) {
      children = <MetricChartCardLoading hasChart size="large" />;
    } else {
      const { plans } = data as GetAppPlanChurnResponse;
      const legends = growthPlans.map(p => p.id);
      const charts = growthPlans.map(p => p.data.planChurn);
      const customerChurns = growthPlans.map(p => p.data.customerChurn.overview);
      const mrrLost = growthPlans.map(p => p.data.mrrLost.overview);
      const churnPercentage = growthPlans.map(p => p.data.churnPercentage.overview);
      const comparisons = growthPlans.map(p => p.data.comparison);

      const combineAdditionalMetric = {
        customerChurn: {
          overview: {
            bigNumber: _(customerChurns).sumBy('bigNumber'),
            previousBigNumber: _(customerChurns).sumBy('previousBigNumber')
          }
        },
        mrrLost: {
          overview: {
            bigNumber: _(mrrLost).sumBy('bigNumber'),
            previousBigNumber: _(mrrLost).sumBy('previousBigNumber')
          }
        },
        churnPercentage: {
          overview: {
            bigNumber: _(churnPercentage).sumBy('bigNumber'),
            previousBigNumber: _(churnPercentage).sumBy('previousBigNumber')
          }
        }
      };

      const flattenTableData = _(comparisons).flatten().value();

      const combineTableData = {
        overview: {
          customerChurn: _(flattenTableData).sumBy('customerChurn'),
          mrrLost: _(flattenTableData).sumBy('mrrLost'),
          churnPercentage: _(flattenTableData).sumBy('churnPercentage')
        },
        data: flattenTableData
      };

      const tableSortFunction = (rows: any[], index: number, direction: 'ascending' | 'descending' | 'none') => {
        if ([1, 2].includes(index))
          return [...rows].sort((rowA, rowB) => {
            const amountA = rowA[index] as number;
            const amountB = rowB[index] as number;

            return direction === 'descending' ? amountB - amountA : amountA - amountB;
          });
        return rows;
      };

      const dataTableMapping = {
        planName: {
          tableIndex: 0,
          link: generatePath(match.url, match.params)
        },
        customerChurn: {
          tableIndex: 1
        },
        mrrLost: {
          tableIndex: 2,
          valueFormatter: formatCurrency
        },
        churnPercentage: {
          tableIndex: 3,
          valueFormatter: (value: number, rowData: AppPlanChurn.ComparisonItem) => {
            if (rowData) {
              return `${formatPercent(rowData.churnPercentage / 100)}`;
            }
            return `${formatPercent(value / 100)}`;
          }
        }
      };

      children = (
        <MetricChartCard
          metricName="planChurnGrowth"
          size="large"
          hasPreviousComparison={hasPreviousComparison}
          overviewConfig={{
            show: true,
            type: 'multiple-tags',
            hideMetricOverview: true,
            options: plans.map(p => ({ label: p, value: p })),
            triggerFunction: handleUpdateLatestSelectedOption
          }}
          chartConfig={{
            legends,
            show: true,
            data: charts
          }}
          additionalMetricConfig={{
            show: !!charts.length,
            data: combineAdditionalMetric,
            order: ['customerChurn', 'mrrLost', 'churnPercentage']
          }}
          additionalTableConfig={{
            show: !!charts.length,
            cardTitle: 'Comparison',
            dataTableMapping,
            data: combineTableData,
            columnContentTypes: ['text', 'numeric', 'numeric', 'numeric'],
            headings: ['App Plan', 'Customer Churn', 'MRR Lost', 'Churn Percentage'],
            totalsName: { singular: 'Summary', plural: 'Summary' },
            sortable: [false, true, false, false],
            defaultSortDirection: 'descending',
            sortFunction: tableSortFunction
          }}
        />
      );
    }

    return <ChartContainer>{children}</ChartContainer>;
  }, [data, growthPlans, handleUpdateLatestSelectedOption, hasPreviousComparison, isFetching, match]);

  return (
    <PageContentContainer>
      <DatePickerContainer>
        <DatePicker
          hasPreviousComparison={hasPreviousComparison}
          setHasPreviousComparison={setHasPreviousComparison}
          committedCurrentValue={committedCurrentValue}
          setCommittedCurrentValue={handleUpdateCommittedCurrentValue}
          committedPreviousValue={committedPreviousValue}
          setCommittedPreviousValue={setCommittedPreviousValue}
        />
      </DatePickerContainer>
      {churnPercentageChart}
      {planGrowthChart}
      <NoticeBlock>
        <p>
          {isFetching ? (
            'Your data is loading...'
          ) : (
            <>
              Your data was last updated&nbsp;
              <RelativeTime value={metricCalculatedAt} />
            </>
          )}
        </p>
      </NoticeBlock>
    </PageContentContainer>
  );
};

export default AppPlanChurnPage;
