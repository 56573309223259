/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Sat, Jul 24th 2021, 5:11:02 pm
 *
 * *****************************************************
 */

import ProjectLoading from 'features/project/views/pages/ProjectLoading';
import ProjectMetric from 'features/project/views/pages/ProjectMetric';
import ProjectSettings from 'features/project/views/pages/ProjectSettings';
import { RouteDef } from 'utils/routes';

import * as projectPaths from './paths';

/**
 * Route definitions for projects page group
 */
const projectRouteDefs: RouteDef[] = [
  {
    path: projectPaths.PROJECT_METRICS_REVENUE,
    exact: true,
    isPrivate: true,
    silentRefreshLoadingComponent: ProjectLoading,
    redirect: projectPaths.PROJECT_METRICS_REVENUE_OVERVIEW
  },
  {
    path: projectPaths.PROJECT_METRICS_INSTALL_AND_UNINSTALL,
    exact: true,
    isPrivate: true,
    silentRefreshLoadingComponent: ProjectLoading,
    redirect: projectPaths.PROJECT_METRICS_INSTALL_AND_UNINSTALL_OVERVIEW
  },
  {
    path: projectPaths.PROJECT_METRICS_USER_AND_CUSTOMER_V2,
    exact: true,
    isPrivate: true,
    silentRefreshLoadingComponent: ProjectLoading,
    redirect: projectPaths.PROJECT_METRICS_USER_AND_CUSTOMER_OVERVIEW_V2
  },
  {
    path: projectPaths.PROJECT_METRICS_USER_AND_CUSTOMER,
    exact: true,
    isPrivate: true,
    silentRefreshLoadingComponent: ProjectLoading,
    redirect: projectPaths.PROJECT_METRICS_USER_AND_CUSTOMER_OVERVIEW
  },
  {
    path: projectPaths.PROJECT_METRICS_PLAN_AND_LOCATION,
    exact: true,
    isPrivate: true,
    silentRefreshLoadingComponent: ProjectLoading,
    redirect: projectPaths.PROJECT_METRICS_PLAN_AND_LOCATION_OVERVIEW
  },
  {
    path: [
      projectPaths.PROJECT_METRICS_DASHBOARD,
      projectPaths.PROJECT_METRICS_REVENUE_OVERVIEW,
      projectPaths.PROJECT_METRICS_NET_REVENUE,
      projectPaths.PROJECT_METRICS_MRR,
      projectPaths.PROJECT_METRICS_MRR_NEW,
      projectPaths.PROJECT_METRICS_MRR_UPGRADE,
      projectPaths.PROJECT_METRICS_MRR_DOWNGRADE,
      projectPaths.PROJECT_METRICS_MRR_REACTIVATION,
      projectPaths.PROJECT_METRICS_MRR_CANCELLATION,
      projectPaths.PROJECT_METRICS_MRR_CHURN,
      projectPaths.PROJECT_METRICS_ONE_TIME_CHARGES,
      projectPaths.PROJECT_METRICS_USAGE_CHARGES,
      projectPaths.PROJECT_METRICS_ADD_ON,
      projectPaths.PROJECT_METRICS_FAILED_CHARGES,
      projectPaths.PROJECT_METRICS_REFUNDS_AND_CREDITS,
      projectPaths.PROJECT_METRICS_COSTS,
      projectPaths.PROJECT_METRICS_INSTALL_AND_UNINSTALL_OVERVIEW,
      projectPaths.PROJECT_METRICS_INSTALL,
      projectPaths.PROJECT_METRICS_REOPEN,
      projectPaths.PROJECT_METRICS_UNINSTALL,
      projectPaths.PROJECT_METRICS_CLOSE,
      projectPaths.PROJECT_METRICS_USER_AND_CUSTOMER_OVERVIEW_V2,
      projectPaths.PROJECT_METRICS_CUSTOMER_CHURN_V2,
      projectPaths.PROJECT_METRICS_ARPU_V2,
      projectPaths.PROJECT_METRICS_LTV_V2,
      projectPaths.PROJECT_METRICS_ACTIVE_CUSTOMER_V2,
      projectPaths.PROJECT_METRICS_NEW_CUSTOMER_V2,
      projectPaths.PROJECT_METRICS_CUSTOMER_UPGRADE_V2,
      projectPaths.PROJECT_METRICS_CUSTOMER_DOWNGRADE_V2,
      projectPaths.PROJECT_METRICS_CUSTOMER_CANCELLATION_V2,
      projectPaths.PROJECT_METRICS_CUSTOMER_REACTIVATION_V2,
      projectPaths.PROJECT_METRICS_CUSTOMER_GROWTH_V2,
      projectPaths.PROJECT_METRICS_CONVERSION_RATE_V2,
      projectPaths.PROJECT_METRICS_AVERAGE_CUSTOMER_LIFESPAN_V2,
      projectPaths.PROJECT_METRICS_ACTIVE_USER_V2,
      projectPaths.PROJECT_METRICS_USER_AND_CUSTOMER_OVERVIEW,
      projectPaths.PROJECT_METRICS_CUSTOMER_CHURN,
      projectPaths.PROJECT_METRICS_ARPU,
      projectPaths.PROJECT_METRICS_LTV,
      projectPaths.PROJECT_METRICS_ACTIVE_CUSTOMER,
      projectPaths.PROJECT_METRICS_NEW_CUSTOMER,
      projectPaths.PROJECT_METRICS_CUSTOMER_UPGRADE,
      projectPaths.PROJECT_METRICS_CUSTOMER_DOWNGRADE,
      projectPaths.PROJECT_METRICS_CUSTOMER_CANCELLATION,
      projectPaths.PROJECT_METRICS_CUSTOMER_REACTIVATION,
      projectPaths.PROJECT_METRICS_CUSTOMER_GROWTH,
      projectPaths.PROJECT_METRICS_CONVERSION_RATE,
      projectPaths.PROJECT_METRICS_AVERAGE_CUSTOMER_LIFESPAN,
      projectPaths.PROJECT_METRICS_ACTIVE_USER,
      projectPaths.PROJECT_METRICS_SUBSCRIPTION,
      projectPaths.PROJECT_METRICS_PLAN_AND_LOCATION_OVERVIEW,
      projectPaths.PROJECT_METRICS_APP_PLAN,
      projectPaths.PROJECT_METRICS_APP_PLAN_DETAIL,
      projectPaths.PROJECT_METRICS_APP_PLAN_CHURN,
      projectPaths.PROJECT_METRICS_APP_PLAN_CHURN_DETAIL,
      projectPaths.PROJECT_METRICS_SHOPIFY_PLAN,
      projectPaths.PROJECT_METRICS_SHOPIFY_PLAN_CHURN,
      projectPaths.PROJECT_METRICS_LOCATION,
      projectPaths.PROJECT_METRICS_LOCATION_CHURN,
      projectPaths.PROJECT_METRICS_ACTIVITIES,
      projectPaths.PROJECT_METRICS_SHOPIFY_PLAN_DETAIL,
      projectPaths.PROJECT_METRICS_SHOPIFY_PLAN_CHURN_DETAIL,
      projectPaths.PROJECT_METRICS_LOCATION_DETAIL,
      projectPaths.PROJECT_METRICS_LOCATION_CHURN_DETAIL
    ],
    exact: true,
    component: ProjectMetric,
    isPrivate: true,
    silentRefreshLoadingComponent: ProjectLoading
  },
  {
    path: [projectPaths.PROJECT_SETTINGS_INFO, projectPaths.PROJECT_METRICS_SETTINGS],
    exact: true,
    component: ProjectSettings,
    isPrivate: true,
    silentRefreshLoadingComponent: ProjectLoading
  }
];

export default projectRouteDefs;
