/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Wed, Sep 22nd 2021, 10:29:52 am
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';

export const Container = styled.div<{ isTotal: boolean }>`
  padding: 1.6rem 2rem;
  display: flex;
  align-items: center;
  ${({ isTotal }) =>
    !isTotal &&
    `
    box-shadow: inset 0px -1px 0px ${focus2Colors.divider.bottom};
  `}
`;

export const Count = styled.span`
  text-align: end;
  min-width: 2.6rem;
  ${focus2Texts.website.heading['H5 - 14px']}
  margin-right: 0.8rem;
`;

export const Name = styled.span<{ isTotal: boolean }>`
  ${({ isTotal }) =>
    isTotal
      ? `
      ${focus2Texts.website.heading['H5 - 14px']}
      `
      : `
      ${focus2Texts.website['Body 2 - 14px']}
  `}
  margin-right: 1.2rem;
`;

export const RevenueContainer = styled.div`
  margin-left: auto;
`;
