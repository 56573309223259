import { generatePath } from 'react-router-dom';

import { emptyAPI } from 'states/services';
import { buildParams } from 'states/services/metrics';
import { GetMetricRequest } from 'states/services/metrics/types';

import * as Endpoints from './endpoints';
import * as Types from './types';

const customerChurnApi = emptyAPI.injectEndpoints({
  endpoints: builder => ({
    fetchCustomerChurnChart: builder.query<Types.CustomerChurnChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_CUSTOMER_CHURN_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_CUSTOMER_CHURN_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.CustomerChurnChartResponse }) => {
        return response.data;
      }
    }),
    fetchCustomerChurnRateChart: builder.query<Types.CustomerChurnRateChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_CUSTOMER_CHURN_RATE_CHART_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_CUSTOMER_CHURN_RATE_CHART_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.CustomerChurnRateChartResponse }) => {
        return response.data;
      }
    }),
    fetchCustomerChurnBreakdown: builder.query<Types.CustomerChurnBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_CUSTOMER_CHURN_BREAKDOWN_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_CUSTOMER_CHURN_BREAKDOWN_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.CustomerChurnBreakdownResponse }) => {
        return response.data;
      }
    }),
    fetchCustomerChurnChartV2: builder.query<Types.CustomerChurnChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_CUSTOMER_CHURN_CHART_V2_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_CUSTOMER_CHURN_CHART_V2_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.CustomerChurnChartResponse }) => {
        return response.data;
      }
    }),
    fetchCustomerChurnRateChartV2: builder.query<Types.CustomerChurnRateChartResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_CUSTOMER_CHURN_RATE_CHART_V2_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_CUSTOMER_CHURN_RATE_CHART_V2_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.CustomerChurnRateChartResponse }) => {
        return response.data;
      }
    }),
    fetchCustomerChurnBreakdownV2: builder.query<Types.CustomerChurnBreakdownResponse, GetMetricRequest>({
      query: ({ projectId, ...rest }) => {
        const params = buildParams(rest);
        return `${generatePath(Endpoints.METRICS_CUSTOMER_CHURN_BREAKDOWN_V2_ENDPOINT.url, {
          projectId
        })}?${params}`;
      },
      extraOptions: {
        isPrivate: Endpoints.METRICS_CUSTOMER_CHURN_BREAKDOWN_V2_ENDPOINT.isPrivate
      },
      transformResponse: (response: { data: Types.CustomerChurnBreakdownResponse }) => {
        return response.data;
      }
    })
  })
});

export const {
  useFetchCustomerChurnBreakdownQuery,
  useFetchCustomerChurnChartQuery,
  useFetchCustomerChurnRateChartQuery,
  useFetchCustomerChurnBreakdownV2Query,
  useFetchCustomerChurnChartV2Query,
  useFetchCustomerChurnRateChartV2Query
} = customerChurnApi;
