/*
 * *****************************************************
 * Copyright (C) BoostCommerce.net
 *
 * This file is part of commercial BoostCommerce.net projects.
 *
 * This file can not be copied and/or distributed without the express
 * permission of BoostCommerce.net
 *
 * @Date:   Mon, Oct 4th 2021, 3:34:36 pm
 *
 * *****************************************************
 */

import styled from 'styled-components';

import focus2Colors from 'constants/focus-2-colors';
import focus2Texts from 'constants/focus-2-texts';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;

  & > div {
    flex: 1 1 0px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h2`
  ${focus2Texts.website.heading['H4 - 16px']}
  margin-right: 0.8rem;
`;

export const BigNumber = styled.p`
  ${focus2Texts.chart['Number - 28px']}
  margin-right: 0.4rem;
`;

export const PreviousBigNumber = styled.span`
  ${focus2Texts.website.heading['H5 - 14px']}
`;

export const Tooltip: Record<string, any> = {};

Tooltip.Content = styled.div`
  padding: 1.6rem 1.2rem;
`;

Tooltip.Description = styled.p``;

Tooltip.Title = styled.div`
  ${focus2Texts.website.heading['H4 - 16px']}
  margin-bottom: 0.4rem;
`;

Tooltip.Button = styled.div`
  margin-top: 2rem;
`;

Tooltip.Activator = styled.div`
  .Polaris-Icon {
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
  }
`;

export const Dropdown = styled.div`
  & > button {
    border: none;
    box-shadow: none;
    padding: 0;
    min-height: 1rem;
    min-width: 0;
  }
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
`;

export const InformationSection = styled.div``;

export const TimeFilterSection = styled.div<{ mode: 'small' | 'large' }>`
  ${({ mode }) => {
    if (mode === 'large') {
      return `
      display: flex;
      justify-content: end;
      @media (max-width: 64em) {
        display: none;
      }`;
    }
    return `
    margin-top: 1.2rem;
    display: none;
    @media (max-width: 64em) {
      display: block;
    }`;
  }}
`;

export const Overview: Record<string, any> = {};

Overview.Container = styled.div`
  display: flex;
  margin-top: 0.8rem;
  & > div {
    flex: 1 1 0px;
  }
`;

Overview.LeftSide = styled.div``;

Overview.LeftTop = styled.div`
  display: flex;
  align-items: baseline;
`;

Overview.LeftBottom = styled.div`
  ${focus2Texts.website['Body 2 - 14px']}
  color: ${focus2Colors.text.subdued};
`;

Overview.RightSide = styled.div``;

export const ForecastFormContainer = styled.div`
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
`;

export const Skeleton: Record<string, any> = {};

Skeleton.Container = styled.div`
  display: flex;
  margin-top: 0.8rem;
  & > div {
    flex: 1 1 0px;
  }
`;

Skeleton.LeftSide = styled.div``;

Skeleton.RightSide = styled.div`
  & > .Polaris-SkeletonDisplayText--sizeMedium {
    width: 23rem;
    max-width: 23rem;
    margin-left: auto;
  }
`;

Skeleton.LeftTop = styled.div`
  display: flex;
  gap: 0.4rem;
  justify-content: start;
  align-items: end;
  margin-bottom: 0.8rem;

  & > .Polaris-SkeletonDisplayText--sizeMedium {
    width: 20rem;
    max-width: 20rem;
    height: 2.8rem;
  }
`;

Skeleton.LeftBottom = styled.div`
  & > .Polaris-SkeletonDisplayText--sizeMedium {
    width: 11.8rem;
    height: 1.4rem;
  }
`;
